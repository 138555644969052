import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from 'aws-amplify';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { REGEX_PHONE } from "../../lib/constant";

import ConfirmCode from '../../lib/Auth/confirmCode';
import Login from '../../lib/Auth/login';
import SaveUser from '../../lib/Analytics/saveUser';
import SaveOrganization from '../../lib/Analytics/saveOrganization';
import CircularProgress from '@mui/material/CircularProgress';

const ConfirmCodeForm = (props) => {
  const classes = useStyle(props);
  let navigate = useNavigate();
  const loc = useLocation();

  const [code, setCode] = useState('');
  const [errors, setErrors] = useState({ errorName: false, errorEmail: false, errorMessage: false, errorSyntax: false });
  const [isLoading, setIsLoading] = useState(false)

  const handleCodeChange = (value) => {
    if (REGEX_PHONE.test(value)) {
      setCode(value);
    }
  }

  const handleErrors = () => {
    let error = {};
    if (code === '') {
      error.code = "Favor de ingresar el código de activación";
      errors.errorCode = true;
      setErrors(errors);
    }
    return error;
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const resendEmail = async () => {
    await Auth.resendSignUp(loc.state.email);
  };

  const goToContactUs = () => {
    navigate('/#Contacts');
  }

  const confirmCode = async (event) => {
    try {
      event.preventDefault();
      const error = handleErrors();
      if (Object.keys(error).length === 0) {
        setIsLoading(true)
        const statusCode = await ConfirmCode(loc.state.email, code);
        if (statusCode.success) {

          const login = await Login(loc.state.email, loc.state.password);
          const session = await Auth.currentSession();
          const item = {
            email: loc.state.email,
            phone: loc.state.phone,
            group: loc.state.type,
            organizationRecordKey: loc.state.key,
            subscriptionType: loc.state.subscriptionType
          };
          
            const statusOrg = await SaveOrganization(item);
            if (statusOrg.success) {
              setIsLoading(false)
              navigate('/companyInformation', {
                state: {
                  email: loc.state.email,
                  organizationRecordKey: loc.state.key,
                  subscriptionType: loc.state.subscriptionType,
                }
              });
            } 
            else {
              setIsLoading(false)
              handleAlert('error', statusOrg.error);
            }
        } 
        else {
          setIsLoading(false)
          handleAlert('error', statusCode.error);
        }
      }
      else {
        setIsLoading(false)
        for (const value in error) {
          handleAlert('error', error[value])
        }
      }
    } catch (err) {
      console.log("ERR", err);
    }
  }

  return (
    <div className={classes.containerForm}>
      {
        isLoading ?
        <CircularProgress />
        :
        <form className={classes.form}>
          <div>
            <p className={classes.paragraph}>
              Hemos enviado un correo al correo electrónico proporcionado:{' '}
              <span style={{ color: 'blue' }}>{loc.state.email}</span>.
              Recuerda revisar tu bandeja de correo no deseado.
              Por favor ingresa el código a continuación:
            </p>
            <div className={classes.codeContainer}>
              <TextField
                variant="outlined"
                name='code'
                id="code"
                required
                inputProps={{ maxLength: 6, style: { fontSize: 30, textAlign: 'center' } }}
                value={code}
                onChange={(event) => handleCodeChange(event.target.value)}
                className={classes.codeInput}
              />
            </div>
          </div>
          <button className={classes.containerButton} onClick={confirmCode}>
            <p className={classes.labelButton}>Continuar</p>
          </button>
          <div className={classes.errorsContainer}>
            <div className={classes.row}>
              <p className={classes.labelText}>¿No recibiste tu código?</p>
              <p className={classes.helpText} onClick={() => resendEmail()}>Reenviar Correo</p>
            </div>
            <div className={classes.row}>
              <p className={classes.labelText}>¿Tienes problemas?</p>
              <p className={classes.helpText} onClick={() => goToContactUs()}>Contáctanos</p>
            </div>
        </div>
        </form>
      }
      
    </div>
  );
}

const useStyle = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.625rem',
    width: '100%',
    marginBottom: '6.25rem',
    paddingTop: 50,
    paddingBottom: 50,
    width: '100%',
    minWidth: 200,
    [theme.breakpoints.down('md')]: {
      padding: 20,
      zIndex: 0,
      marginLeft: '-5%'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-10%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  paragraph: {
    ...theme.typography.mediumText,
    fontSize: 16,
    lineHeight: 2,
    marginBottom: 15,
    textAlign: 'center',
  },
  containerButton: {
    backgroundColor: theme.palette.primary.main,
    width: '14.063rem',
    height: '3.125rem',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  labelButton: {
    color: theme.palette.white.main,
    fontSize: 16
  },
  codeContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  codeInput: {
    marginBottom: '5% !important',
    fontSize: 30,
  },
  errorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 15,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
  labelText: {
    ...theme.typography.mediumText,
    fontWeight: 100,

  },
  helpText: {
    ...theme.typography.mediumText,
    marginLeft: 10,
    color: 'blue',
    cursor: 'pointer'
  },
}));

export default withSnackbar(ConfirmCodeForm);