import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const editLead = async (leads) => {
  try {
    const result = await API.put(API_ANALYTICS, '/users/saveLeads', {
      body: {
        leads
      }
    });
    return {
      success: true,
      failedItems: result.failedItems,
      succesfulItems: result.succesfulItems
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default editLead;