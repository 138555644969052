import React from 'react';
import { makeStyles } from '@mui/styles';
import Logo from '../../assets/images/Logo.svg'
import { NavLink } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ULR_FACEBOOK,ULR_INSTAGRAM } from '../../lib/constant'
const useStyle = makeStyles(theme =>({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: "11.125rem",
        background: theme.palette.primary.main,
        position:'relative',
        marginTop: 'auto',
    },
    containerLogo: {
        background: theme.palette.white.main,
        width: '50%',
        height: "11.125rem",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imgLogo: {
        height: '3.125rem',
        width: '100%',
    },
    containerMenu: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        width: '100%',
        color: theme.palette.white.main,
    },
    containerDiv: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center'
        },
    },
    linksFooters: {
        color: theme.palette.white.main,
        fontSize: 16,
        textAlign: 'left'
    },
    copyRight: {
        fontSize: 18,
    },
    marginTop:{
        marginTop: '3.25rem',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        },
    },
    socialMedia: {
        marginRight: 10,
        color: theme.palette.white.main,
        cursor: 'pointer',
        "&:visited": {
            color: theme.palette.white.main,
        },
        "&:last-child": {
            marginRight: 0,
        }
    }
}))

const Footer = () => {
    const classes = useStyle();
    const date = new Date();
  return (
    <footer className = {classes.container}>
        <div className={classes.containerMenu}>
            <div className={classes.containerDiv}>
                <p className={classes.copyRight}>Copyright © {date.getFullYear()} FlexeCorp - Todos los derechos reservados.</p>
                
                <section>
                    <a href={ULR_FACEBOOK} target = "_blank" className={classes.socialMedia}>
                        <FacebookIcon fontSize = "large" />
                    </a>
                    <a href={ULR_INSTAGRAM} target = "_blank" className={classes.socialMedia}>
                        <InstagramIcon fontSize = "large"/>
                    </a>

                </section>

            </div>
            <div className={`${classes.containerDiv} ${classes.marginTop}`}>
                <NavLink to = {'/termConditions'} className={classes.linksFooters}>Términos y condiciones</NavLink>
                <a href='#' className={classes.linksFooters} >Aviso de privacidad</a>
            </div>
        </div>
        <div className = {classes.containerLogo}>
            <img src={Logo} alt='Logo' className={classes.imgLogo}/>
        </div>
    </footer>
  )
};

export default Footer;
