const states = [ 
        { label: 'Aguascalientes', value: 1 },
        { label: 'Baja California', value: 2 },
        { label: 'Baja California Sur', value: 3 },
        { label: 'Chihuahua', value: 4 },
        { label: 'Chiapas', value: 5 },
        { label: 'Campeche', value: 6 },
        { label: 'Ciudad de México', value: 7 },
        { label: 'Coahuila de Zaragoza', value: 8 },
        { label: 'Colima', value: 9 },
        { label: 'Durango', value: 10 },
        { label: 'Guerrero', value: 11 },
        { label: 'Guanajuato', value: 12 },
        { label: 'Hidalgo', value: 13 },
        { label: 'Jalisco', value: 14 },
        { label: 'Michoacán de Ocampo', value: 15 },
        { label: 'México', value: 16 },
        { label: 'Morelos', value: 17 },
        { label: 'Nayarit', value: 18 },
        { label: 'Nuevo León', value: 19 },
        { label: 'Oaxaca', value: 20 },
        { label: 'Puebla', value: 21 },
        { label: 'Quintana Roo', value: 22 },
        { label: 'Querétaro', value: 23 },
        { label: 'Sinaloa', value: 24 },
        { label: 'San Luis Potosí', value: 25 },
        { label: 'Sonora', value: 26 },
        { label: 'Tabasco', value: 27 },
        { label: 'Tlaxcala', value: 28 },
        { label: 'Tamaulipas', value: 29 },
        { label: 'Veracruz de Ignacio de la Llave', value: 30 },
        { label: 'Yucatán', value: 31 },
        { label: 'Zacatecas', value: 32 }, 
    ]

    export default states;