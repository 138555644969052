import React from 'react';
import { makeStyles } from '@mui/styles';
import Users from '../../assets/images/icons/users.svg'

import Bag from '../../assets/images/icons/bag.svg'
import ShoppingCart from '../../assets/images/icons/shoppingCart.svg'
import CreditCards from '../../assets/images/icons/creditCards.svg'
import Support from '../../assets/images/icons/support.svg'

const useStyle = makeStyles(theme => ({
    title: {
        fontSize: '4.375rem',
        color: theme.palette.white.main,
        width: '35.938rem',

    },
    titleServices: {
        ...theme.typography.title,
        fontSize: 20

    },
    subtitle: {
        ...theme.typography.subtitle,
        color: '#5D5D60',
    },
    containerCard: {
        width: '100%',
        boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
        borderRadius: '2.313rem',
        padding: '60px 0 60px 0',
        background: theme.palette.white.main,
    },
    contextCard: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        fontSize: '3em',
        width:'100%',   
        gap: '60px'
    },
    image: {
        width: '20%',
        height: '3rem',
    },
    services: {
        ...theme.typography.serviceText
    },
    containerText: {
        marginTop: '31.25rem'
    },
    icon: {
        position: 'relative',
        top: '0.625rem'
    },
    links: {
        color: theme.palette.white.main,
        fontSize: '2rem'
    },
    context2Container: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%',
    },
    contextText: {
        width: '80%'
    },
}))
const CardServices = () => {
    const classes = useStyle();
    return (
        <div className={classes.containerCard}>

            <div className={classes.contextCard}>
                <div className={classes.context2Container}>
                    <img src={Users} alt='icono de bolsa' className={classes.image} />
                    <div className={classes.contextText}>
                        <h2 className={classes.titleServices}>Grupos demográficos</h2>
                        <p className={classes.subtitle}> (Edades, géneros, estado civil)</p>
                    </div>
                </div>
                <div className={classes.contextCard}>
                    <div className={classes.context2Container}>
                        <img src={Bag} alt='icono de bolsa' className={classes.image} />
                        <div className={classes.contextText}>
                            <h2 className={classes.titleServices}>Preferencias de compra</h2>
                            <p className={classes.subtitle}> (Cadenas, comercios, en línea)​</p>
                        </div>
                    </div>
                </div>
                <div className={classes.contextCard}>
                    <div className={classes.context2Container}>
                        <img src={ShoppingCart} alt='icono de bolsa' className={classes.image} />
                        <div className={classes.contextText}>
                            <h2 className={classes.titleServices}>Rangos de Gasto por Tipo de Compra</h2>
                            <p className={classes.subtitle}> (Alimentos, ropa, diversión)​</p>
                        </div>
                    </div>
                </div>

                <div className={classes.contextCard}>
                    <div className={classes.context2Container}>
                        <img src={CreditCards} alt='icono de bolsa' className={classes.image} />

                        <div className={classes.contextText}>
                            <h2 className={classes.titleServices}>Formas de Pago</h2>
                            <p className={classes.subtitle}> (Debito, crédito, digital)​</p>
                        </div>
                    </div>
                </div>

                <div className={classes.contextCard}>
                    <div className={classes.context2Container}>
                        <img src={Support} alt='icono de bolsa' className={classes.image} />

                        <div className={classes.contextText}>
                            <h2 className={classes.titleServices}>Sentimiento Laboral</h2>
                            <p className={classes.subtitle}>Otras vinculadas.</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default CardServices