import { Auth } from 'aws-amplify';
import CognitoErrors from './cognitoErrors';
const signup = async (email, password, phone_number, group) => {
  try {
    const user = await Auth.signUp({
      username: email,
      password: password,
      phone_number,
      attributes: {
        'custom:group': group,
      },
    });
    return {
      success: true,
      user: user,
    }
  } catch (err) {
    return {
      success: false,
      error: CognitoErrors(err.message),
    }
  }
}

export default signup;