import { API } from 'aws-amplify';
import { API_QUESTIONS } from '../constant';

const activateCategory = async (categoryId,type) => {
  const action = type ? 'activate' : 'deactivate'
  try {
    const result = await API.del(API_QUESTIONS, `/category/${categoryId}`,{
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY
        },
        body: {
          type: action
        }
    });
    return {
      success: true,
    }
  } catch (err) {
    
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default activateCategory;