import React, { useState,useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { TablePagination } from '@mui/material';
import moment from 'moment-timezone';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import getTermsAndConditions from '../../../lib/Admin/manageDocuments/getTermsandConditions';
import ModalDelete from '../../../components/surveysComponents/modals/modalQuestionDelete';
import deleteTermsandConditions from '../../../lib/Admin/manageDocuments/deleteTermsandConditions';

const ManageTyC_PP = (props) => {
  const classes = useStyle()
  const navigate = useNavigate();

  const [selectedTerms, seTSelectedTerms] = useState({id: '',version: ''})
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const [allTerms, setAllTerms] = useState([])
  
  useEffect(() => {
    getTerms()
  }, [])

  const getTerms = async() => {
    const resultTerms = await getTermsAndConditions(props.type);
    setAllTerms(resultTerms.data)
    setIsLoading(false)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleDelete = async(id) => {
    const result = await deleteTermsandConditions(id)
    if(result.success){
      setOpenModalDelete(false)
      handleAlert('success',result.message)
      getTerms();
    }
    else{
      handleAlert('error',result.message)
    }
  }

  const table = () => {
    if(allTerms.length > 0){
      const active =  allTerms.filter(item => item.active === true)
      return(
        allTerms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,index) => (
        <tr className ={classes.tr} key = {index}>
          <td className={`${classes.tableRegister} ${classes.question}`} data-label="Nombre">{props.type === 'terms' ? 'Terminos y condiciones' : 'Política de privacidad'}</td>
          <td className={classes.tableRegister} data-label="Versión">{item.version}</td>
          <td className={classes.tableRegister} data-label="Fecha de creación">{moment(item.createdAt).format('DD-MM-YYYY')}</td>
          <td className={classes.tableRegister} data-label="Activo">{item.active && <CheckCircleIcon sx = {{color: '#000000'}}/>}</td>
          <td className={classes.tableRegister} data-label="Acciones">
            <div className = {classes.actionContainer}>
              {
                !item.active &&
                <button className={classes.buttonAction} onClick = {() => (seTSelectedTerms({ id: item.termsAndConditionsRecordKey, version: item.version }),setOpenModalDelete(true))}>
                  <DeleteIcon sx = {{color: '#EE715B'}}/>
                </button>
              }
              <button className={classes.buttonAction} onClick = {() => navigate('/admin/manageTerms',{state: {type: props.type, data: item }})}>
                <EditIcon sx = {{color: '#039EDA'}}/>
              </button>   

            </div>
          </td>
        </tr>
      ))
    )
    }
    else {
      return (<td colSpan={5} className={`${classes.tableRegister}`}>Por el momento no hay Documentos</td>)
    }
    
  }

  return (
    <>
    {
        isLoading ?
        <CircularProgress/>
        :
        <>
          <div className={`${classes.rowContainer} ${classes.tableContainer}`}>
            <table className={classes.table}>
              <thead className={classes.thead}>
                <tr>
                  <th className={classes.th}>Nombre</th>
                  <th className={classes.th}>Versión</th>
                  <th className={classes.th}>Fecha de creación</th>
                  <th className={classes.th}>Activo</th>
                  <th className={classes.th}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {
                  table()
                }
              </tbody>
            </table>
            <TablePagination
              component="div"
              count={allTerms.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage='Registros por hoja'
            />
          </div>
          <ModalDelete
            open={openModalDelete}
            data={selectedTerms}
            text = {`¿Estás seguro de eliminar la versión ${selectedTerms.version} de ${props.type === 'terms' ? 'terminos y condiciones' : 'política de privacidad'}`}
            delete={() => handleDelete(selectedTerms.id)}
            handleClose={() => setOpenModalDelete(false)}
          />
        </>
    }
    </>
  )
}

export default withSnackbar(ManageTyC_PP)

const useStyle = makeStyles(theme => ({
  rowContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '20px',
    // overflowX: 'auto',
    margin: '30px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      padding: 'none',
    },
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  table: {
    borderCollapse: 'collapse'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px !important',
    gap: 15,
    [theme.breakpoints.down('md')]: {
      flexDirection:'column'
    },
  },
  inputs: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  thead: {
    borderBottom:  `1px solid ${theme.palette.lightGray.main}`,
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
      borderBottom: 'none',
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    width: '25%',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    fontSize: '14px !important',
    textAlign: 'center',
    width: '25%',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.lightGray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'justify',
      width: '80%',
        '&::before': {
            display:'flex',
            justifyContent: 'space-between',
            content: 'attr(data-label)',
            fontWeight: 'bold',
            textTransform: 'uppercase'
        },
        '&:last-child':{
            borderBottom: 'none'
        }
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 15
  },
  buttonAction:{
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center'
  },
  tr: {
    borderBottom:  `1px solid ${theme.palette.lightGray.main}`,
    [theme.breakpoints.down('md')]: {
      borderBottom: 'none',
      backgroundColor: theme.palette.white.main
    },
  },
  question: {
    textAlign: 'justify'
  }
}))