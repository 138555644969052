import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import TabPanelComponent from '../../../components/TabPanel/TabPanel';
import ManageTyC_PP from './manageTyC_PP';
import PrimaryButton from '../../../components/primaryButton';

const ManageDocuments = () => {
    const classes = useStyle()
    const navigate = useNavigate();
    const [type, setType] = useState('terms')

    const handleType = (index) => {
      setType(index === 0 ? 'terms' : 'privacity')
    }

  return (
    <main className={classes.containerBackground}>
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>Documentos</h1>
        <PrimaryButton width = "15%" handleClick = {() => navigate('/admin/manageTerms',{state: {type}})} text = "Nuevo" color = "#000000" />
      </div>
      <TabPanelComponent
        tabs = {['Términos y Condiciones','Aviso de Privacidad']}
        panels = {[
          <ManageTyC_PP type = "terms"/>,
          <ManageTyC_PP type = "privacity"/>,
        ]}
        selected = {handleType}
      />
    </main>
  )
}

export default ManageDocuments;

const useStyle = makeStyles(theme => ({
    containerBackground: {
      ...theme.globals.containerBackground
    },
    title: {
      fontSize: '62px',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        textAlign: 'center',
        fontSize: '40px',
      },
    },
    titleContainer: {
      display: 'flex',
      width: '80%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
    },
  }))