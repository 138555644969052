import React from 'react';
import { makeStyles } from '@mui/styles';
import Background from '../../../assets/images/backgroundComingSoon.svg';
import ComingSoonIcon from '../../../assets/images/icons/ComingSoon.svg';

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.palette.primary.main
  },
  title: {
    ...theme.typography.title,
    color: theme.palette.white.main,
    fontSize: 80,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  subtitle: {
    ...theme.typography.mediumText,
    color: theme.palette.white.main,
    fontSize: 22,
    fontWeight: '400',
    textAlign: 'justify',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    maxWidth: 1200,
    marginTop: 20
  },
  imgIcon: {
    width: '20%'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    // marginBottom: '10%',
    [theme.breakpoints.down('md')]: {
      margin: '0 10%',
    },
  },
  socialMediaIcon: {
    background: theme.palette.white.main,
    borderRadius: 5,
    padding: '20%',
    width: '60%',
  },
  lastTitle: {
      ...theme.typography.mediumText,
      color: theme.palette.white.main,
      fontSize: 22,
      fontWeight: '400',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
          fontSize: 16,
      },
      marginTop: 20,
  },
}))

const WaitForContact = () => {
  const classes = useStyle();
  return (
    <div className={classes.containerBackground}>
      <div className={classes.textContainer}>
        <img src={ComingSoonIcon} alt="coming soon icon" className={classes.imgIcon} />
        <h1 className={classes.title}>Empresas – Premium</h1>
        <h5 className={classes.subtitle}>Gracias por registrarte y compartirnos la información de tu empresa y su representante legal. Estaremos contactándote en los próximos días, con una propuesta de nuestros servicios que sea adecuada para ayudarte a conocer mejor tu mercado por medio de reportes analíticos personalizados. </h5>
        <h5 className={classes.lastTitle}>Saludos cordiales, </h5>
        <h5 className={classes.subtitle}>Flexeanalytics</h5>
      </div>
    </div>
  )
}

export default WaitForContact;