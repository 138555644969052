import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { useNavigate, useLocation } from "react-router-dom";

import PrimaryButton from '../../../components/primaryButton';
import { CheckBox } from '../../../components/checkBox';
import Background from '../../../assets/images/backgroundGrayyBottom.svg'
import { TextField } from '@mui/material';
import { GENDERS } from '../../../lib/constant';
import States from '../../../lib/location/states';
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import EditOrganization from '../../../lib/Analytics/editOrganization';

const useStyle = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      margin: 0,
      backgroundPosition: 'center',
      alignItems: 'center',
    },
    containerForm: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      backgroundColor: theme.palette.white.main,
      borderRadius: '0.625rem',
      boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
      width: '70%',
      marginBottom: '6.25rem',
      paddingTop: 50,
      paddingBottom: 20,
      [theme.breakpoints.down('md')]: {
        padding: 20,
        zIndex: 0,
      },
      [theme.breakpoints.down('sm')]: {
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
      gap: '10px',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
    },
    inputs: {
      width: '100%',
      marginTop: '5% !important'
    },
    containerFormSection: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        zIndex: 0,
      },
    },
    containerForm1: {
      marginRight: '5%',
      [theme.breakpoints.down('md')]: {
        marginRight: 0
      },
      '&:last-child' : {
        marginRight: 0,
      }
    },
    containerSection: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
      gap: '5%',
    },
    containerAge : {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'space-between',
      gap: '10%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      },
    },
    subtitle: {
      marginBottom: '3%'
    },
    title: {
      ...theme.typography.title,
      fontSize: 60,
      color: theme.palette.primary.main,
      lineHeight: 1,
      margin: '5% 0',
      [theme.breakpoints.down('sm')]: {
          fontSize: 30,
          width: '90%',
          textAlign: 'center',
      },
  },
}));

const CompanyDataType = (props) => {
  const classes = useStyle(props);
  let navigate = useNavigate();
  const loc = useLocation();

  const [category, setCategory] = useState(
    {
      Agricultural: false,
      Industrial: false,
      Services: false,
      Wholesalers: false,
      Retail: false,
      Financial: false,
      Technology: false,
      Academic: false,
      Transport: false,
      Mining: false,
      Entertainment: false,
      News: false,
      Shopping: false,
      Games: false,
      Other: '',
    }
  )

  //Demografico
  const [minAge, setMinAge] = useState(18)
  const [maxAge, setMaxAge] = useState(18)
  const [gender, setGender] = useState({selectGender: '', allGender: false})
  const [state, setState] = useState({selectState: '',allState: false})

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  }

  const handleErrors = () => {
    let error = {};
    const options = Object.entries(category)
    const emptyOptions = options.some(([key, value]) => value === true)

    if (minAge === '') {
      error.minAge = 'Favor de ingresar la Edad Mínima';
    }

    if (maxAge === '') {
      error.maxAge = 'Favor de ingresar el Edad Máxima';
    }

    if (gender.selectGender === '' && !gender.allGender) {
      error.gender = 'Favor de seleccionar un Género';
    }

    if (state.selectState === '' && !state.allState) {
      error.state = 'Favor de seleccionar un Estado';
    }

    if (maxAge < 18 || minAge < 18) {
      error.ageLimit = 'Favor de ingresar edades mayores a 18';
    }

    if (maxAge < minAge ) {
      error.rangeAge = 'La edad mínima debe ser inferior a la edad máxima';
    }
    
    if(!emptyOptions && category.Other === ''){
      error.options = 'Favor de selecionar un sector, servicio y/o uso de aplicacion';
    }

    return error;
  }

  const saveDataType = async(event) => {
    try {
      event.preventDefault();
      const error = handleErrors()

      if(Object.keys(error).length === 0){

        const item = {
          dataTypeCategory: {
            category: category,
            MinAge: minAge,
            MaxAge: maxAge,
            AllGender: gender.allGender,
            Gender: gender.selectGender,
            AllState: state.allState,
            State: state.selectState
          }
        }

        const statusOrg = await EditOrganization(loc.state.organizationRecordKey, item);

        if (statusOrg.success) {
            if(loc.state.subscriptionType === 'standard') {
            navigate('/comingSoon');
            }
            else{
              navigate('/Success');
            }
        } else {
          handleAlert('error', statusOrg.error);
        }

      }
      else{
        for (const value in error) {
          handleAlert('error', error[value]);
        }
      }
    }
    catch(error) {

    }
  }

  const handleOnChange = (event) => {
    const { name, value } = event.target

    switch (name) {
      case 'agricultural':
        setCategory({...category,Agricultural: !category.Agricultural})
      break;

      case 'industrial':
        setCategory({...category,Industrial: !category.Industrial})
      break;

      case 'services':
        setCategory({...category,Services: !category.Services})
      break;

      case 'wholesalers':
        setCategory({...category,Wholesalers: !category.Wholesalers})
      break;

      case 'retail':
        setCategory({...category,Retail: !category.Retail})
      break;

      case 'others':
        setCategory({...category,Other: value})
      break;

      case 'financial':
        setCategory({...category,Financial: !category.Financial})
      break;

      case 'technology':
        setCategory({...category,Technology: !category.Technology})
      break;

      case 'academic':
        setCategory({...category,Academic: !category.Academic})
      break;

      case 'transport':
        setCategory({...category,Transport: !category.Transport})
      break;

      case 'mining':
        setCategory({...category,Mining: !category.Mining})
      break;

      case 'entertainment':
        setCategory({...category,Entertainment: !category.Entertainment})
      break;

      case 'news':
        setCategory({...category,News: !category.News})
      break;

      case 'shopping':
        setCategory({...category,Shopping: !category.Shopping})
      break;
      
      case 'games':
        setCategory({...category,Games: !category.Games})
      break;

      case 'minimumAge':
        setMinAge(parseInt(value))
      break;

      case 'maximumAge':
        setMaxAge(parseInt(value))
      break;

      case 'gender':
        const genderSelect = event.currentTarget === null ? {...gender,selectGender: value} : {...gender,allGender: !gender.allGender}
        setGender(genderSelect)
      break;

      case 'states':
        const stateSelect = event.currentTarget === null ? {...state, selectState: value} : {...state, allState: !state.allState}
        setState(stateSelect)
      break;
    }
  }

  return (
  <main className = {classes.container}>
    <div>
      <h2 className={classes.title}>
        ¿Que datos te interesan?
      </h2>
    </div>
    <div className = {classes.containerForm}>
      <form className = {classes.form}>
        <div className = {classes.containerFormSection}>
          <section className = {classes.containerForm1}>
            <h2 className={classes.subtitle}>Sectores Económicos</h2>
            <CheckBox
              label = "agricultural"
              description = "Agropecuario"
              check = {category.Agricultural}
              name = "agricultural"
              onChange = {handleOnChange}
            />

            <CheckBox
              label = "industrial"
              description = "Industrial"
              check = {category.Industrial}
              name = "industrial"
              onChange = {handleOnChange}
            />

            <CheckBox
              label = "services"
              description = "Servicios"
              check = {category.Services}
              name = "services"
              onChange = {handleOnChange}
            />

            <CheckBox
              label = "wholesalers"
              description = "Comercios Mayoristas"
              check = {category.Wholesalers}
              name = "wholesalers"
              onChange = {handleOnChange}
            />

            <CheckBox
              label = "retail"
              description = "Comercios Minoristas"
              check = {category.Retail}
              name = "retail"
              onChange = {handleOnChange}
            />

            <TextField
              label="Otros"
              variant="outlined"
              name='others'
              value={category.Other}
              onChange = {handleOnChange}
              className = {classes.inputs}
            />
          </section>

          <section className = {classes.containerForm1}>
            <h2 className={classes.subtitle}>Servicios</h2>

            <CheckBox
              label = "financial"
              description = "Financieros"
              check = {category.Financial}
              name = "financial"
              onChange = {handleOnChange}
            />

            <CheckBox
              label = "technology"
              description = "Tecnología"
              check = {category.Technology}
              name = "technology"
              onChange = {handleOnChange}
            />

            <CheckBox
              label = "academic"
              description = "Académico"
              check = {category.Academic}
              name = "academic"
              onChange = {handleOnChange}
            />

            <CheckBox
              label = "transport"
              description = "Transporte"
              check = {category.Transport}
              name = "transport"
              onChange = {handleOnChange}
            />

            <CheckBox
              label = "mining"
              description = "Minería"
              check = {category.Mining}
              name = "mining"
              onChange = {handleOnChange}

            />
          </section>

          <section className = {classes.containerForm1}>
            <h2 className={classes.subtitle}>Uso de Aplicaciones</h2>

            <CheckBox
              label = "entertainment"
              description = "Entretenimiento"
              check = {category.Entertainment}
              name = "entertainment"
              onChange = {handleOnChange}
            />

            <CheckBox
              label = "news"
              description = "Noticias"
              check = {category.News}
              name = "news"
              onChange = {handleOnChange}
            />

            <CheckBox
              label = "shopping"
              description = "Compras"
              check = {category.Shopping}
              name = "shopping"
              onChange = {handleOnChange}
            />

            <CheckBox
              description = "Juegos"
              label = "games"
              check = {category.Games}
              name = "games"
              onChange = {handleOnChange}
            />

          </section>
        </div>

        <div>
          <h2 className={classes.subtitle}>Datos Demográficos</h2>
          <div className={classes.containerSection}>

            <section >
              <h3 className={classes.subtitle}>Edad</h3>
              <div className={classes.containerAge}>

                <TextField
                  label="Edad Mínima"
                  variant="outlined"
                  required
                  type="number"
                  name = "minimumAge"
                  inputProps={{ min:18, max: 90 }}
                  value={minAge}
                  onChange={handleOnChange}
                  className={classes.inputs}
                />

                <TextField
                  label="Edad Máxima"
                  variant="outlined"
                  required
                  type="number"
                  name = "maximumAge"
                  inputProps={{ min:18, max: 90 }}
                  value={maxAge}
                  onChange={handleOnChange}
                  className={classes.inputs}
                />
              </div>
            </section>

            <section>
              <h3 className={classes.subtitle}>Género</h3>

                <CheckBox
                  description = "Todos los Géneros"
                  label = "allGenders"
                  check = {gender.allGender}
                  name = "gender"
                  onChange = {handleOnChange}
                />

                <FormControl className={classes.inputs}>
                  <InputLabel id='gender-select-label'>Género</InputLabel>
                  <Select
                    labelId='gender-select-label'
                    id='gender-select'
                    label='Género'
                    variant='outlined'
                    value={gender.selectGender}
                    name='gender'
                    onChange={handleOnChange}
                    className={classes.selectContainer}
                  >
                    {GENDERS.map(item => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

            </section>

            <section>
              <h3 className={classes.subtitle}>Estado</h3>

                <CheckBox
                    description = "Todos los Estados"
                    label = "allState"
                    check = {state.allState}
                    name = "states"
                    onChange = {handleOnChange}
                />

                <FormControl className={classes.inputs} fullWidth>
                  <InputLabel id='state-select-label'>Estado</InputLabel>
                  <Select
                    labelId='state-select-label'
                    id='state-select'
                    label='Estado'
                    variant='outlined'
                    value={state.selectState}
                    name="states"
                    onChange={handleOnChange}
                  >
                    {
                      States.map(items => (
                        <MenuItem value={items.label}>{items.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>

            </section>
          </div>

        </div>

        <PrimaryButton handleClick = {saveDataType} text = "Continuar" color = "#000000"/>

      </form>
    </div>
  </main>
)
}
export default withSnackbar(CompanyDataType);