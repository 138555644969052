import { API } from 'aws-amplify';
import { API_QUESTIONS } from '../constant';

const saveQuestion = async (questionText,questionType,options = [],category) => {
  const body = {
    questionText,
    questionType,
    options,
    category
  }
  
  try {
    const result = await API.post(API_QUESTIONS, '/',{
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY
        },
        body
    });
    
    return {
      success: true,
      data: result.data,
    }
  } catch (err) {
    debugger
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default saveQuestion;