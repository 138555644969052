import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import { withSnackbar } from 'notistack';
import Background from '../../../assets/images/backgroundGrayyBottom.svg';
import PrimaryButton from '../../../components/primaryButton';
import GetOrganizations from '../../../lib/Admin/getOrganizations';
import StampBill from '../../../lib/Billing/stampBill';
import MoneyFormat from '../../../lib/moneyFormat';

const BillingScreen = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [rfc, setRfc] = useState('');
  const [subtotal, setSubtotal] = useState('0.00');
  const [commission, setCommission] = useState('0.00');
  const [iva, setIva] = useState('0.00');
  const [total, setTotal] = useState('0.00');
  const [disableEditSubtotal, setDisableEditSubtotal] = useState(true);
  const [showWarningText, setShowWarningText] = useState(false);

  useEffect(() => {
    getOrganizations();
  }, []);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getOrganizations = async () => {
    try {
      const response = await GetOrganizations();
      if (response.success && response.organizations.length > 0) {
        const approvedOrgs = response.organizations.filter(org => org.approval === 'approved').map((org) => ({
          organizationRecordKey: org.organizationRecordKey,
          label: org.name,
          rfc: org.rfc,
          amount: org.limitToPay,
        }));
        setOrganizations(approvedOrgs);
        setLoading(false);
      } else {
        setLoading(false);
        handleAlert('error', 'Algo salió mal');
      }
    } catch (err) {
      setLoading(false);
      handleAlert('error', 'Algo salió mal');
    }
  } 

  const handleSelectOrganization = (organization) => {
    if (organization !== null) {
      setSelectedOrg(organization);
      if (organization.amount) {
        setShowWarningText(false);
        handleAmountChange(organization.amount.toFixed(2));
      } else {
        setShowWarningText(true);
        handleAmountChange('0.00');
      }
      setRfc(organization.rfc);
    } else {
      setSelectedOrg(null);
      setRfc('');
      setShowWarningText(false);
      handleAmountChange('0.00');
    }
    setDisableEditSubtotal(true);
  }

  const handleAmountChange = (amount) => {
    if (!isNaN(amount)) {
      const newAmount = amount.replace(/(\.\d{2})\d+/g, '$1');
      const newCommission = (Number(newAmount) * 0.10).toFixed(2);
      const newIva = ((Number(newAmount) + Number(newCommission)) * 0.16).toFixed(2);
      const newTotal = (Number(newAmount) + Number(newCommission) + Number(newIva)).toFixed(2);
      setSubtotal(newAmount);
      setCommission(newCommission);
      setIva(newIva);
      setTotal(newTotal);
    }
  }

  const handleEditIconClick = () => {
    setDisableEditSubtotal(!disableEditSubtotal);
  }

  const checkForErrors = () => {
    if (selectedOrg === null) {
      handleAlert('warning', 'Selecciona una empresa');
      return true;
    }
    if (Number(subtotal) === 0) {
      handleAlert('warning', 'Ingresa un monto');
      return true;
    }
    return false;
  }

  const stamp = async () => {
    try {
      setLoading(true);
      if (checkForErrors()) {
        setLoading(false);
        return;
      }
      const organization = {
        organizationRecordKey: selectedOrg.organizationRecordKey,
        nameReceiver: selectedOrg.label,
        rfcReceiver: selectedOrg.rfc,
      }
      const response = await StampBill(organization, Number(subtotal));
      if (response.success) {
        handleAlert('success', 'Factura timbrada con éxito');
      } else {
        handleAlert('error', response.message);
      }
      handleSelectOrganization(null);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleAlert('error', 'Algo salió mal');
    }
  }

  if (loading) {
    return (
      <div className={classes.emptyContainer}>
        <CircularProgress color="inherit" />
      </div>
    )
  }

  return (
    <div className={classes.containerBackground}>
      <p className={classes.title}>
        Facturación
      </p>
      <p className={classes.subtitle}>
        Ingresa los datos necesarios, para generar un CFDI y enviarlo a timbrar
      </p>
      <div className={classes.dataContainer}>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid xs={12} md={6} item>
            <p className={classes.label}>Datos Receptor</p>
            <Autocomplete 
              disablePortal
              className={classes.searchInput}
              id="organizations-search-input"
              options={organizations}
              renderInput={(params) => (<TextField {...params} label="Empresa" />)}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.organizationRecordKey}>
                    {option.label}
                  </li>
                )
              }}
              onChange={(event, selectedOption) => {handleSelectOrganization(selectedOption)}}
            />
            <TextField
              label="RFC Receptor"
              value={rfc}
              className={classes.input}
              disabled
            />
            {showWarningText && (<p className={classes.warningText}>*La empresa seleccionada aún no tiene subastas para la dispersión</p>)}
          </Grid>
          <Grid xs={12} md={6} item>
            <div className={classes.row}>
              <p className={classes.label}>Montos</p>
              <EditIcon className={classes.icon} fontSize='medium' color='info' onClick={() => handleEditIconClick()}/>
            </div>
            <TextField
              label="Subtotal"
              className={classes.input}
              placeholder="0.00"
              value={disableEditSubtotal ? MoneyFormat(subtotal) : subtotal}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={(event) => {handleAmountChange(event.target.value)}}
              disabled={disableEditSubtotal}
            />
            <TextField
              label="Comisión"
              className={classes.input}
              placeholder="0.00"
              value={MoneyFormat(commission)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              disabled
            />
            <TextField
              label="IVA"
              className={classes.input}
              placeholder="0.00"
              value={MoneyFormat(iva)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              disabled
            />
            <TextField
              label="Total"
              className={classes.input}
              placeholder="0.00"
              value={MoneyFormat(total)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item className={classes.buttonContainer}>
            <PrimaryButton
              text="Generar Factura"
              color="#000000"
              handleClick={stamp}
            />
        </Grid>
      </div>
    </div>
  )
}

export default withSnackbar(BillingScreen);

const useStyles = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  title: {
    margin: '2%  0 0 0',
    fontSize: '62px',
    width: '80% ',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  subtitle: {
    margin: '0 0 2% 0',
    fontSize: '40px',
    width: '80% ',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  label: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    marginLeft: '10px',
    marginTop: '4px',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '30px',
    margin: '20px',
  },
  input: {
    width: '100%',
    marginTop: '15px !important',
    marginBottom: '15px !important',
  },
  searchInput: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    verticalAlign: 'top',
    width: '100%',
    marginTop: '15px !important',
    marginBottom: '15px !important',
  },
  warningText: {
    color: theme.palette.red.main,
    textAlign: 'center',
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    paddingLeft: '30%',
    paddingRight: '30%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
}));