import React, { useState, createRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Background from '../../assets/images/backgroundGrayyBottom.svg';
import moment from 'moment-timezone';
import getLegalDoc from '../../lib/legalDocs/getLegalDocNoAuth'

import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
const rem = 16;
const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  titleAndCardsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    margin: '5%  0 10% 0',
    backgroundColor: theme.palette.white.main,
    width: '80%',

    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
    overflowX: 'auto',
    margin: '0 auto',
    marginTop: 40,
    marginBottom: 10,
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  document: {
    width: '100%'
  },

  closeHeader: {
    fontSize: 2.25 * rem,
    paddingTop: '50px',
    paddingBottom: '50px',
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

const ReadLegalDoc = (props) => {
  const classes = useStyle();
  const today = moment().format('YYYY-MM-DD')
  const [loading, setLoading] = useState(true);
  const [previewPdf, setPreviewPdf] = useState(null);
  const [numPages, setNumPages] = useState(null);
  // const divElement = createRef()
  const [divElement, setDivElemnt] = useState(createRef());
  const canvasRef = createRef();
  const [pageStyle, setPageStyle] = useState({
    width: divElement?.current?.clientWidth,
    height: 'auto'
  })

  useEffect(() => {
    getDocument();
  }, [])

  const getDocument = async () => {
    const response = await getLegalDoc(props.documentName);
    setPreviewPdf(response.url)
    setLoading(false)
  }


  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <div className={classes.containerBackground}>
      <div ref={divElement} onClick ={() => canvasRef.current.width = divElement?.current?.clientWidth} className={classes.titleAndCardsContainer}>
        {loading
          ? <div></div>
          : 
          <>
            <div className={classes.closeHeader}>
              {props.title}
            </div>
            <Document file={previewPdf} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page width={divElement?.current?.clientWidth} key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
        </>
        } 
      </div>
    </div>
  );
}

export default ReadLegalDoc;