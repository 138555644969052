import React from 'react'
import { makeStyles } from '@mui/styles';
import Background from '../../../assets/images/backgroundComingSoon.svg'
import ComingSoonIcon from '../../../assets/images/icons/ComingSoon.svg'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ULR_FACEBOOK,ULR_INSTAGRAM } from  '../../../lib/constant'


const useStyle = makeStyles(theme => ({
    containerBackground: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        margin: 0,
        backgroundPosition: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: theme.palette.primary.main
    },
    title: {
        ...theme.typography.title,
        color: theme.palette.white.main,
        fontSize: 80,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 40,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
        },
    },
    subtitle: {
        ...theme.typography.mediumText,
        color: theme.palette.white.main,
        fontSize: 22,
        fontWeight: '400',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
        },
    },
    imgIcon: {
        width: '20%'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10%',
        [theme.breakpoints.down('md')]: {
            margin: '0 10%',
        },
    },
    socialMediaContainer: {
        display: 'flex',
        width: '30%',
        justifyContent: 'space-around',
        marginTop: '3%'
    },
    socialMediaIcon: {
        background: theme.palette.white.main,
        borderRadius: 5,
        padding: '50%',
        color: theme.palette.primary.main
    }
}))

const ComingSoon = () => {
    const classes = useStyle();
  return (
    <div className={classes.containerBackground}>
        <div className={classes.textContainer}>
            <img src={ComingSoonIcon} alt ="coming soon icon" className={classes.imgIcon}/>
            <h1 className={classes.title}>Próximamente...</h1>
            <h5 className={classes.subtitle}>Seguimos trabajando para brindarte la mejor experiencia.</h5>
            <div className={classes.socialMediaContainer}>
                <a href={ULR_INSTAGRAM} target="_blank">
                    <InstagramIcon className={classes.socialMediaIcon} fontSize	 = "large" />
                </a>
                <a href={ULR_FACEBOOK} target="_blank">
                    <FacebookIcon className={classes.socialMediaIcon} fontSize	 = "large" />
                </a>
            </div>
        </div>
    </div>
  )
}

export default ComingSoon