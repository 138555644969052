import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { REGEX_EMAIL } from "../../lib/constant";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Login from '../../lib/Auth/login';
import PasswordInput from '../passwordInput';
import getUser from '../../lib/User/getUser';
import PrimaryButton from '../primaryButton';

const UserLoginForm = (props) => {
  const classes = useStyle(props);
  let navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ errorName: false, errorEmail: false, errorMessage: false, errorSyntax: false });
  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  const handleErrors = () => {
    let error = {};
    if (email === '') {
      error.email = "Favor de ingresar un correo electrónico";
      errors.errorEmail = true;
      setErrors(errors);
    } else if (!REGEX_EMAIL.test(email)) {
      error.emailSyntax = "Favor de ingresar un correo electrónico válido";
      errors.emailSyntax = true;
      setErrors(errors);
    } else if (password === '') {
      error.password = "Favor de ingresar una contraseña";
      errors.errorPassword = true;
      setErrors(errors);
    }
    return error;
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const login = async (event) => {
    event.preventDefault();
    const error = handleErrors();
    if (Object.keys(error).length === 0) {
      const status = await Login(email, password);

      if (status.success) {
        const user = await getUser(status.user.attributes.sub)
        status.user.attributes['acceptAnalyticsManagesData'] = user.data.acceptAnalyticsManagesData
                
        setCookie('user', status.user.attributes, { path: '/' });
        navigate('/',{state: {phone: user.data.phone}});
      } else {
        handleAlert('error', status.error);
      }
    }
    else {
      for (const value in error) {
        handleAlert('error', error[value])
      }
    }
  }

  return (
    <div className={classes.containerForm}>
      <form className={classes.form}>
        <div>
          <TextField
            label="Correo Electrónico"
            variant="outlined"
            name='email'
            required
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className={classes.inputs}
          />
          <PasswordInput
            label = "Contraseña"
            name = "password"
            value = {password}
            handlerPassword = {(event) => setPassword(event.target.value)}
          />
        </div>

        <PrimaryButton handleClick = {login} text = "Login" color = "#000000"/>
      </form>
    </div>
  );
}

const useStyle = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.625rem',
    width: '100%',
    padding: '50px 0',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 20,
      zIndex: 0,
      marginLeft: '-5%'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-10%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  inputs: {
    width: '100%',
    marginBottom: '10% !important'
  },
}));

export default withSnackbar(UserLoginForm);