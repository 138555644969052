import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Background from '../../../assets/images/backgroundGrayyBottom.svg';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
} from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import GetQuestionnarie from '../../../lib/Analytics/getQuestionnarie';
import GetYears from '../../../lib/years';
import {
  MONTHS,
  INTERVAL_TYPES,
} from '../../../lib/constant';

const filterObj = {
  interval: { active: false, value: null },
  month: { active: false, value: null },
  year: { active: false, value: null },
}
const YEARS = GetYears();

const DownloadAnalytics = (props) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [intervals, setIntervals] = useState([]);
  const [shownIntervals, setShownIntervals] = useState([]);
  const [filters, setFilters] = useState(filterObj);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getIntervals();
  }, []);

  const getIntervals = async () => {
    const { companyId } = params;
    const result = await GetQuestionnarie(companyId);
    if (result.success) {
      result.data.sort((a, b) => b.endTimestamp - a.endTimestamp);
      result.data.forEach(item => {
        item.month = item.endDate.substring(3, 5);
        item.year = item.endDate.substring(6);
      });
      setIntervals(result.data);
      setShownIntervals(result.data);
    } else if (result.error === 'No intervals found') {
      handleAlert('error', 'No existe analítica para esta empresa');
      setIntervals([]);
    } else {
      handleAlert('error', 'Algo salio mal');
    }
    setLoading(false);
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getIntervalType = (type) => {
    if (type === 'weekly') return 'Semanal';
    else if (type === 'biweekly') return 'Quincenal';
    else return 'Mensual';
  }

  const handleFilterChange = (type, option) => {
    const newFilters = {...filters};
    if (option === null) {
      newFilters[type]['active'] = false;
      newFilters[type]['value'] = null;
    } else {
      newFilters[type]['active'] = true;
      newFilters[type]['value'] = option.value;
    }
    const intervalsToFilter = [...intervals];
    const filteredIntervals = intervalsToFilter.filter((interval) => {
      if (newFilters['interval']['active'] && newFilters['interval']['value'] !== interval.type) return false;
      if (newFilters['month']['active'] && newFilters['month']['value'] !== interval.month) return false;
      if (newFilters['year']['active'] && newFilters['year']['value'] !== interval.year) return false;
      return true;
    });
    setFilters(newFilters);
    setShownIntervals(filteredIntervals);
  }

  const navigateToCharts = (item) => {
    navigate('/charts', {
      state: {
        interval: item.intervalRecordKey,
      },
    });
  }

  const table = () => {
    return (
      shownIntervals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
        <TableRow key={item.intervalRecordKey}>
          <TableCell>
            {`Reporte ${item.endDate}`}
          </TableCell>
          <TableCell>
            {getIntervalType(item.type)}
          </TableCell>
          <TableCell>
            {MONTHS[Number(item.month) - 1].label}
          </TableCell>
          <TableCell>
            {item.year}
          </TableCell>
          <TableCell>
            <EqualizerIcon className={classes.icon} fontSize='large' color='info' onClick={() => navigateToCharts(item)} />
          </TableCell>
        </TableRow>
      ))
    );
  }

  if (loading) {
    return (
      <div className={classes.emptyContainer}>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <div className={classes.containerBackground}>
      <h1 className={classes.title}>
        Analítica de usuarios
      </h1>
      <p className={classes.subtitle}>
        Visualiza tus reportes de analítica
      </p>
      <div className={classes.filtersContainer}>
        <Autocomplete
          disablePortal
          className={classes.autocomplete}
          id="type-input"
          options={INTERVAL_TYPES}
          renderInput={(params) => (<TextField {...params} label="Intervalo" />)}
          onChange={(event, selectedOption) => handleFilterChange('interval', selectedOption)}
        />
        <Autocomplete
          disablePortal
          className={classes.autocomplete}
          id="type-input"
          options={MONTHS}
          renderInput={(params) => (<TextField {...params} label="Mes" />)}
          onChange={(event, selectedOption) => handleFilterChange('month', selectedOption)}
        />
        <Autocomplete
          disablePortal
          className={classes.autocomplete}
          id="type-input"
          options={YEARS}
          renderInput={(params) => (<TextField {...params} label="Año" />)}
          onChange={(event, selectedOption) => handleFilterChange('year', selectedOption)}
        />
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginBottom: '30px' }}>
        <Box marginBottom={'30px'} className={classes.tableContainer}>
          <TableContainer className={classes.table}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.th}>Nombre</TableCell>
                  <TableCell className={classes.th}>Intervalo</TableCell>
                  <TableCell className={classes.th}>Mes</TableCell>
                  <TableCell className={classes.th}>Año</TableCell>
                  <TableCell className={classes.th}>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shownIntervals.length < 1 ? (
                  <TableRow>
                    <TableCell>
                      {intervals.length < 1 ? 'Aún no tienes cuestionarios registrados' : 'Ningún cuestionario cumple los criterios'}
                    </TableCell>
                  </TableRow>
                ) : (
                  table()
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={shownIntervals.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Registros por hoja'
          />
        </Box>
      </Box>
    </div>
  )
}

export default withSnackbar(DownloadAnalytics);

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  title: {
    margin: '2%  0 0% 0',
    fontSize: '62px',
    width: '80% ',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  subtitle: {
    fontSize: '50px',
    width: '80% ',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '20px',
    overflowX: 'auto',
    margin: '30px',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },
  autocomplete: {
    width: '100%',
    marginLeft: '20px',
    marginRight: '20px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
    overflowX: 'auto',
    margin: '0 auto',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  icon: {
    cursor: 'pointer'
  },
  noIntervalsContainer: {
    backgroundColor: 'theme.palette.white.main',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
    textAlign: 'center',
  },
  emptyContainer: {
    display: 'flex',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));