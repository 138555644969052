import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { useLocation } from "react-router"
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import * as R from 'ramda';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import Background from '../../assets/images/backgroundGrayyBottom.svg';
import { config } from '../../lib/configTextEditor'
import PrimaryButton from '../primaryButton';
import updateTermsAndConditions from  '../../lib/Admin/manageDocuments/updateTermsandConditions'
import saveTermsandConditions from  '../../lib/Admin/manageDocuments/saveTermsandConditions'

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
  title: {
    fontSize: '62px',
    margin: '20px 0px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '40px',
    },
    textAlign: 'center',
  },
  loadingContainer: {
    margin: '0 auto'
  },
  titleContainer: {
      display: 'flex',
      width: '80%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
  },
  titleAndCardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
    margin: '0 auto',
    marginBottom: '10%',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  inputs: {
    width: '100%',
    marginBottom: '10px !important'
  },
  formContainer: {
    margin: 15,
    display: 'flex',
    width: '95%',
    gap: 30,
  },
  progressBarContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editor: {
    maxHeight: '300px',
    overflow: 'scroll',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      maxHeight: '250px',
    },
  }
}));

const UploadLegalDoc2 = (props) => {
  const classes = useStyle();
  const navigate =  useNavigate();
  const { state } = useLocation();
  const data = R.pathOr(null, ['data'], state) 
  
  const [version, setVersion] = useState(R.pathOr('', ['version'], data));
  const [editText, setEditText] = useState(R.pathOr('', ['content'], data))
  const [activated, setActivated] = useState(R.pathOr(false, ['active'], data) === true ? true : false)

  const handleOnChange = (event) => {
    const { value, name } = event.target
    switch (name) {
      case 'version':
        setVersion(value)
      break;

      default:
        setActivated(!activated)
      break;
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  }

  const handleEditor = (editorState)=> {
    setEditText(editorState)
  }
  
  const handleError = () => {
    const result = []
    if(version === ''){
      result.push('Favor de ingresar una versión')
    }
    if(editText === ''){
      result.push(`Favor de ingresar ${state.type === 'terms' ? 'los términos y condiciones' : 'las politicas de privacidad'} `)
    }
    return result
  }

  const saveTC = async() => {
    const errors = handleError();
    if(errors.length > 0){
      errors.forEach(element => {
        handleAlert('error', element)
      });
    }
    else {
      const body = {active: activated,version: version, content: editText.replaceAll('"', "'")}
      debugger
      const result = data === null ? await saveTermsandConditions(state.type,body) : await updateTermsAndConditions(state.data.termsAndConditionsRecordKey,body)
      if(result.success){
        handleAlert('success',result.message)
        navigate(-1)
      }
      else {
        handleAlert('error',result.message)
      }
    }
  }

  return (
    <div className={classes.containerBackground}>

      <div className={classes.titleContainer}>
        <h3 className={classes.title}>
          {state.type === 'terms' ? 'Términos y Condiciones' : 'Aviso de Privacidad'}
        </h3>
        <PrimaryButton width = "15%" handleClick = {saveTC} text = "Guardar" color = "#000000" />
      </div>

      <div className={classes.titleAndCardsContainer}>
        <div className={classes.formContainer}>
          <TextField
            label="Versión"
            variant="outlined"
            name='version'
            required
            value={version}
            inputProps={{ maxLength: 20}}
            onChange={handleOnChange}
            className={classes.inputs}
          />
          {
            data !== '' &&
            <PrimaryButton width = "15%" handleClick = {handleOnChange} margin = '0px' text = {activated === true ? 'Desactivar' : 'Activar'} color = "#000000" />
          }
        </div>
        <div style={{width: '95%'}}>
          <SunEditor 
            onChange={handleEditor}
            setDefaultStyle = "font-family: calibri;"
            setOptions = {config}
            defaultValue={editText}
          />
        </div>
      </div>
    </div>
  );
}

export default withSnackbar(UploadLegalDoc2);