import React from 'react'
import { makeStyles } from '@mui/styles';

const SecondaryButton = (props) => {
    const classes = useStyle()

  return (
    <button className={classes.button} onClick={props.onClick}>
        <p>{props.title}</p>
    </button>
  )
}

export default SecondaryButton

const useStyle = makeStyles(theme => ({
  button: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: `2px solid ${theme.palette.darkGray.main}`,
    color: theme.palette.darkGray.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
  }
}))