import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const changeStatus = async (orderId) => {
  try {
    const result = await API.patch(API_ANALYTICS, `/bidding-orders/changestatus/${orderId}`,
      {
        body: {
          status: "DEPOSITED_TOTALLY"
        }
      });
    return {
      success: true,
      data: result.data
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default changeStatus;
