import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const updateItem = async (itemRecordKey, orderRecordKey, amount) => {
  try {
    const result = await API.patch(API_ANALYTICS, `/bidding-orders/item/${itemRecordKey}`, {
      body: {
        orderRecordKey,
        amount: parseFloat(parseFloat(amount).toFixed(2))
      }
    });
    return {
      success: true,
      data: result.data
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default updateItem;