import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const gerOrders = async (organizationRecordKey) => {
  try {
    const result = await API.get(API_ANALYTICS, '/bidding-orders', {
      queryStringParameters: {
        organizationRecordKey: organizationRecordKey
      }
    });
    return {
      success: true,
      data: result.data
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default gerOrders;