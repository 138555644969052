import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Cell,
  ResponsiveContainer,
  PieChart,
  Pie,
  LabelList,
  Tooltip,
} from 'recharts';

const COLORS = ['#9FDEF1', '#2A5D78', '#F6AA54', '#B9E52F', '#E436BB', '#80ABE7', '#9E63FF', '#30CB71', '#ED90C7', '#DE3B00'];

const ChartPieOutline = (props) => {
  const classes = useStyle();
  const dataFormatted = props.data.filter((item) => item.value !== 0);
  return (
    <div className={classes.chartContainer}>
      <h3 className={classes.title}>{props.title}</h3>
      <ResponsiveContainer height={500}>
        <PieChart>
          <Tooltip />
          <Pie
            dataKey="value"
            data={dataFormatted}
            innerRadius={150}
          >
            <LabelList dataKey="label" position="outside" stroke='black' fill='black' offset={10} className={classes.labelList} />
            {props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ChartPieOutline;

const useStyle = makeStyles(theme => ({
  chartContainer: {
    backgroundColor: 'white',
    border: `5px solid ${theme.palette.lightGray.main}`,
  },
  title: {
    marginTop: 10,
    marginLeft: 10,
  },
  label: {
    fontSize: 50,
  },
  labelList: {
    fontWeight: 'lighter',
  },
}));