import React, { useState,useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import getCategory from '../../../lib/Admin/getCategory';
import activateCategory from '../../../lib/Admin/activateCategory';
import EditIcon from '@mui/icons-material/Edit';
import SearchBar from '../../../components/searchBar';
import { TablePagination } from '@mui/material';
import PrimaryButton from '../../../components/primaruButtonLite';
import ModalDelete from '../../../components/surveysComponents/modals/modalQuestionDelete';
import ModalCategory from '../../../components/surveysComponents/modals/modalCategory';
import theme from '../../../styles/theme'

const CategoryScreen = (props) => {
    const classes = useStyle()
    const [allCategories, setAllCategories] = useState([])
    const [category, setCategory] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [selectCategory, setSelectCategory] = useState({ id: '', category: '', type: ''})
    const [openModalDelete, setOpenModalDelete] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [search, setSearch] = useState('')

  useEffect(() => {
    getCategories()
  }, [])

  const getCategories = async() => {
    const resultCategory = await getCategory()
    const filter = resultCategory.data.filter(item => item.value !== 0)
    setAllCategories(filter)
    setCategory(filter)
    setIsLoading(false)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const table = () => {
    return(
      category.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,index) => (
        <tr className ={classes.tr} key = {index}>
          <td className={classes.tableRegister} data-label="Categoria">{item.name}</td>
          <td className={classes.tableRegister} data-label="Acciones">
            <div className = {classes.actionContainer}>
              <button className={classes.buttonAction} onClick = {() => (setSelectCategory({ id: item.value, category: item.name}),setOpenModal(true))}>
                <EditIcon sx = {{color: '#039EDA'}}/>
              </button>  

              <button className={classes.buttonActivate}
                style = {{backgroundColor: item.isActivated ? theme.palette.red.main : theme.palette.green.main}}
                onClick = {() => (setSelectCategory({ id: item.value, category: item.name, type: item.isActivated}),setOpenModalDelete(true))}
              >
                {item.isActivated ? 'Desactivar' : 'Activar'}
              </button>

            </div>
          </td>
        </tr>
      ))
    )
  }

  const activateDesactivateCategory = async(category) => {
      const result = await activateCategory(category.id,!category.type)
      if(result.success) {
        getCategories()
        setOpenModalDelete(false)
        handleAlert('success', `Categoría ${category.type ? 'activada' : 'desactivada'} con exito`)
      }
      else {
        handleAlert('error','Favor de intentarlo nuevamente')
      }
  }

  const handleFilter = (event) => {
    const { value } = event.target
    if(value !== ''){
      setSearch(value)
      const filter = allCategories.filter(items => items.name.includes(value))
      setCategory(filter)
    }
    else{
      setSearch('')
      setCategory(allCategories)
    }
  }

  return (
    <>
    {
      isLoading ?
      <CircularProgress/>
      :
      <>
        <div className={classes.rowContainer}>
          <SearchBar
            value={search}
            handleOnChange={handleFilter}
            name='search'
          />

          <div className={classes.buttonContainer}>
            <PrimaryButton onClick = {() => (setSelectCategory({}),setOpenModal(true))} title = "Nueva Categoria"/>
          </div>
        </div>

          <ModalDelete
            open={openModalDelete}
            data={selectCategory}
            text = {`¿Estás seguro de ${selectCategory.type ? 'desactivar' : 'activar'} la categoría ${selectCategory.category}?`}
            delete={() => activateDesactivateCategory(selectCategory)}
            handleClose={() => setOpenModalDelete(false)}
          />

          <ModalCategory
            open={openModal}
            onClose={() => setOpenModal(false)}
            categoryInfo={selectCategory}
            getCategories={getCategories}
          />

        <div className={`${classes.rowContainer} ${classes.tableContainer}`}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr>
                <th className={`${classes.th} ${classes.textAlignLeft}`}>Categoria</th>
                <th className={`${classes.th} ${classes.textAlignRight}`}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                table()
              }
            </tbody>
          </table>
          <TablePagination
            component="div"
            count={category.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Registros por hoja'
          />
        </div>
      </>
    }
    </>
  )
}

export default withSnackbar(CategoryScreen)

const useStyle = makeStyles(theme => ({
  rowContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '20px',
    margin: '30px 0px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      padding: 'none',
    },
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  table: {
    borderCollapse: 'collapse'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px !important',
    gap: 15,
    [theme.breakpoints.down('md')]: {
      flexDirection:'column'
    },
  },
  inputs: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  thead: {
    borderBottom:  `1px solid ${theme.palette.lightGray.main}`,
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
      borderBottom: 'none',
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    width: '25%',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    fontSize: '14px !important',
    textAlign: 'justify',
    width: '25%',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.lightGray.main}`,
      display: 'block',
      fontSize: '.8em',
      width: '80%',
        '&::before': {
            display:'flex',
            justifyContent: 'space-between',
            content: 'attr(data-label)',
            fontWeight: 'bold',
            textTransform: 'uppercase'
        },
        '&:last-child':{
            borderBottom: 'none'
        }
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 15
  },
  buttonAction:{
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center'
  },
  tr: {
    borderBottom:  `1px solid ${theme.palette.lightGray.main}`,
    [theme.breakpoints.down('md')]: {
      borderBottom: 'none',
      backgroundColor: theme.palette.white.main
    },
  },
  textAlignLeft: {
    textAlign: 'left'
  },
  textAlignRight: {
    textAlign: 'right'
  },
  buttonActivate: {
    color: theme.palette.white.main,
    borderRadius: 50,
    padding: 5,
  }
}))