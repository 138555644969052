import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const getOrganization = async (organizationRecordKey) => {
  try {
    const result = await API.get(API_ANALYTICS, '/users/', {
      queryStringParameters: {
        organizationRecordKey:organizationRecordKey,
        type: 'organization'
      }
    });
    return {
      success: true,
      data: result.data
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default getOrganization;