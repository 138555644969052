import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const editOrganization = async (organizationRecordKey, item) => {
  try {
    const result = await API.put(API_ANALYTICS, '/users', {
      body: {
        type: 'organization',
        organizationRecordKey: organizationRecordKey,
        item: item
      }
    });
    return {
      success: true,
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default editOrganization;