const removeAccents = text => {
  const patternAccents = 'áéíóúÁÉÍÓÚ';
  const patternNoAccents = 'aeiouAEIOU';
  const NOT_FOUND = -1;

  return text
    .split('')
    .map(letter => {
      const index = patternAccents.indexOf(letter);
      if (index !== NOT_FOUND) {
        return patternNoAccents[index];
      }

      return letter;
    })
    .join('');
};
export default removeAccents;