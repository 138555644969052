const saveResponseQuestions = async (body) => {
    try {
        const questionnaire = await fetch(`${process.env.REACT_APP_URL_ENDPOINT_SURVEY}/responses`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "x-api-key": process.env.REACT_APP_API_KEY_SURVEY
            },
            body: JSON.stringify(body),
        })
        
        const response = await questionnaire.json()
        if(response.success){
            return {
                success: true
            }
        }
        else{
            return {
                success: false
            }
        }
    } catch (err) {
      return {
        success: false,
        error: 'Algo salio mal',
      }
    }
  }
  
  export default saveResponseQuestions;