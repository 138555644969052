import React from 'react';
import { makeStyles } from '@mui/styles';
import Google from '../../assets/images/icons/googlePlay.svg';
import Apple from '../../assets/images/icons/appStore.svg';

const useStyle = makeStyles(theme => ({
  appsContainer: {
    margin: '1% auto 5% auto',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
  imgIcons: {
    width: '70%'
  },
}));

const AppsContainer = (props) => {
  const classes = useStyle(props);

  return (
    <div className={classes.appsContainer}>
      <p>Descarga nuestras apps en :</p>
      <div className={classes.iconContainer}>
        <a href='https://play.google.com/store/apps/details?id=com.flexwallet'>
          <img src={Google} alt="google-store icon" className={classes.imgIcons} />
        </a>

        <a href='https://apps.apple.com/mx/app/nerito/id1551319508'>
          <img src={Apple} alt="app-store icon" className={classes.imgIcons} />
        </a>
      </div>
    </div>
  );
}

export default AppsContainer;