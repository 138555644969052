import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Background from '../../../assets/images/backgroundGrayyBottom.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import * as R from 'ramda';
import { CLABE_BILLING } from '../../../lib/constant';
import GetOrganization from '../../../lib/Analytics/getOrganization';
import GetLeads from '../../../lib/Analytics/getLeads';

const Payments = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState(null);
  const [amounts, setAmounts] = useState(null);

  useEffect(() => {
    getPaymentData();
  }, []);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getPaymentData = async () => {
    try {
      const session = await Auth.currentSession();
      const organizationRecordKey = R.pathOr('N/A', ['accessToken', 'payload', 'sub'], session);
      const organizationResponse = await GetOrganization(organizationRecordKey);
      const leadsResponse = await GetLeads(organizationRecordKey);
      if (!organizationResponse.success || !leadsResponse.success) {
        setLoading(false);
        handleAlert('error', 'Algo salio mal');
      }
      const organization = organizationResponse.data;
      const leads = leadsResponse.data;
      const interval = getCurrentInterval(organization.paymentInterval);
      const leadsSum = leads.reduce((partialSum, lead) => partialSum + Number(lead.amount), 0);
      const amounts = calculateAmounts(leadsSum);
      setDates(interval);
      setAmounts(amounts);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleAlert('error', 'Algo salio mal');
    }
  }

  const getCurrentInterval = (type) => {
    const today = new Date();
    const weekDay = today.getDay();
    const startDate = new Date();
    const endDate = new Date();
    const freezeDate = new Date();
    if (type === 'weekly') {
      startDate.setDate(startDate.getDate() - weekDay);
      endDate.setDate(endDate.getDate() + (6 - weekDay));
      freezeDate.setDate(endDate.getDate() - 2);
    } else if (type === 'biweekly') {
      const dateNumber = today.getDate();
      if (dateNumber < 15) {
        startDate.setDate(1);
        endDate.setDate(15);
      } else {
        startDate.setDate(16);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
      }
      freezeDate.setDate(endDate.getDate() - getFreezeDateDiff(weekDay));
    } else {
      startDate.setDate(1);
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);
      freezeDate.setDate(endDate.getDate() - getFreezeDateDiff(weekDay));
    }
    const startDateFormat = `${startDate.getDate()}/${startDate.getMonth()}/${startDate.getFullYear()}`; 
    const endDateFormat = `${endDate.getDate()}/${endDate.getMonth()}/${endDate.getFullYear()}`;
    const freezeDateFormat = `${freezeDate.getDate()}/${freezeDate.getMonth()}/${freezeDate.getFullYear()}`;
    return { start: startDateFormat, end: endDateFormat, freeze: freezeDateFormat };
  }

  const getFreezeDateDiff = (weekDay) => {
    if (weekDay === 1 || weekDay === 2 || weekDay === 7) {
      return 4;
    } else if (weekDay === 3 || weekDay === 4 || weekDay === 5) {
      return 2;
    } else {
      return 3;
    }
  }

  const calculateAmounts = (baseAmount) => {
    const commission = baseAmount * 0.10;
    const iva = (baseAmount + commission) * 0.16;
    const total = baseAmount + commission + iva;
    return {
      baseAmount: isNaN(baseAmount) ? '0.00' : baseAmount.toFixed(2),
      commission: isNaN(commission) ? '0.00' : commission.toFixed(2),
      iva: isNaN(iva) ? '0.00' : iva.toFixed(2),
      total: isNaN(total) ? '0.00' : total.toFixed(2),
    };
  }

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <div className={classes.containerBackground}>
      <p className={classes.title}>
        Pagos
      </p>
      <p className={classes.subtitle}>
        {`Pago ${dates.start} - ${dates.end}`}
      </p>
      <div className={classes.paymentDataContainer}>
        <Grid container>
          <Grid md={12} lg={5} item={true} className={classes.clabeContainer}>
            <div className={classes.clabeContainer}>
              <p className={classes.clabeText}>CLABE para depositar</p>
              <p className={classes.clabeTextBold}>{CLABE_BILLING}</p>
            </div>
          </Grid>
          <Grid md={12} lg={7} item={true}>
            <div className={classes.amountsContainer}>
              <div className={classes.row}>
                <p className={classes.text}>Monto total de dispersión</p>
                <p className={classes.textBold}>{`$ ${amounts.baseAmount}`}</p>
              </div>
              <div className={classes.row}>
                <p className={classes.text}>Comision 10%</p>
                <p className={classes.textBold}>{`${amounts.commission}`}</p>
              </div>
              <div className={classes.row}>
                <p className={classes.text}>IVA</p>
                <p className={classes.textBold}>{`${amounts.iva}`}</p>
              </div>
              <div className={classes.separator}/>
              <div className={classes.row}>
                <p className={classes.text}>Total</p>
                <p className={classes.textBold}>{`$ ${amounts.total}`}</p>
              </div>
            </div>
            <div className={classes.labelContainer}>
              <p className={classes.label}>
                {`*Tienes hasta el ${dates.freeze} para modificar tu oferta por la compra de datos de cada prospecto`}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withSnackbar(Payments);

const useStyles = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    margin: '2%  0 0 0',
    fontSize: '62px',
    width: '80% ',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  subtitle: {
    margin: '0 0 2% 0',
    fontSize: '50px',
    width: '80% ',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  paymentDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    width: '80%',
    padding: '10px',
    margin: '20px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
  clabeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginBottom: 40,
  },
  amountsContainer: {
    borderRadius: 30,
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
  },
  labelContainer: {
    marginTop: 10,
    textAlign: 'center',
  },
  text: {
    fontSize: '28px',
  },
  textBold: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
  clabeText: {
    fontSize: '36px',
  },
  clabeTextBold: {
    fontSize: '36px',
    fontWeight: 'bold',
  },
  label: {
    fontSize: '20px',
  },
  separator: {
    height: '1px',
    border: `1px solid ${theme.palette.gray.main}`,
  },
}));