import React from 'react'
import { makeStyles } from '@mui/styles';
import Background from "../../../assets/images/backgroundContactForm.svg";
import ConfirmCodeForm from '../../../components/confirmCodeForm';

const useStyle = makeStyles(theme => ({
    containerBackground: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        margin: 0,
        backgroundPosition: 'bottom',
        alignItems: 'center',
    },
    registerContainer: {
        width: '40%'
    },
    title: {
        ...theme.typography.title,
        fontSize: 60,
        color: theme.palette.primary.main,
        textAlign: 'center',
        lineHeight: 1,
        margin: '5% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
        },
    },
}))

const ConfirmCode = () => {
    const classes = useStyle();

    return (
    <div className={classes.containerBackground}>
        <div className={classes.registerContainer}>
            <h2 className={classes.title}>
              Código de Activación
            </h2>
            <ConfirmCodeForm />
        </div>
    </div>
  )
}

export default ConfirmCode;