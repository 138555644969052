import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from "react-router"
import { makeStyles } from '@mui/styles';

import Background from '../../../assets/images/backgroundGrayyBottom.svg'


import SearchBar from '../../../components/searchBar';
import CircularProgress from '@mui/material/CircularProgress';

import GetQuestionnarie from '../../../lib/Analytics/getQuestionnarie';
import { QUESTIONNARIE_OPTIONS } from '../../../lib/constant'
import PrimaryButton from '../../../components/primaryButton';
import { Box} from '@mui/material';
import DetailQuestionnaire from '../../../components/surveys/detailQuestionnaire';
import Surveys from '../../../components/surveys/surveys';

const Survey = (props) => {
  const classes = useStyle();
  const perPage = 10

  const { companyId } = useParams()
  const { pathname } = useLocation()
  const [pages, setPages] = useState(0);

  const [search, setSearch] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectOption, setSelectOption] = useState(QUESTIONNARIE_OPTIONS[0])
  const [selectQuestionnarie1, setSelectQuestionnarie] = useState('')
  const [questionnaries, setQuestionnaries] = useState([]);
  const [selectedQuestionnarie, setSelectedQuestionnarie] = useState(null);
  const [itemsView, setItemsView] = useState([]);

  const company = pathname.split('=')[1]
  const resultCompanyId = companyId ? companyId : company ? company : undefined
  const navigate = useNavigate();

  useEffect(() => {
    getQuestionnaires()
  }, [])

  const handleFilter = (event) => {
    const { value } = event.target
    setSearch(value)
    const result = itemsView.filter(items => {
      if (items.startDate) return items.startDate.includes(value)
      if (items.questionText) return items.questionText.includes(value)
    })
    setPages(Math.ceil(result.length / perPage));
    setListSearch(result)
  }

  const changeOption = (value) => {
    if (selectQuestionnarie1) {
      const filter = QUESTIONNARIE_OPTIONS.filter(item => item.value === value)
      setSelectOption(filter[0]);
    }
  };

  const getQuestionnaires = async () => {
    setLoading(true);
    const result = await GetQuestionnarie(resultCompanyId)
    if (result.success) {
      setQuestionnaries(result.data ? result.data : null)
    }
    setLoading(false)
  }

  return (
    <main className={classes.containerBackground}>
      <div className={classes.titleContainer}>
        <div>
          <h1 className={classes.title}>
            Cuestionarios
          </h1>
          <p className={classes.subtitle}>
            {selectOption.value === 2 ? 'Detalle de cuestionario' : 'Todos los cuestionarios'}
          </p>
        </div>
        {selectOption.value === 2 &&
          <div className={classes.buttonContainer}>
            <PrimaryButton color={'black'} text='Ver cuestionarios' handleClick={() => changeOption(1)} />
          </div>
        }
      </div>
      <div className={classes.rowContainer}>
        <SearchBar
          value={search}
          handleOnChange={handleFilter}
          name='searchBar'
          width='80%'
        />
        <button className={classes.button} onClick={() => navigate('/newQuestionnarie', { state: { organizationRecordKey: resultCompanyId } })}>
          <p>Nuevo cuestionario</p>
        </button>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginBottom: '30px' }}>
        {loading ?
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
          :
          selectedQuestionnarie ?
            <DetailQuestionnaire
              companyId={resultCompanyId}
              selectedQuestionnarie={selectedQuestionnarie}
              setItemsView={setItemsView}
              listSearch={listSearch}
            />
            :
            <Surveys
              companyId={resultCompanyId}
              setSelectedQuestionnarie={setSelectedQuestionnarie}
              setItemsView={setItemsView}
              listSearch={listSearch}
            />
        }
      </Box>
    </main >
  )
};

export default Survey;

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '62px',
    width: '50% ',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  subtitle: {
    margin: '0 0 2% 0',
    fontSize: '50px',
    width: '80% ',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    marginTop: '2%',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '20px',
    overflowX: 'auto',
    margin: '30px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '20%',
  },
  button: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.darkGray.main}`,
    color: theme.palette.white.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
  },
}))
