import { Table, TableBody, TableRow, TableCell, TableContainer, TableHead, TablePagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';


const TableComponent = (props) => {
  const classes = useStyle();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderBodyItems = (items) => {
    return (
      <>
        {
          items.value.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
            <TableCell data-label="Aceptar">
              {item}
            </TableCell>
          )
          )
        }
      </>
    )

  }

  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead >
          <TableRow >
            {props.titles.map(title => (
              <TableCell >{title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.bodyItems.map(item => (
              <TableRow onClick={() => props.onClickRow(item)}>
                {renderBodyItems(item)}
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      {props.pagination &&
        <TablePagination
          component="div"
          count={props.bodyItems.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Registros por hoja'
        />
      }

    </TableContainer>
  )
}

export default TableComponent;

const useStyle = makeStyles(theme => ({}))