// const updateTermsAndConditions = async(id,active = false,version,content) => {
  const updateTermsAndConditions = async(id,data) => {
    const object = Object.assign({}, data)
    object['applicationKey'] = "ANALYTICS"
    const body = JSON.stringify(object)

    try {
      const terms = await fetch(`${process.env.REACT_APP_ENDPOINT_API_TERMS2}/${id}`, {
        method: "PATCH",
        headers: {
          "x-api-key": process.env.REACT_APP_TERMS_API_KEY,
          "Content-Type": 'application/json'
        },
        body
      }).then(response => response.json())
      debugger
      if(terms.success){
        return {
          success: true,
          message: 'Actualización exitosa'
        }
      }
      else{
        return {
          success: false,
          message: 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde'
        }
      }

    } catch (err) {
      debugger
      return {
        success: false,
        error: 'Algo salio mal',
      }
    }
  }
  
  export default updateTermsAndConditions;