const moneyFormat = (number) => {
  const fixed = Number(number).toFixed(2);
  const parts = fixed.split('.');
  const numberPart = parts[0];
  const decimalPart = parts[1] || '00';
  const numberFormatter = Intl.NumberFormat('en-US');
  const numberFormatted = numberFormatter.format(numberPart);
  const newNumber = numberFormatted + '.' + decimalPart;
  return newNumber;
}

export default moneyFormat;