import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';
import Login from '../Auth/login'
import Logout from '../Auth/logout'

const getApprovedOrganization = async (email,password) => {
    const status = await Login(email,password)

    if(status.success === false){
        return { success: false, error: status.error }
    }

    else if(status.success && status.user.attributes['custom:group'] !== undefined){
        const organizationRecordKey = status.user.attributes['sub']

        try {
          const result = await API.get(API_ANALYTICS, '/users/organization', {
              queryStringParameters: {
                  organizationRecordKey: organizationRecordKey
              }
            });
            if(result.approval){
                return { success: true, user: status.user }
            }
            else {
                await Logout();
                return { success: false }
            }
      
        } catch (err) {
          return {
            success: false,
            error: 'Algo salio mal',
          }
        }
    }
    else if(status.user.attributes['custom:group'] === undefined){

        return { success: true, admin: true, user: status.user }
    }

}

export default getApprovedOrganization;