import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Background from '../../../assets/images/backgroundGrayyBottom.svg';
import Pagination from '@mui/material/Pagination';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PrimaryButton from '../../../components/primaryButton';
import csvtojsonV2 from 'csvtojson';
import * as R from 'ramda';
import { useNavigate } from "react-router-dom";
import {
  BANKS,
  REGEX_EMAIL,
  REGEX_LETTERS,
  REGEX_PHONE,
  REGEX_RFC
} from "../../../lib/constant";
import { clabe } from 'clabe-validator';
import SaveLeads from '../../../lib/Analytics/saveLeads';
import CircularProgress from '@mui/material/CircularProgress';
import ModalErrors from '../../../components/modalLeadsErrors';
import { Box, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, TablePagination } from '@mui/material';


const UploadLeads = (props) => {
  const classes = useStyle();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [leads, setLeads] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [pages, setPages] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);
  const [modalErrorsVisible, setModalErrorsVisible] = useState(false);
  const [failedItems, setFailedItems] = useState([])
  const [perPage, setPerPage] = useState(10);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const getFile = async (e) => {
    try {
      const file = e.target.files[0];
      const fileText = await file.text();
      const fileJson = await csvtojsonV2().fromString(fileText);
      delete fileJson.error;
      const leadsArr = fileJson.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
      const status = verifyFile(leadsArr);
      if (status !== '') {
        handleAlert('error', status);
      } else {
        const duplicatedStatues = verifyDuplicated(leadsArr)
        if (duplicatedStatues !== '') {
          handleAlert('error', duplicatedStatues);
        } else {
          setLeads(leadsArr);
          setFile(file);
          setPages(Math.ceil(leadsArr.length / perPage));
          setShowTable(true);
        }
      }
    } catch (err) {
      console.log({ err })
      handleAlert('error', 'Algo Salio Mal');
    }
  }

  const verifyFile = (leads) => {
    let errorMessage = '';
    for (const lead of leads) {
      if (R.isNil(lead.nombre) || lead.nombre === '') {
        errorMessage = `Campo "nombre" vacío (Renglón: ${lead.position})`;
        break;
      } if (!REGEX_LETTERS.test(lead.nombre)) {
        errorMessage = `Error de formato en campo "nombre" (Renglón: ${lead.position})`;
        break;
      } if (lead['segundo nombre']) {
        if (!REGEX_LETTERS.test(lead['segundo nombre'])) {
          errorMessage = `Error de formato en campo "segundo nombre" (Renglón: ${lead.position})`;
          break;
        }
      } if (R.isNil(lead['apellido paterno']) || lead['apellido paterno'] === '') {
        errorMessage = `Campo "apellido paterno" vacío (Renglón: ${lead.position})`;
        break;
      } if (!REGEX_LETTERS.test(lead['apellido paterno'])) {
        errorMessage = `Error de formato en campo "apellido paterno" (Renglón: ${lead.position})`;
        break;
      } if (R.isNil(lead['apellido materno']) || lead['apellido materno'] === '') {
        errorMessage = `Campo "apellido materno" vacío (Renglón: ${lead.position})`;
        break;
      } if (!REGEX_LETTERS.test(lead['apellido materno'])) {
        errorMessage = `Error de formato en campo "apellido materno" (Renglón: ${lead.position})`;
        break;
      } if (R.isNil(lead.correo) || lead.correo === '') {
        errorMessage = `Campo "correo" vacío (Renglón: ${lead.position})`;
        break;
      } if (!REGEX_EMAIL.test(lead.correo)) {
        errorMessage = `Error de formato en campo "correo" (Renglón: ${lead.position})`;
        break;
      } if (R.isNil(lead.clabe) || lead.clabe === '') {
        errorMessage = `Campo "clabe" vacío (Renglón: ${lead.position})`;
        break;
      }
      // validar si es clabe o tarjeta
      if (lead.clabe.length > 16) {
        if (!clabe.validate(lead.clabe).ok) {
          errorMessage = `Error de formato en campo "clabe" (Renglón: ${lead.position})`;
          break;
        }
      }
      if (lead.clabe.length === 16) {
        const bank = BANKS.find(item => item.label === lead.banco)
        if (!bank) {
          errorMessage = `Error de formato en campo "banco" no coincide con el catalogo (Renglón: ${lead.position})`;
          break;
        }
      }
      if (R.isNil(lead.telefono) || lead.telefono === '') {
        errorMessage = `Campo "telefono" vacío (Renglón: ${lead.position})`;
        break;
      } if (!REGEX_PHONE.test(lead.telefono)) {
        errorMessage = `Error de formato en campo "telefono" (Renglón: ${lead.position})`;
        break;
      } if (lead.rfc) {
        if (lead.rfc !== '' && !REGEX_RFC.test(lead.rfc.toUpperCase())) {
          errorMessage = `Error de formato en campo "rfc" (Renglón: ${lead.position})`;
          break;
        }
      } if (R.isNil(lead['codigo postal']) || lead['codigo postal'] === '') {
        errorMessage = `Campo "codigo postal" vacío (Renglón: ${lead.position})`;
        break;
      }
    }
    return errorMessage;
  }

  const verifyDuplicated = (leads) => {
    const tempLeads = [];
    const includedLeads = []
    leads.forEach((item, index) => {
      const included = tempLeads.filter(lead => lead.correo === item.correo || lead.telefono === item.telefono)
      if (included.length > 0) {
        includedLeads.push(index + 1)
      } else {
        tempLeads.push(item)
      }
    })
    if (includedLeads.length > 0) {
      return `Los siguientes registros estan repetidos(correo y/o teléfono): ${includedLeads}`
    }
    return ''
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handlePagination = (event, value) => {
    setSelectedPage(value);
  }

  const cleanFileInput = (event) => {
    event.target.value = null
  }

  const saveFile = async (event) => {
    setLoading(true);
    try {
      event.preventDefault();
      const session = await Auth.currentSession();
      const organizationRecordKey = R.pathOr('N/A', ['accessToken', 'payload', 'sub'], session);
      const leadsFormatted = leads.map(lead => ({
        name: lead.nombre,
        secondName: lead['segundo nombre'],
        paternalLastName: lead['apellido paterno'],
        maternalLastName: lead['apellido materno'],
        email: lead.correo,
        clabe: lead.clabe,
        rfc: lead.rfc,
        phone: lead.telefono,
        status: 'waiting',
        postalCode: lead['codigo postal'],
        bankName: lead.banco
      }));
      const response = await SaveLeads(organizationRecordKey, leadsFormatted);
      if (response.success) {
        if (response.failedItems.length > 0) {
          handleAlert('warning', 'Algunos prospectos no se guardaron correctamente');
          setFailedItems(response.failedItems);
          setModalErrorsVisible(true)
        } else {
          handleAlert('success', 'Archivo guardado con éxito');
          goToSavedLeads()
        }
        setSaveSuccess(true);
        setLoading(false)
      } else {
        setLoading(false);
        handleAlert('error', response.error);
      }
    } catch (err) {
      setLoading(false)
      let errorMessage = 'Algo salió mal';
      if (err === 'No current user') {
        errorMessage = 'Sesión Inválida';
      }
      handleAlert('error', errorMessage);
    }
  }

  const table = () => {
    if (leads.length > 0) {
      return (
        leads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <tr key={index} className={classes.tableHeader}>
            <td className={classes.tableRegister} data-label="ID">{item.position}</td>
            <td className={classes.tableRegister} data-label="Name">{item.nombre}</td>
            <td className={classes.tableRegister} data-label="Second Name">{item['segundo nombre']}</td>
            <td className={classes.tableRegister} data-label="Paternal LastName">{item['apellido paterno']}</td>
            <td className={classes.tableRegister} data-label="Maternal LastName">{item['apellido materno']}</td>
            <td className={classes.tableRegister} data-label="RFC">{item['rfc']}</td>
            <td className={classes.tableRegister} data-label="Email">{item['correo']}</td>
            <td className={classes.tableRegister} data-label="Clabe">{item['clabe']}</td>
            <td className={classes.tableRegister} data-label="Banco">{item['banco']}</td>
            <td className={classes.tableRegister} data-label="Phone">{item['telefono']}</td>
            <td className={classes.tableRegister} data-label="Postal code">{item['codigo postal']}</td>
          </tr>
        ))
      );
    }
  }

  const goToSavedLeads = () => {
    navigate('/viewLeads');
  }

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="inherit" />
      </div>
    )
  }

  return (
    <div className={classes.containerBackground}>
      <ModalErrors
        onClose={() => setModalErrorsVisible(false)}
        open={modalErrorsVisible}
        failedItems={failedItems}
        from='leads'
      />
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>
          Subir prospectos
        </h1>
        <div className={classes.buttonContainer}>
          <PrimaryButton color={'black'} text='Ver prospectos guardados' handleClick={goToSavedLeads} />
        </div>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.hintContainer}>
          <h3>Consideraciones generales:</h3>
          <text>1. Los archivos, deben mantener los nombres de columnas que se indican en el layout</text>
          <br />
          <text>2. Se deben respetar los tipos de dato que se especifícan en cada campo</text>
          <br />
          <text>3. Se deben respetar las longitudes máximas indicadas con el número entre paréntesis en cada campo</text>
        </div>
        <div className={classes.fileContainer}>
          {file ?
            <div className={classes.fileNameContainer}>
              <text className={classes.fileName}>
                {file.name.length < 30 ? file.name : `${file.name.slice(0, 27)}...`}
              </text>
            </div>
            :
            <a href={process.env.REACT_APP_LAYOUT_PROSPECTS_URL}
              className={classes.fileDownloadButton}>
              <p>Descargar Formato</p>
            </a>}
          <div className={classes.fileUploadButton}>
            <label for='fileCd' className={classes.uploadLabel}>
              <text>
                Subir Archivo
              </text>
              <input
                type='file'
                name='fileCd'
                id='fileCd'
                className={classes.uploadInput}
                onChange={getFile}
                onClick={cleanFileInput}
                file={file}
                accept='.csv'
                disabled={false}
              />
            </label>
          </div>
        </div>
      </div>
      {showTable ?
        <>
          <Box marginBottom={'30px'} className={classes.boxStyle}>
            <TableContainer className={classes.infoContainer}>
              <Table style={{ width: '100%' }}>
                <TableHead className={classes.thead}>
                  <TableRow classes={classes.infoRow}>
                    <TableCell className={classes.th}>ID</TableCell>
                    <TableCell className={classes.th}>Nombre</TableCell>
                    <TableCell className={classes.th}>Segundo Nombre</TableCell>
                    <TableCell className={classes.th}>Apellido Paterno</TableCell>
                    <TableCell className={classes.th}>Apellido Materno</TableCell>
                    <TableCell className={classes.th}>RFC</TableCell>
                    <TableCell className={classes.th}>Correo Electrónico</TableCell>
                    <TableCell className={classes.th}>Clabe Interbancaria</TableCell>
                    <TableCell className={classes.th}>Banco</TableCell>
                    <TableCell className={classes.th}>Télefono</TableCell>
                    <TableCell className={classes.th}>Codigo postal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table()}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={leads.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage='Registros por hoja'
              />
            </TableContainer>
          </Box>
          <div className={classes.saveContainer}>
            <PrimaryButton color={'black'} text='GUARDAR' handleClick={saveFile} />
          </div>
        </>
        :
        <Box marginBottom={'30px'} className={classes.boxStyle}>
          <TableContainer className={classes.infoContainer}>
            <Table>
              <TableHead className={classes.thead}>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classes.th}>nombre</TableCell>
                  <TableCell className={classes.th}>segundo nombre</TableCell>
                  <TableCell className={classes.th}>apellido paterno</TableCell>
                  <TableCell className={classes.th}>apellido materno</TableCell>
                  <TableCell className={classes.th}>rfc</TableCell>
                  <TableCell className={classes.th}>correo electrónico</TableCell>
                  <TableCell className={classes.th}>clabe interbancaria</TableCell>
                  <TableCell className={classes.th}>nombre del banco</TableCell>
                  <TableCell className={classes.th}>teléfono</TableCell>
                  <TableCell className={classes.th}>codigo postal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classes.tableRegister} data-label="column">Long. Maxima (30)</TableCell>
                  <TableCell className={classes.tableRegister} data-label="column">Long. Maxima (30)</TableCell>
                  <TableCell className={classes.tableRegister} data-label="column">Long. Maxima (30)</TableCell>
                  <TableCell className={classes.tableRegister} data-label="column">Long. Maxima (30)</TableCell>
                  <TableCell className={classes.tableRegister} data-label="column">Long. Maxima (13)</TableCell>
                  <TableCell className={classes.tableRegister} data-label="column">Long. Maxima (60)</TableCell>
                  <TableCell className={classes.tableRegister} data-label="column">Long. Maxima (16 - 18)</TableCell>
                  <TableCell className={classes.tableRegister} data-label="column">Long. Maxima ()</TableCell>
                  <TableCell className={classes.tableRegister} data-label="column">Long. Maxima (10)</TableCell>
                  <TableCell className={classes.tableRegister} data-label="column">Long. Maxima (10)</TableCell>
                </TableRow>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classes.tableRegister} data-label="column"></TableCell>
                  <TableCell className={classes.tableRegister} data-label="column"></TableCell>
                  <TableCell className={classes.tableRegister} data-label="column"></TableCell>
                  <TableCell className={classes.tableRegister} data-label="column"></TableCell>
                  <TableCell className={classes.tableRegister} data-label="column"></TableCell>
                  <TableCell className={classes.tableRegister} data-label="column"></TableCell>
                  <TableCell className={classes.tableRegister} data-label="column"></TableCell>
                  <TableCell className={classes.tableRegister} data-label="column"></TableCell>
                  <TableCell className={classes.tableRegister} data-label="column">Sin código de país</TableCell>
                  <TableCell className={classes.tableRegister} data-label="column"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

      }
    </div >
  )
}

export default withSnackbar(UploadLeads);

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    marginTop: '2%'
  },
  title: {
    fontSize: '62px',
    width: '80% ',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  infoContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80% !important',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
    overflowX: 'auto',
    margin: '0 auto',
    marginTop: 30,
    marginBottom: 10,
  },
  hintContainer: {
    padding: '10px 10px 0px 10px'
  },
  fileContainer: {
    display: 'flex',
    width: '45%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  fileDownloadButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '5px 10px 5px 10px',
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    border: '2px solid black',
    textDecoration: 'none',
    fontWeight: 'bold',
    width: 200,
    marginRight: '10px',
  },
  fileUploadButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadLabel: {
    width: 200,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.white.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
    marginLeft: '10px',
  },
  uploadInput: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    border: 0,
  },
  fileNameContainer: {
    marginLeft: 5,
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    // width: '25%',
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    width: 'auto'
  },
  saveContainer: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
    overflowX: 'auto',
    margin: '0 auto',
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    fontSize: 15,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {

      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  thead: {
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.white.main,
    padding: 15,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
      borderBottom: `3px solid ${theme.palette.gray.main}`,
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    width: '20%',
  },
  loading: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0px 20px 0px 0px',
  },
  boxStyle: {
    display: 'flex',
    width: '100%'
  }
}));