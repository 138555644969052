import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const saveOrganization = async (item) => {
  try {
    const result = await API.post(API_ANALYTICS, '/users', {
      body: {
        type: 'organization',
        organizationRecordKey: item.organizationRecordKey,
        item: item,
      }
    });
    return {
      success: true,
    }
  } catch (err) {
    console.log("ERR SAVE ORG", err);
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default saveOrganization;