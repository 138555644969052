import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Pagination from '@mui/material/Pagination';
import csvtojsonV2 from 'csvtojson';
import * as R from 'ramda';
import GetLeads from '../../lib/Analytics/getLeads';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import {
  REGEX_EMAIL,
  REGEX_LETTERS,
  REGEX_PHONE,
  MIN_BIDDING,
  MAX_BIDDING,
} from "../../lib/constant";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { CheckBox } from '../../components/checkBox';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchBar from '../../components/searchBar';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import { CSVLink, CSVDownload } from "react-csv";
import saveBidding from '../../lib/Bidding/saveBidding';
import saveBatchBidding from '../../lib/Bidding/saveBatchBidding';
import ModalErrors from '../modalLeadsErrors';
import removeAccents from '../../lib/removeAccents';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import CreatePPFile from '../../lib/CreateFiles/ppFile';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { Box, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, TablePagination } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import editOrganization from '../../lib/Analytics/editOrganization';

const OrganizationLeads = (props) => {
  const classes = useStyle();

  let navigate = useNavigate();
  const perPage = 10;
  const [cookies] = useCookies(['user']);

  const [file, setFile] = useState(null);
  const [leads, setLeads] = useState([]);
  const [offer, setOffer] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [succesfulItems, setSuccesfulItems] = useState([])
  const [failedItems, setFailedItems] = useState([])
  const organization = props.organization;
  const from = props.from;
  const [modalErrorsVisible, setModalErrorsVisible] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [search, setSearch] = useState('');
  const [shownLeads, setShownLeads] = useState([]);
  const [showData, setShowData] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getSavedLeads();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createPPFile = async () => {
    setLoading(true);
    try {
      const companyId = cookies.user.sub;
      const operations = leads.map(item => ({
        "name": `${item.name} ${item.secondName} ${item.paternalLastName} ${item.maternalLastName}`,
        "email": item.email,
        "destinationAccount": item.clabe,
        "amount": item.amount || "1",
        "description": `Pago Analytics ${moment().format("DD/MM/YYYY")}`,
        "applicationDate": moment().format("DDMMYYYY"),
        "leadRecordKey": item.leadRecordKey
      }))
      const response = await CreatePPFile(companyId, operations);
      if (response.success) {
        handleAlert('success', 'Archivo creado correctamente. Espere 30 minutos para ver los resultados')
      }
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
    }
    setLoading(false);
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getSavedLeads = async () => {
    try {
      setLoading(true);
      const response = await GetLeads(organization);
      const leadsArr = response.data.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
      setLeads(leadsArr);
      setShownLeads(leadsArr);
      if (from !== 'admin') {
        if (leadsArr.filter(item => !item.status || item.status === 'waiting').length > 0) {
          setShowData(false)
        }
      }
      setTotalLeads(leadsArr.length)
      setFile(null);
      setLoading(false);
    } catch (err) {
      handleAlert('error', 'Algo salió mal');
    }
  }

  const createCSV = (arrayToConvert) => {
    const csvData = [["nombre", "telefono", "propuesta"]]
    arrayToConvert.map((lead) => (
      csvData.push([
        (`${lead.name} ${lead.secondName} ${lead.paternalLastName} ${lead.maternalLastName}` || ''),
        (lead.phone || ''),
        (lead.amount || 0),
      ])
    ))
    setCsvData(csvData);
  }

  const getFile = async (e) => {
    try {
      const file = e.target.files[0];
      const fileText = await file.text();
      const fileJson = await csvtojsonV2().fromString(fileText);
      const indexError = []
      const leadsArr = fileJson.map((item, index) => {
        const newAmount = item.propuesta;
        const oldLead = leads.filter(leadItem => leadItem.phone === item.telefono)[0]
        if (oldLead) {
          const lastAmount = oldLead.amount || 0
          return {
            ...oldLead,
            ...item,
            position: index + 1,
            lastAmount,
            newAmount,
          }
        }
        else {
          indexError.push(index + 1)
          return false
        }

      }).filter(item => item !== false)
      if (leadsArr.length > 0) {
        const status = verifyFile(leadsArr);
        if (status !== '') {
          handleAlert('error', status);
        }
        else if (indexError.length > 0) {
          handleAlert('error', `Se ha modificado el campo "teléfono" (Renglón: ${indexError})`);
        }
        else {
          setLeads(leadsArr);
          setSelectedLeads(leadsArr);
          setFile(file);
        }
      }
      else {
        handleAlert('error', `Se ha modificado el campo "teléfono" (Renglón: ${indexError})`);
      }
    } catch (err) {
      setFile(null)
      handleAlert('error', 'Algo Salio Mal');
    }
  }

  const cleanFileInput = (event) => {
    event.target.value = null
  }

  const verifyFile = (leads) => {
    let errorMessage = '';
    for (const lead of leads) {
      if (R.isNil(lead.nombre) || lead.nombre === '') {
        errorMessage = `Campo "nombre" vacío (Renglón: ${lead.position})`;
        break;
      } else if (!REGEX_LETTERS.test(lead.nombre)) {
        errorMessage = `Error de formato en campo "nombre" (Renglón: ${lead.position})`;
        break;
      } else if (R.isNil(lead.telefono) || lead.telefono === '') {
        errorMessage = `Campo "telefono" vacío (Renglón: ${lead.position})`;
        break;
      } else if (!REGEX_PHONE.test(lead.telefono)) {
        errorMessage = `Error de formato en campo "telefono" (Renglón: ${lead.position})`;
        break;
      } else if (R.isNil(lead.propuesta) || lead.propuesta === '') {
        errorMessage = `Campo "propuesta" vacío (Renglón: ${lead.position})`;
        break;
      } else if (isNaN(parseFloat(lead.propuesta))) {
        errorMessage = `Campo "propuesta" debe ser un número (Renglón: ${lead.position})`;
        break;
      } else if (parseFloat(lead.propuesta) < MIN_BIDDING || parseFloat(lead.propuesta) > MAX_BIDDING) {
        errorMessage = `Campo "propuesta" debe estar en un rango entre 2,000.00 a 25,000.00 (Renglón: ${lead.position})`;
        break;
      }
    }
    return errorMessage;
  }

  const getType = (tempAmount) => {
    const amount = parseInt(tempAmount);
    if (amount >= 2000 && amount <= 6000) {
      return 'Adopter'
    } else if (amount >= 6001 && amount <= 12000) {
      return 'Evolver'
    } else if (amount >= 12001 && amount <= 25000) {
      return 'Explorer'
    } else {
      return 'N/A'
    }
  }

  const handleNewAmount = (index, amount) => {
    const newLeads = [...leads];
    newLeads[index].amount = amount;
    setLeads(newLeads);
  }

  const saveAmount = async (index, amount) => {
    if (amount >= 2000) {
      const newLeads = [...leads];
      newLeads[index].lastAmount = newLeads[index].amount
      newLeads[index].newAmount = amount;
      newLeads[index].amount = amount;
      newLeads[index].offer = false
      handleChecked(index);
      setLeads(newLeads);
    } else {
      handleAlert('warning', 'El monto debe ser mayor o igual a 2000');
    }
  }

  const saveOffer = async (index, amount) => {
    const { organizationRecordKey, leadRecordKey, userType } = leads[index]
    try {
      const response = await saveBidding(organizationRecordKey, leadRecordKey, 'offered', amount, userType);
      if (response.success) {
        const newLeads = [...leads];
        newLeads[index].amount = amount;
        setLeads(newLeads);
        handleAlert('success', `Se guardó la ${from === 'admin' ? 'subasta' : 'oferta'} del prospecto.`);
      }
      else {
        handleAlert('error', response.message);
      }
    } catch (err) {
      handleAlert('error', err.message);
    }
  }

  const changeOffer = (index) => {
    const newLeads = [...leads];
    if (newLeads[index].offer && newLeads[index].amount) {
      newLeads[index].amount = newLeads[index].lastAmount;
    }
    newLeads[index].offer = !newLeads[index].offer;
    setLeads(newLeads);
  }

  const saveBatchOffer = async () => {
    setLoading(true);
    if (selectedLeads.length > 0) {
      try {
        const purgedLeads = selectedLeads.map(item => ({
          leadRecordKey: item.leadRecordKey,
          newAmount: item.newAmount,
          lastAmount: item.lastAmount,
          userType: getType(item.newAmount)
        }));
        const response = await saveBatchBidding(selectedLeads[0].organizationRecordKey, purgedLeads, from === 'admin' ? 'bidding' : 'offered');
        const limitToPay = getTotal()
        const responseEditUser = await editOrganization(organization, {limitToPay});
        if (response.success) {
          if (response.failedItems.length > 0) {
            const newFailedItems = response.failedItems.map(item => {
              const oldItem = leads.filter(lead => lead.leadRecordKey === item.leadRecordKey)[0]
              return {
                ...item,
                ...oldItem,
              }
            })
            setFailedItems(newFailedItems)
            handleAlert('warning', 'Algunos prospectos no se guardaron correctamente, errores.csv')
            setModalErrorsVisible(true)
          }
          else {
            handleAlert('success', 'Prospectos guardados con éxito')
            getSavedLeads();
          }
        }
        else {
          handleAlert('error', 'Algo salió mal');
        }
      } catch (err) {
        handleAlert('error', 'Algo salió mal');
      }
    } else {
      handleAlert('warning', 'Selecciona al menos un prospecto para continuar');
    }
    setLoading(false);
  }

  const handleChecked = (index) => {
    const newLeads = [...leads];
    const newSelectedLeads = [...selectedLeads];
    newLeads[index].checked = !newLeads[index].checked;
    setLeads(newLeads);
    const indexSelectedLeads = newSelectedLeads.indexOf(newLeads[index]);
    if (indexSelectedLeads == -1 && newLeads[index].checked) {
      newSelectedLeads.push(newLeads[index]);
    }
    else {
      newSelectedLeads.splice(indexSelectedLeads, 1);
    }
    if (newSelectedLeads.length === newLeads.length) {
      handleSelectAll()
    }
    setSelectedLeads(newSelectedLeads)
    if (selectAll) setSelectAll(false)
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const newSelectedLeads = [];
    const newLeads = [...leads];
    newLeads.map((item) => {
      item.checked = !selectAll;
      if (item.checked) {
        newSelectedLeads.push(item);
      }
    })
    if (selectAll) {
      setSelectedLeads([])
    }
    else {
      setSelectedLeads(newSelectedLeads)
    }
    setLeads(newLeads);
  }

  const moneyFormat = (amount) => {
    let newAmount = amount;
    if (newAmount !== undefined) {
      if (typeof amount === 'string') {
        newAmount = parseFloat(amount)
      }
      const formatedAmount = newAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      })
      return formatedAmount
    } else {
      return '$0'
    }
  }

  const getTotal = () => {
    let total = 0;
    if (leads.length > 0) {
      leads.map((item) => {
        if (item.amount) {
          total = total + parseFloat(item.amount);
        } else {
          total = total + 0;
        }
      })
    }
    return total
  }

  const handleFilter = (event) => {
    const filter = (event.target.value).toLowerCase();
    const filteredArr = leads.filter(item => getFullName(item).includes(filter));
    setSearch(filter);
    setShownLeads(filteredArr);
  }

  const getFullName = (item) => {
    const fullName = `${item.name} ${item.secondName} ${item.paternalLastName} ${item.maternalLastName}`
    return removeAccents(fullName).toLowerCase();
  }

  const formatDate = (dateString) => {
    if(!dateString) return 'N/A'
    const date = new Date(dateString);
    const dateFormatted = new Intl.DateTimeFormat("es-MX", {
      year: "numeric",
      day: "2-digit",
      month: "2-digit"
    }).format(date);

    return dateFormatted
  }

  const table = () => {
    if (shownLeads.length > 0) {
      return (
        shownLeads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <TableRow key={index} className={classes.tableHeader}>
            <TableCell className={classes.tableRegisterCheckbox} data-label="Aceptar">
              <CheckBox
                check={item.checked}
                name={`checked${index}`}
                onChange={() => handleChecked(index)}
              />
            </TableCell>
            <TableCell className={classes.tableRegister} data-label="Nombre completo">{`${item.name} ${item.secondName} ${item.paternalLastName} ${item.maternalLastName}`}</TableCell>
            <TableCell className={classes.tableRegister} data-label="Teléfono" align="center">{item.phone}</TableCell>
            {from === 'admin' ?
              <TableCell className={classes.table} align="center">
                {item.externalRecordKey ? <CheckCircleRoundedIcon sx={{ fontSize: 30, color: '#40AB74' }} /> : <RemoveCircleRoundedIcon sx={{ fontSize: 30 }} />}
              </TableCell>
              :
              <>
                <TableCell className={classes.tableRegister} data-label="Fecha de último pago" align="center">{formatDate(item.lastPaymentDate)}</TableCell>
                <TableCell className={classes.tableRegister} data-label="Cantidad de último pago" align="center">${item.lastAmount || '0'}</TableCell>
              </>}
            <TableCell className={classes.tableRegister} data-label="Tipo de usuario" align="center">{getType(item.amount)}</TableCell>
            <TableCell className={classes.tableRegister} data-label="Propuesta de pago" align="center">
              {item.offer || offer ?
                <TextField
                  label="Oferta"
                  variant="outlined"
                  name='offer'
                  id="offer"
                  type="number"
                  value={item.amount}
                  onChange={(event) => handleNewAmount(index, event.target.value)}
                  className={classes.inputs}
                />
                :
                item.amount ?
                  `${moneyFormat(item.amount || 0)}` :
                  `${moneyFormat(item.lastAmount || 0)}` || '$0'
              }
            </TableCell>
            <TableCell className={classes.tableRegisterActions} data-label="Ofertar" align="center">
              {item.offer &&
                <button className={classes.acceptButton}
                  onClick={() => { saveAmount(index, item.amount) }}>
                  <CheckIcon fontSize={'medium'} />
                </button>}
              {!offer &&
                <>
                  <button className={item.offer ? classes.cancelButton : classes.offerButton} onClick={() => changeOffer(index)} >
                    {item.offer ?
                      <CloseIcon fontStyle={'medium'} />
                      :
                      <p className={item.offer ? classes.labelDarkButton : classes.labelButton}>{from === 'admin' ? 'Subastar' : 'Ofertar'}</p>
                    }
                  </button>
                </>
              }
            </TableCell>
          </TableRow>
        ))
      );
    }
  }

  const fileTable = () => {
    if (leads.length > 0) {
      return (
        leads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <TableRow key={index} className={classes.tableHeader}>
            <TableCell className={classes.tableRegister} data-label="">{item.position}</TableCell>
            <TableCell className={classes.tableRegister} data-label="Nombre completo">{item.nombre}</TableCell>
            <TableCell className={classes.tableRegister} data-label="Teléfono" align="center">{item['telefono']}</TableCell>
            <TableCell className={classes.tableRegister} data-label="Fecha de último pago" align="center">{item.lastPaymentDate || 'N/A'}</TableCell>
            <TableCell className={classes.tableRegister} data-label="Cantidad de último pago" align="center">${item.lastPayment || '0'}</TableCell>
            <TableCell className={classes.tableRegister} data-label="Tipo de usuario" align="center">{getType(item['propuesta'])}</TableCell>
            <TableCell className={classes.tableRegister} data-label="Propuesta de pago" align="center">{moneyFormat(item['propuesta'])}</TableCell>
          </TableRow>
        ))
      );
    }
  }

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="inherit" />
      </div>
    )
  }

  return (
    <Box width="100%">
      <ModalErrors
        onClose={() => setModalErrorsVisible(false)}
        open={modalErrorsVisible}
        failedItems={failedItems}
        from='biding'
      />
      {showData ?
        <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <div className={classes.tableAndPaginationContainer}>
              <div className={classes.row}>
                <div className={classes.total}>
                  <text className={classes.totalText}>Total de prospectos</text>
                  <text className={classes.totalTextBold}>{totalLeads}</text>
                </div>
                <div className={classes.total}>
                  <text className={classes.totalText}>Monto total de subasta</text>
                  <text className={classes.totalTextBold}>{moneyFormat(getTotal())}</text>
                </div>
              </div>
            </div>
          <div className={classes.tableAndPaginationContainer}>
            <div className={classes.row}>
              {!file &&
                <SearchBar
                  value={search}
                  handleOnChange={handleFilter}
                />
              }
              {/* <button className={classes.searchButton}>
            <text className={classes.searchButtonText}>
              Busqueda avanzada
            </text>
          </button> */}
            </div>
            <div className={classes.fileContainer}>
              <button onClick={() => createCSV(leads)} className={classes.uploadLabel}>
                <CSVLink
                  style={{ display: 'flex', alignItems: 'center', color: 'white', textDecoration: 'none' }}
                  data={csvData}
                  filename="formato.csv">
                  Descargar formato
                </CSVLink>
              </button>
              <div className={classes.fileUploadButton}>
                <label for='fileCd' className={classes.uploadLabel}>
                  <text>
                    Subir Archivo
                  </text>
                  <input
                    type='file'
                    name='fileCd'
                    id='fileCd'
                    className={classes.uploadInput}
                    onChange={getFile}
                    onClick={cleanFileInput}
                    file={file}
                    accept='.csv'
                    disabled={false}
                  />
                </label>
              </div>
              {file !== null &&
                <div className={classes.fileNameContainer}>
                  <text className={classes.fileName}>
                    {file.name.length < 30 ? file.name : `${file.name.slice(0, 27)}...`}
                  </text>
                </div>
              }
            </div>
            <text className={classes.hint}>
              Se pueden ofertar en pantalla o descargar el siguiente archivo para corregirlos y enviarlos de nuevo.
              <br />
              Recuerda que no se debe cambiar el campo "telefono", ya que es lo que nos permite identificar el usuario.
            </text>
          </div>
          <div className={classes.tableAndPaginationContainer}>
            {leads.length > 0 ?
              <TableContainer className={classes.tableContainer}>
                <Table aria-label="collapsible table">
                  <TableHead className={classes.thead}>
                    <TableRow className={classes.tableHeader}>
                      <TableCell className={classes.th}>
                        {!file &&
                          <Tooltip title="Seleccionar todos" disableFocusListener disableTouchListener arrow placement="top">
                            <Checkbox
                              name='Select all'
                              checked={selectAll}
                              onChange={() => handleSelectAll()}
                            />
                          </Tooltip>
                        }</TableCell>
                      <TableCell className={classes.th}>Nombre completo</TableCell>
                      <TableCell className={classes.th} align="center">Teléfono</TableCell>
                      {from === 'admin' && !file ?
                        <TableCell className={classes.th} align="center">App de recabar datos </TableCell>
                        :
                        <>
                          <TableCell className={classes.th} align="center">Fecha de último pago</TableCell>
                          <TableCell className={classes.th} align="center">Cantidad de último pago</TableCell>
                        </>}
                      <TableCell className={classes.th} align="center">Tipo de usuario</TableCell>
                      <TableCell className={classes.th} align="center">Propuesta de pago</TableCell>
                      {!file &&
                        <TableCell className={classes.th} align="center">{from === 'admin' ? 'Subastar' : 'Ofertar'}</TableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {file ? fileTable() : table()}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={leads.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage='Registros por hoja'
                />
                <div className={classes.saveContainer}>
                  <button className={classes.saveButton} onClick={() => saveBatchOffer()}>
                    <p className={classes.labelButton}>Guardar</p>
                  </button>
                </div >
              </TableContainer>
              :
              <div className={classes.noLeadsContainer}>
                <text className={classes.title}>Esta empresa aun no tiene prospectos registrados</text>
              </div>
            }
            {<div id="csv" />}
          </div>
        </div>
        :
        <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', alignItems: 'center', width: '90%' }}>
          <div className={classes.tableAndPaginationContainer}>
            <div className={classes.noLeadsContainer}>
              <text className={classes.title}>Flexeanalytics está revisando la información de prospectos</text>
            </div>
          </div>
        </div>
      }
    </Box>
  )

}

export default withSnackbar(OrganizationLeads)

const useStyle = makeStyles(theme => ({
  loading: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
  noLeadsContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '40vh',
    // minWidth: '100vw',
    justifyContent: 'center'
  },
  title: {
    margin: '2%  0 0 0',
    fontSize: '30px',
    width: '80% ',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  subtitle: {
    margin: '0 0 2% 0',
    fontSize: '50px',
    width: '80% ',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  uploadLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderRadius: 10,
    ...theme.typography.input,
  },
  uploadInput: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    border: 0,
  },
  fileNameContainer: {
    marginLeft: 5,
  },
  saveContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
    margin: 15,
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    margin: '0 auto',
    // marginTop: 40,
    marginBottom: 10,
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
    overflow: 'hidden',
  },
  tableRegisterActions: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'flex',
      flexDirection: 'column',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
    overflow: 'hidden',
  },
  tableRegisterCheckbox: {
    display: 'flex',
    padding: 25,
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
    overflow: 'hidden',
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  thead: {
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.white.main,
    padding: 15,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
      borderBottom: `3px solid ${theme.palette.gray.main}`,
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  acceptButton: {
    backgroundColor: '#40AB74',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    padding: 5,
    margin: 5,
    width: '20px',
    height: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  offerButton: {
    backgroundColor: '#5D5D60',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    padding: 5,
    margin: 5,
    alignItems: 'center',
  },
  cancelButton: {
    border: '1px solid #5D5D60',
    backgroundColor: '#ffffff',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    padding: 5,
    margin: 5,
    width: '20px',
    height: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  saveButton: {
    backgroundColor: '#000',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    width: '15%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    padding: 10,
    margin: 5,
  },
  labelButton: {
    color: theme.palette.white.main,
    fontSize: 16
  },
  labelDarkButton: {
    color: '#5D5D60',
    fontSize: 16
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  userActions: {
    borderBottom: '1px solid #C4C4C4',
    display: 'flex',
    flexDirection: 'row',
    padding: '20px',
    justifyContent: 'space-between',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    width: '10%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '115px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '0px',
    },
  },
  search: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    color: '#878787'
  },
  tableAndPaginationContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '90%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
    margin: '20px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10
  },
  total: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '45%'
  },
  totalText: {
    fontSize: '28px',
  },
  totalTextBold: {
    fontSize: '28px',
    fontWeight: 'bold'
  },
  searchButton: {
    backgroundColor: theme.palette.gray.main,
    borderRadius: '20px',
    padding: '5px',
    margin: '0px 20px',
    minWidth: '10%'
  },
  searchButtonText: {
    color: theme.palette.white.main,
    fontSize: '16px',
    fontWeight: '600'
  },
  fileContainer: {
    display: 'flex',
    width: '40%',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0px 20px',
    gap: '10px'
  },
  fileUploadButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadLabel: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.darkGray.main}`,
    color: theme.palette.white.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
  },
  uploadInput: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    border: 0,
  },
  hint: {
    display: 'flex',
    ...theme.typography.subtitle,
    color: '#878787',
    margin: '15px 0px 10px 15px',
  }
}));