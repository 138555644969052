const formatPayloadConfirmOrder = (data) => {
  const payload = {
    conceptCode: 'NOMINA',
    detail: {
      orderRecordKey: data.orderRecordKey,
      subtotalOrder: data.totalOrder,
      organizationRecordKey: data.organizationRecordKey
    }
  }
  return payload
}

export default formatPayloadConfirmOrder