import React,{ useState } from 'react';
import { makeStyles } from '@mui/styles'
import { useNavigate } from "react-router-dom";
import Background from "../../../assets/images/backgroundContactForm.svg";
import { TextField } from '@mui/material';
import PrimaryButton from '../../../components/primaryButton';
import { Auth } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import { REGEX_EMAIL,REGEX_PASSWORD,REGEX_PHONE } from '../../../lib/constant';
import CircularProgress from '@mui/material/CircularProgress';
import PasswordInput from '../../../components/passwordInput';
import CognitoErrors from '../../../lib/Auth/cognitoErrors';

const ForgotPassword = (props) => {
    const classes = useStyles()
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const [codeSended, setCodeSended] = useState(false)
    const [email, setEmail] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [code, setCode] = useState('')

    const handleAlert = (variant, message) => {
        props.enqueueSnackbar(message, { variant });
    }

    const handlerChange = event => {
        const { name, value } = event.target
        switch(name){
            case 'email': 
                setEmail(value)
            break;

            case 'newPassword': 
                setNewPassword(value)
            break;

            case 'confirmPassword': 
                setConfirmPassword(value)
            break;
            
            case 'code':
                if(REGEX_PHONE.test(value)){
                    setCode(value)
                }
            break;
        }
    }

    const handlerErrors = () => {
        const errors = []
        switch(codeSended){

            case true:
                if(code === ''){
                    errors.push('El código  de verificación no puede estar vació')
                }

                if(newPassword === '' || confirmPassword === ''){
                    errors.push('La contraseña no puede estar vacía')
                }

                if(newPassword !== confirmPassword){
                    errors.push('La nueva contraseña y la confirmación de la contraseña deben ser iguales')
                }

                if(!REGEX_PASSWORD.test(newPassword)){
                    errors.push('La contraseña debe tener mínimo 6 caracteres, incluyendo por lo menos una mayúscula, un número y un caracter especial (!¡@#$%^&*(),.¿?":{}|<>_-)')
                }
            break;

            case false:
                if(email === ''){
                    errors.push('El correo electrónico no puede estar vacío')
                }
                else if(!REGEX_EMAIL.test(email)){
                    errors.push('Favor de ingresar un correo electrónico válido')
                }
            break;
        }
        return errors
    }

    const sendCode = async() => {
        const error = handlerErrors()

        if(error.length === 0){
            try {
                setIsLoading(true)
                const sendCode =  await Auth.forgotPassword(email.trim())

                if(sendCode){
                    setCodeSended(true)
                    setIsLoading(false)
                    handleAlert('success', 'Código Enviado')
                }
            }
            catch(error) {
                setIsLoading(false)
                handleAlert('error', CognitoErrors(error.message))
            }
        }
        else{
            handleAlert('error', error[0])
        }
    }

    const _newPassword = async() => {
        const error = handlerErrors()
        if(error.length === 0){
            try{
                await Auth.forgotPasswordSubmit(email.trim(), code, newPassword)
                handleAlert('success', 'Contraseña restablecida exitosamente!')
                navigate('/companyLogin')
            }
            catch(error) {
                handleAlert('error', CognitoErrors(error.message))
            }
        }
        else{
            error.forEach(element => {
                handleAlert('error', element)
            });
        }

    }

  return (
    <main className={classes.contianer}>
        <div className={classes.registerContainer}>

            <h2 className={classes.title}>
                Recuperar Contraseña
            </h2>

            {
                isLoading ?
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
                :
                <div className={classes.containerForm}>
                {
                    codeSended ?
                    <>
                        <p className={classes.description}>
                            Enviamos un código secreto a tu correo electrónico.
                            Por favor ingrésalo a continuación.
                        </p>

                        <TextField
                            label="Código"
                            variant="outlined"
                            name='code'
                            required
                            value={code}
                            onChange={handlerChange}
                            className={classes.inputs}
                            inputProps={{ maxLength: 6 }}
                        />

                        <PasswordInput
                            label = "Nueva Contraseña"
                            name = "newPassword"
                            value = {newPassword}
                            handlerPassword = {handlerChange}
                        />

                        <PasswordInput
                            label = "Confirmar Contraseña"
                            name = "confirmPassword"
                            value = {confirmPassword}
                            handlerPassword = {handlerChange}
                        />
                    </>
                :
                    <>
                        <p className={classes.description}>
                            Enviaremos un código para restaurar contraseña a tu correo
                            electrónico asociado a la cuenta que deseas recuperar.
                        </p>
                        <TextField
                            label="Correo Electrónico"
                            variant="outlined"
                            name='email'
                            required
                            value={email}
                            onChange={handlerChange}
                            className={classes.inputs}
                            type = 'email'
                        />
                    </>
                }
                    <PrimaryButton 
                        margin = '20px 0px 0px 0px' 
                        handleClick = {() => !codeSended ? sendCode() : _newPassword()} 
                        text = "Enviar" 
                        color = "#000000"
                    />
                    <p className={classes.register} onClick = {() => navigate('/companyRegister', { state: { subscriptionType: 'premium' } })}>
                        Aún no tengo cuenta, <b>Registrarme</b>
                    </p>
                </div>
            }
            
        </div>
    </main>
  )
}

export default withSnackbar(ForgotPassword);

const useStyles = makeStyles(theme => ({
    contianer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        margin: 0,
        backgroundPosition: 'bottom',
        alignItems: 'center',
        minHeight: '100vh',
    },
    containerForm: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        backgroundColor: theme.palette.white.main,
        borderRadius: '0.625rem',
        padding: '5%',
        width: '50%',
        margin: '0 auto',
        flexDirection: 'column',
        marginBottom: '10%',
        [theme.breakpoints.down('md')]: {
          padding: 20,
          zIndex: 0,
        },
    },
    title: {
        ...theme.typography.title,
        fontSize: 60,
        color: theme.palette.primary.main,
        textAlign: 'center',
        lineHeight: 1,
        margin: '5% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
        },
    },
    description: {
        ...theme.typography.subtitle,
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'justify',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    inputs: {
        marginBottom: '20px !important'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    register: {
        textAlign: 'center',
        fontSize: 14,
        marginTop: 20,
        '&:hover' : {
          cursor: 'pointer'
        }
    }
    
}))