import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Background from '../../../assets/images/backgroundGrayyBottom.svg';
import PrimaryButton from '../../../components/primaryButton';
import csvtojsonV2 from 'csvtojson';
import * as R from 'ramda';
import EditIcon from '@mui/icons-material/Edit';
import GetLeads from '../../../lib/Analytics/getLeads';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBar from '../../../components/searchBar';
import removeAccents from '../../../lib/removeAccents';
import { useNavigate } from "react-router-dom";
import CreateACFile from '../../../lib/CreateFiles/acFile';
import { useCookies } from 'react-cookie';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, TablePagination } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import EditLead from '../../../lib/Analytics/editLead';
import {
  BANKS,
  REGEX_EMAIL,
  REGEX_LETTERS,
  REGEX_PHONE,
  REGEX_RFC
} from "../../../lib/constant";
import { clabe } from 'clabe-validator';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Delete from '@mui/icons-material/Delete';
import deleteLead from '../../../lib/Analytics/deleteLead';
import ConfirmDeleteLeadModal from '../../../components/confirmDeleteLeadModal';

const ViewLeads = (props) => {
  const classes = useStyle();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [leads, setLeads] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [search, setSearch] = useState('');
  const [shownLeads, setShownLeads] = useState([]);
  const [cookies] = useCookies(['user']);
  const [failedItems, setFailedItems] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [openDeleteItemModal, setOpenDeleteItemModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getSavedLeads();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setOpen = (open, index) => {
    const newData = [...leads];
    newData[index].open = open;
    setLeads(newData)
    setShownLeads(newData)
  }

  const setEdit = (edit, index) => {
    const newData = [...leads];
    newData[index].edit = edit;
    setLeads(newData)
    setShownLeads(newData)
  }

  const getSavedLeads = async () => {
    try {
      setLoading(true);
      const session = await Auth.currentSession();
      const organizationRecordKey = R.pathOr('N/A', ['accessToken', 'payload', 'sub'], session);
      const response = await GetLeads(organizationRecordKey);
      const leadsArr = response.data.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
      setLeads(leadsArr)
      setShownLeads(leadsArr)
      const failedItems = leadsArr.filter((lead) => lead.fileStatus === 'error' || lead.fileStatus === 'draft')
      setFailedItems(failedItems);
      setLoading(false);
    } catch (err) {
      handleAlert('error', 'Algo salió mal');
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleFilter = (event) => {
    const filter = (event.target.value).toLowerCase();
    const filteredArr = leads.filter(item => getFullName(item).includes(filter));
    setSearch(filter);
    setShownLeads(filteredArr);
  }

  const getFullName = (item) => {
    const fullName = `${item.name} ${item.secondName} ${item.paternalLastName} ${item.maternalLastName}`
    return removeAccents(fullName).toLowerCase();
  }

  const createFile = async () => {
    setLoading(true);
    try {
      const companyId = cookies.user.sub;
      const prospects = leads.filter(item => {
        if (item.fileStatus === 'draft') {
          return {
            "name": `${item.name} ${item.secondName} ${item.paternalLastName} ${item.maternalLastName}`,
            "phoneNumber": item.phone,
            "email": item.email,
            "clabe": item.clabe,
            "leadRecordKey": item.leadRecordKey
          }
        }
      })
      const response = await CreateACFile(companyId, prospects);
      if (response.success) {
        await getSavedLeads();
        handleAlert('success', 'Archivo creado correctamente. Espere 30 minutos para ver los resultados')
      }
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
    }
    setLoading(false);
  }

  const getStatus = (status) => {
    switch (status) {
      case 'draft':
        return (
          <PendingIcon sx={{ fontSize: 30 }} />
        )
      case 'processing':
        return (
          <TimelapseIcon sx={{ fontSize: 30, color: '#E6C80B' }} />
        )
      case 'success':
        return (
          <CheckCircleIcon sx={{ fontSize: 30, color: '#40AB74' }} />
        )
      case 'error':
        return (
          <ErrorIcon sx={{ fontSize: 30, color: '#CC3C3C' }} />
        )
      default:
        break;
    }
  }


  const handleNewValue = (index, value, field) => {
    const newLeads = [...leads];
    switch (field) {
      case 'clabe':
        newLeads[index].clabe = value;
        break;
      case 'name':
        newLeads[index].name = value;
        break;
      case 'secondName':
        newLeads[index].secondName = value;
        break;
      case 'paternalLastName':
        newLeads[index].paternalLastName = value;
        break;
      case 'maternalLastName':
        newLeads[index].maternalLastName = value;
        break;
      case 'phone':
        newLeads[index].phone = value;
        break;
      case 'email':
        newLeads[index].email = value;
        break;
      case 'rfc':
        newLeads[index].rfc = value;
        break;
      case 'bankName':
        newLeads[index].bankName = value;
        break;
      default:
        break;
    }
    setLeads(newLeads);
  }

  const validate = (lead) => {
    let errorMessage = '';
    if (R.isNil(lead.name) || lead.name === '') {
      errorMessage = `Campo "nombre" vacío`;
    } else if (!REGEX_LETTERS.test(lead.name)) {
      errorMessage = `Error de formato en campo "nombre"`;
    } else if (lead.secondName) {
      if (!REGEX_LETTERS.test(lead.secondName)) {
        errorMessage = `Error de formato en campo "segundo nombre"`;
      }
    } else if (R.isNil(lead.paternalLastName) || lead.paternalLastName === '') {
      errorMessage = `Campo "apellido paterno" vacío`;
    } else if (!REGEX_LETTERS.test(lead.paternalLastName)) {
      errorMessage = `Error de formato en campo "apellido paterno"`;
    } else if (R.isNil(lead.maternalLastName) || lead.maternalLastName === '') {
      errorMessage = `Campo "apellido materno" vacío`;
    } else if (!REGEX_LETTERS.test(lead.maternalLastName)) {
      errorMessage = `Error de formato en campo "apellido materno"`;
    } else if (R.isNil(lead.email) || lead.email === '') {
      errorMessage = `Campo "correo" vacío`;
    } else if (!REGEX_EMAIL.test(lead.email)) {
      errorMessage = `Error de formato en campo "correo"`;
    } else if (R.isNil(lead.clabe) || lead.clabe === '') {
      errorMessage = `Campo "clabe" vacío`;
    } else if (!clabe.validate(lead.clabe).ok) {
      errorMessage = `Error de formato en campo "clabe"`;
    } else if (R.isNil(lead.phone) || lead.phone === '') {
      errorMessage = `Campo "telefono" vacío`;
    } else if (!REGEX_PHONE.test(lead.phone)) {
      errorMessage = `Error de formato en campo "telefono"`;
    } else if (lead.rfc) {
      if (lead.rfc !== '' && !REGEX_RFC.test(lead.rfc)) {
        errorMessage = `Error de formato en campo "rfc"`;
      }
    }
    return errorMessage;
  }

  const editLead = async (index, lead) => {
    try {
      setEdit(false, index)
      const editLead = { ...lead, fileStatus: 'draft' }
      const error = validate(lead);
      if (error !== '') {
        setEdit(true, index)
        handleAlert('error', error);
      } else {
        const response = await EditLead([editLead]);
        if (response.success) {
          await getSavedLeads();
          handleAlert('success', 'Se ha guardado el prospecto con éxito')
        } else {
          handleAlert('error', 'Algo salió mal')
        }
      }
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
    }
  }

  const editBatchLeads = async (leads) => {
    try {
      const response = await EditLead(leads);
      if (response.success) {
        handleAlert('success', 'Se han guardado los prospectos con éxito')
      } else {
        handleAlert('error', 'Algo salió mal')
      }
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
    }
  }

  const table = () => {
    if (shownLeads.length > 0) {
      return (
        shownLeads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <>
            <TableRow key={index} className={classes.tableHeader} sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell className={classes.info} data-label="Name" align="center">{item.edit ?
                <TextField
                  variant="outlined"
                  name='name'
                  id="name"
                  type="text"
                  value={item.name}
                  onChange={(event) => handleNewValue(index, event.target.value, 'name')}
                  className={classes.inputs}
                /> : item.name}
              </TableCell>
              <TableCell className={classes.info} data-label="SecondName" align="center">{item.edit ?
                <TextField
                  variant="outlined"
                  name='secondName'
                  id="secondName"
                  type="text"
                  value={item.secondName}
                  onChange={(event) => handleNewValue(index, event.target.value, 'secondName')}
                  className={classes.inputs}
                /> : item.secondName}
              </TableCell>
              <TableCell className={classes.info} data-label="PaternalLastName" align="center">{item.edit ?
                <TextField
                  variant="outlined"
                  name='paternalLastName'
                  id="paternalLastName"
                  type="text"
                  value={item.paternalLastName}
                  onChange={(event) => handleNewValue(index, event.target.value, 'paternalLastName')}
                  className={classes.inputs}
                /> : item.paternalLastName}
              </TableCell>
              <TableCell className={classes.info} data-label="MaternalLastName" align="center">{item.edit ?
                <TextField
                  variant="outlined"
                  name='maternalLastName'
                  id="maternalLastName"
                  type="text"
                  value={item.maternalLastName}
                  onChange={(event) => handleNewValue(index, event.target.value, 'maternalLastName')}
                  className={classes.inputs}
                /> : item.maternalLastName}</TableCell>
              <TableCell className={classes.info} data-label="Clabe" align="center">
                {item.edit ?
                  <TextField
                    variant="outlined"
                    name='clabe'
                    id="clabe"
                    type="text"
                    value={item.clabe}
                    onChange={(event) => handleNewValue(index, event.target.value, 'clabe')}
                    className={classes.inputs}
                    inputProps={{ maxLength: 18 }}
                  /> :
                  item.clabe ? item.clabe : 0}
              </TableCell>
              <TableCell className={classes.info} data-label="App" align="center">
                {item.externalRecordKey ? <CheckCircleRoundedIcon sx={{ fontSize: 30, color: '#40AB74' }} /> : <RemoveCircleRoundedIcon sx={{ fontSize: 30 }} />}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!item.open, index)}
                >
                  {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <IconButton
                  aria-label="edit row"
                  size="small"
                  onClick={() => !item.edit ? setEdit(!item.edit, index) : editLead(index, item)}
                >
                  {(!item.edit ?
                    <EditIcon className={classes.icon} fontSize='medium' color='info' />
                    : <CheckIcon />)}
                </IconButton>
                <IconButton
                  aria-label="edit row"
                  size="small"
                  onClick={(e) => handleDeleteItemModal(e, item)}
                >
                  <Delete className={classes.icon} fontSize='medium' color='error' />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse in={item.open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1, display: 'flex', flexDirection: 'row' }}>
                    <div className={classes.halfContainer}>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>Teléfono:</text>
                        {item.edit ?
                          <TextField
                            variant="outlined"
                            name='phone'
                            id="phone"
                            type="text"
                            value={item.phone}
                            inputProps={{ maxLength: 10 }}
                            onChange={(event) => handleNewValue(index, event.target.value, 'phone')}
                            className={classes.inputs}
                          /> :
                          <text className={classes.info}>{item.phone}</text>}
                      </div>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>Correo electrónico:</text>
                        {item.edit ?
                          <TextField
                            variant="outlined"
                            name='email'
                            id="email"
                            type="text"
                            value={item.email}
                            onChange={(event) => handleNewValue(index, event.target.value, 'email')}
                            className={classes.inputs}
                          /> :
                          <text className={classes.info}>{item.email}</text>}
                      </div>
                    </div>
                    <div className={classes.halfContainer}>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>RFC:</text>
                        {item.edit ?
                          <TextField
                            variant="outlined"
                            name='rfc'
                            id="rfc"
                            type="text"
                            value={item.rfc}
                            onChange={(event) => handleNewValue(index, event.target.value, 'rfc')}
                            className={classes.inputs}
                          /> :
                          <text className={classes.info}>{item.rfc}</text>}
                      </div>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>Banco:</text>
                        {item.edit ?
                          <Select
                            value={item.bankName || BANKS[0].label}
                            name="bankName"
                            onChange={(event) => handleNewValue(index, event.target.value, 'bankName')}
                            className={classes.selectContainer}
                          >
                            {
                              BANKS.map(item => (
                                <MenuItem value={item.label}>{item.label}</MenuItem>
                              ))
                            }
                          </Select> :
                          <text className={classes.info}>{item.bankName}</text>}
                      </div>
                    </div>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ))
      );
    }
  }

  const handleDeleteItemModal = (e, item) => {
    e.stopPropagation();

    setSelectedItem(item)
    setOpenDeleteItemModal(true)
  }

  const deleteItem = async (lead) => {
    setLoading(true)
    const res = await deleteLead(lead.leadRecordKey)

    if (res.success) {
      // initData()
      getSavedLeads()
    }
    setOpenDeleteItemModal(false)
    setLoading(false)
  }

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="inherit" />
      </div>
    )
  }

  return (
    <div className={classes.containerBackground}>
      <ConfirmDeleteLeadModal
        onClose={() => setOpenDeleteItemModal(false)}
        open={openDeleteItemModal}
        item={selectedItem}
        deleteItem={() => deleteItem(selectedItem)}
      />
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>
          Prospectos guardados
        </h1>
        <div className={classes.buttonContainer}>
          <PrimaryButton color={'black'} text='Subir prospectos' handleClick={() => navigate('/uploadLeads')} />
        </div>
      </div>
      <Box width={'80%'} margin={'30px'}>
        <div className={classes.tableContainer}>
          <SearchBar
            value={search}
            handleOnChange={handleFilter}
          />
          {/* <button className={classes.searchButton}>
            <text className={classes.searchButtonText}>
              Busqueda avanzada
            </text>
          </button> */}
        </div>
      </Box>
      <Box width={'80%'} marginBottom={'40px'}>
        <TableContainer className={classes.tableContainer}>
          <Table aria-label="collapsible table">
            <TableHead className={classes.thead}>
              <TableRow classes={classes.infoRow}>
                <TableCell className={classes.th} align="center">Nombre</TableCell>
                <TableCell className={classes.th} align="center">Segundo nombre</TableCell>
                <TableCell className={classes.th} align="center">Apellido paterno</TableCell>
                <TableCell className={classes.th} align="center">Apellido materno</TableCell>
                <TableCell className={classes.th} align="center">Clabe interbancaria</TableCell>
                <TableCell className={classes.th} >App de recabar datos</TableCell>
                <TableCell className={classes.th} align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table()}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={leads.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Registros por hoja'
          />
        </TableContainer>
      </Box>
    </div>
  )
}

export default withSnackbar(ViewLeads);

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    marginTop: '2%'
  },
  title: {
    fontSize: '62px',
    width: '80% ',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  fileInputContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  fileInputContainer2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    width: '40%',
    marginTop: -100,
    marginBottom: -20,
    [theme.breakpoints.down('lg')]: {
      width: '60%',
      marginTop: -50,
      marginBottom: -20,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 0,
      marginBottom: -20,
    },
  },
  saveContainer: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '100%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '20px',
    overflowX: 'auto',
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  thead: {
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.white.main,
    padding: 15,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
      borderBottom: `3px solid ${theme.palette.gray.main}`,
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
    overflow: 'hidden',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
  },
  loading: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
  fileContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '15px 0px',
    gap: '10px',
    justifyContent: 'space-between'
  },
  fileUploadButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // margin: '5px'
  },
  uploadLabel: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.darkGray.main}`,
    color: theme.palette.white.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
    height: 'fit-content',
    margin: '5px'
  },
  uploadInput: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    border: 0,
  },
  hint: {
    display: 'flex',
    ...theme.typography.subtitle,
    color: '#878787',
  },
  errorIcon: {
    width: '25px',
    height: '25px',
    backgroundColor: '#CC3C3C',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  halfContainer: {
    width: '50%',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: '0px 20px 0px 0px',
  },
  infoBold: {
    ...theme.typography.subtitle,
    fontWeight: '600',
    fontSize: '14px',
  },
  info: {
    ...theme.typography.subtitle,
    fontSize: '14px !important',
    textAlign: 'center',
    marginLeft: '10px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '20%',
  },
}));