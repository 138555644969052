import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { useNavigate, useLocation } from "react-router-dom";
import {
  MAXIMUM_SIZE
} from "../../../lib/constant";

import EditOrganization from '../../../lib/Analytics/editOrganization';
import UploadFiles from '../../../lib/Analytics/uploadFiles';
import FileInput from '../../../components/fileInput';
import axios from 'axios';
import PrimaryButton from '../../../components/primaryButton';
import LinearProgress from '@mui/material/LinearProgress';
import Background from "../../../assets/images/backgroundContactForm.svg";
import CircularProgress from '@mui/material/CircularProgress';

const CompanyUploadFiles = (props) => {
  const classes = useStyle(props);
  let navigate = useNavigate();
  const loc = useLocation();

  const [CDFile, setCDFile] = useState({data: null, from: null})
  const [ACFile, setACFile] = useState({data: null, from: null})
  const [IOFile, setIOFile] = useState({data: null, from: null})
  const [PRFile, setPRFile] = useState({data: null, from: null}) //Esta es opcional
  const [isUploading, setIsUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const progressTotal = {CDFile: 0,ACFile: 0,IOFile: 0,PRFile: 0 }
  const [isLoading, setIsLoading] = useState(false)

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  }

  const handleErrors = () => {
    let error = {};

    if (CDFile === null) {
      error.CDFile = 'Favor de ingresar el Comprobante de Domicilio';
    }

    else if (ACFile === null) {
      error.ACFile = 'Favor de ingresar el Acta Constitutiva';
    }
    
    else if (IOFile === null) {
      error.IOFile = 'Favor de ingresar la Identificación Oficial';
    }
    return error;
  }

  const handleFileType = (type, size) => {
    const fileType = type.toLowerCase().split('/')
    // if(fileType[1] === 'pdf' || fileType[1] === 'jpg' || fileType[1] === 'jpeg') {
    //   if(size < MAXIMUM_SIZE ) {
    //     return {type: true, size: true}
    //   }
    //   else{
    //     return {type: true, size: false}
    //   }
    // }
    // else{
    //   return {type: false, size: false}
    // }
    return fileType[1] === 'pdf' || fileType[1] === 'jpg' || fileType[1] === 'jpeg' ? {type : true} : {type : false}
  }

  const uploadProgress = (progressEvent,from) => {
    const barProgress = 100 * (progressEvent.loaded / progressEvent.total);
    progressTotal[from] = barProgress
    const progressT = progressTotal.PRFile !== 0 ? ((progressTotal.CDFile+progressTotal.ACFile+progressTotal.IOFile+progressTotal.PRFile)/4) : ((progressTotal.CDFile+progressTotal.ACFile+progressTotal.IOFile)/3)
    
    setProgress(progressT);
    if( progressT >= 99 ) setIsLoading(true);
  }

  const saveInformation = async (event) => {
    try {
      event.preventDefault();
      const error = handleErrors()

      if (Object.keys(error).length === 0) {

        const filesArray = PRFile.data === null ? [CDFile,ACFile,IOFile]
        : [CDFile,ACFile,IOFile,PRFile]

        const documentName = filesArray.map(item => {
            const fileType = item.from === 'CDFile' ? 'ComprobanteDomicilio' : 
            item.from === 'ACFile' ? 'ActaConstitutiva' :
            item.from === 'IOFile' ? 'IdentificaciónOficial' :
            'PoderesRepresentate'
            const fileName = `${fileType}.${item.data.name.slice(-3)}`

            return fileName
        })

        const item = {
            documentName: {
              CDFile: documentName[0],
              ACFile: documentName[1],
              IOFile: documentName[2],
              PRFile: PRFile.data === null ? null : documentName[3]
            }
        }

        const statusOrg = await EditOrganization(loc.state.organizationRecordKey, item);

        if (statusOrg.success) {

          const allUrlFiles = filesArray.map(item => {
            const fileName = item.from === 'CDFile' ? documentName[0] : 
            item.from === 'ACFile' ? documentName[1] :
            item.from === 'IOFile' ? documentName[2] :
            item.from === 'PRFile' && documentName[3]
            
            return UploadFiles(loc.state.organizationRecordKey,fileName,item.data.type)
          })

          const resultUrlFiles  = await Promise.all(allUrlFiles)

          const files = filesArray.map((items,index) => {
            setIsUploading(true)
            return axios.put(resultUrlFiles[index].url, items.data , {
              headers: { 'Content-Type': '' },
              onUploadProgress: (progressEvent) => uploadProgress(progressEvent,filesArray[index].from),
            })
          })

          const result = await Promise.all(files)
          const resultUpload = result.some( item => item.status === 200 )

          if(resultUpload){
            setIsLoading(false)
            handleAlert('success', 'Archivos almacenados con éxito');
            navigate('/companyDataType',{
              state: {
              organizationRecordKey: loc.state.organizationRecordKey,
              subscriptionType: loc.state.subscriptionType
            }
            });
          }
          else{
            setIsLoading(false)
            handleAlert('error', 'No se logró subir el documento, favor de intentarlo nuevamente');
          }

        }
        else {
          handleAlert('error', statusOrg.error);
        }
      }
      else {
        for (const value in error) {
          handleAlert('error', error[value]);
        }
      }

    } catch (err) {
      console.log("ERR", err);
    }
  }

  const handleFile = (event) => {
    const  {files, name } = event.target
    const responseType = handleFileType(files[0].type,files[0].size)

    if(responseType.type /*&& responseType.size*/) {
      switch (name) {
        case 'CDFile':
          setCDFile({data: files[0], from: name})
        break;

        case 'ACFile':
          setACFile({data: files[0], from: name})
        break;

        case 'IOFile':
          setIOFile({data: files[0], from: name})
        break;

        case 'PRFile':
          setPRFile({data: files[0], from: name})
        break;
      }
    }
    else {
      const error = responseType.type === false && responseType.size === false ? 
      'Solo se aceptan archivos PDF,JPG y JPEG | Tamaño Máximo es de 5MB' :
      responseType.type === false ? 'Solo se aceptan archivos PDF,JPG y JPEG' :
      responseType.size === false && 'Documento demasiado grande'
      
      handleAlert('error', error);
    }
  }

  return (
    <div className={classes.containerBackground}>
        <div className={classes.registerContainer}>

        <h2 className={classes.title}>
          Documentación de la Empresa
        </h2>

            <main className={classes.containerForm}>
              {
                isLoading ? <CircularProgress />
                :
                <form className={classes.form}>
                  <div className={classes.containerCompanyForm}>

                    <div className={classes.containerForm1}>

                      <label className = {classes.labelButtonFile}>
                        <p className={classes.textFile}>Comprobante de Domicilio*</p>
                        <FileInput
                          type = 'file'
                          id = "CDFile"
                          name = "CDFile"
                          onChange = {handleFile} 
                          file = {CDFile.data}
                          disabled = {false}
                          width = '100%'
                        />
                      </label>

                      <label className = {classes.labelButtonFile}>
                        <p className={classes.textFile}>Acta Constitutiva*</p>

                        <FileInput
                          type = 'file'
                          id = "ACFile"
                          name = "ACFile"
                          width = '100%'
                          onChange = {handleFile} 
                          file = {ACFile.data}
                          disabled = {false}
                        />
                      </label>
                    </div>

                    <div className={classes.containerForm2}>

                      <label className = {classes.labelButtonFile}>
                        <p className={classes.textFile}>Identificación Oficial*</p>
                        <FileInput
                          type = 'file'
                          id = "IOFile"
                          name = "IOFile"
                          width = '100%'
                          onChange = {handleFile} 
                          file = {IOFile.data}
                          disabled = {false}
                        />
                      </label>

                      <label className = {classes.labelButtonFile}>
                        <p className={classes.textFile}>Poderes del Representate</p>
                        
                        <FileInput
                          type = 'file'
                          id = "PRFile"
                          name = "PRFile"
                          width = '100%'
                          onChange = {handleFile} 
                          file = {PRFile.data}
                          disabled = {false}
                        />
                      </label>

                    </div>

                  </div>

                  <div>
                    <p>Formatos Aceptados: pdf, jpg, jpeg.</p>
                    {/* <p>Formatos Aceptados: pdf, jpg, jpeg. | Tamaño Máximo: 5MB</p> */}
                  </div>
                  
                  <div className={classes.progressBarContainer}>
                      {
                        isUploading && (
                          <>
                            <LinearProgress
                              color='primary'
                              variant='determinate'
                              value={progress.toFixed(0)}
                              sx = {{width: '90%'}}
                            />
                            <p className ={classes.progressBarText}>{progress.toFixed(0)}%</p>
                          </>
                        )
                      }
                  </div>

                  <PrimaryButton handleClick = {saveInformation} text = "Continuar" color = "#000000"/>

                </form>
              }
              
            </main>

        </div>
        
    </div>
    )
}
export default withSnackbar(CompanyUploadFiles);

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    margin: 0,
    backgroundPosition: 'bottom',
    alignItems: 'center',
  },
  registerContainer: {
  width: '60%'
  },
  title: {
    ...theme.typography.title,
    fontSize: 60,
    color: theme.palette.primary.main,
    textAlign: 'center',
    lineHeight: 1,
    margin: '5% 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },

  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.625rem',
    width: '100%',
    marginBottom: '6.25rem',
    paddingTop: 50,
    paddingBottom: 50,
    [theme.breakpoints.down('md')]: {
      padding: 20,
      zIndex: 0,
      marginLeft: '-5%'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-10%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  containerButton: {
    backgroundColor: theme.palette.primary.main,
    width: '14.063rem',
    height: '3.125rem',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  labelButton: {
    color: theme.palette.white.main,
    fontSize: 16
  },
  inputs: {
    width: '100%',
    marginBottom: '10% !important'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
  icons: {
    width: '35px',
  },
  phoneContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10%',
  },
  inputPhone: {
    width: '90%',
  },
  appsContainer: {
    margin: '1% auto 10% auto'
  },
  imgIcons: {
    width: '70%'
  },
  selectContainer: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
  },
  containerCompanyForm: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      zIndex: 0,
    },
  },
  containerForm1: {
    width: '45%',
    marginRight: '10%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 0
    },
  },
  containerForm2: {
    width: '45%',
    marginLeft: '5%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0
    },
  },
  cityContainer: {
    display: 'flex',
    width: '100%',
    alignContent: 'center',
    justifyContent: 'space-between',
    marginBottom: '10%'
  },
  stateSelect: {
    width: '95%'
  },
  fileButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonFiles: {
    backgroundColor: "transparent",
    width: '60%',
    height: '3.125rem',
    borderRadius: '4.25rem',
    margin: '1.875rem 0 ',
    border: `3px solid ${theme.palette.darkGray.main}`,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  labelButtonFile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '5%',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: '10%'
    },
  },
  textFile: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  progressBarContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  progressBarText: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  }
}));