import React from 'react';
import { makeStyles } from '@mui/styles';
import BackgroundBottom from '../../../../assets/images/backgroundBottom.svg'
import CardServices from '../../../../components/cardServices';
import CardDescription from '../../../../components/cardDescription';
import Company from '../../../../assets/images/icons/Company.svg'
import SecondaryButton from '../../../../components/secondaryButton';

const useStyle = makeStyles(theme => ({
    container: {
        backgroundImage: `url(${BackgroundBottom})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        backgroundColor: '#CBCBCC32',
        paddingTop: '200px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '200px',
    },
    containerBackground: {
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },

    },
    title: {
        color: theme.palette.white.main,
        width: '100%',
        fontSize: '62px',
        [theme.breakpoints.down('md')]: {
            fontSize: '40px'
        },

    },
    titleServices: {
        ...theme.typography.title,
        fontSize: 20,
    },
    subtitle: {
        ...theme.typography.subtitle,
        color: '#5D5D60',
    },
    containerCard: {
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginTop: '40px'

        },
    },
    contextCard: {
        margin: '3.125rem 0',
        fontSize: '3em',
    },
    image: {
        width: '3rem',
        height: '3rem',
        marginRight: '1.25rem'
    },
    services: {
        ...theme.typography.serviceText,
        width: '150px'
    },
    containerText: {
        width: '50%',
        marginRight: '10%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    icon: {
        position: 'relative',
        top: '0.625rem'
    },
    context2Container: {
        display: 'flex',
        margin: 'auto auto 0 3.125rem'
    },
    cardContainer: {
        marginTop: '30%'
    },
    buttonContainer: {
        width:' 50%',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    }
}))

const DataType = () => {
    const classes = useStyle();

    return (
        <div className={classes.container}>
            <div className={classes.containerBackground}>
                <div className={classes.containerText}>
                        <h3 className={classes.services}>Que ofrecemos</h3>
                        <h1 className={classes.title}>Analítica de Datos para conocer mercados​</h1>
                    <div className={classes.cardContainer}>
                        <CardDescription
                            img = {Company}
                            title = "Empresas"
                            description = "Como empresa o negocio, puedes suscribirte a diversos servicios analíticos para poder conocer información relevante de las preferencias y hábitos de uso de las plataformas digitales de los usuarios relacionados con: "
                            titleButton = "Registrate ahora"
                            colorButton = "#5D5D60"
                        />
                    </div>
                </div>
                <div className={classes.containerCard}>
                    <CardServices />
                    <div className={classes.buttonContainer}>
                        <SecondaryButton 
                            text = "Suscribirme"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DataType;
