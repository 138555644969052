import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const ChartBarVertical = (props) => {
  const classes = useStyle();
  return (
    <div className={classes.chartContainer}>
      <h3 className={classes.title}>{props.title}</h3>
      <ResponsiveContainer height={500}>
        <BarChart
          data={props.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={props.dataKey} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey={props.dataKey1} stackId="a" fill="#9FDEF1" />
          {props.dataKey2 && <Bar dataKey={props.dataKey2} stackId="a" fill="#2A5D78" />}
          {props.dataKey3 && <Bar dataKey={props.dataKey3} stackId="a" fill="#F6AA54" />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ChartBarVertical;

const useStyle = makeStyles(theme => ({
  chartContainer: {
    backgroundColor: 'white',
    border: `5px solid ${theme.palette.lightGray.main}`,
  },
  title: {
    marginTop: 10,
    marginLeft: 10,
  },
}));