import React from 'react';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles(theme => ({
  searchContainer: {
    display: 'flex',
    height: (props) => props.height ? props.height : 40,
    width: (props) => props.width ? props.width : '100%',
    marginBottom: (props) => props.marginBottom ? props.marginBottom : '0px',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
    background: theme.palette.white.main,
    borderRadius: 50,
    border: `1px solid black`,
  },
  searchInput: {
    height: '100%',
    width: '100%',
    borderRadius: 50,
    fontSize: 22,
    outline: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize:16,
    },
  },
  icon: {
    margin: '0 1%'
  },
}));

const SearchBar = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.searchContainer}>
      <SearchIcon color ="primary" className={classes.icon} fontSize='large'/>
      <input
        className={classes.searchInput}
        placeholder='Buscar'
        value={props.value}
        onChange={props.handleOnChange}
        name = {props.name || 'searchBar'}
      />
    </div>
  );
}

export default SearchBar;