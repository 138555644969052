import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const deleteLead = async (leadRecordKey) => {
  try {
    const result = await API.del(API_ANALYTICS, '/users/saveLeads', {
      body: {
        leadRecordKey
      }
    });
    return {
      success: result.success,
      data: result.message
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default deleteLead;