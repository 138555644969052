const getTermsAndConditions = async(type) => {
  const application =  type === 'terms' ? 'ANALYTICS_TYC' :'ANALYTICS_PP'
  try {
    const terms = await fetch(`${process.env.REACT_APP_ENDPOINT_API_TERMS2}?applicationKey=${application}`, {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_TERMS_API_KEY
      }
    }).then(response => response.json())

    if(terms.success){
      return {
        success: true,
        data: terms.data
      }
    }
    else{
      return {
        success: false,
        data: terms.data
      }
    }

  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}
  
export default getTermsAndConditions;