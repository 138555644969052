const deleteTermsAndConditions = async(id) => {
    try {
      const terms = await fetch(`${process.env.REACT_APP_ENDPOINT_API_TERMS2}/${id}`, {
        method: "DELETE",
        headers: {
          "x-api-key": process.env.REACT_APP_TERMS_API_KEY
        }
      }).then(response => response.json())
      debugger
      if(terms.success){
        return {
          success: true,
          message: 'Eliminación exitosa'
        }
      }
      else{
        return {
          success: false,
          message: 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde'
        }
      }

    } catch (err) {
      return {
        success: false,
        error: 'Algo salio mal',
      }
    }
  }
  
  export default deleteTermsAndConditions;