import { makeStyles } from '@mui/styles';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import PrimaryButton from '../primaryButton';
import { useState } from 'react';
import SaveResponseQuestions from '../../lib/Questionnaire/saveResponseQuestions'
import CircularProgress from '@mui/material/CircularProgress';



const QuestionTypeOne = (props) => {
  const classes = useStyle();

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const saveQuestion = async () => {
    try {
      setLoading(true)
      const body = {
        employeeId: props.questionInfo.employeeId,
        neritoId: null,
        questionId: props.questionInfo.questionId,
        companyId: props.questionInfo.companyId,
        period: props.questionInfo.period,
        questionType: props.questionInfo.questionType,
        response: value,
        userName: null,
      }
      const questionnaire = await SaveResponseQuestions(body)
      if (questionnaire.success) {
        props.handleAlert('success', 'Pregunta guardada con exito');
        const newQuestions = props.questions.filter(question => question.questionId !== props.questionInfo.questionId)
        setValue('')
        props.setQuestions(newQuestions)
        setLoading(false)
      } else {
        props.handleAlert('error', 'Algo salio mal');
        setLoading(false)
      }
    } catch (error) {
      props.handleAlert('error', 'Algo salio mal');
      setLoading(false)
    }
  }

  return (
    <>
      <div>
        <TextareaAutosize className={classes.textArea} aria-label="minimum height" rowsMin={5} rowsMax={5} value={value} onChange={handleChange} />
      </div>
      {
        loading ?
          <div className={classes.loading}>
            <CircularProgress />
          </div> :
          <div className={classes.buttonContainer}>
            <div className={classes.button}>
              <PrimaryButton classname={classes.button} text={'Guardar'} handleClick={() => saveQuestion()} />
            </div>
          </div>
      }
    </>
  )

}

export default QuestionTypeOne

const useStyle = makeStyles(theme => ({
  textArea: {
    width: '100%',
    border: '1px solid',
    borderColor: 'rgb(224 224 224 / 20)',
    maxHeight: 81,
    minHeight: 81,
    marginTop: 10,
    borderRadius: '5px',
    fontFamily: 'Arial'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: '30%'
  }
}))