import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme =>({
    button: {
        backgroundColor: (props) => (props.color ? props.color : "#FFFFFF90"),
        width: (props) => (props.width ? props.width : '100%'), 
        height: '3.125rem',
        borderRadius: '4.25rem',
        margin: (props) => (props.margin ? props.margin : '3% 0'),
        border: `1px solid ${theme.palette.primary.main}`,
        '&:hover': {
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            margin: '10% 0 !important',
            width: '100% !important'
        },
    },
    label: {
        color: (props) => (props.textColor ? props.textColor : props.color ? theme.palette.white.main :  "#000000" ),
        fontSize: 16,
        fontWeight: 'bold'
    }
}))

const PrimaryButton = (props) => { 
    const classes = useStyle(props);
     
  return (
    <button className={classes.button} onClick = {props.handleClick}>
        <p className={classes.label}>{props.text}</p>
    </button>
  )
};

export default PrimaryButton;
