import * as R from 'ramda';

const dataFilter = (data, filters) => {
  const genders = {
    title: 'Genéro de Prospectos',
    data: [
      { name: 'Masculino', value: 0 },
      { name: 'Femenino', value: 0 },
      { name: 'Otro', value: 0 },
    ],
  };
  
  const ageGroup = {
    title: 'Edad de Prospectos',
    data: [
      { name: '10-19', Femenino: 0, Masculino: 0, Otro: 0 },
      { name: '20-29', Femenino: 0, Masculino: 0, Otro: 0 },
      { name: '30-39', Femenino: 0, Masculino: 0, Otro: 0 },
      { name: '40-49', Femenino: 0, Masculino: 0, Otro: 0 },
      { name: '50-59', Femenino: 0, Masculino: 0, Otro: 0 },
      { name: '60+', Femenino: 0, Masculino: 0, Otro: 0 },
    ],
  };

  try {
    const leadsFiltered = Object.values(data.leadsObject).filter(lead => {
      if (filters.age.active && (lead.age < filters.age.minValue || lead.age > filters.age.maxValue)) {
        return false;
      }
      if (!R.isEmpty(filters.state.selectedKeys) && !filters.state.selectedKeys.includes(lead.state)) {
        return false;
      }
      if (!R.isEmpty(filters.city.selectedKeys) && !filters.city.selectedKeys.includes(lead.city)) {
        return false;
      }
      return true;
    });

    //Handle lead data manipulation
    leadsFiltered.forEach(lead => {
      if (lead.gender === 'Masculino') {
        genders.data[0].value++;
        ageGroup.data[assignAgeGroup(lead.age)][lead.gender]++;
      } else if (lead.gender === 'Femenino') {
        genders.data[1].value++;
        ageGroup.data[assignAgeGroup(lead.age)][lead.gender]++;
      } else {
        genders.data[2].value++;
        ageGroup.data[assignAgeGroup(lead.age)][lead.gender]++;
      }
    });
    const leadsLength = leadsFiltered.length;
    genders.data[0]['label'] = `Masculino ${genders.data[0].value} (${(genders.data[0].value / leadsLength * 100).toFixed(2)}%)`;
    genders.data[1]['label'] = `Femenino ${genders.data[1].value} (${(genders.data[1].value / leadsLength * 100).toFixed(2)}%)`;
    genders.data[2]['label'] = `Otro ${genders.data[2].value} (${(genders.data[2].value / leadsLength * 100).toFixed(2)}%)`;

    //Handle question data manipulation
    const leadKeys = leadsFiltered.map(lead => lead.userRecordKey);

    const questionsType2 = data.questionsByType.q2.map(question => {
      question.options = question.options.map(option => ({ ...option, numAns: 0}));
      question.answers.forEach(answer => {
        if (leadKeys.includes(answer.neritoId)) {
          question.options[answer.response.optionId - 1]['numAns']++;
        }
      });
      return {
        title: question.questionText,
        data: question.options.map(option => ({
          name: option.descripcion,
          value: option.numAns,
          label: `${option.descripcion} ${option.numAns} (${(option.numAns / leadsLength * 100).toFixed(2)}%)`,
        })),
      };
    });
    
    const questionsType3 = data.questionsByType.q3.map(question => {
      question.options = question.options.map(option => ({ ...option, numAns: 0}));
      question.answers.forEach(answer => {
        answer.response.options.forEach(option => {
          if (option.value && leadKeys.includes(answer.neritoId)) {
            question.options[option.optionId - 1]['numAns']++;
          }
        });
      });
      return {
        title: question.questionText,
        data: question.options.map(option => ({
          name: option.descripcion,
          value: option.numAns,
          label: `${option.descripcion} ${option.numAns} (${(option.numAns / leadsLength * 100).toFixed(2)}%)`,
        })),
      }
    });
    
    return {
      genders,
      ageGroup,
      questionsType2,
      questionsType3,
    }
  } catch (err) {
    throw new Error(err);
  }
}

//Assign age group
const assignAgeGroup = (age) => {
  if (age > 10 && age < 20) { return 0 }
  else if (age > 20 && age < 30) { return 1 }
  else if (age > 30 && age < 40) { return 2 }
  else if (age > 40 && age < 50) { return 3 }
  else if (age > 50 && age < 60) { return 4 }
  else { return 5 }
}

export default dataFilter;