import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { REGEX_EMAIL } from "../../lib/constant";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import OrganizationApproved from '../../lib/Analytics/getApprovedOrganization';
import PasswordInput from '../passwordInput';
import CircularProgress from '@mui/material/CircularProgress';

const useStyle = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.625rem',
    width: '100%',
    marginBottom: '6.25rem',
    paddingTop: 50,
    paddingBottom: 50,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 20,
      zIndex: 0,
      marginLeft: '-5%'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-10%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  containerButton: {
    backgroundColor: theme.palette.primary.main,
    width: '14.063rem',
    height: '3.125rem',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  labelButton: {
    color: theme.palette.white.main,
    fontSize: 16
  },
  inputs: {
    width: '100%',
    marginBottom: '10% !important'
  },
  loading: {
    display: 'flex',
    minHeight: '25vh',
    flexDirection: 'column',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
  forgotPassword: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.blue.main,
    '&:hover' : {
      cursor: 'pointer'
    }
  }
}));

const CompanyLoginForm = (props) => {

  const classes = useStyle(props);
  let navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const { user } = cookies;

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ errorName: false, errorEmail: false, errorMessage: false, errorSyntax: false });

  const handleErrors = () => {
    let error = {};
    if (email === '') {
      error.email = "Favor de ingresar un correo electrónico";
      errors.errorEmail = true;
      setErrors(errors);
    } else if (!REGEX_EMAIL.test(email)) {
      error.emailSyntax = "Favor de ingresar un correo electrónico válido";
      errors.emailSyntax = true;
      setErrors(errors);
    } else if (password === '') {
      error.password = "Favor de ingresar una contraseña";
      errors.errorPassword = true;
      setErrors(errors);
    }
    return error;
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const login = async (event) => {
    event.preventDefault();
    setLoading(true)
    const error = handleErrors();
    if (Object.keys(error).length === 0) {
      const status = await OrganizationApproved(email, password)
      setLoading(false)
      if (status.success) {
        setCookie('user', status.user.attributes, { path: '/' });
        if (status.admin) {
          navigate('/admin/organizationList');
        } else {
          navigate('/');
        }
      }
      else if(status.error) {
        handleAlert('error', status.error);
      }
      else {
        navigate('/comingSoon');
      }
    }
    else {
      setLoading(false)
      for (const value in error) {
        handleAlert('error', error[value])
      }
    }
  }

  return (
    <div className={classes.containerForm}>
      {
        loading
        ? <div className={classes.loading}>
          <CircularProgress color="inherit" />
        </div>
        : <form className={classes.form}>

            <div>
              <TextField
                label="Correo Electrónico"
                variant="outlined"
                name='email'
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                className={classes.inputs}
              />
              <PasswordInput
                label = "Contraseña"
                name = "password"
                value = {password}
                handlerPassword = {(event) => setPassword(event.target.value)}
              />
            </div>

            <button className={classes.containerButton} onClick={login}>
              <p className={classes.labelButton}>Inicia Sesión</p>
            </button>

            <p className={classes.forgotPassword} onClick={() => navigate('/forgotPassword')}>Olvidé mi contraseña</p>

          </form>
      }
    </div>
  );
}

export default withSnackbar(CompanyLoginForm);