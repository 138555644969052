import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const updateUser = async (data) => {
  try {
    const result = await API.put(API_ANALYTICS, '/users/',{
        body : data
    });
    return {
      success: true,
      message: result.message
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default updateUser;