import React from 'react';
import { makeStyles } from '@mui/styles';
import PrimaryButton from '../primaryButton';
import BackgroundCard from '../../assets/images/cardServicesBackground.svg'
import SecondaryButton from '../secondaryButton';

const useStyle = makeStyles(theme => ({
    containerCard: {
        ...theme.globals.centerVertical,
        background: (props) => (props.background != null ? props.background : 'linear-gradient(180deg, rgb(119,119,120) 20%, rgb(100,100,102) 30%, rgb(91,92,99) 50%)'),
        borderRadius: 30,
        width: '100%',
        "&:hover": {
            boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 40%)'
        },
        height: '100%',
        [theme.breakpoints.down('md')]: {
            marginBottom: '10%'
        },
    },
    description: {
        ...theme.typography.subtitle,
        width: '100%',
        color: (props) => props.background ? 'black' : 'white',
        textAlign: 'center',
        margin: '0.625rem 0'
    },
    lineDecoration: {
        borderTop: `1px solid ${theme.palette.primary.main}`,
        width: '90%',
        margin: '1.25rem auto 0.625rem auto',
    },
    title: {
        ...theme.typography.title,
        fontSize: '3.125rem',
        color: (props) => props.background ? 'black' : 'white',
        marginTop: '1.25rem',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 30
        },
    },
    buttonContainer:{
        width: '80%',
    },
    textContainer: {
        padding: ' 0 5%',
        [theme.breakpoints.down('md')]: {
            padding: ' 0 10%'
        },
    }

}))

const CardPrice = (props) => {
    const classes = useStyle(props);
    return <div className={classes.containerCard}>
        <p className={classes.title}>{props.title}</p>
        <div className={classes.lineDecoration}></div>
        <div className={classes.textContainer}>
            <p className={classes.description}>
                {props.content}
            </p>
        </div>
        <div className={classes.buttonContainer}>
            {props.color !== 'transparent' ?
                <PrimaryButton color={props.color} text={props.text} handleClick = {props.handleClick} />
                :
                <SecondaryButton text={props.text} handleClick = {props.handleClick}/>
            }
        </div>
    </div>;
};

export default CardPrice;
