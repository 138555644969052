import { Auth } from 'aws-amplify';

const login = async (email, password) => {
  try {
    const user = await Auth.signIn(email, password);
    return {
      success: true,
      user: user,
    }
  } catch (err) {
    return {
      success: false,
      error: getErrorMessage(err.message),
    }
  }
}

const getErrorMessage = (message) => {
  if (message === 'User does not exist.') {
    return 'El usuario no existe';
  } else if (message === 'Incorrect username or password.') {
    return 'Nombre de usuario o contraseña incorrecta';
  } else {
    return 'Algo salio mal'
  }
}

export default login;