import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { withSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { Auth } from 'aws-amplify';
import * as R from 'ramda';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import { CHART_FILTERS } from '../../../lib/constant';
import ChartBarVertical from '../../../components/chartBarVertical';
import ChartBarHorizontal from '../../../components/chartBarHorizontal';
import ChartPieFill from '../../../components/chartPieFill';
import ChartPieOutline from '../../../components/chartPieOutline';
import GetChartsData from '../../../lib/Charts/getChartsData';
import DataFilter from '../../../lib/Charts/dataFilter';
import ChartMap from '../../../components/chartMap';

const Charts = (props) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const loc = useLocation();

  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [interval, setInterval] = useState({});
  const [chartsData, setChartsData] = useState({}); //Data to display in charts
  const [data, setData] = useState({}); //Unprocessed data for filtering
  const [filters, setFilters] = useState(CHART_FILTERS);
  const [ageSlider, setAgeSlider] = useState([0, 100]);

  useEffect(() => {
    getData();
  }, []);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleReturn = () => {
    navigate(-1);
  };

  const getData = async () => {
    try {
      const session = await Auth.currentSession();
      const organizationRecordKey = R.pathOr('N/A', ['accessToken', 'payload', 'sub'], session);
      const response = await GetChartsData(organizationRecordKey, loc.state.interval, '');
      if (response.success) {
        const cData = {
          genders: response.data.genders,
          ageGroup: response.data.ageGroup,
          nearbyBusinesses: response.data.nearbyBusinesses,
          questionsType2: response.data.questionsType2,
          questionsType3: response.data.questionsType3,
        }
        const unprocessedData = {
          leadsObject: response.data.leadsObject, //Lead Data
          questionsByType: response.data.questionsByType, //Questionnaire Data
        }
        const filtersObj = {...filters};
        filtersObj.gender['values'] = response.data.filterSets.gender.map(item => ({ label: item, checked: false}));
        filtersObj.state['values'] = response.data.filterSets.state.map(item => ({ label: item, checked: false}));
        filtersObj.city['values'] = response.data.filterSets.city.map(item => ({ label: item, checked: false}));
        const intervalDates = {
          startDate: moment(response.data.intervalStartDate, 'DD-MM-YYYY').format('DD/MM/YY'),
          endDate: moment(response.data.intervalEndDate, 'DD-MM-YYYY').format('DD/MM/YY'),
        }
        setOrganizationName(response.data.organizationName);
        setInterval(intervalDates);
        setChartsData(cData);
        setData(unprocessedData);
        setFilters(filtersObj);
        setLoading(false);
      } else {
        setHasError(true);
        throw new Error(response.error);
      }
    } catch (err) {
      handleAlert('error', 'Algo salio mal');
      setHasError(true);
      setLoading(false);
    }
  }

  const handleAgeSlider = (event, value) => {
    setAgeSlider([value[0], value[1]]);
  }

  const handleSetFilters = (name, value, index) => {
    const filterObj = {...filters};
    if (name === 'state') {
      filterObj.state.values[index].checked = value;
    } else if (name === 'city') {
      filterObj.city.values[index].checked = value;
    } else {
      filterObj.age.active = true;
      filterObj.age.minValue = value[0];
      filterObj.age.maxValue = value[1];
    }
    setFilters(filterObj);
  }

  const handleFilterData = () => {
    try {
      const filterObj = {...filters};
      filterObj.state.selectedKeys = (filters.state.values.filter(item => item.checked === true)).map(item => item.label);
      filterObj.city.selectedKeys = (filters.city.values.filter(item => item.checked === true)).map(item => item.label);
      const cData = DataFilter(data, filters);
      setChartsData(cData);
    } catch (err) {
      handleAlert('error', 'Algo salio mal');
      setLoading(false);
    }
  }

  const generatePDF = () => {
    try {
      const input = document.getElementById('pdf');
      html2canvas(input, { useCORS: true, allowTaint: true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({ orientation: 'landscape' });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.internal.pageSize.height = pdfHeight;
        pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, '', 'NONE');
        pdf.save('analitica.pdf');
      });
    } catch (err) {
      handleAlert('error', 'Algo salio mal');
    }
  }

  if (loading) {
    return (
      <Grid container className={classes.emptyContainer}>
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  if (hasError) {
    return (
      <Grid container className={classes.emptyContainer}>
        <div className={classes.errorContainer}>
          <p className={classes.subtitle}>No existe analítica para este periodo</p>
          <Button variant="contained" color="primary" className={classes.returnButton} onClick={handleReturn}>Regresar</Button>
        </div>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.container}>
      <Grid xs={2} className={classes.filtersContainer} item={true}>
        <p>Rango de Edad</p>
        <Slider
          value={[ageSlider[0], ageSlider[1]]}
          valueLabelDisplay="auto"
          marks={[{value: 0, label: '0'}, {value: 100, label: '100'}]}
          color="info"
          onChange={handleAgeSlider}
          onChangeCommitted={(event, value) => handleSetFilters('age', value)}
        />
        <Divider />
        <p>Estado</p>
        <FormGroup className={classes.formGroup}>
          {filters.state.values && filters.state.values.map((state, index) => (
            <FormControlLabel 
              name="state"
              key={`state-${index}`}
              control={<Checkbox color="info" />}
              label={state.label}
              className={classes.checkbox}
              checked={state.checked}
              onChange={(event) => handleSetFilters(event.target.name, event.target.checked, index)}
            />
          ))}
        </FormGroup>
        <Divider />
        <p>Ciudad</p>
        <FormGroup>
          {filters.city.values && filters.city.values.map((city, index) => (
            <FormControlLabel 
              name="city"
              key={`state-${index}`}
              control={<Checkbox color="info" />}
              label={city.label}
              className={classes.checkbox}
              checked={city.checked}
              onChange={(event) => handleSetFilters(event.target.name, event.target.checked, index)}
            />
          ))}
        </FormGroup>
        <div className={classes.filterButtonContainer}>
          <Button variant="contained" color="info" className={classes.filterButton} onClick={handleFilterData}>Aplicar</Button>
        </div>
        <div className={classes.filterButtonContainer}>
          <Button variant="contained" color="primary" className={classes.filterButton} onClick={generatePDF}>Descargar Reporte</Button>
        </div>
      </Grid>
      <Grid id={'pdf'} container xs={10} item={true}>
        <Grid xs={12} item={true}>
          <p className={classes.title}>{`Reporte analítico ${organizationName}`}</p>
          <p className={classes.subtitle}>{`${interval.startDate} - ${interval.endDate}`}</p>
        </Grid>
        {(R.hasPath(['nearbyBusinesses'], chartsData) && !R.isEmpty(chartsData.nearbyBusinesses.data)) && (
          <Grid xs={12} item={true}>
            <ChartMap 
              title={chartsData.nearbyBusinesses.title}
              center={chartsData.nearbyBusinesses.center}
              points={chartsData.nearbyBusinesses.data}
            />
          </Grid>
        )}
        {R.hasPath(['ageGroup'], chartsData) && (
          <Grid xs={12} lg={6} item={true}>
            <ChartBarVertical
              data={chartsData.ageGroup.data}
              dataKey="name"
              dataKey1="Femenino"
              dataKey2="Masculino"
              dataKey3="Otro"
              title={chartsData.ageGroup.title}
            />
          </Grid>
        )}
        {R.hasPath(['genders'], chartsData) && (
          <Grid xs={12} lg={6} item={true}>
            <ChartPieFill
              data={chartsData.genders.data}
              title={chartsData.genders.title}
            />
          </Grid>
        )}
        {R.hasPath(['questionsType2'], chartsData) && chartsData.questionsType2.map((question, index) => {
          return question.data.length < 4 ? (
            <Grid key={index} xs={12} item={true}>
              <ChartPieOutline
                data={question.data}
                title={question.title}
              />
            </Grid>
          ) : (
            <Grid key={index} xs={12} item={true}>
              <ChartBarHorizontal
                data={question.data}
                dataKey="name"
                dataKey1="value"
                title={question.title}
              />
            </Grid>
          )
        })}
        {R.hasPath(['questionsType3'], chartsData) && chartsData.questionsType3.map((question, index) => (
          <Grid key={index} xs={12} item={true}>
            <ChartBarHorizontal
              data={question.data}
              dataKey="name"
              dataKey1="value"
              title={question.title}
            />
          </Grid>
        ))}
        {R.isEmpty(chartsData) && (
          <div className={classes.emptyContainer} />
        )}
      </Grid>
    </Grid>
  );
}

export default withSnackbar(Charts);

const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.lightGray.main,
    display: 'flex',
  },
  filtersContainer: {
    backgroundColor: theme.palette.lightGray.main,
    padding: '10px 20px 10px 20px',
  },
  formGroup: {
    marginBottom: 10,
  },
  checkbox: {
    marginBottom: -10,
  },
  filterButtonContainer: {
    marginTop: 25,
  },
  filterButton: {
    width: '100%',
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.lightGray.main,
    height: '100vh',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px',
  },
  returnButton: {
    marginTop: '15px !important',
    width: '50%',
  },
  title: {
    marginLeft: 10,
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    marginLeft: 10,
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));