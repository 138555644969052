const DeleteQuestion = async (questionId) => {
  return fetch(`${process.env.REACT_APP_URL_ENDPOINT_SURVEY}/${questionId}`, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_SURVEY
    }
  })
    .then(response => response.json())
    .then(item => {
      return {
        success: true,
        message: 'Eliminación exitosa'
      }
    })
    .catch(err => {
      return {
        success:false,
        message: 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde'
      }
    })
}

export default DeleteQuestion;