import React, { useState,useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from "react-router-dom";
import { withSnackbar } from 'notistack';

import Background from "../../../assets/images/backgroundContactForm.svg";
import {
    REGEX_EMAIL,
    REGEX_RFC,
    GENDERS,
    REGEX_LETTERS_AND_NUMBERS,
  } from "../../../lib/constant";

import MenuItem from "@mui/material/MenuItem";
import StatesCities from '../../../lib/location/statesCities';
import States from '../../../lib/location/states';

import Select from "@mui/material/Select";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import PrimaryButton from '../../../components/primaryButton';
import getEmail from '../../../lib/Analytics/getLeadsWithoutNerito';
import EditUser from '../../../lib/Analytics/editUser';

const useStyle = makeStyles(theme => ({
    containerBackground: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        margin: 0,
        backgroundPosition: 'bottom',
        alignItems: 'center',
    },
    registerContainer: {
        width: '60%'
    },
    title: {
        ...theme.typography.title,
        fontSize: 60,
        color: theme.palette.primary.main,
        textAlign: 'center',
        lineHeight: 1,
        margin: '5% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
        },
    },

    containerForm: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        backgroundColor: theme.palette.white.main,
        borderRadius: '0.625rem',
        width: '100%',
        margin: '6% 0 10% 0',
        paddingTop: 50,
        [theme.breakpoints.down('md')]: {
          // flexDirection: 'column',
          padding: 20,
          zIndex: 0,
          marginLeft: '-5%'
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: '-10%',
        },
      },
      form: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        gap: '10px',
        [theme.breakpoints.down('md')]: {
          width: '100%'
        },
    
      },
      containerButton: {
        backgroundColor: theme.palette.primary.main,
        width: '14.063rem',
        height: '3.125rem',
        borderRadius: '4.25rem',
        '&:hover': {
          cursor: 'pointer'
        },
        width: '60%',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
          width: '100%'
        },
        marginBottom: '30px'
      },
      labelButton: {
        color: theme.palette.white.main,
        fontSize: 16
      },
      inputs: {
        width: '100%',
        marginBottom: '10% !important'
      },
      iconContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
      },
      icons: {
        width: '35px',
      },
      birthdayContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '5%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
      },
      appsContainer: {
        margin: '1% auto 10% auto'
      },
      imgIcons: {
        width: '70%'
      },
      select: {
        width: '90%',
        [theme.breakpoints.down('md')]: {
          width: 'auto'
        },
      },
    containerCompanyForm: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gap: '5%',
        alignContent: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
          zIndex: 0,
        }
    },
}))

const LeadInformation = (props) => {
    const classes = useStyle(props);
    let navigate = useNavigate();
    const loc = useLocation();
    const date = new Date();
    const minDate = `${date.getFullYear() - 16}-${('0'+ (date.getMonth()+1)).slice(-2)}-${('0'+date.getDate()).slice(-2)}`
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('');
    const [birthdate, setBirthdate] = useState(minDate)
    const [gender, setGender] = useState(0)
    const [rfc, setRfc] = useState('');
    const [address, setAddress] = useState('')
    const [state, setState] = useState('');
    const [city, setCity] = useState();
    const [cities, setCities] = useState([]);
    const [enableCities, setEnableCities] = useState(false);
    const [organization, setOrganization] = useState('')

    useEffect(() => {
      getUserEmail()
    }, [])

    const getUserEmail = async () => {
      const responseEmail = await getEmail(loc.state.email,loc.state.phone)
      if(responseEmail.success){
        setName(`${responseEmail.data[0].name} ${responseEmail.data[0].secondName}`)
        setLastName(`${responseEmail.data[0].paternalLastName} ${responseEmail.data[0].maternalLastName}`)
        setEmail(loc.state.email)
        setOrganization(responseEmail.data[0].organizationRecordKey)
      }
      else {
        handleAlert('error', 'Lo lamento, debe de registrarse con el correo electrónico y/o número teléfonico que dio de alta la organización')
        navigate('/leadRegister');
      }
    }

    const handleAlert = (variant, message) => {
      props.enqueueSnackbar(message, { variant });
    };
  
    const handleErrors = () => {
      let error = {}
      if (name === '') {
        error.name = "Favor de ingresar su Nombre"
      }

      if (lastName === '') {
        error.lastName = "Favor de ingresar su Apellido"
      }

      if(rfc){
        if (!REGEX_RFC.test(rfc)) {
            error.rfc = "Favor de ingresar un RFC válido"
        }
      }

      if (email === '') {
        error.email = "Favor de ingresar un Correo Electrónico"
      }
  
      if (!REGEX_EMAIL.test(email)) {
        error.emailSyntax = "Favor de ingresar un Correo Electrónico válido"
      }
  
      if (state === '') {
        error.state = "Favor de seleccionar un Estado"
      }
  
      if (city === '') {
        error.city = "Favor de seleccionar una Ciudad"
      }
  
      if (address === '') {
        error.legalAddress = "Favor de ingresar una Dirección"
      }
  
      if (birthdate === '') {
        error.birthdate = "Favor de ingresar su Fecha de Nacimiento"
      }

      if (gender === '') {
        error.gender = "Favor de seleccionar su Género"
      }
  
      return error
    }
  
    const saveInformation = async (event) => {
      event.preventDefault();
      const error = handleErrors()
      if (Object.keys(error).length === 0) {
        const item = {
          name: name,
          lastName: lastName,
          birthdate:birthdate,
          rfc: rfc,
          country_code: loc.state.country_code,
          phone: loc.state.phone,
          email: email,
          gender: gender,
          state: state,
          city: city,
          address: address,
          acceptAnalyticsManagesData: false,
          organization: organization
        }
        const statusUser = await EditUser(loc.state.userRecordKey, item);
        if (statusUser.success) {
          navigate('/userLogin');
        }
        else {
          handleAlert('error', statusUser.error);
        }
      }
      else {
        for (const value in error) {
          handleAlert('error', error[value]);
        }
      }
    }
  
    const handleOnChange = (event) => {
      const { name, value } = event.target
      switch (name) {
        case 'name':
            setName(value)
        break;

        case 'lastName':
            setLastName(value)
        break;

        case 'birthdate':
            setBirthdate(value)
        break;

        case 'email':
          setEmail(value)
        break;

        case 'states':
          setState(value)
          setEnableCities(!enableCities)
          const cities = StatesCities(value)
          setCities(cities)
        break;
        case 'city':
          setCity(value)
        break;

        case 'address':
            setAddress(value)
        break;

        case 'rfc':
        if (REGEX_LETTERS_AND_NUMBERS.test(value)) {
          setRfc(value)
        }
        break;
      }
    }

    return (
    <main className={classes.containerBackground}>
        <section className={classes.registerContainer}>
            <h2 className={classes.title}>
                Información personal
            </h2>
            
            <div className={classes.containerForm}>
                <form className={classes.form}>
                    <div className={classes.containerCompanyForm}>
                    <div className={classes.containerForm1}>
                        <TextField
                            label="Nombre(s)"
                            variant="outlined"
                            name='name'
                            required
                            value={name}
                            onChange={handleOnChange}
                            className={classes.inputs}
                        />
                        <TextField
                            label="Correo Electrónico"
                            variant="outlined"
                            name='email'
                            required
                            value={email}
                            onChange={handleOnChange}
                            className={classes.inputs}
                        />
                        <TextField
                            label="RFC con Homoclave"
                            variant="outlined"
                            name='rfc'
                            value={rfc}
                            onChange={handleOnChange}
                            className={classes.inputs}
                        />

                        <TextField
                            label="Dirección"
                            variant="outlined"
                            name='address'
                            // id='address'
                            required
                            value={address}
                            onChange={handleOnChange}
                            className={classes.inputs}
                        />
                    </div >
                    <div className={classes.containerForm2}>
                        <TextField
                            label="Apellido(s)"
                            variant="outlined"
                            name='lastName'
                            required
                            inputProps={{ maxLength: 50 }}
                            value={lastName}
                            onChange={handleOnChange}
                            className={classes.inputs}
                        />
                        <div className = {classes.birthdayContainer}>
                            <TextField
                                id="birthdate"
                                label="Fecha de Nacimiento"
                                type="date"
                                name="birthdate"
                                defaultValue={minDate}
                                required
                                className={classes.inputs}
                                inputProps={{ min: "1950-01-01", max:minDate }}
                                onChange={handleOnChange}
                            />

                            <FormControl className={`${classes.inputs}`}>
                                <InputLabel id='gender-select-label'>Género</InputLabel>
                                <Select
                                  labelId='gender-select-label'
                                  id='gender-select'
                                  label='Género'
                                  variant='outlined'
                                  value={gender}
                                  name='genderSelect'
                                  required
                                  classes={classes.select}
                                  onChange={(event) => setGender(event.target.value)}
                                >
                                    {GENDERS.map(item => (
                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <FormControl className={classes.inputs} fullWidth>
                          <InputLabel id='state-select-label'>Estado</InputLabel>
                          <Select
                            labelId='state-select-label'
                            id='state-select'
                            label='Estado'
                            variant='outlined'
                            value={state}
                            name="states"
                            onChange={handleOnChange}
                          >
                            {
                              States.map(items => (
                                <MenuItem value={items.label}>{items.label}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>

                        <FormControl className={classes.inputs} fullWidth>
                            <InputLabel id='city-select-label'>Ciudad</InputLabel>
                            <Select
                                labelId='city-select-label'
                                id='city-select'
                                label='Ciudad'
                                variant='outlined'
                                value={city}
                                name="city"
                                onChange={handleOnChange}
                                disabled={!enableCities}
                              >
                                {
                                  cities.map(items => (
                                    <MenuItem value={items}>{items}</MenuItem>
                                  ))
                                }
                            </Select>
                        </FormControl>

                    </div>
                    </div >

                    <PrimaryButton handleClick = {saveInformation} text = "Continuar" color = "#000000"/>

                </form >
            </div >
        </section>
    </main>
  )
}

export default withSnackbar(LeadInformation);