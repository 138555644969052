import axios from 'axios';

const downloadBills = async (orderRecordKey) => {
  try {
    const BILLING_API = process.env.REACT_APP_BILLING_API;
    const BILLING_API_KEY = process.env.REACT_APP_BILLING_API_KEY;
    const result = await axios.get(`${BILLING_API}/zip`, {
      params: {
        orderRecordKey,
      },
      headers: {
        'x-api-key': BILLING_API_KEY,
      },
    });
    return result.data;
  } catch (err) {
    throw new Error(err.message);
  }
}

export default downloadBills;