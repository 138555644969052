import { BANKS } from "../constant";

const formatPayloadOrder = (payload, organizationRecordKey, formInputs) => {

  const items = payload.map(item => (
    {
      leadRecordKey: item.leadRecordKey,
      amount: parseFloat(parseFloat(item.amount).toFixed(2)),
      clabe: item.clabe,
      bankName: getBank(item.clabe, item.bankName).label,
      phoneNumber: item.phone,
      firstName: item.name,
      lastName: item.paternalLastName,
      rfc: item.rfc,
      zipCode: item.postalCode,
    }
  ))
  return {
    ...formInputs,
    period: `${formInputs.period.split('-')[1]}/${formInputs.period.split('-')[0]}`,
    organizationRecordKey,
    items
  }
}

const getBank = (acc, ebank) => {
  const index = BANKS.findIndex(item => item.id.substring(item.id.length - 3) === acc.substring(0, 3));
  const bankInfo = BANKS[index];
  if (bankInfo) {
    // setBank(bankInfo)
    return bankInfo
    // setFormInputs({ ...formInputs, receiverInstitution: bankInfo})
  } else {
    const b = BANKS.find(item => item.label === ebank)
    return b
  }
}

export default formatPayloadOrder