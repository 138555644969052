import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const saveBatchBidding = async (organizationRecordKey, leads, status) => {
  try {
    const response = await API.put(API_ANALYTICS, '/bidding/batch', {
      body: {
        organizationRecordKey,
        leads,
        status,
      }
    });
    return {
      success: true,
      failedItems: response.failedItems,
      succesfulItems: response.succesfulItems,
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default saveBatchBidding;