import { Auth } from 'aws-amplify';

const logout = async () => {
  try {
    await Auth.signOut({ global: true });
    return {
      success: true,
    }
  } catch (err) {
    return {
      success: false,
      err: err.message,
    }
  }
}

export default logout;