import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const sendEmail = async (userRecordKey, email) => {
  try {
    const result = await API.post(API_ANALYTICS, '/users/code', {
      body: {
        userRecordKey: userRecordKey,
        email: email
      }
    });
    return {
      success: true,
    }
  } catch (err) {
    console.log(err)
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default sendEmail;