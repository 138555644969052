import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const approvalOrgatization = async (organizationRecordKey, approval) => {
  try {
    const result = await API.put(API_ANALYTICS, '/users/approval', {
      body: {
        organizationRecordKey,
        approval
      }
    });
    return {
      success: true,
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default approvalOrgatization;