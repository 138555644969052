import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { useNavigate, useLocation } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  REGEX_EMAIL,
  REGEX_RFC,
  REGEX_CURP,
  GENDERS,
  MAXIMUM_SIZE
} from "../../lib/constant";
import COUNTRIES from '../../lib/location/countries';
import AppsContainer from '../appsContainer';
import moment from 'moment'
import EditOrganization from '../../lib/Analytics/editOrganization';
import UploadFiles from '../../lib/Analytics/uploadFiles';
import FileInput from '../fileInput';
import PrimaryButton from '../primaryButton';

const useStyle = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.625rem',
    width: '100%',
    marginBottom: '6.25rem',
    paddingTop: 50,
    paddingBottom: 50,
    [theme.breakpoints.down('md')]: {
      padding: 20,
      zIndex: 0,
      marginLeft: '-5%'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-10%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  containerButton: {
    backgroundColor: theme.palette.primary.main,
    width: '14.063rem',
    height: '3.125rem',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  labelButton: {
    color: theme.palette.white.main,
    fontSize: 16
  },
  inputs: {
    width: '100%',
    marginBottom: '10% !important'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
  icons: {
    width: '35px',
  },
  phoneContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10%',
  },
  inputPhone: {
    width: '90%',
  },
  appsContainer: {
    margin: '1% auto 10% auto'
  },
  imgIcons: {
    width: '70%'
  },
  selectContainer: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
  },
  containerCompanyForm: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      zIndex: 0,
    },
  },
  containerForm1: {
    width: '45%',
    marginRight: '10%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 0
    },
  },
  containerForm2: {
    width: '45%',
    marginLeft: '5%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0
    },
  },
  cityContainer: {
    display: 'flex',
    width: '100%',
    alignContent: 'center',
    justifyContent: 'space-between',
    marginBottom: '10%'
  },
  stateSelect: {
    width: '95%'
  },
  fileButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonFiles: {
    backgroundColor: "transparent",
    width: '60%',
    height: '3.125rem',
    borderRadius: '4.25rem',
    margin: '1.875rem 0 ',
    border: `3px solid ${theme.palette.darkGray.main}`,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  labelButtonFile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '5%',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: '10%'
    },
  },
  textFile: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
}));

const CompanyRepresentativeForm = (props) => {
  const classes = useStyle(props);
  let navigate = useNavigate();
  const loc = useLocation();
  const maxDate = `${moment().format('YYYY') - 18}-${moment().format('MM-DD')}`

  const [name, setName] = useState('');
  const [paternalSurname, setPaternalSurname] = useState('');
  const [maternalSurname, setMaternalSurname] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [gender, setGender] = useState('');
  const [nationality, setNationality] = useState('');
  const [curp, setCurp] = useState('');
  const [rfc, setRfc] = useState('');
  // const [position, setPosition] = useState('');
  const [phone, setPhone] = useState('');
  // const [cellphone, setCellPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('')

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  }

  const handleErrors = () => {
    let error = {};
    if (name === '') {
      error.name = 'Favor de ingresar un Nombre';
    } 
    else if (paternalSurname === '') {
      error.paternalSurname = 'Favor de ingresar un Apellido Paterno';
    } 
    else if (maternalSurname === '') {
      error.maternalSurname = 'Favor de ingresar un Apellido Materno';
    } 
    else if (birthdate === '') {
      error.birthdate = 'Favor de ingresar una Fecha de Nacimiento';
    } 
    else if (gender === '') {
      error.gender = 'Favor de seleccionar un Género';
    } 
    else if (nationality === '') {
      error.nationality = 'Favor de seleccionar una Nacionalidad';
    } 
    else if (curp === '') {
      error.curp = 'Favor de ingresar un CURP';
    } 
    else if (!REGEX_CURP.test(curp)) {
      error.curp = 'Favor de ingresar un CURP válido';
    } 
    else if (rfc === '') {
      error.rfc = 'Favor de ingresar un RFC';
    } 
    else if (!REGEX_RFC.test(rfc)) {
      error.rfc = 'Favor de ingresar un RFC válido';
    } 
    // else if (position === '') {
    //   error.position = 'Favor de ingresar un Cargo en la Empresa';
    // } 
    else if (email === '') {
      error.email = 'Favor de ingresar un Correo Electrónico';
    } 
    else if (!REGEX_EMAIL.test(email)) {
      error.email = 'Favor de ingresar un Correo Electrónico válido';
    } 
    else if (phone === '') {
      error.phone = 'Favor de ingresar un Teléfono';
    } 
    else if (address === '') {
      error.address = 'Favor de ingresar una Dirección';
    } 
    return error;
  }

  const saveInformation = async (event) => {
    try {
      event.preventDefault();
      const error = handleErrors()

      if (Object.keys(error).length === 0) {
        const item = {
          approval: 'pending',
          legalRepresentative: {
            name: name,
            paternalSurname: paternalSurname,
            maternalSurname: maternalSurname,
            birthdate: birthdate,
            gender: gender,
            nationality: nationality,
            curp: curp,
            rfc: rfc,
            // position: position,
            phone: phone,
            address: address
          }
        }

        const statusOrg = await EditOrganization(loc.state.organizationRecordKey, item);

        if (statusOrg.success) {
          navigate('/companyDocuments',{
            state: {
            organizationRecordKey: loc.state.organizationRecordKey,
            subscriptionType: loc.state.subscriptionType
          }
          });

        } else {
          handleAlert('error', statusOrg.error);
        }
      }
      else {
        for (const value in error) {
          handleAlert('error', error[value]);
        }
      }

    } catch (err) {
      console.log("ERR", err);
    }
  }

  return (
    <div className={classes.containerForm}>
      <form className={classes.form}>
        <div className={classes.containerCompanyForm}>
          <div className={classes.containerForm1}>
            <TextField
              label='Nombre'
              variant='outlined'
              name='Name'
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
              className={classes.inputs}
            />
            <TextField
              label='Apellido Materno'
              variant='outlined'
              name='maternalSurname'
              required
              value={maternalSurname}
              onChange={(event) => setMaternalSurname(event.target.value)}
              className={classes.inputs}
            />
            <FormControl className={classes.inputs} fullWidth>
              <InputLabel id='gender-select-label'>Género</InputLabel>
              <Select
                labelId='gender-select-label'
                id='gender-select'
                label='Género'
                variant='outlined'
                value={gender}
                name='genderSelect'
                onChange={(event) => setGender(event.target.value)}
                className={classes.selectContainer}
              >
                {GENDERS.map(item => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label='CURP'
              variant='outlined'
              name='curp'
              required
              value={curp}
              onChange={(event) => setCurp(event.target.value)}
              className={classes.inputs}
            />
            {/* <TextField
              label='Cargo en la Empresa'
              variant='outlined'
              name='position'
              required
              value={position}
              onChange={(event) => setPosition(event.target.value)}
              className={classes.inputs}
            /> */}
            <TextField
              label='Teléfono'
              variant='outlined'
              name='phone'
              required
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              className={classes.inputs}
            />
          </div>

          <div className={classes.containerForm2}>
            <TextField
              label='Apellido Paterno'
              variant='outlined'
              name='paternalSurname'
              required
              value={paternalSurname}
              onChange={(event) => setPaternalSurname(event.target.value)}
              className={classes.inputs}
            />
            <TextField
              id="birthdate"
              label="Fecha de Nacimiento"
              type="date"
              defaultValue="1950-01-01"
              className={classes.inputs}
              inputProps={{ min: "1950-01-01", max: maxDate}}
              onChange={(event) => setBirthdate(event.target.value)}
            />
            <FormControl className={classes.inputs} fullWidth>
              <InputLabel id='nationality-select-label'>Nacionalidad</InputLabel>
              <Select
                labelId='nationality-select-label'
                id='nationality-select'
                label='Nacionalidad'
                variant='outlined'
                value={nationality}
                name='nationalitySelect'
                onChange={(event) => setNationality(event.target.value)}
                className={classes.selectContainer}
              >
                {COUNTRIES.map(item => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label='RFC'
              variant='outlined'
              name='rfc'
              required
              value={rfc}
              onChange={(event) => setRfc(event.target.value)}
              className={classes.inputs}
            />
            <TextField
              label='Correo Electrónico'
              variant='outlined'
              name='email'
              required
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className={classes.inputs}
            />
            {/* <TextField
              label='Celular'
              variant='outlined'
              name='cellphone'
              required
              value={cellphone}
              onChange={(event) => setCellPhone(event.target.value)}
              className={classes.inputs}
            /> */}

            <TextField
              label='Dirección'
              variant='outlined'
              name='address'
              required
              value={address}
              inputProps={{ maxLength: 150 }}
              onChange={(event) => setAddress(event.target.value)}
              className={classes.inputs}
            />


          </div>
        </div>

        <PrimaryButton handleClick = {saveInformation} text = "Continuar" color = "#000000"/>

      </form>
    </div>
  )
}

export default withSnackbar(CompanyRepresentativeForm);