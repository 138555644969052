import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { Box, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, TablePagination } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { CSVLink, CSVDownload } from "react-csv";
import removeAccents from '../../lib/removeAccents';
import SearchBar from '../../components/searchBar';
import csvtojsonV2 from 'csvtojson';
import {
  REGEX_EMAIL,
  REGEX_LETTERS,
  REGEX_PHONE,
  MIN_BIDDING,
  MAX_BIDDING,
} from "../../lib/constant";
import * as R from 'ramda';
import { useNavigate } from "react-router-dom";
import CreatePPFile from '../../lib/CreateFiles/ppFile';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import saveBatchBidding from '../../lib/Bidding/saveBatchBidding';
import ModalErrors from '../modalLeadsErrors';
import CircularProgress from '@mui/material/CircularProgress';
import getOrganization from '../../lib/Analytics/getOrganization';
import getValidCurrentInterval from '../../lib/Analytics/getValidateCurrentInterval'
import validateAmountInterval from '../../lib/Analytics/validateAmountInterval'
import editOrganization from '../../lib/Analytics/editOrganization';
import PrimaryButton from '../primaryButton';
import formatPayloadOrder from '../../lib/Orders/formatOrderBody';
import createOrder from '../../lib/Orders/createOrder';

const DispersionTable = (props) => {
  const classes = useStyle();
  let navigate = useNavigate();
  const [cookies] = useCookies(['user']);

  const companyId = cookies.user.sub;
  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState(props.leads);
  const [comments, setComent] = useState('');
  const [period, setPeriod] = useState('');
  const [shownLeads, setShownLeads] = useState(props.shownLeads);
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [search, setSearch] = useState('');
  const [failedItems, setFailedItems] = useState([]);
  const [modalErrorsVisible, setModalErrorsVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleOnChange = (event) => {
    const { name, value, id, } = event.target
    switch (name) {
      case 'comments':
        setComent(value)
        break;
      case 'period':
        setPeriod(value)
        break;
      default:
        break;
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event) => {
    const filter = (event.target.value).toLowerCase();
    const filteredArr = leads.filter(item => getFullName(item).includes(filter));
    setSearch(filter);
    setShownLeads(filteredArr);
  }

  const getFullName = (item) => {
    const fullName = `${item.name} ${item.secondName} ${item.paternalLastName} ${item.maternalLastName}`
    return removeAccents(fullName).toLowerCase();
  }

  const setEdit = (edit, index) => {
    const newData = [...leads];
    newData[index].edit = edit;
    setLeads(newData)
    setShownLeads(newData)
  }

  const getType = (tempAmount) => {
    const amount = parseInt(tempAmount);
    if (amount >= 2000 && amount <= 6000) {
      return 'Adopter'
    } else if (amount >= 6001 && amount <= 12000) {
      return 'Evolver'
    } else if (amount >= 12001 && amount <= 25000) {
      return 'Explorer'
    } else {
      return 'N/A'
    }
  }

  const moneyFormat = (amount) => {
    let newAmount = amount;
    if (newAmount !== undefined) {
      if (typeof amount === 'string') {
        newAmount = parseFloat(amount)
      }
      const formatedAmount = newAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      })
      return formatedAmount
    } else {
      return '$0'
    }
  }

  const getTotal = () => {
    let total = 0;
    if (leads.length > 0) {
      leads.map((item) => {
        if (item.amount) {
          total = total + parseFloat(item.amount);
        } else {
          total = total + 0;
        }
      })
    }
    return total
  }

  const handleNewAmount = (index, amount) => {
    const newLeads = [...leads];
    newLeads[index].amount = amount;
    setLeads(newLeads);
  }

  const editLead = async (index, lead) => {
    try {
      setEdit(false, index)
      const editLead = { ...lead }
      props.editLead(editLead)
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
    }
  }

  const createCSV = (arrayToConvert) => {
    const csvData = [["nombre", "telefono", "propuesta"]]
    arrayToConvert.map((lead) => (
      csvData.push([
        (`${lead.name} ${lead.secondName} ${lead.paternalLastName} ${lead.maternalLastName}` || ''),
        (lead.phone || ''),
        (lead.amount || 0),
      ])
    ))
    setCsvData(csvData);
  }

  const getFile = async (e) => {
    setLoading(true);
    try {
      const file = e.target.files[0];
      const fileText = await file.text();
      const fileJson = await csvtojsonV2().fromString(fileText);
      const leadsArr = fileJson.map((item, index) => {
        const newAmount = parseFloat(item.propuesta);
        const oldLead = leads.filter(leadItem => leadItem.phone === item.telefono)[0]
        const lastAmount = oldLead.amount || 0
        return {
          ...oldLead,
          ...item,
          position: index + 1,
          lastAmount,
          newAmount,
        }
      });
      const status = verifyFile(leadsArr);
      if (status !== '') {
        handleAlert('error', status);
      } else {
        setLeads(leadsArr);
        setShownLeads(leadsArr);
        await saveBatchOffer(leadsArr);
        setFile(file);
      }
    } catch (err) {
      setFile(null)
      handleAlert('error', 'Algo Salio Mal');
    }
    setLoading(false);
  }

  const verifyFile = (leads) => {
    let errorMessage = '';
    for (const lead of leads) {
      if (R.isNil(lead.nombre) || lead.nombre === '') {
        errorMessage = `Campo "nombre" vacío (Renglón: ${lead.position})`;
        break;
      } else if (!REGEX_LETTERS.test(lead.nombre)) {
        errorMessage = `Error de formato en campo "nombre" (Renglón: ${lead.position})`;
        break;
      } else if (R.isNil(lead.telefono) || lead.telefono === '') {
        errorMessage = `Campo "telefono" vacío (Renglón: ${lead.position})`;
        break;
      } else if (!REGEX_PHONE.test(lead.telefono)) {
        errorMessage = `Error de formato en campo "telefono" (Renglón: ${lead.position})`;
        break;
      } else if (R.isNil(lead.propuesta) || lead.propuesta === '') {
        errorMessage = `Campo "propuesta" vacío (Renglón: ${lead.position})`;
        break;
      } else if (isNaN(parseFloat(lead.propuesta))) {
        errorMessage = `Campo "propuesta" debe ser un número (Renglón: ${lead.position})`;
        break;
      } else if (parseFloat(lead.propuesta) < MIN_BIDDING || parseFloat(lead.propuesta) > MAX_BIDDING) {
        errorMessage = `Campo "propuesta" debe estar en un rango entre 2,000.00 a 25,000.00 (Renglón: ${lead.position})`;
        break;
      }
    }
    return errorMessage;
  }

  const cleanFileInput = (event) => {
    event.target.value = null
  }

  const createPPFile = async () => {
    if (comments === '') {
      handleAlert('error', 'Comentario vacio')
      return
    }
    if (period === '') {
      handleAlert('error', 'Periodo vacio')
      return
    }
    setLoading(true);
    try {

      // const validateDispR = await validateDisbursement()

      // if (!validateDispR.success) {
      //   handleAlert('error', `${validateDispR.message}`)
      //   setLoading(false);
      //   return
      // }
      const fileteredLeads = leads.filter(lead => {
        if (lead.elegible || lead.elegible === undefined) {
          return lead
        }
      })
      const body = formatPayloadOrder(fileteredLeads, companyId, { period, comments })
      const response = await createOrder(body);
      if (response.success) {
        handleAlert('success', 'Orden creada exitosamente')
        navigate('/orders', { state: { from: 'bidding' } })
      } else {
        handleAlert('error', 'Algo salio mal')
      }
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
    }
    setLoading(false);
  }

  const saveBatchOffer = async (shownLeads) => {
    setLoading(true);
    if (shownLeads.length > 0) {
      try {
        const purgedLeads = shownLeads.map(item => ({
          leadRecordKey: item.leadRecordKey,
          newAmount: item.newAmount,
          lastAmount: item.lastAmount,
          userType: getType(item.newAmount)
        }));
        const response = await saveBatchBidding(companyId, purgedLeads, 'offered');
        if (response.success) {
          if (response.failedItems.length > 0) {
            const newFailedItems = response.failedItems.map(item => {
              const oldItem = leads.filter(lead => lead.leadRecordKey === item.leadRecordKey)[0]
              return {
                ...item,
                ...oldItem,
              }
            })
            setFailedItems(newFailedItems)
            handleAlert('warning', 'Algunos prospectos no se guardaron correctamente, errores.csv')
            setModalErrorsVisible(true)
          }
          else {
            handleAlert('success', 'Prospectos guardados con éxito')
            props.getSavedLeads();
          }
        }
        else {
          handleAlert('error', 'Algo salió mal');
        }
      } catch (err) {
        handleAlert('error', 'Algo salió mal');
      }
    } else {
      handleAlert('warning', 'Selecciona al menos un prospecto para continuar');
    }
    setLoading(false);
  }

  const editStatus = async (index, item) => {
    try {
      let lead = item;
      if (lead.elegible != undefined) {
        lead.elegible = !lead.elegible
      } else {
        lead.elegible = false
      }
      const edited = await editLead(index, lead, 'leads')

    } catch (error) {
      handleAlert('error', 'Algo salió mal');


    }
  }

  const getText = (item) => {
    let text;

    if (item.elegible != undefined) {
      text = item.elegible ? 'Desactivar' : 'Activar'
    } else {
      text = 'Desactivar'
    }
    return text
  }
  const formatDate = (dateString) => {
    if(!dateString) return 'N/A'
    const date = new Date(dateString);
    const dateFormatted = new Intl.DateTimeFormat("es-MX", {
      year: "numeric",
      day: "2-digit",
      month: "2-digit"
    }).format(date);

    return dateFormatted
  }

  const table = () => {
    if (shownLeads.length > 0) {
      return (
        shownLeads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <>
            <TableRow key={index} className={classes.tableHeader} sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell className={classes.info} data-label="Name" align="left">{`${item.name} ${item.secondName} ${item.paternalLastName} ${item.maternalLastName}`}</TableCell>
              <TableCell className={classes.info} data-label="lastPaymentDate" align="center">{formatDate(item.lastPaymentDate)}</TableCell>
              <TableCell className={classes.info} data-label="lastPayment" align="center">{moneyFormat(item.lastAmount) || '0'}</TableCell>
              <TableCell className={classes.info} data-label="Tipo de usuario" align="center">{getType(item.amount)}</TableCell>
              <TableCell className={classes.info} data-label="Clabe" align="center">
                {item.edit ?
                  <TextField
                    label="Oferta"
                    variant="outlined"
                    name='offer'
                    id="offer"
                    type="number"
                    value={item.amount}
                    onChange={(event) => handleNewAmount(index, event.target.value)}
                    className={classes.inputs}
                  /> :
                  item.amount ?
                    `${moneyFormat(item.amount || 0)}` :
                    `${moneyFormat(item.lastAmount || 0)}` || '$0'}
              </TableCell>
              <TableCell align="right">
                <div className={classes.actionContainer}>
                  {!item.edit &&
                    <PrimaryButton handleClick={() => editStatus(index, item)} text={getText(item)} color="black" />
                  }
                  <IconButton
                    aria-label="edit row"
                    size="small"
                    onClick={() => !item.edit ? setEdit(!item.edit, index) : editLead(index, item, 'leads')}
                  >
                    {!item.edit ?
                      <EditIcon className={classes.icon} fontSize='medium' color='info' />
                      : <CheckIcon />}
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          </>
        ))
      );
    }
  }

  return (
    <>
      <ModalErrors
        onClose={() => setModalErrorsVisible(false)}
        open={modalErrorsVisible}
        failedItems={failedItems}
      />
      <Box marginBottom={'30px'}>
        <div className={classes.tableContainer}>
          <div className={classes.row}>
            <SearchBar
              value={search}
              handleOnChange={handleFilter}
              marginBottom={'15px'}
            />
          </div>
          <text className={classes.hint}>
            Los siguientes datos se deben dar de alta en Banorte para poder llevar a cabo el proceso de dispersión por la venta de datos.
          </text>
          <div className={classes.fileContainer}>
            {
              loading ?
                <div className={classes.loadingContainer}>
                  <div className={classes.loading}>
                    <CircularProgress />
                  </div>
                </div>
                :
                <>
                  <div className={classes.fileUploadButton}>
                    <TextField
                      label="Comentario"
                      variant="outlined"
                      name='comments'
                      required
                      value={comments}
                      onChange={handleOnChange}
                      className={classes.inputs}
                    />
                  </div>
                  <div className={classes.fileUploadButton}>
                    <TextField
                      id="period"
                      label="Periodo"
                      type="date"
                      name="period"
                      defaultValue="2017-05-24"
                      className={classes.inputs}
                      inputProps={{ min: "1950-01-01", max: moment().format('YYYY-MM-DD') }}
                      onChange={handleOnChange}
                    />
                  </div>
                  <div className={classes.fileUploadButton} onClick={createPPFile}>
                    <text className={classes.uploadLabel}>
                      Crear orden
                    </text>
                  </div>
                </>
            }
            <div className={classes.fileUploadButton} onClick={() => navigate('/orders', { state: { from: 'bidding' } })}>
              <text className={classes.uploadLabel}>
                Ver ordenes
              </text>
            </div>
          </div>
        </div>
      </Box>
      <Box marginBottom={'30px'}>
        <div className={classes.tableContainer}>
          <div className={classes.row}>
            <text className={classes.hint}>
              Se pueden editar en pantalla o descargar el siguiente archivo para corregirlos y enviarlos de nuevo.
              <br />
              Recuerda que no se debe cambiar el campo "telefono", ya que es lo que nos permite identificar el usuario.
            </text>
            <div>
              <button onClick={() => createCSV(leads)} className={classes.uploadLabel}>
                <CSVLink
                  style={{ display: 'flex', alignItems: 'center', color: 'white', textDecoration: 'none' }}
                  data={csvData}
                  filename="formato.csv">
                  Descargar formato
                </CSVLink>
              </button>
              <div className={classes.fileUploadButton}>
                <label for='fileCd' className={classes.uploadLabel}>
                  <text>
                    Subir Archivo
                  </text>
                  <input
                    type='file'
                    name='fileCd'
                    id='fileCd'
                    className={classes.uploadInput}
                    onChange={getFile}
                    onClick={cleanFileInput}
                    file={file}
                    accept='.csv'
                    disabled={false}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </Box>
      <Box marginBottom={'30px'}>
        <TableContainer className={classes.tableContainer}>
          <div className={classes.rowTotal}>
            <div className={classes.total}>
              <text className={classes.totalText}>Total de prospectos</text>
              <text className={classes.totalTextBold}>{leads.length}</text>
            </div>
            <div className={classes.total}>
              <text className={classes.totalText}>Monto total de subasta</text>
              <text className={classes.totalTextBold}>{moneyFormat(getTotal())}</text>
            </div>
          </div>
          <Table aria-label="collapsible table">
            <TableHead className={classes.thead}>
              <TableRow classes={classes.infoRow}>
                <TableCell className={classes.th} align="left">Nombre</TableCell>
                <TableCell className={classes.th} align="center">Fecha de último pago</TableCell>
                <TableCell className={classes.th} align="center">Cantidad de último pago</TableCell>
                <TableCell className={classes.th} align="center">Tipo de usuario</TableCell>
                <TableCell className={classes.th} align="center">Propuesta de pago</TableCell>
                <TableCell className={classes.th} align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table()}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={leads.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Registros por hoja'
          />
        </TableContainer>
      </Box>
    </>
  )
}

export default withSnackbar(DispersionTable)

const useStyle = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    marginTop: '2%'
  },
  title: {
    fontSize: '62px',
    width: '80% ',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  fileInputContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  fileInputContainer2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    width: '40%',
    marginTop: -100,
    marginBottom: -20,
    [theme.breakpoints.down('lg')]: {
      width: '60%',
      marginTop: -50,
      marginBottom: -20,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 0,
      marginBottom: -20,
    },
  },
  saveContainer: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '100%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '20px',
    overflowX: 'auto',
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  thead: {
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.white.main,
    padding: 15,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
      borderBottom: `3px solid ${theme.palette.gray.main}`,
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
    overflow: 'hidden',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fileContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '15px 0px',
    gap: '10px',
    justifyContent: 'space-between'
  },
  fileUploadButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // margin: '5px'
  },
  uploadLabel: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.darkGray.main}`,
    color: theme.palette.white.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
    height: 'fit-content',
    margin: '5px'
  },
  uploadLabelDisabled: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.gray.main,
    border: `2px solid ${theme.palette.darkGray.main}`,
    color: theme.palette.white.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
    height: 'fit-content',
    margin: '5px'
  },
  uploadInput: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    border: 0,
  },
  hint: {
    display: 'flex',
    ...theme.typography.subtitle,
    color: '#878787',
  },
  errorIcon: {
    width: '25px',
    height: '25px',
    backgroundColor: '#CC3C3C',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  halfContainer: {
    width: '50%',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0px 20px 0px 0px',
  },
  infoBold: {
    ...theme.typography.subtitle,
    fontWeight: '600',
    fontSize: '14px',
  },
  info: {
    ...theme.typography.subtitle,
    fontSize: '14px !important',
    textAlign: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '20%',
  },
  rowTotal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottom: `1px solid ${theme.palette.gray.main}`,
  },
  total: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '45%'
  },
  totalText: {
    fontSize: '28px',
  },
  totalTextBold: {
    fontSize: '28px',
    fontWeight: 'bold'
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '50px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  warning: {
    backgroundColor: 'white',
    color: 'red'
  },
  activeLead: {
    backgroundColor: 'red',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row'
  }
}));