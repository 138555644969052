const COUNTRIES = [ 
        // { label: 'Afganistán', value: 1 },
        // { label: 'Albania', value: 2 },
        // { label: 'Alemania', value: 3 },
        // { label: 'Andorra', value: 4 },
        // { label: 'Angola', value: 5 },
        // { label: 'Antigua y Barbuda', value: 6 },
        // { label: 'Arabia Saudita', value: 7 },
        // { label: 'Argelia', value: 8 },
        // { label: 'Argentina', value: 9 },
        // { label: 'Armenia', value: 10 },
        // { label: 'Australia', value: 11 },
        // { label: 'Austria', value: 12 },
        // { label: 'Azerbaiyán', value: 13 },
        // { label: 'Bahamas', value: 14 },
        // { label: 'Bangladés', value: 15 },
        // { label: 'Barbados', value: 16 },
        // { label: 'Baréin', value: 17 },
        // { label: 'Bélgica', value: 18 },
        // { label: 'Belice', value: 19 },
        // { label: 'Benín', value: 20 },
        // { label: 'Bielorrusia', value: 21 },
        // { label: 'Birmania', value: 22 },
        // { label: 'Bolivia', value: 23 },
        // { label: 'Bosnia y Herzegovina', value: 24 },
        // { label: 'Botsuana', value: 25 },
        // { label: 'Brasil', value: 26 },
        // { label: 'Brunéi', value: 27 },
        // { label: 'Bulgaria', value: 28 },
        // { label: 'Burkina Faso', value: 29 },
        // { label: 'Burundi', value: 30 },
        // { label: 'Bután', value: 31 },
        // { label: 'Cabo Verde', value: 32 },
        // { label: 'Camboya', value: 33 },
        // { label: 'Camerún', value: 34 },
        // { label: 'Canadá', value: 35 },
        // { label: 'Catar', value: 36 },
        // { label: 'Chad', value: 37 },
        // { label: 'Chile', value: 38 },
        // { label: 'China', value: 39 },
        // { label: 'Chipre', value: 40 },
        // { label: 'Ciudad del Vaticano', value: 41 },
        // { label: 'Colombia', value: 42 },
        // { label: 'Comoras', value: 43 },
        // { label: 'Corea del Norte', value: 44 },
        // { label: 'Corea del Sur', value: 45 },
        // { label: 'Costa de Marfil', value: 46 },
        // { label: 'Costa Rica', value: 47 },
        // { label: 'Croacia', value: 48 },
        // { label: 'Cuba', value: 49 },
        // { label: 'Dinamarca', value: 50 },
        // { label: 'Dominica', value: 51 },
        // { label: 'Ecuador', value: 52 },
        // { label: 'Egipto', value: 53 },
        // { label: 'El Salvador', value: 54 },
        // { label: 'Emiratos Árabes Unidos', value: 55 },
        // { label: 'Eritrea', value: 56 },
        // { label: 'Eslovaquia', value: 57 },
        // { label: 'Eslovenia', value: 58 },
        // { label: 'España', value: 59 },
        // { label: 'Estados Unidos', value: 60 },
        // { label: 'Estonia', value: 61 },
        // { label: 'Etiopía', value: 62 },
        // { label: 'Filipinas', value: 63 },
        // { label: 'Finlandia', value: 64 },
        // { label: 'Fiyi', value: 65 },
        // { label: 'Francia', value: 66 },
        // { label: 'Gabón', value: 67 },
        // { label: 'Gambia', value: 68 },
        // { label: 'Georgia', value: 69 },
        // { label: 'Ghana', value: 70 },
        // { label: 'Granada', value: 71 },
        // { label: 'Grecia', value: 72 },
        // { label: 'Guatemala', value: 73 },
        // { label: 'Guyana', value: 74 },
        // { label: 'Guinea', value: 75 },
        // { label: 'Guinea-Bisáu', value: 76 },
        // { label: 'Guinea Ecuatorial', value: 77 },
        // { label: 'Haití', value: 78 },
        // { label: 'Honduras', value: 79 },
        // { label: 'Hungría', value: 80 },
        // { label: 'India', value: 81 },
        // { label: 'Indonesia', value: 82 },
        // { label: 'Irak', value: 83 },
        // { label: 'Irán', value: 84 },
        // { label: 'Irlanda', value: 85 },
        // { label: 'Islandia', value: 86 },
        // { label: 'Islas Marshall', value: 87 },
        // { label: 'Islas Salomón', value: 88 },
        // { label: 'Israel', value: 89 },
        // { label: 'Italia', value: 90 },
        // { label: 'Jamaica', value: 91 },
        // { label: 'Japón', value: 92 },
        // { label: 'Jordania', value: 93 },
        // { label: 'Kazajistán', value: 94 },
        // { label: 'Kenia', value: 95 },
        // { label: 'Kirguistán', value: 96 },
        // { label: 'Kiribati', value: 97 },
        // { label: 'Kuwait', value: 98 },
        // { label: 'Laos', value: 99 },
        // { label: 'Lesoto', value: 100 },
        // { label: 'Letonia', value: 101 },
        // { label: 'Líbano', value: 102 },
        // { label: 'Liberia', value: 103 },
        // { label: 'Libia', value: 104 },
        // { label: 'Liechtenstein', value: 105 },
        // { label: 'Lituania', value: 106 },
        // { label: 'Luxemburgo', value: 107 },
        // { label: 'Macedonia del Norte', value: 108 },
        // { label: 'Madagascar', value: 109 },
        // { label: 'Malasia', value: 110 },
        // { label: 'Malaui', value: 111 },
        // { label: 'Maldivas', value: 112 },
        // { label: 'Malí', value: 113 },
        // { label: 'Malta', value: 114 },
        // { label: 'Marruecos', value: 115 },
        // { label: 'Mauricio', value: 116 },
        // { label: 'Mauritania', value: 117 },
        { label: 'México', value: 118 },
        // { label: 'Micronesia', value: 119 },
        // { label: 'Moldavia', value: 120 },
        // { label: 'Mónaco', value: 121 },
        // { label: 'Mongolia', value: 122 },
        // { label: 'Montenegro', value: 123 },
        // { label: 'Mozambique', value: 124 },
        // { label: 'Namibia', value: 125 },
        // { label: 'Nauru', value: 126 },
        // { label: 'Nepal', value: 127 },
        // { label: 'Nicaragua', value: 128 },
        // { label: 'Níger', value: 129 },
        // { label: 'Nigeria', value: 130 },
        // { label: 'Noruega', value: 131 },
        // { label: 'Nueva Zelanda', value: 132 },
        // { label: 'Omán', value: 133 },
        // { label: 'Países Bajos', value: 134 },
        // { label: 'Pakistán', value: 135 },
        // { label: 'Palaos', value: 136 },
        // { label: 'Panamá', value: 137 },
        // { label: 'Papúa Nueva Guinea', value: 138 },
        // { label: 'Paraguay', value: 139 },
        // { label: 'Perú', value: 140 },
        // { label: 'Polonia', value: 141 },
        // { label: 'Portugal', value: 142 },
        // { label: 'Reino Unido de Gran Bretaña e Irlanda del Norte', value: 143 },
        // { label: 'República Centroafricana', value: 144 },
        // { label: 'República Checa', value: 145 },
        // { label: 'República del Congo', value: 146 },
        // { label: 'República Democrática del Congo', value: 147 },
        // { label: 'República Dominicana', value: 148 },
        // { label: 'República Sudafricana', value: 149 },
        // { label: 'Ruanda', value: 150 },
        // { label: 'Rumanía', value: 151 },
        // { label: 'Rusia', value: 152 },
        // { label: 'Samoa', value: 153 },
        // { label: 'San Cristóbal y Nieves', value: 154 },
        // { label: 'San Marino', value: 155 },
        // { label: 'San Vicente y las Granadinas', value: 156 },
        // { label: 'Santa Lucía', value: 157 },
        // { label: 'Santo Tomé y Príncipe', value: 158 },
        // { label: 'Senegal', value: 159 },
        // { label: 'Serbia', value: 160 },
        // { label: 'Seychelles', value: 161 },
        // { label: 'Sierra Leona', value: 162 },
        // { label: 'Singapur', value: 163 },
        // { label: 'Siria', value: 164 },
        // { label: 'Somalia', value: 165 },
        // { label: 'Sri Lanka', value: 166 },
        // { label: 'Suazilandia', value: 167 },
        // { label: 'Sudán', value: 168 },
        // { label: 'Sudán del Sur', value: 169 },
        // { label: 'Suecia', value: 170 },
        // { label: 'Suiza', value: 171 },
        // { label: 'Surinam', value: 172 },
        // { label: 'Tailandia', value: 173 },
        // { label: 'Tanzania', value: 174 },
        // { label: 'Tayikistán', value: 175 },
        // { label: 'Timor Oriental', value: 176 },
        // { label: 'Togo', value: 177 },
        // { label: 'Tonga', value: 178 },
        // { label: 'Trinidad y Tobago', value: 179 },
        // { label: 'Túnez', value: 180 },
        // { label: 'Turkmenistán', value: 181 },
        // { label: 'Turquía', value: 182 },
        // { label: 'Tuvalu', value: 183 },
        // { label: 'Ucrania', value: 184 },
        // { label: 'Uganda', value: 185 },
        // { label: 'Uruguay', value: 186 },
        // { label: 'Uzbekistán', value: 187 },
        // { label: 'Vanuatu', value: 188 },
        // { label: 'Venezuela', value: 189 },
        // { label: 'Vietnam', value: 190 },
        // { label: 'Yemen', value: 191 },
        // { label: 'Yibuti', value: 192 },
        // { label: 'Zambia', value: 193 },
        // { label: 'Zimbabue', value: 194 },
];

export default COUNTRIES;