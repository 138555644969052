import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const ChartBarHorizontal = (props) => {
  const classes = useStyle();
  return (
    <div className={classes.chartContainer}>
      <h3 className={classes.title}>{props.title}</h3>
      <ResponsiveContainer height={500}>
        <BarChart
          data={props.data}
          layout='vertical'
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number"/>
          <YAxis width={150} dataKey={props.dataKey} type="category" scale="band"/>
          <Tooltip />
          <Bar dataKey={props.dataKey1} stackId="a" fill="#9FDEF1" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ChartBarHorizontal;

const useStyle = makeStyles(theme => ({
  chartContainer: {
    backgroundColor: 'white',
    border: `5px solid ${theme.palette.lightGray.main}`,
  },
  title: {
    marginTop: 10,
    marginLeft: 10,
  },
}));