import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import SearchBar from '../../../components/searchBar';
import Background from '../../../assets/images/backgroundGrayyBottom.svg';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, TablePagination, CircularProgress } from '@mui/material';

import GetOrganizations from '../../../lib/Admin/getOrganizations';

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
    minHeight: '100vh'
  },
  title: {
    margin: '2%  0 0 0',
    fontSize: '62px',
    width: '80% ',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  titleAndCardsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    margin: '5%  0 10% 0',
  },
  searchBarContainer: {
    marginTop: 20,
    width: '80%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
    borderRadius: '20px',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
    overflowX: 'auto',
    margin: '0 auto',
    marginTop: 40,
    marginBottom: 10,
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {

      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  thead: {
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.white.main,
    padding: 15,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
      borderBottom: `3px solid ${theme.palette.gray.main}`,
    },
  },
  pendingText: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.darkGray.main,
    borderRadius: 50,
    paddingTop: 5,
    paddingBottom: 5,
  },
  deniedText: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    borderRadius: 50,
    paddingTop: 5,
    paddingBottom: 5,
  },
  approvedText: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.green.main,
    borderRadius: 50,
    paddingTop: 5,
    paddingBottom: 5,
  },
  icon: {
    cursor: 'pointer',
    backgroundColor: 'white',
  },
  loading: {
    display: 'flex',
    minHeight: '25vh',
    flexDirection: 'column',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const OrganizationList = (props) => {

  let navigate = useNavigate();

  useEffect(() => {
    getOrgs();
  }, []);

  const classes = useStyle();
  const perPage = 10;

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const [shownOrgs, setShownOrgs] = useState([]);
  const [pages, setPages] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getOrgs = async () => {
    try {
      const result = await GetOrganizations();
      setShownOrgs(result.organizations);
      setOrganizations(result.organizations);
      setPages(Math.ceil(result.organizations.length / perPage));
      setLoading(false);
    } catch (err) {
      handleAlert('error', 'Algo Salio');
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handlePagination = (event, value) => {
    setSelectedPage(value);
  }

  const handleFilter = (event) => {
    const filter = (event.target.value).toLowerCase();
    const filteredArr = organizations.filter(item => item.name.toLowerCase().includes(filter));
    setSearch(filter);
    setShownOrgs(filteredArr);
  }

  const table = () => {
    if (shownOrgs.length > 0) {
      return (
        shownOrgs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <TableRow key={index} className={classes.tableHeader} onClick={() => navigate('/admin/organizationDetails', { state: { organizationRecordKey: item.organizationRecordKey } })}>
            <TableCell className={classes.tableRegister} data-label="Name" align="center">{item.name}</TableCell>
            <TableCell className={classes.tableRegister} data-label="Phone" align="center">{item.phone}</TableCell>
            <TableCell className={classes.tableRegister} data-label="Email" align="center">{item.email}</TableCell>
            <TableCell className={classes.tableRegister} data-label="RFC" align="center">{item.rfc}</TableCell>
            {
              item.approval === 'approved' ? (
                <TableCell className={classes.tableRegister} data-label="RFC" align="center">
                  <p className={classes.approvedText}>Aprobado</p>
                </TableCell>
              ) : item.approval === 'denied' ? (
                <TableCell className={classes.tableRegister} data-label="RFC" align="center">
                  <p className={classes.deniedText}>Denegado</p>
                </TableCell>
              ) : (
                <TableCell className={classes.tableRegister} data-label="RFC" align="center">
                  <p className={classes.pendingText}>Pendiente</p>
                </TableCell>
              )}
          </TableRow>
        ))
      );
    }
  }

  if (loading) {
    return (
      <div className={classes.containerBackground}>
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.containerBackground}>
      <h1 className={classes.title}>
        Organizaciones
      </h1>
      <div className={classes.searchBarContainer}>
        <SearchBar
          value={search}
          handleOnChange={handleFilter}
        />
      </div>
      <Box width={'80%'} marginBottom={'40px'}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.thead}>
              <TableRow className={classes.tableHeader}>
                <TableCell className={classes.th} align="center">Empresa</TableCell>
                <TableCell className={classes.th} align="center">Telefono</TableCell>
                <TableCell className={classes.th} align="center">Correo Electronico</TableCell>
                <TableCell className={classes.th} align="center">RFC</TableCell>
                <TableCell className={classes.th} align="center">Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table()}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={organizations.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Registros por hoja'
          />
        </TableContainer>
      </Box>
    </div>
  );
}

export default withSnackbar(OrganizationList);