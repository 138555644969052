import { API } from 'aws-amplify';

const CreateACFile = async (companyId, prospects) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_CREATEACFILE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY_CREATEFILES,
      },
      body: JSON.stringify({
        "companyId": companyId,
        "prospects": prospects
      })
    })
    return await response.json()
    
  } catch (err) {
    return {
      error: err,
      success: false,
    }
  }
}

export default CreateACFile;