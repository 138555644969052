import React, { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import GetLeads from '../../lib/Analytics/getLeads';
import getAvailableQuestions from '../../lib/Questionnaire/getAvailableQuestions';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionForms from '../questionForms';
import TableComponent from '../tableComponent'
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

const EmployeeQuestions = (props) => {

  const classes = useStyle();
  const [leads, setLeads] = useState([])
  const [selectedLead, setSelectedLead] = useState(null)
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getLeads()
  }, [selectedLead])

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getLeads = async () => {
    try {
      setLoading(true);
      const leads = await GetLeads(props.companyId)
      const newLeads = await getQuestions(leads.data)
      setLeads(newLeads)
      setLoading(false);

    } catch (error) {
      handleAlert('error', 'Algo salio mal.')
    }
  }

  const getQuestions = async (leadsData) => {
    try {
      const newLeads = leadsData.map(async (lead) => {
        const questions = await getAvailableQuestions(lead.phone)
        const newLead = {
          ...lead,
          availableQuestions: questions
        }
        return newLead
      })

      let done = await Promise.allSettled(newLeads)
      done = done.filter(item => item.status === 'fulfilled')
      const leads = done.map(item => {
        return {
          value: [item.value.name, item.value.availableQuestions.data.length],
          info: item.value
        }
      })
      return leads
    } catch (error) {
      handleAlert('error', 'Algo salio mal.')
    }
  }

  const handlerSelectedLead = (lead) => {
    setLoading(true)
    const value = lead.info.availableQuestions.data.map(question => {
      return question
    })
    setSelectedLead(value)
    setLoading(false)
  }

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.tableContainerAdmin}>
      <div className={classes.backButton}>
        {selectedLead &&
          <IconButton onClick={() => setSelectedLead(null)}>
            <ArrowBackIcon />
          </IconButton>
        }
      </div>
      {
        loading ?
          <div className={classes.loading}>
            <CircularProgress />
          </div>
          :
          <div>
            {!selectedLead ?
              <TableComponent
                titles={['Nombre completo', 'Preguntas pendientes']}
                bodyItems={leads}
                onClickRow={handlerSelectedLead}
                pagination
              />
              :
              selectedLead.length <= 0 ?
                <div className={classes.emptyQuestions}>
                  No hay preguntas
                </div>
                :
                selectedLead.map((lead, index) => (
                  <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography sx={{ width: '100%', flexShrink: 0, fontSize: 18 }}>
                        {lead.questionText}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <QuestionForms
                        questionInfo={lead}
                        handleAlert={handleAlert}
                        questions={selectedLead}
                        setQuestions={setSelectedLead}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))
            }
          </div>
      }

    </div>
  )
}

export default withSnackbar(EmployeeQuestions);

const useStyle = makeStyles(theme => ({
  tableContainerAdmin: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '100%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    paddingBottom: '2%',
    overflowX: 'auto',
    margin: '0 auto',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    margin: '0 auto',
    // marginTop: 40,
    marginBottom: 10,
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
    overflow: 'hidden',
  },
  tableRegisterActions: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'flex',
      flexDirection: 'column',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
    overflow: 'hidden',
  },
  emptyQuestions: {
    display: 'flex',
    justifyContent: 'center',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center'
  },
  backButton: {
    paddingTop: '5px',
    paddingBottom: '10px',
  }
}))