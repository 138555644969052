import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const getLeadsEmail = async (email,phone) => {
  try {
    const result = await API.get(API_ANALYTICS, '/users/saveLeads', {
      queryStringParameters : {
        email: email,
        phone: phone
      }
    });
    
    if(result.success){
      return {
        success: true,
        data: result.data
      }
    }
    else {
      return {
        success: false,
        msg: 'Algo salio mal'
      }
    }
    
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default getLeadsEmail;