import React from 'react';
import { makeStyles } from '@mui/styles';
import '../../styles/styles.css'

const style = makeStyles(theme =>({
    button: {
        backgroundColor:"transparent",
        width: '100%',
        height: '3.125rem',
        borderRadius: '4.25rem',
        margin: (props) => (props.margin ? props.margin : '3% 0'),
        border: (props) => `3px solid ${(props.color ? props.color : theme.palette.white.main )}`,
        '&:hover': {
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            margin: '10% 0 !important',
        },
    },
    label: {
        color: (props) => (props.color ? props.color : theme.palette.white.main),
        fontSize: 16,
        fontWeight: 'bold'
    }
}))

const SecondaryButton = (props) => { 
    const classes = style(props);
     
  return (
    <button className={classes.button} onClick = {props.handleClick}>
        <p className={classes.label}>{props.text}</p>
    </button>
  )
};

export default SecondaryButton;
