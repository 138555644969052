import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const LoadFiles = async (organizationRecordKey,documentName) => {
  try {
    const result = await API.get(API_ANALYTICS, '/users/files'
    , {
        queryStringParameters: {
        organizationRecordKey,
        documentName
      }
    }
    );
    return {
      url: result.url,
      success: true,
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default LoadFiles;