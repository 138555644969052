import { API } from 'aws-amplify';
import { API_QUESTIONS } from '../constant';

const deleteQuestion = async (questionId) => {
  try {
    const result = await API.del(API_QUESTIONS,`/${questionId}`,{
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY
        }
    });
    return {
      success: result.success,
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default deleteQuestion;