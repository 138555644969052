import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';
import * as R from 'ramda';

const getQuestionnaire = async (organizationRecordKey) => {
  try {
    const result = await API.get(API_ANALYTICS, '/intervals', {
      queryStringParameters: {
        organizationRecordKey:organizationRecordKey,
      }
    });
    return {
      success: true,
      data: result.data
    }
  } catch (err) {
    let error = 'Algo salio mal';
    if (R.hasPath(['response', 'data', 'error'], err)) {
      error = err.response.data.error;
    } 
    return {
      success: false,
      error: error,
    }
  }
}

export default getQuestionnaire;