import React from 'react';
import { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import Background from '../../../assets/images/backgroundGrayyBottom.svg'
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
  RadioGroup,
  Radio,
  FormControlLabel,
  TableRow,
  TableCell,
  Collapse,
  TablePagination
} from "@mui/material";
import SecondaryButton from '../../../components/secondaryButtonLite';
import PrimaryButton from '../../../components/primaruButtonLite';

import DeleteIcon from '@material-ui/icons/Clear';
import AddTask from '@material-ui/icons/AddCircle';
import moment from 'moment-timezone';
import GetOrganization from '../../../lib/Analytics/getOrganization';
import SaveQuestionnarie from '../../../lib/Analytics/saveQuestionnarie';
import GetCategory from '../../../lib/Admin/getCategory';
import getQuestions from '../../../lib/Admin/getQuestions';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchBar from '../../../components/searchBar';

const NewQuestionnarie = (props) => {
  const loc = useLocation();
  const today = moment().format('YYYY-MM-DD');
  const classes = useStyle();
  const navigate = useNavigate();
  const perPage = 10

  const [questions, setQuestions] = useState([])
  const [questionName, setQuestionName] = useState('')
  const [dates, setDates] = useState({ selectDates: null, specificDates: { start: today, end: today } })
  const [enableSpecificDate, setEnableSpecificDate] = useState(false)
  const [questionType, setQuestionType] = useState(1)
  const [answers, setAnswers] = useState([])
  const [textAnswers, setTextAnswers] = useState('')
  const [dateRange, setDateRange] = useState([])
  const [paymentInterval, setPaymentInterval] = useState('')

  //DEFAULT QUESTIONS
  const [enableDefaultQuestions, setEnableDefaultQuestions] = useState(false)
  const [allCategories, setAllCategories] = useState([])
  const [allDefaultQuestions, setAllDefaultQuestions] = useState([])
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState('')
  const [defaultQuestions, setDefaultQuestions] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getOrganization(loc.state.organizationRecordKey)
  }, [])

  const getOrganization = async (organization) => {
    const [resultOrganization, resultQuestions,resultCategory] = await Promise.all([GetOrganization(organization),getQuestions(),GetCategory()])
    const filter = resultCategory.data.filter(item => item.isActivated === true)

    // handlePeriods(resultOrganization.data.paymentInterval)
    // setPaymentInterval(resultOrganization.data.paymentInterval)
    setAllDefaultQuestions(resultQuestions.data)
    setAllCategories(filter)
    setDefaultQuestions(resultQuestions.data)
  }

  const handlePeriods = (type) => {
    const dates = []

    switch (type) {
      case 'weekly':
        const firstDate = moment().startOf('week');
        const lastDate = moment().startOf('day').add(3, 'months').startOf('month').add(1, 'week').startOf('week');
        const diff = lastDate.diff(firstDate, 'weeks');

        for (let index = 0; index < diff; index++) {
          const startDate = moment().startOf('week').add(7 * index, 'days');
          const endDate = moment().endOf('week').add(7 * index, 'days');

          dates.push({
            startDate: startDate.format('DD-MM-YYYY'),
            endDate: endDate.format('DD-MM-YYYY'),
            value: index + 1
          });
        }
        dates.push({
          label: 'Ingresar otro período',
          value: 0
        });
        setDateRange(dates)
        break;

      case 'biweekly':
        const dateNumber = moment().date();
        let index = dateNumber > 15 ? 1 : 0;

        for (index; index < 6; index++) {
          const startDate = (index % 2 === 0) ? moment().startOf('month') : moment().startOf('month').add(15, 'days');
          const endDate = (index % 2 === 0) ? moment().startOf('month').add(15, 'days').subtract(1, 'millisecond') : moment().endOf('month');
          startDate.add(Math.floor(index / 2), 'month');
          endDate.add(Math.floor(index / 2), 'month');

          dates.push({
            startDate: startDate.format('DD-MM-YYYY'),
            endDate: endDate.format('DD-MM-YYYY'),
            value: index + 1
          });
        }
        dates.push({
          label: 'Ingresar otro período',
          value: 0
        });
        setDateRange(dates)
        break;

      case 'monthly':
        for (let index = 0; index < 3; index++) {

          const startDate = moment().startOf('month').add(index, 'month');
          const endDate = moment().endOf('month').add(index, 'month');

          dates.push({
            startDate: startDate.format('DD-MM-YYYY'),
            endDate: endDate.format('DD-MM-YYYY'),
            value: index + 1
          });
        }
        dates.push({
          label: 'Ingresar otro período',
          value: 0
        });
        setDateRange(dates)
        break;
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOnChange = (event) => {
    const { value, name, id } = event.target
    switch (name) {
      case 'selectDates':
        if (value === 0) {
          setEnableSpecificDate(true)
          setDates({ ...dates, selectDates: value })
        } else {
          setDates({ ...dates, selectDates: value })
          setEnableSpecificDate(false)
        }
        break;

      case 'questionType':
        setQuestionType(parseInt(value));
        break;

      case 'textAnswers':
        setTextAnswers(value)
        break;

      case 'question':
        setQuestionName(value)
        break;

      case 'date':
        if (id === 'startDate') {
          setDates({ ...dates, specificDates: { ...dates.specificDates, start: value } })
        }
        else {
          const endDate = moment(value, 'YYYY-MM-DD')
          if (moment.duration(endDate.diff(dates.specificDates.start)).asWeeks() >= 1) {
            setDates({ ...dates, specificDates: { ...dates.specificDates, end: value } })
          }
          else {
            handleAlert('error', "Las fechas seleccionadas deben ser mínimo de 1 semana")
          }
        }
        break;

      case 'search':
        setSearch(value)
        const result = allDefaultQuestions.filter(items => items.questionText.includes(value))
        setDefaultQuestions(result)
        break;

      case 'category':
        setCategory(value)
        const filter = allDefaultQuestions.filter(items => items.category == value)
        setDefaultQuestions(filter)
        break;
    }
  }

  const handlerDelete = (id, type) => {
    switch (type) {
      case 'options':
        const optionsResut = answers.filter(items => items.optionId !== id)
        setAnswers(optionsResut)
        break;

      case 'question':
        const questionResult = questions.filter((items, index) => index !== id)
        setQuestions(questionResult)
        break;
    }
  }

  const handleAnswers = () => {
    const count = answers.length + 1
    const error = handleErrors('answers')
    if (questionType !== 1 && error.length === 0) {
      if (answers.length === 0) {
        setAnswers([{ 'optionId': count, 'descripcion': textAnswers }])
        setTextAnswers('')
      }
      else {
        setAnswers([...answers, { 'optionId': count, 'descripcion': textAnswers }])
        setTextAnswers('')
      }
    }
    else {
      handleAlert('error', error[0])
      setAnswers([])
    }
  }

  const handleErrors = (type) => {
    const errors = []

    switch (type) {
      case 'addQuestion':
        if (questionName === '') {
          errors.push("El campo 'Pregunta' es requerido")
        }
        if (questionType !== 1 && answers.length === 0) {
          errors.push("Favor de agregar respuestas a su pregunta")
        }
        if (questionType !== 1 && answers.length <= 1) {
          errors.push("Favor de agregar más de una respuesta")
        }
        return errors

      case 'answers':
        if (textAnswers === '' && questionType !== 1 && answers.length < 1) {
          errors.push("El campo 'Respuestas' es requerido")
        }
        return errors

      case 'questionnarie':
        if (dates.selectDates === null) {
          errors.push("Favor de seleccionar un rango de fechas")
        }

        if (enableSpecificDate && (dates.specificDates.start >= dates.specificDates.end)) {
          errors.push("La fecha de inicio debe ser menor a la fecha final")
        }

        if (questions.length < 1) {
          errors.push("Favor de agregar preguntas al questionario")
        }
        return errors
    }
  }

  const addQuestion = () => {
    const error = handleErrors('addQuestion')
    if (error.length === 0) {
      const question = questionType === 1 ?
        {
          questionText: questionName,
          questionType,
          available: true
        }
        :
        {
          questionText: questionName,
          questionType,
          options: answers,
          available: true
        }

      setQuestions([...questions, question])
      setQuestionName('')
      setQuestionType(1)
      setAnswers([])
      setTextAnswers('')
      handleAlert('success', "Pregunta Agregada")
    }
    else {
      error.forEach(element => {
        handleAlert('error', element)
      });
    }
  }

  const addQuestionnaire = async () => {
    const error = handleErrors('questionnarie')

    if (error.length === 0) {
      const period = dateRange.filter(item => item.value === dates.selectDates);

      const data = {
        organizationRecordKey: loc.state.organizationRecordKey,
        startDate: dates.selectDates ? moment(period[0].startDate)._i : moment(dates.specificDates.start).format('DD-MM-YYYY'),
        endDate: dates.selectDates ? moment(period[0].endDate)._i : moment(dates.specificDates.end).format('DD-MM-YYYY'),
        type: paymentInterval,
        questions: questions
      }

      const response = await SaveQuestionnarie(data)
      if (response.success) {
        handleAlert('success', 'Cuestionario Registrado')
        navigate(-1)
      }
      else {
        handleAlert('error', 'Ha ocurrido un , por favor inténtelo de nuevo más tarde')
      }
    }
    else {
      error.forEach(element => {
        handleAlert('error', element)
      });
    }

  }

  const handlerDefaultQuestions = (item, answers = []) => {
    const question = item.questionType === 1 ?
      {
        questionText: item.questionText,
        questionType: item.questionType,
        available: true
      }
      :
      {
        questionText: item.questionText,
        questionType: item.questionType,
        options: answers,
        available: true
      }

    setQuestions([...questions, question])
    handleAlert('success', "Pregunta Agregada")
  }

  const defaultQuestionsTable = () => {
    if (defaultQuestions.length > 0) {
      return (
          defaultQuestions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
          <>
            <tr>
              <td className={`${classes.tableRegister} ${classes.question}`} data-label="Pregunta" >{item.questionText}</td>
              <td className={`${classes.tableRegister}`} data-label="Tipo">{item.questionType === 1 ? 'Texto libre' : item.questionType === 2 ? 'Selección única' : 'Selección múltiple'}</td>
              <td className={`${classes.tableRegister}`} data-label="Cantidad de opciones" >{item.options ? item.options.length : ''}</td>
              <td className={`${classes.tableRegister}`} data-label="Acciones">
                <div className={classes.actionContainer}>
                  {
                    item.questionType !== 1 &&
                    <button className={`${classes.buttonAdd}`} onClick={() => (setIsOpen(!isOpen), item.open ? item.open = false : item.open = true)}>
                      {
                        item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                      }
                    </button>
                  }

                  <button className={classes.buttonAdd} onClick={() => handlerDefaultQuestions(item, item.options && item.options)}>
                    <AddTask />
                  </button>
                </div>
              </td>
            </tr>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell className={classes.collapseContainer} colSpan={6}>
                <Collapse in={item.open} timeout="auto" unmountOnExit>
                  <text className={classes.infoBold}>Respuestas</text>

                  <div className={classes.responseContainer}>
                    {
                      item.questionType !== 1 &&
                      item.options.map(item => (
                        <text className={classes.info}>{item.descripcion}</text>
                      ))
                    }
                  </div>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ))
      )

    }
    else {
      <p>Por el momento no hay preguntas de catalogo</p>
    }
  }

  return (
    <div className={classes.containerBackground}>
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>
          Nuevo Cuestionario
        </h1>
      </div>
      <section className={`${classes.tableContainer} ${classes.periodContainer}`}>
        <article className={classes.gridColumn}>
          <h3>Período de pago</h3>
          <FormControl className={`${classes.marginTop}`} fullWidth>
            <InputLabel id='dates-select-label'>Fechas</InputLabel>
            <Select
              labelId='dates-select-label'
              id='dates-select'
              label='Fechas'
              variant='outlined'
              value={dates.selectDates}
              name="selectDates"
              onChange={handleOnChange}
            >
              {
                dateRange.map(items => (
                  <MenuItem value={items.value}>{items.label || `${items.startDate}   ${items.endDate}`}</MenuItem>
                ))
              }
            </Select>
          </FormControl>

          {
            enableSpecificDate &&
            <div className={classes.specificDates}>
              <FormControl>
                <InputLabel htmlFor="startDate">Fecha de Inicio*</InputLabel>
                <OutlinedInput
                  label='Fecha de Inicio'
                  variant="outlined"
                  name='date'
                  id="startDate"
                  required
                  type="date"
                  value={dates.specificDates.start}
                  onChange={handleOnChange}
                  inputProps={{ min: today, max: moment().add(3, 'M').format('YYYY-MM-DD'), startAdornment: '' }}
                  fullWidth
                  startAdornment={' '}
                />
              </FormControl>

              <FormControl>
                <InputLabel htmlFor="endDate">Fecha de Fin*</InputLabel>
                <OutlinedInput
                  label='Fecha de Fin'
                  name='date'
                  id="endDate"
                  required
                  type="date"
                  value={dates.specificDates.end}
                  onChange={handleOnChange}
                  inputProps={{ min: today, max: moment().add(3, 'M').format('YYYY-MM-DD') }}
                  fullWidth
                  startAdornment={' '}
                />
              </FormControl>
            </div>
          }
        </article>

        <article className={classes.addQuestionContainer}>
          <div className={classes.buttonsContainer}>
            {/* <button className={classes.button} onClick={addQuestionnaire}>
              <text>Registrar</text>
            </button> */}
            <PrimaryButton onClick = {addQuestionnaire} title = "Registrar"/>
          </div>
        </article>
      </section>

      <div className={classes.tableContainer}>
        {
          enableDefaultQuestions ?
            <article>
              <div className={classes.headerContainer}>
                <button className={classes.buttonAdd} onClick={() => setEnableDefaultQuestions(false)}>
                  <ArrowBackIosIcon />
                </button>
                <h3>Preguntas de Catálogo</h3>
              </div>

              <div className={classes.filterContainer}>
                <SearchBar
                  value={search}
                  handleOnChange={handleOnChange}
                  name='search'
                  width='85%'
                />

                <FormControl className={`${classes.inputs} ${classes.marginTop}`} fullWidth>
                  <InputLabel id='category-select-label'>Categoría</InputLabel>
                  <Select
                    labelId='category-select-label'
                    id='category-select'
                    label='Categoría'
                    variant='outlined'
                    value={category}
                    name="category"
                    onChange={handleOnChange}
                  >
                    {
                      allCategories.map((items,index) => (
                        <MenuItem key={index} value={items.value}>{items.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>

              </div>
              <table className={classes.table}>
                <thead className={classes.thead}>
                  <tr>
                    <th className={classes.th} >Pregunta</th>
                    <th className={classes.th} >Tipo</th>
                    <th className={classes.th} >Cantidad de opciones</th>
                    <th className={classes.th} >Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    defaultQuestionsTable()
                  }
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={defaultQuestions.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage='Registros por hoja'
              />

            </article>
            :
            <article>
              <h3>Registro de preguntas</h3>
              <h4 className={classes.titleQuestion}>
                Nueva Pregunta
              </h4>
              <div className={`${classes.inputQuestionContainer} ${classes.marginTop}`} fullWidth>
                <TextField
                  label='Pregunta'
                  variant="outlined"
                  name='question'
                  required
                  value={questionName}
                  onChange={handleOnChange}
                  fullWidth
                />
                <div className={classes.addbuttonContainer}>

                  <PrimaryButton onClick = {addQuestion} title = "Agregar pregunta"/>
                  <PrimaryButton onClick = {() => setEnableDefaultQuestions(true)} title = "Preguntas de catálogo"/>

                </div>
              </div>
              <RadioGroup row value={questionType} onChange={handleOnChange} className={classes.containerRadioButton}>
                <FormControlLabel name='questionType' value='1' control={<Radio color="primary" size="large" />} label="Texto libre" />
                <FormControlLabel name='questionType' value='2' control={<Radio color="primary" size="large" />} label="Selección Única" />
                <FormControlLabel name='questionType' value='3' control={<Radio color="primary" size="large" />} label="Selección Múltiple" />
              </RadioGroup>

              {
                questionType !== 1 &&
                <div>
                  <h4 className={classes.titleQuestion}>
                    Respuesta
                  </h4>
                  <div className={classes.newAnswersContainer}>
                    <TextField
                      label='Respuesta'
                      variant="outlined"
                      name='textAnswers'
                      required
                      value={textAnswers}
                      onChange={handleOnChange}
                      fullWidth
                    />

                    <button className={classes.buttonAdd} onClick={handleAnswers}>
                      <AddTask fontSize="large" />
                    </button>
                  </div>
                  {
                    answers.length > 0 ?
                      answers.map(item => (
                        <div className={classes.containerAnswers}>
                          <span>{item.descripcion}</span>
                          <button className={classes.deleteButton} onClick={() => handlerDelete(item.optionId, 'options')}><DeleteIcon /></button>
                        </div>
                      ))
                      :
                      <>
                        <p>Aún no se han ingresado respuestas para la pregunta.</p>
                      </>
                  }
                </div>
              }
            </article>
        }
      </div>
    </div>
  )
};

export default withSnackbar(NewQuestionnarie);

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
    paddingBottom: '30px'
  },
  title: {
    fontSize: '62px',
    width: '50% ',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    marginTop: '2%',
  },
  titleAndCardsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    margin: '3% 0',
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(300px,1fr))',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      padding: '5%'
    }
  },
  inputQuestionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'center',
    gap: '2%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 0,
      width: '90%'
    }
  },
  gridColumn: {
    marginRight: '5%',
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    }
  },
  addbuttonContainer: {
    display: 'flex',
    width: '50%',
    gap: 20,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  containerRadioButton: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  newAnswersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '2%',
    [theme.breakpoints.down('sm')]: {
      gap: 0,
    }
  },
  buttonAdd: {
    border: 'none',
    color: '#5D5D60',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center'
  },
  containerAnswers: {
    width: '95%',
    display: 'grid',
    gridTemplateColumns: '1fr 50px',
    alignItems: 'center',
    wordBreak: 'break-all',
    marginLeft: '5%',
  },
  questionContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 50px',
    wordBreak: 'break-all',
    alignItems: 'center',
    gap: '3%',
    marginBottom: '3%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit,minmax(50px,1fr))',
      gap: 0,
    }
  },
  deleteButton: {
    border: 'none',
    color: '#CC3C3C',
    borderRadius: 30,
    cursor: 'pointer',
    background: 'transparent',
  },
  periodContainer: {
    display: 'grid',
    margin: '3% 0',
    gridTemplateColumns: '1fr 200px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr'
    }
  },
  specificDates: {
    display: 'grid',
    gap: '5%',
    gridTemplateColumns: 'repeat(auto-fit,minmax(300px,1fr))',
    marginTop: '3%'
  },
  addQuestionContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  marginTop: {
    marginTop: '1% !important'
  },
  headerContainer: {
    display: 'flex'
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },
  inputs: {
    width: '30% !important',
    marginLeft: '5% !important',
    [theme.breakpoints.down('md')]: {
      width: '80% !important',
      marginLeft: 'none !important'
    }
  },
  thead: {
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    width: '25%',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    fontSize: '14px !important',
    textAlign: 'center',
    width: '25%',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.div}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'justify',
      width: '80%',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: 'none'
      }
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  info: {
    ...theme.typography.subtitle,
    fontSize: '14px !important',
    textAlign: 'center',
  },
  infoBold: {
    ...theme.typography.subtitle,
    fontWeight: '600',
    fontSize: '14px',
  },
  responseContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(100px,1fr))',
    gap: 25,
  },
  collapse: {
    marginLeft: 30,
    [theme.breakpoints.down('md')]: {
      marginLeft: 'none',
    },
  },
  question: {
    textAlign: 'justify'
  },
  collapseContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px !important',
    },
  },
  button: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.darkGray.main}`,
    color: theme.palette.white.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
  },
  buttonContainer: {
    width: '20%',
  },
  table: {
    width: '100%'
  }
}))