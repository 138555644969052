import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const saveUser2 = async (item) => {
  try {
    const result = await API.post(API_ANALYTICS, '/users/registerUser', {
      body: {
        items: item,
      }
    });
    return {
      success: true,
    }
  } catch (err) {
    console.log("ERR SAVE USER", err);
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default saveUser2;