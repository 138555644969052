import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Background from '../../assets/images/backgroundGrayyBottom.svg';
import TextField from '@mui/material/TextField';
import FileInput from '../fileInput';
import PrimaryButton from '../primaryButton'
import uploadLegalDocs from '../../lib/legalDocs/uploadLegalDocs'
import moment from 'moment-timezone';
import LinearProgress from '@mui/material/LinearProgress';

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  title: {
    fontSize: '62px',
    width: '60% ',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '40px',
    },
    textAlign: 'center',
  },
  titleAndCardsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    margin: '5%  0 10% 0',
    backgroundColor: theme.palette.white.main,
    width: '80%',

    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
    overflowX: 'auto',
    margin: '0 auto',
    marginTop: 40,
    marginBottom: 10,
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  inputs: {
    width: '100%',
    marginBottom: '10% !important'
  },
  formContainer: {
    marginTop: 20,
    
  },
  progressBarContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const UploadLegalDoc = (props) => {
  const classes = useStyle();
  const today = moment().format('YYYY-MM-DD') 
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState(today);
  const [file, setFile] = useState({ data: null, from: null });
  const [maxDate, setMaxDate] = useState(today);
  const [errors, setErrors] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  }

  const handleFileType = (type, size) => {
    const fileType = type.toLowerCase().split('/')
    return fileType[1] === 'pdf' || fileType[1] === 'jpg' || fileType[1] === 'jpeg' ? { type: true } : { type: false }
  }

  const handleFile = (event) => {
    const { files, name } = event.target
    const responseType = handleFileType(files[0].type, files[0].size)
    if (responseType.type) {
      setFile({ data: files[0], from: name })
    }
    else {
      const error = responseType.type === false && responseType.size === false ?
        'Solo se aceptan archivos PDF | Tamaño Máximo es de 5MB' :
        responseType.type === false ? 'Solo se aceptan archivos PDF' :
          responseType.size === false && 'Documento demasiado grande'
      handleAlert('error', error);
    }
  }

  const handleErrors  = () => {
    let error = {}
    if (maxDate === today) {
      error.maxDate = "Elegir una fecha futura"
      errors.errorMaxDate = true
      setErrors(errors)
    }
    if (!file.data) {
      error.file = "Elegir un archivo a subir"
      errors.errorFile = true
      setErrors(errors)
    }

    return error
  }

  const uploadProgress = (progressEvent) => {
    const barProgress = 100 * (progressEvent.loaded / progressEvent.total)
    setProgress(barProgress);
  }


  const upload = async (event) => {
    event.preventDefault();
    const error = handleErrors();
    if (Object.keys(error).length === 0) {
      setIsUploading(true);
      const response = await uploadLegalDocs(file.data, file.type, props.documentName, maxDate, today, uploadProgress)
      if (response.success) {
        setVersion(today);  
        setFile({ data: null, from: null });
        handleAlert('success', 'Archivo subido con éxito');
        
      } else {
        handleAlert('error', response.error);
      }
    }
    else {
      for (const value in error) {
        handleAlert('error', error[value])
      }
    }
  }

  if (loading) {
    return (
      <div></div>
    )
  }
  return (
    <div className={classes.containerBackground}>
      <div className={classes.titleAndCardsContainer}>
        <h3 className={classes.title}>
          {props.title}
        </h3>
        <div className={classes.formContainer}>
          <TextField
            label="Versión"
            variant="outlined"
            name='version'
            required
            value={version}
            onChange={(event) => setVersion(event.target.value)}
            className={classes.inputs}
            disabled={true}
          />
          <TextField
            id="maxDate"
            label="Fecha máxima de aceptación"
            type="date"
            name="maxDate"
            defaultValue={"2017-05-24"}
            className={classes.inputs}
            value={maxDate}
            inputProps={{ min: "1950-01-01" }}
            onChange={(event) => setMaxDate(event.target.value)}
          />
          <label className={classes.labelButtonFile}>
            <p className={classes.textFile}>{props.title}</p>
            <FileInput
              type='file'
              id="file"
              name="file"
              onChange={handleFile}
              file={file.data}
              disabled={false}
              width='100%'
            />
          </label>
          <PrimaryButton text={'Subir'} handleClick={upload}/>
        <div className={classes.progressBarContainer}>
            {
              isUploading && (
                <>
                  <LinearProgress
                    color='primary'
                    variant='determinate'
                    value={progress.toFixed(0)}
                    sx = {{width: '90%'}}
                  />
                  <p className ={classes.progressBarText}>{progress.toFixed(0)}%</p>
                </>
              )
            }
        </div>
        </div>
      </div>
    </div>
  );
}

export default withSnackbar(UploadLegalDoc);