import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';

import PrimaryButton from '../../primaruButtonLite';
import SecondaryButton from '../../secondaryButtonLite';

import { withSnackbar } from 'notistack';
import { TextField } from '@mui/material';
import * as R from 'ramda';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import saveCategory from '../../../lib/Admin/saveCategory';
import editCategory from '../../../lib/Admin/editCategory';

const ModalCategory = (props) => {
  const categoryInfo = props.categoryInfo;
  const classes = useStyles();

  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState(R.pathOr({},['category'],categoryInfo))

  useEffect(() => {
    setCategory(R.pathOr('',['category'],categoryInfo))
  }, [categoryInfo])

  const saveCategories = async() => {
    setLoading(true);
    if (category === '') {
      handleAlert('error', "El campo 'Categoria' es requerido")
      setLoading(false);
    }
    else {
      const result = Object.keys(categoryInfo).length > 0 ? await editCategory(categoryInfo.id,category) : await saveCategory(category)
      if(result.success){
          handleAlert('success', result.message)
          props.getCategories()
          props.onClose()
      }
      else {
          handleAlert('error', result.error)
      }
      setLoading(false);
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}
      style={{ overflow: 'scroll' }}>
      { loading ?
        <div className={`${classes.containerBackgroundModalGrid} ${classes.loadingContainer}`}>
          <CircularProgress />
        </div>
        :
        <main className={classes.containerBackgroundModalGrid}>
            <div className={classes.containerQuestion}>
              <h1 className={classes.titleQuestion}> {Object.keys(categoryInfo).length ? 'Editar Categoría' : 'Nueva Categoría'} </h1>
              <TextField
                label='Categoría'
                variant="outlined"
                name='category'
                required
                value={category}
                onChange={(event) => setCategory(event.target.value)}
                fullWidth
                size="small"
              />
            </div>

            <div className={classes.containerButtons}>
                <PrimaryButton
                title="Cancelar"
                onClick={props.onClose}
                />

                <SecondaryButton
                title={Object.keys(categoryInfo).length ? 'Actualizar' :'Registrar'}
                onClick={saveCategories}
                />
            </div>
        </main>
      }
    </Modal>
  );
};

export default withSnackbar(ModalCategory);

const useStyles = makeStyles(theme => ({
  containerBackgroundModal: {
    width: '75%',
    background: "#FFFFFF",
    margin: '5% auto',
    padding: '2%',
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    },
  },
  containerBackgroundModalGrid: {
    width: '30%',
    background: "#FFFFFF",
    margin: '5% auto',
    padding: '2%',
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    },
  },
  containerGridQuestion: {
    width: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr minmax(100px, 1fr)',
    maxHeight: '90vh',
    gridGap: 15,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  containerQuestion: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
  },
  titleQuestion: {
    textAlign: 'center',
    ...theme.typography.title,
  },

  containerButtons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '20%',
    marginTop: 15,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      width: '62%',
    },
  },
  buttonStyle: {
    width: 'auto',
    margin: "5px 30px 0px",
    [theme.breakpoints.down('xs')]: {
      margin: "auto",
      marginBottom: '5%',
      display: 'flex'
    },
  },
  labelCheckbox: {
    ...theme.typography.mediumText,
    marginTop: 10
  },
  hidden: {
    visibility: 'false',
  },
  buttonDelete: {
    border: 'none',
    color: '#EE715B',
    padding: '9% 0',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
  },
  containerAnswers: {
    width: '95%',
    display: 'grid',
    gridTemplateColumns: '1fr 50px',
    alignItems: 'center',
    wordBreak: 'break-all',
    marginLeft: '5%',
  },
  containerRadioButton: {
    display: 'flex',
    flexDirection: 'column'
  },
  answersContainer: {
    overflow: 'scroll',
  },
  buttonAdd: {
    border: 'none',
    color: '#5D5D60',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
    fontWeight: 'bold',
    paddingRight: 15,
    paddingTop: 5
  },
  newAnswersContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dateInput: {
    margin: '5px 0'
  },
  subtitleQuestion: {
    ...theme.typography.subtitle1,
    fontWeight: 'bold'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}));
