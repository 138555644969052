import { API } from 'aws-amplify';

const GetResults = async (companyId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_GETRESULTS}/payrollFile/File?companyId=${companyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY_CREATEFILES,
      }
    })
    return {
      response: await response.json(),
      success: true,
    }
  } catch (err) {
    return {
      success: false,
      error: err
    }
  }
}

export default GetResults;