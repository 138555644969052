import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from "react-router-dom";
import { withSnackbar } from 'notistack';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  REGEX_EMAIL,
  COUNTRY_CODE,
  REGEX_LETTERS,
  REGEX_PHONE,
  REGEX_RFC,
  REGEX_LETTERS_AND_NUMBERS,
  PAYMENT_INTERVALS,
} from "../../lib/constant";
import StatesCities from '../../lib/location/statesCities';
import States from '../../lib/location/states';
import moment from 'moment';

import EditOrganization from '../../lib/Analytics/editOrganization';

const useStyle = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.625rem',
    width: '100%',
    marginBottom: '6.25rem',
    paddingTop: 50,
    [theme.breakpoints.down('md')]: {
      // flexDirection: 'column',
      padding: 20,
      zIndex: 0,
      marginLeft: '-5%'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-10%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },

  },
  containerButton: {
    backgroundColor: theme.palette.primary.main,
    width: '14.063rem',
    height: '3.125rem',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    marginBottom: '30px'
  },
  labelButton: {
    color: theme.palette.white.main,
    fontSize: 16
  },
  inputs: {
    width: '100%',
    marginBottom: '10% !important'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
  icons: {
    width: '35px',
  },
  phoneContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10%',
  },
  inputPhone: {
    width: '90%',
  },
  appsContainer: {
    margin: '1% auto 10% auto'
  },
  imgIcons: {
    width: '70%'
  },
  selectContainer: {
    width: '22%',
    marginRight: '5%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
  },
  containerCompanyForm: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      zIndex: 0,
    },
  },
  containerForm1: {
    width: '45%',
    marginRight: '10%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 0
    },
  },
  containerForm2: {
    width: '45%',
    marginLeft: '5%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0
    },
  },
  cityContainer: {
    display: 'flex',
    width: '100%',
    alignContent: 'center',
    justifyContent: 'space-between',
    marginBottom: '10%'
  },
  stateSelect: {
    width: '95%'
  },
  fileButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonFiles: {
    backgroundColor: "transparent",
    width: '60%',
    height: '3.125rem',
    borderRadius: '4.25rem',
    margin: '1.875rem 0 ',
    border: `3px solid ${theme.palette.darkGray.main}`,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  labelButtonFile: {
    color: theme.palette.darkGray.main,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textFile: {
    width: '40%',
    textAlign: 'center',
    color: theme.palette.darkGray.main,
    fontWeight: 'bold'
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    fontWeight: 600,
  },
  checkRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  checkbox: {
    width: 15,
    height: 15,
  },
  subtitle: {
    padding: '10px',
  },
  smallInput: {
    width: '60%',
    marginBottom: '10% !important'
  },
  miniInput: {
    width: '35%',
    marginBottom: '10% !important'
  }

}))

const CompanyInformationForm = (props) => {
  const classes = useStyle(props);
  let navigate = useNavigate();
  const loc = useLocation();
  
  //Company Info
  const [companyName, setCompanyName] = useState('');
  const [rfc, setRfc] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('+52');
  const [email, setEmail] = useState(loc.state.email);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [enableCities, setEnableCities] = useState(false);
  const [cities, setCities] = useState([]);
  const [zipCode, setZipCode] = useState('');
  const [legalAddress, setLegalAddress] = useState('');
  const [contactName, setContactName] = useState('');
  const [paymentInterval, setPaymentInterval] = useState('');

  //Contitution Info
  const [constitutiveActNo, setConstitutiveActNo] = useState('');
  const [constitutionDate, setConstitutionDate] = useState('');
  const [publicNotary, setPublicNotary] = useState('');
  const [notaryNo, setNotaryNo] = useState('');
  const [notaryAddress, setNotaryAddress] = useState('');

  // const [tradeName, setTradeName] = useState('');

  const [errors, setErrors] = useState(
    { errorName: false, errorEmail: false, errorMessage: false, errorSyntax: false })

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleErrors = () => {
    let error = {}
    if (companyName === '') {
      error.companyName = "Favor de ingresar el Nombre de la Empresa"
      errors.errorcompanyName = true
      setErrors(errors)
    }

    else if (!REGEX_RFC.test(rfc)) {
      error.rfc = "Favor de ingresar un RFC válido"
      errors.errorRfc = true
      setErrors(errors)
    }

    else if (phone === '') {
      error.phone = "Favor de ingresar un Nombre de Contacto"
      errors.errorName = true
      setErrors(errors)
    }

    else if (email === '') {
      error.email = "Favor de ingresar un Correo Electrónico"
      errors.errorEmail = true
      setErrors(errors)
    }

    else if (!REGEX_EMAIL.test(email)) {
      error.emailSyntax = "Favor de ingresar un Correo Electrónico válido"
      errors.emailSyntax = true
      setErrors(errors)
    }

    else if (state === '') {
      error.state = "Favor de seleccionar un Estado"
      errors.state = true
      setErrors(errors)
    }

    else if (city === '') {
      error.city = "Favor de seleccionar una Ciudad"
      errors.city = true
      setErrors(errors)
    }

    else if (zipCode === '') {
      error.zipCode = "Favor de seleccionar un Código Postal"
      errors.zipCode = true
      setErrors(errors)
    }

    else if (legalAddress === '') {
      error.legalAddress = "Favor de ingresar la Dirección Legal"
      errors.errorLegalAddress = true
      setErrors(errors)
    }

    else if (contactName === '') {
      error.contactName = "Favor de ingresar un Nombre de Contacto"
      errors.errorContactName = true
      setErrors(errors)
    }

    // else if (paymentInterval === '') {
    //   error.paymentInterval = "Favor de seleccionar un Intervalo de Pago"
    //   errors.errorpaymentInterval = true
    //   setErrors(errors)
    // }

    else if (constitutiveActNo === '') {
      error.constitutiveActNo = "Favor de ingresar el Número de Acta Constitutiva"
      errors.errorConstitutiveActNo = true
      setErrors(errors)
    }

    else if (constitutionDate === '') {
      error.constitutionDate = "Favor de ingresar la Fecha de Constitución"
      errors.errorContitutionDate = true
      setErrors(errors)
    }

    else if (publicNotary === '') {
      error.publicNotary = "Favor de ingresar un Notario Público"
      errors.errorPublicNotary = true
      setErrors(errors)
    }

    else if (notaryNo === '') {
      error.notaryNo = "Favor de ingresar el Número de Notaria"
      errors.errorNotaryNo = true
      setErrors(errors)
    }

    else if (notaryAddress === '') {
      error.notaryAddress = "Favor de ingresar la Dirección de la Notaria"
      errors.errorNotaryAddress = true
      setErrors(errors)
    }

    return error
  }

  // const senEmail = (event) => {
  //   event.preventDefault();
  //   const error = handleErrors()
  //   if (Object.keys(error).length === 0) {
  //     //AQUI VA LA LLAMADA DEL BACK
  //   }
  //   else {
  //     for (const value in error) {
  //       handleAlert('error', error[value])
  //     }
  //   }
  // }

  const saveInformation = async (event) => {
    event.preventDefault();
    const error = handleErrors()
    if (Object.keys(error).length === 0) {
      const item = {
        name: companyName,
        // tradeName: tradeName,
        rfc: rfc,
        phone: `${country}${phone}`,
        email: email,
        state: state,
        city: city,
        zipCode: zipCode,
        address: legalAddress,
        // paymentInterval: paymentInterval,
        contact: {
          name: contactName,
        },
        subscriptionType: loc.state.subscriptionType,
        constitutiveActNo: constitutiveActNo,
        constitutionDate: constitutionDate,
        publicNotary: publicNotary,
        notaryNo: notaryNo,
        notaryAddress: notaryAddress,
        comission_percentage: 0
      }
      const statusOrg = await EditOrganization(loc.state.organizationRecordKey, item);
      if (statusOrg.success) {
        if (item.subscriptionType === 'standard') {
          // navigate('/comingSoon');
          navigate('/companyDataType', {
            state: {
              organizationRecordKey: loc.state.organizationRecordKey,
              subscriptionType: item.subscriptionType
            }
          });

        } else {
          // navigate('/waitContact');
          navigate('/companyRepresentative', {
            state: {
              organizationRecordKey: loc.state.organizationRecordKey,
              subscriptionType: item.subscriptionType
            }
          });
        }
      }
      else {
        handleAlert('error', statusOrg.error);
      }
    }
    else {
      for (const value in error) {
        handleAlert('error', error[value]);
      }
    }
  }

  const handleOnChange = (event) => {
    const { name, value, id, } = event.target
    switch (name) {
      case 'companyName':
        setCompanyName(value)
        break;
      case 'rfc':
        if (REGEX_LETTERS_AND_NUMBERS.test(value)) {
          setRfc(value)
        }
        break;
      case 'countryCodeSelect':
        setCountry(value)
        break;
      case 'phone':
        if (REGEX_PHONE.test(value)) {
          setPhone(value)
        }
        break;
      case 'email':
        setEmail(value)
        break;
      case 'states':
        setState(value)
        setEnableCities(true)
        const cities = StatesCities(value)
        setCities(cities)
        break;
      case 'city':
        setCity(value)
        break;
      case 'zipCode':
        if (REGEX_PHONE.test(value)) {
          setZipCode(value)
        }
        break;
      case 'legalAddress':
        setLegalAddress(value)
        break;
      case 'contactName':
        if (REGEX_LETTERS.test(value)) {
          setContactName(value);
        }
        break;
      // case 'paymentInterval':
      //   setPaymentInterval(value);
      //   break;
      // case 'tradeName':
      //   setTradeName(value)
      //   break;
      case 'constitutiveActNo':
        setConstitutiveActNo(value)
        break;
      case 'constitutionDate':
        setConstitutionDate(value)
        break;
      case 'publicNotary':
        setPublicNotary(value)
        break;
      case 'notaryNo':
        setNotaryNo(value)
        break;
      case 'notaryAddress':
        setNotaryAddress(value);
        break;
      default:
        break;
    }
  }

  return (
    <div className={classes.containerForm}>
      <form className={classes.form}>
        <h5 className={classes.subtitle}>Información general de la empresa</h5>
        <div className={classes.containerCompanyForm}>
          <div className={classes.containerForm1}>
            <TextField
              label="Nombre de la Empresa"
              variant="outlined"
              name='companyName'
              required
              value={companyName}
              onChange={handleOnChange}
              className={classes.inputs}
            />
            <div className={classes.phoneContainer}>
              <Select
                value={country}
                name="countryCodeSelect"
                onChange={handleOnChange}
                className={classes.selectContainer}
              >
                {
                  COUNTRY_CODE.map(items => (
                    <MenuItem key={items.value} value={items.value}>{items.label}</MenuItem>
                  ))
                }
              </Select>
              <TextField
                label="Número de Teléfono"
                variant="outlined"
                name='phone'
                required
                inputProps={{ maxLength: 10 }}
                value={phone}
                onChange={handleOnChange}
                className={classes.inputPhone}
              />
            </div>
            <FormControl className={classes.inputs} fullWidth>
              <InputLabel id='state-select-label'>Estado</InputLabel>
              <Select
                labelId='state-select-label'
                id='state-select'
                label='Estado'
                variant='outlined'
                value={state}
                name="states"
                onChange={handleOnChange}
              >
                {
                  States.map(items => (
                    <MenuItem key={items.label} value={items.label}>{items.label}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <TextField
              label="Código Postal"
              variant="outlined"
              name='zipCode'
              required
              value={zipCode}
              inputProps={{ maxLength: 5 }}
              onChange={handleOnChange}
              className={classes.inputs}
            />
            <TextField
              label="Nombre del Contacto"
              variant="outlined"
              name='contactName'
              id='name'
              required
              value={contactName}
              onChange={handleOnChange}
              className={classes.inputs}
            />
          </div >
          <div className={classes.containerForm2}>
            {/* <TextField
                label="Nombre Comercial"
                variant="outlined"
                name='tradeName'
                value={tradeName}
                onChange={handleOnChange}
                className={classes.inputs}
              /> */}
            <TextField
              label="RFC con Homoclave"
              variant="outlined"
              name='rfc'
              required
              inputProps={{ maxLength: 13 }}
              value={rfc}
              onChange={handleOnChange}
              className={classes.inputs}
            />
            <TextField
              label="Email"
              variant="outlined"
              name='email'
              id='email'
              required
              value={email}
              onChange={handleOnChange}
              className={classes.inputs}
              disabled
            />
            <FormControl className={classes.inputs} fullWidth>
              <InputLabel id='city-select-label'>Ciudad</InputLabel>
              <Select
                labelId='city-select-label'
                id='city-select'
                label='Ciudad'
                variant='outlined'
                value={city}
                name="city"
                onChange={handleOnChange}
                disabled={!enableCities}
              >
                {
                  cities.map(items => (
                    <MenuItem key={items} value={items}>{items}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <TextField
              label="Dirección Fiscal"
              variant="outlined"
              name='legalAddress'
              required
              value={legalAddress}
              onChange={handleOnChange}
              className={classes.inputs}
            />
            {/* <FormControl className={classes.inputs} fullWidth>
              <InputLabel id='payment-select-label'>Intervalo de Pago</InputLabel>
              <Select
                labelId='payment-select-label'
                id='payment-select'
                label='Intervalo de Pago'
                variant='outlined'
                value={paymentInterval}
                name="paymentInterval"
                onChange={handleOnChange}
              >
                {
                  PAYMENT_INTERVALS.map(item => (
                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                  ))
                }
              </Select>
            </FormControl> */}
          </div>
        </div >
        <h5 className={classes.subtitle}>Información de la constitución de la empresa</h5>
        <div className={classes.containerCompanyForm}>
          <div className={classes.containerForm1}>
            <TextField
              label="Número de Acta Constitutiva"
              variant="outlined"
              name='constitutiveActNo'
              required
              value={constitutiveActNo}
              onChange={handleOnChange}
              className={classes.inputs}
            />
            <TextField
              label="Notario público"
              variant="outlined"
              name='publicNotary'
              required
              value={publicNotary}
              onChange={handleOnChange}
              className={classes.inputs}
            />
            <TextField
              label="Dirección notario"
              variant="outlined"
              name='notaryAddress'
              required
              value={notaryAddress}
              onChange={handleOnChange}
              className={classes.inputs}
            />
          </div>
          <div className={classes.containerForm2}>
            <TextField
              id="constitutionDate"
              label="Fecha de Constitución"
              type="date"
              name="constitutionDate"
              defaultValue="2017-05-24"
              className={classes.inputs}
              inputProps={{ min: "1950-01-01", max: moment().format('YYYY-MM-DD')}}
              onChange={handleOnChange}
            />
            <TextField
              label="No. notaria"
              variant="outlined"
              name='notaryNo'
              required
              value={notaryNo}
              onChange={handleOnChange}
              className={classes.inputs}
            />
          </div>
        </div>
        <button className={classes.containerButton} onClick={saveInformation}>
          <p className={classes.labelButton}>{'Continuar'}</p>
        </button>
      </form >
    </div >
  )
}

export default withSnackbar(CompanyInformationForm);