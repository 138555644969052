import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const getQuestionnaire = async (intervalRecordKey) => {
  try {
    const result = await API.get(API_ANALYTICS, `/intervals/${intervalRecordKey}`);

    return {
      success: true,
      data: result.data
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default getQuestionnaire;