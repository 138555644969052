import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Background from '../../../assets/images/backgroundGrayyBottom.svg';
import { useNavigate, useLocation } from "react-router-dom";
import CheckCircle from '@mui/icons-material/CheckCircle';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SecondaryButton from '../../../components/secondaryButton';
import PrimaryButton from '../../../components/primaryButton';
import approvalOrgatization from '../../../lib/Analytics/approvalOrgatization';
import TabPanel from '../../../components/TabPanel/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';

import LoadFiles from '../../../lib/Analytics/getFiles';
import OrganizationDetailsComponent from '../../../components/organizationDetails';
import OrganizationLeadsComponent from '../../../components/organizationLeads';
import OrganizationLeads from '../../../components/organizationLeads';
import Files from '../../../components/results/files';
import Results from '../../../components/results/results';
import GetResults from '../../../lib/CreateFiles/getResults';
import SurveyComponent from '../../../components/surveys/surveys';
import DetailQuestionnaire from '../../../components/surveys/detailQuestionnaire';
import GetQuestionnarie from '../../../lib/Analytics/getQuestionnarie';
import GetQuestions from '../../../lib/Analytics/getQuestions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import editOrganization from '../../../lib/Analytics/editOrganization';
import EmployeeQuestions from '../../../components/employeesQuestions';
import gerOrders from '../../../lib/Orders/getOrders';
import confirmOrder from '../../../lib/Orders/confirmOrder';
import payOrder from '../../../lib/Orders/payOrder';
import getOrganization from '../../../lib/Analytics/getOrganization';

const OrganizationDetails = (props) => {
  const loc = useLocation();
  const navigate = useNavigate();
  const organizationRecordKeys = loc.state.organizationRecordKey

  const classes = useStyle();
  const perPage = 10;

  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState({});
  const [viewLeads, setViewLeads] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileResults, setFileResults] = useState([]);
  const [questionnaries, setQuestionnaries] = useState([]);
  const [selectedQuestionnarie, setSelectedQuestionnarie] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [enableToPay, setEnableToPay] = useState(false);
  const [itemsView, setItemsView] = useState([]);
  const [listSearch, setListSearch] = useState([])
  const [openDeleteOrderModal, setOpenDeleteOrderModal] = useState(false)
  const [refresh, setRefresh] = useState(false);


  useEffect(() => {
    handleGetOrganization(organizationRecordKeys);
  }, [refresh])

  const handleGetOrganization = async (organizationRecordKeys) => {
    try {
      const org = await getOrganization(organizationRecordKeys)
      if (org.data) {
        setOrganization(org.data)
        setEnableToPay(org.data.enableToPay)
      } else {
        handleAlert('error', 'Ha ocurrido un error, no se pudo obtener la informacion de la compañia')
        navigate(-1)
        return
      }
      await getAllOrders(organizationRecordKeys)
      await getQuestionnaires(organizationRecordKeys);
    } catch (error) {
      handleAlert('error', 'Ha ocurrido un error, no se pudo obtener la informacion de la compañia')
      navigate(-1)
    }
    setLoading(false)
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const approvalCompany = async (event, typeButton = 'denied') => {
    const type = organization.approval === 'denied' ? 'approved' :
      organization.approval === 'approved' ? 'denied' :
        organization.approval === 'pending' && typeButton === 'approved' ? 'approved' : 'denied'

    const result = await approvalOrgatization(loc.state.organization.organizationRecordKey, type)
    if (result.success) {
      navigate(-1)
    }
    else {
      handleAlert('error', 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde')
    }
  }

  const enablePayroll = async () => {
    setLoading(true)
    await editOrganization(organization.organizationRecordKey, { enableToPay: !enableToPay, endDateInterval: null });
    setEnableToPay(!enableToPay)
    setLoading(false)
  };

  const loadFiles = async (documentName) => {
    const urlFiles = await LoadFiles(organization.organizationRecordKey, documentName)
    window.open(urlFiles.url)
  }

  const getAllOrders = async (organizationRecordKey) => {
    try {
      const order = await gerOrders(organizationRecordKey)
      if (order.success) {
        setResults(order.data)
      } else {
        // handleAlert('error', 'Algo salio mal')
      }
    } catch (error) {
      // handleAlert('error', 'Algo salio mal')
    }
    setLoading(false)
  }

  const selectFile = (file, index) => {
    setSelectedFile(file);
    setFileResults(file.detail)
  }

  const getQuestionnaires = async (organizationRecordKey) => {
    setLoading(true);
    const result = await GetQuestionnarie(organizationRecordKey)
    if (result.data) {
      setQuestionnaries(result.data ? result.data : null)
    }
    setLoading(false)
  }

  const selectQuestionnarie = (questionnarie, index) => {
    setSelectedQuestionnarie(questionnarie)
    getQuestions(questionnarie)
  }

  const getQuestions = async (questionnarie) => {
    // setLoading(true)
    const result = await GetQuestions(questionnarie.intervalRecordKey)
    setQuestions(result.data)
    // setLoading(false)
  }

  const selectOrder = async (file, index) => {
    setSelectedFile(file);
    // const response = await getOrder(fileRecordKey)
    // console.log({response})
    //tomamos el id y hacemos el get a la orden
    // const resultsError = file.detail.filter(item => !item.successful);
    // const resultsSuccess = file.detail.filter(item => item.successful);
    // setDataErrors(resultsError)
    // setDataSuccess(resultsSuccess)
  }

  const handleDeleteOrderModal = (order) => {
    setOpenDeleteOrderModal(true)
  }

  const handleConfirmOrder = async (orderId) => {
    try {
      setLoading(true)
      const res = await confirmOrder(orderId)
      if (res.success) {
        handleAlert('success', 'Orden confirmada exitosamente')
        await getAllOrders()
      } else {
        // handleAlert('error', 'Algo salio mal')
      }
    } catch (error) {
      // handleAlert('error', 'Algo salio mal')
    }
    setLoading(false)
  }
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <div className={classes.containerBackground}>
      {
        loading ?
          <div className={classes.loading}>
            <CircularProgress />
          </div>
          :
          <div className={classes.titleAndCardsContainer}>
            <h1 className={classes.title}>
              Detalles
            </h1>
            <div className={classes.rowLabels}>
              <div className={classes.titleContainer}>
                <h2 className={classes.organizationName}>{organization.name}</h2>
                {organization.approval === 'pending' ?
                  <RemoveCircle sx={{ color: '#5D5D60', fontSize: 40, marginLeft: '10px' }} />
                  : organization.approval === 'approved' ?
                    <CheckCircle sx={{ color: '#40AB74', fontSize: 40, marginLeft: '10px' }} />
                    :
                    <CancelRoundedIcon sx={{ color: '#CC3C3C', fontSize: 40, marginLeft: '10px' }} />
                }
              </div>
              <div className={classes.containerButton}>
                {organization.approval != 'pending' &&
                  <PrimaryButton
                    text={!enableToPay ? "Activar dispersion" : "Desactivar dispersion"}
                    handleClick={() => enablePayroll()}
                    color="#5D5D60"
                  />
                }
                <SecondaryButton
                  text={organization.approval === 'denied' ? 'Activar' : 'Desactivar'}
                  handleClick={approvalCompany}
                  color="#5D5D60"
                />
                {organization.approval === 'pending' &&
                  <PrimaryButton
                    text="Activar"
                    handleClick={(event) => approvalCompany(event, 'approved')}
                    color="#000000"
                  />
                }
              </div>
            </div>
            <TabPanel
              // tabs={['Detalles', 'Subastas', 'Dispersión', 'Cuestionarios', 'Preguntas']}
              tabs={['Detalles', 'Subastas', 'Dispersión']}
              panels={[
                <div className={!viewLeads ? classes.detailsContainer : classes.centerDiv}>
                  <OrganizationDetailsComponent organization={organization} setLoading={setLoading} />
                </div>,
                <OrganizationLeads organization={organization.organizationRecordKey} from={'admin'} />,
                selectedFile ?
                  <>
                    <IconButton onClick={() => setSelectedFile(null)}>
                      <ArrowBackIcon />
                    </IconButton>
                    <Results data={fileResults} />
                  </>
                  :
                  <Files
                    // data={results}
                    from={'admin'}
                    setLoading={setLoading}
                    handleAlert={handleAlert}
                    setOpenDeleteOrderModal={setOpenDeleteOrderModal}
                    companyId={organization.organizationRecordKey}
                  />,
                selectedQuestionnarie ?
                  <>
                    <IconButton onClick={() => setSelectedQuestionnarie(null)}>
                      <ArrowBackIcon />
                    </IconButton>
                    <DetailQuestionnaire
                      companyId={organization.organizationRecordKey}
                      selectedQuestionnarie={selectedQuestionnarie}
                      setItemsView={setItemsView}
                      listSearch={listSearch}
                    />
                  </>
                  :
                  <SurveyComponent
                    companyId={organization.organizationRecordKey}
                    setSelectedQuestionnarie={setSelectedQuestionnarie}
                    setItemsView={setItemsView}
                    listSearch={listSearch}
                  />,
                <EmployeeQuestions
                  companyId={organization.organizationRecordKey}
                />
              ]}
            />
          </div>
      }
    </div >
  );
}

export default withSnackbar(OrganizationDetails);

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '62px',
    width: '80% ',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  titleAndCardsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '5%'
  },
  detailsContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    overflowX: 'auto',
    margin: '0 auto',
    marginTop: 40,
    marginBottom: 10,
    padding: '20px 40px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  icon: {
    cursor: 'pointer',
    backgroundColor: 'white',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: '20px 0px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  rowLabels: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    width: '80% ',
  },
  organizationName: {
    ...theme.typography.title,
    fontSize: '50px',
    fontWeight: '500',
  },
  containerButton: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column'
    },
  },
  button: {
    backgroundColor: theme.palette.white.main,
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    border: '1px solid #000',
    padding: '10px 20px'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  leadsButton: {
    backgroundColor: '#000',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    border: '1px solid #000',
    padding: '10px 20px',
  },
  leadsButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  leadsButtonLabel: {
    color: theme.palette.white.main,
    ...theme.typography.button,
    fontWeight: '500'
  },
  centerDiv: {
    justifyContent: 'center',
    display: 'flex',
  },
  loading: {
    display: 'flex',
    minHeight: '25vh',
    flexDirection: 'column',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
}));