import React, { useState,useEffect } from 'react';
import DOMPurify from 'dompurify'
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Background from '../../assets/images/backgroundGrayyBottom.svg';
import getDocumentActive from '../../lib/Admin/manageDocuments/getDocumentActive';

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
  loadingContainer: {
    margin: '0 auto'
  },
  title: {
    fontSize: '62px',
    margin: '20px 0px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '40px',
    },
    textAlign: 'center',
  },
  titleAndCardsContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: 25,
    overflowX: 'auto',
    marginBottom: '10%',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  }
}));

const ReadTermsAndConditions2 = () => {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);
  const [termsConditions, setTermsConditions] = useState('')

  useEffect(() => {
    getTermsConditions()
  }, [])

  const getTermsConditions = async() => {
    const result = await getDocumentActive('terms');
    if(result.data){
      setTermsConditions(result.data.content)
    }
    else {
      setTermsConditions('')
    }
    setLoading(false)
  }
  
  return (
    <div className={classes.containerBackground}>
        <h1 className={classes.title}>TÉRMINOS Y CONDICIONES</h1>
      <div className={classes.titleAndCardsContainer}>
        {
          loading ? 
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          : 
            <div className="content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(termsConditions)}}></div>
        } 
      </div>
    </div>
  );
}

export default ReadTermsAndConditions2;