
import axios from 'axios';

const getLegalDoc = async (documentName) => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_ENDPOINT_API_TERMS}/legalDocs/noauth`, {
      params: {
        documentName,
      },
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY_TERMS,
      }
    })

    return {
      url: result.data.url,
      success: true,
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default getLegalDoc;