import axios from 'axios';

const batchStampBills = async (orderRecordKey, serviceDate) => {
  try {
    const BILLING_API = process.env.REACT_APP_BILLING_API;
    const BILLING_API_KEY = process.env.REACT_APP_BILLING_API_KEY;
    const BILLING_ISSUER = process.env.REACT_APP_BILLING_ISSUER;
    const body = {
      orderRecordKey,
      serviceDate,
      rfcIssuer: BILLING_ISSUER,
    };
    const result = await axios.post(`${BILLING_API}/stampRetentions`, {
      ...body,
    }, {
      headers: {
        'x-api-key': BILLING_API_KEY,
      },
    }
    );
    return result.data;
  } catch (err) {
    throw new Error(err.message);
  }
}

export default batchStampBills;