import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';

import { Grid } from '@material-ui/core';
import PrimaryButton from '../../primaryButton';
import SecondaryButton from '../../secondaryButton';

import { withSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/Clear';
import AddTask from '@material-ui/icons/AddCircle';
import { TextField,RadioGroup,Radio,FormControlLabel,MenuItem,FormControl,InputLabel,Select } from '@mui/material';
import * as R from 'ramda';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import editQuestion from '../../../lib/Admin/editQuestion';

const ModalQuestion = (props) => {
  const from = props.from || 'Organization';
  const questionInfo = props.questionInfo;
  const checkBoxApp = true;

  const [loading, setLoading] = useState(false)
  const [question, setQuestions] = useState(questionInfo ? questionInfo.questionText : '')
  const [textAnswers, setTextAnswers] = useState('')
  const [answers, setAnswers] = useState(R.pathOr([], ['options'], questionInfo) || [])
  const [category, setCategory] = useState(questionInfo.category ? questionInfo.category : '')
  const [questionType, setQuestionType] = useState(questionInfo ? questionInfo.questionType : 1)

  useEffect(() => {
    setQuestions(questionInfo.questionText)
    setQuestionType(questionInfo.questionType)
    if(questionInfo.options) setAnswers(questionInfo.options)
  }, [questionInfo])

  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'question':
        setQuestions(value);
      break;

      case 'textAnswers':
        setTextAnswers(value);
      break;

      case 'category':
        setCategory(value)
      break;

      case 'questionType': 
        setQuestionType(parseInt(value))
      break;
    }
  };

  const addQuestion = () => {
    const { companyId } = props;
    setLoading(true);
    try {
      const error = handleErrors('addQuestion')

      if (Object.keys(error).length === 0) {
        fetch(`${process.env.REACT_APP_URL_ENDPOINT_SURVEY}/${props.questionInfo.questionId}`, {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY_SURVEY
          },
          body: JSON.stringify({
            companyId,
            questionText: question,
            options: questionType !== 1 ? answers : [],
            available: checkBoxApp
          })
        })
          .then(response => response.json())
          .then((item) => {
            setQuestions('')
            setAnswers([])
            setTextAnswers('')
            props.getQuestions(props.selectedQuestionnarie)
            props.onClose()
            handleAlert('success', 'Actualización exitosa')
          })
          .catch(err => {
            handleAlert('error', 'Ha ocurrido un , por favor inténtelo de nuevo más tarde')
          })
      }
      else {
        if (error.question && error.answers) {
          handleAlert('error', error.question)
          handleAlert('error', error.answers)
        }
        else {
          const msg = (error.question && error.question) || (error.answers && error.answers)
          handleAlert('error', msg)
        }
      }
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
    }
    setLoading(false)
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleAnswers = () => {
    const count = answers.length + 1
    const error = handleErrors('answers')

    if (questionType !== 1 && Object.keys(error).length === 0) {
      if (answers.length === 0) {
        setAnswers([{ 'optionId': count, 'descripcion': textAnswers }])
        setTextAnswers('')
      }
      else {
        setAnswers([...answers, { 'optionId': count, 'descripcion': textAnswers }])
        setTextAnswers('')
      }
    }
    else {
      handleAlert('error', error.answersText)
      setAnswers([])
    }
  }

  const handleErrors = (type) => {
    let errors = {}
    switch (type) {
      case 'addQuestion':
        if (question === '') {
          errors.question = "El campo 'Pregunta' es requerido"
        }
        if (questionType !== 1 && answers.length === 0) {
          errors.answers = "Favor de agregar respuestas a su pregunta"
        }
        else if (questionType !== 1 && answers.length < 1) {
          errors.answers = "Favor de agregar más de una respuesta"
        }
        return errors

      case 'answers':
        if (textAnswers === '' && questionType !== 1) {
          errors.answersText = "El campo 'Respuestas' es requerido"
        }
        return errors
    }
  }

  const DeleteOptions = (id) => {
    const result = answers.filter(items => items.optionId !== id)
    setAnswers(result)
  }

  const saveQuestionAdmin = async() => {
    setLoading(true);
    const error = handleErrors('addQuestion')

    if(Object.keys(error).length === 0){
      const item = {
        questionText: question,
        questionType: parseInt(questionType),
        options: questionType !== 1 ? answers : [],
        category,
      }
      const result = await editQuestion(props.questionInfo.defaultRecordKey, item)
      if(result.success){
        handleAlert('success', 'Actualización exitosa')
        props.getQuestions()
        props.onClose()
      }
    }
    else {
      if (error.question && error.answers) {
        handleAlert('error', error.question)
        handleAlert('error', error.answers)
      }
      else {
        const msg = (error.question && error.question) || (error.answers && error.answers)
        handleAlert('error', msg)
      }
    }

  }

  const classes = useStyles(questionType);

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}
      style={{ overflow: 'scroll' }}>
      {loading ?
        <div className={`${classes.containerBackgroundModal} ${classes.loadingContainer}`}>
          <CircularProgress />
        </div>
        :
        <main className={`${questionType !== 1 ? classes.containerBackgroundModal : classes.containerBackgroundModalGrid}`}>
          <div className={`${questionType === 1 ? classes.containerQuestion : classes.containerGridQuestion}`}>
            <div>
              <h1 className={classes.titleQuestion}>
                Editar Pregunta
              </h1>
              <TextField
                label='Pregunta'
                variant="outlined"
                name='question'
                required
                value={question}
                onChange={handleChange}
                fullWidth
                size="small"
              />
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <div>
                    <p className={classes.subtitleQuestion}>Tipo de respuesta</p>

                    <RadioGroup name='questionType' value={questionType} onChange={handleChange} className={classes.containerRadioButton}>
                      <FormControlLabel value='1' control={<Radio color="primary" />} label="Texto libre"  disabled = {from === 'Admin' ? false : true} />
                      <FormControlLabel value='2' control={<Radio color="primary" />} label="Selección Única" disabled = {from === 'Admin' ? false : true} />
                      <FormControlLabel value='3' control={<Radio color="primary" />} label="Selección Múltiple" disabled = {from === 'Admin' ? false : true} />
                    </RadioGroup>
                  </div>
                  {
                    from === 'Admin' &&
                    <FormControl size = 'small' fullWidth>
                      <InputLabel id='category-select-label'>Categoria</InputLabel>
                      <Select
                          labelId='category-select-label'
                          id='category-select'
                          label='Categoria'
                          variant='outlined'
                          value={category}
                          name="category"
                          onChange={handleChange}
                          size = 'small'
                          fullWidth
                        >
                        {
                          props.category.map((items,index) => (
                            <MenuItem key={index} value={items.value}>{items.name}</MenuItem>
                          ))
                        }
                      </Select> 
                    </FormControl>
                  }
                </Grid>
              </Grid>
            </div>

            {questionType !== 1 &&
              <>
                <div>
                  <h1 className={classes.titleQuestion}>
                    Respuestas
                  </h1>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.newAnswersContainer}>
                        <TextField
                          label='Respuesta'
                          variant="outlined"
                          name='textAnswers'
                          required
                          value={textAnswers}
                          onChange={handleChange}
                          fullWidth
                          size="small"
                        />

                        <button className={classes.buttonAdd} onClick={handleAnswers}>
                          <AddTask />
                        </button>

                            </div>
                            <div>
                              {
                                answers.length > 0 ?
                                    answers.map((item, index) => (
                                    <div className={classes.containerAnswers} key={index} >
                                      <span>{item.descripcion}</span>
                                      <button className={classes.buttonDelete} onClick={() => DeleteOptions(item.optionId)}><DeleteIcon  /></button>
                                    </div>
                                    ))
                                    :
                                    <>
                                      <p>Aún no se han ingresado respuestas para la pregunta.</p>
                                    </>
                              }

                      </div>
                    </Grid>

                  </Grid>
                </div>

              </>
            }
          </div>

          <div className={classes.containerButtons}>

            <PrimaryButton
              handleClick={props.onClose}
              text="Cancelar"
              color="#000000"
            />

            <SecondaryButton
              text="Registrar"
              handleClick={from === 'Admin' ? saveQuestionAdmin : addQuestion}
              color="#5D5D60"
            />
          </div>
        </main>
      }
    </Modal>
  );
};

export default withSnackbar(ModalQuestion);

const useStyles = makeStyles(theme => ({
  containerBackgroundModal: {
    width: '75%',
    background: "#FFFFFF",
    margin: '5% auto',
    padding: '2%',
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    },
  },
  containerBackgroundModalGrid: {
    width: '30%',
    background: "#FFFFFF",
    margin: '5% auto',
    padding: '2%',
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    },
  },
  containerGridQuestion: {
    width: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr minmax(100px, 1fr)',
    maxHeight: '90vh',
    gridGap: 15,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  containerQuestion: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
  },
  titleQuestion: {
    textAlign: 'center',
    ...theme.typography.title,
  },

  containerButtons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '20%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      width: '62%',
      margin: (questionType) => questionType === 1 ? '0 auto' : '100% auto 0 auto',
    },
  },
  buttonStyle: {
    width: 'auto',
    margin: "5px 30px 0px",
    [theme.breakpoints.down('xs')]: {
      margin: "auto",
      marginBottom: '5%',
      display: 'flex'
    },
  },
  labelCheckbox: {
    ...theme.typography.mediumText,
    marginTop: 10
  },
  hidden: {
    visibility: 'false',
  },
  buttonDelete: {
    border: 'none',
    color: '#EE715B',
    padding: '9% 0',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
  },
  containerAnswers: {
    width: '95%',
    display: 'grid',
    gridTemplateColumns: '1fr 50px',
    alignItems: 'center',
    wordBreak: 'break-all',
    marginLeft: '5%',
  },
  containerRadioButton: {
    display: 'flex',
    flexDirection: 'column'
  },
  answersContainer: {
    overflow: 'scroll',
  },
  buttonAdd: {
    border: 'none',
    color: '#5D5D60',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
    fontWeight: 'bold',
    paddingRight: 15,
    paddingTop: 5
  },
  newAnswersContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dateInput: {
    margin: '5px 0'
  },
  subtitleQuestion: {
    ...theme.typography.subtitle1,
    fontWeight: 'bold'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}));
