import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';

const Results = (props) => {
  const classes = useStyle();
  const data = props.data;
  const [shownData, setShownData] = useState(data);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const success = props.success;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setOpen = (open, index) => {
    const newData = [...data];
    newData[index].open = open;
    setShownData(newData)
  }

  const tableLeads = () => {
    if (shownData.length > 0) {
      return (
        shownData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <>
            <TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell component="th" scope="row" className={classes.info}>
                {`${item.name} ${item.secondName} ${item.paternalLastName} ${item.maternalLastName}`}
              </TableCell>
              <TableCell className={classes.info}>{item.clabe}</TableCell>
              <TableCell className={classes.info}>{item.confirmation}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!item.open, index)}
                >
                  {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={item.open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1, display: 'flex', flexDirection: 'row' }}>
                    <div className={classes.halfContainer}>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>Fecha de aplicación:</text>
                        <text className={classes.info}>{item.applicationDate}</text>
                      </div>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>Fecha de ejecución:</text>
                        <text className={classes.info}>{item.applicationDate}</text>
                      </div>
                    </div>
                    <div className={classes.halfContainer}>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>Correo electrónico:</text>
                        <text className={classes.info}>{item.email}</text>
                      </div>
                    </div>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ))
      )
    }
  }

  const tableBidding = () => {
    if (shownData.length > 0) {
      return (
        shownData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <>
            <TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell component="th" scope="row" className={classes.info}>
                {`${item.name} ${item.secondName} ${item.paternalLastName} ${item.maternalLastName}`}
              </TableCell>
              <TableCell className={classes.info}>{item.destinationAccount}</TableCell>
              <TableCell className={classes.info}>{item.amount}</TableCell>
              <TableCell className={classes.info}>{item.confirmation}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!item.open, index)}
                >
                  {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={item.open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1, display: 'flex', flexDirection: 'row' }}>
                    <div className={classes.halfContainer}>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>Correo electrónico:</text>
                        <text className={classes.info}>{item.email}</text>
                      </div>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>Fecha de aplicación:</text>
                        <text className={classes.info}>{item.applicationDate}</text>
                      </div>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>Fecha de ejecución:</text>
                        <text className={classes.info}>{item.applicationDate}</text>
                      </div>
                    </div>
                    <div className={classes.halfContainer}>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>ID de rastreo:</text>
                        <text className={classes.info}>{item.trackingId}</text>
                      </div>
                      <div className={classes.infoRow}>
                        <text className={classes.infoBold}>Número de movimiento:</text>
                        <text className={classes.info}>{item.movementNumber}</text>
                      </div>
                    </div>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ))
      )
    }
  }

  return (
    <div>
      {shownData.length > 0 ?
        <TableContainer className={classes.tableContainer}>
          <Table aria-label="collapsible table">
            <TableHead className={classes.thead}>
              <TableRow classes={classes.infoRow}>
                <TableCell className={classes.th}>Nombre de usuario</TableCell>
                <TableCell className={classes.th} >Cuenta destino</TableCell>
                {props.from !== 'leads' && <TableCell className={classes.th}>Importe</TableCell>}
                <TableCell className={classes.th} >Respuesta del banco</TableCell>
                <TableCell className={classes.th} align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.from === 'leads' ? tableLeads() : tableBidding()}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={data.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Registros por hoja'
          />
        </TableContainer>
        :
        <div className={classes.tableContainer}>
          <text className={classes.subtitle}>{`No hay regitros ${success ? 'éxitosos' : 'con errores'}`}</text>
        </div>
      }
    </div>
  )
}

export default Results;

const useStyle = makeStyles(theme => ({
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '100%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '20px',
    overflowX: 'auto',
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  thead: {
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.white.main,
    padding: 15,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
      borderBottom: `3px solid ${theme.palette.gray.main}`,
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
    overflow: 'hidden',
  },
  halfContainer: {
    width: '50%',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0px 20px 0px 0px',
  },
  infoBold: {
    ...theme.typography.subtitle,
    fontWeight: '600',
    fontSize: '14px',
  },
  info: {
    ...theme.typography.subtitle,
    fontSize: '14px !important',
  },
  subtitle: {
    margin: '0 0 2% 0',
    fontSize: '20px',
    width: '80% ',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '20px',
    },
  },
}));