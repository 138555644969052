import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const saveLeads = async (organizationRecordKey, leads) => {
  try {
    const result = await API.post(API_ANALYTICS, '/users/saveLeads', {
      body: {
        organizationRecordKey: organizationRecordKey,
        leads: leads,
      }
    });
    return {
      success: true,
      failedItems: result.failedItems,
      succesfulItems: result.succesfulItems
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default saveLeads;