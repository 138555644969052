import QuestionTypeOne from "./QuestionsTypeOne"
import QuestionTypeTwo from "./QuestionsTypeTwo"
import QuestionTypeThree from "./QuestionsTypeThree"

const getQuestionType = (props) => {
  switch (props.questionInfo.questionType) {
    case 1:
      return <QuestionTypeOne
        questionInfo={props.questionInfo}
        handleAlert={props.handleAlert}
        questions={props.questions}
        setQuestions={props.setQuestions}
      />

    case 2:
      return <QuestionTypeTwo
        questionInfo={props.questionInfo}
        handleAlert={props.handleAlert}
        questions={props.questions}
        setQuestions={props.setQuestions}
      />
    case 3:
      return <QuestionTypeThree
        questionInfo={props.questionInfo}
        handleAlert={props.handleAlert}
        questions={props.questions}
        setQuestions={props.setQuestions}
      />
    default:
      return
  }
}

const questionForms = (props) => {
  return (
    <>
      {getQuestionType(props)}
    </>
  )
}



export default questionForms


