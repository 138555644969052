const YEARS = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const years = [];
  for (let index = currentYear; index >= 2022; index--) {
    years.push({ label: `${index}`, value: `${index}` });
  }
  return years;
}

export default YEARS;