import React from 'react'
import { makeStyles } from '@mui/styles';
import TabPanelComponent from '../../../components/TabPanel/TabPanel';
import CategoryScreen from './category';
import Questions from './defaultQuestions';

const DefaultQuestions = (props) => {
  const classes = useStyle()

  return (
    <main className={classes.containerBackground}>
      <h1 className={classes.title}> Preguntas por defecto </h1>

      <TabPanelComponent
        tabs = {['Preguntas','Categorias']}
        panels = {[
          <Questions />,
          <CategoryScreen />
        ]}
      />
    </main>
  )
}

export default DefaultQuestions

const useStyle = makeStyles(theme => ({
  containerBackground: {
    ...theme.globals.containerBackground
  },
  title: {
    fontSize: '62px',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
}))