import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import PrimaryButton from '../../components/primaryButton'

import { makeStyles } from '@mui/styles';

import IconFlex from '../../assets/images/icons/iconFlex.svg'
import PhoneIcon from '@mui/icons-material/Phone';
import Email from '@mui/icons-material/MailOutline';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { withSnackbar } from 'notistack';
import { ULR_FACEBOOK,ULR_INSTAGRAM } from  '../../lib/constant'

const useStyle = makeStyles(theme => ({
  containerForm: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignContent: 'center',
      backgroundColor: theme.palette.white.main,
      borderRadius: '0.625rem',
      width: '100%',
      marginBottom: '6.25rem',
      paddingTop: 50,
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: 20,
        zIndex: 0
      },
  },
  title: {
    ...theme.typography.title,
    fontSize: 72,
    color: theme.palette.primary.main,
    textAlign: 'left',
    lineHeight: 1,
    marginBottom: 10
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  img: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
  },
  inputs: {
    width: '100%',
    marginTop: '3.125rem'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
  },

  icons: {
    width: '35px',
  },
  containerContacts: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-around',
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'row'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center'
    },
  },
  socialMedia: {
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.main,
    }
  },
  socialMediaContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 10
  },
  containerLogoImage: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  containerContactInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '50%',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      width: '50%',
      alignItems: 'space-around',

    },
  },
  email:{
    width: '100%',
    wordWrap: 'break-word',
    textAlign: 'center',
    fontSize: 14,
  }

}))

const ContactsForm = (props) => {
  const classes = useStyle();
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleErrors = () => {
    let error = {}
    const regexEmail = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (name === '') {
      error.name = "Favor de ingresar un Nombre"
    }

    if (email === '') {
      error.email = "Favor de ingresar un Correo Electrónico"
    }

    if (message === '') {
      error.message = "Favor de escribir un Mensaje"
    }

    if (!regexEmail.test(email)) {
      error.emailSyntax = "Favor de ingresar un Correo Electrónicos valido"
    }
    return error
  }

  const sendEmail = (event) => {
    event.preventDefault();
    const error = handleErrors()

    if (Object.keys(error).length === 0) {

      fetch(process.env.REACT_APP_EMAIL_API, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
          name: name,
          email: email,
          message: message
        }),

      })
        .then(response => response.json())
        .then(item => {
          setName('')
          setEmail('')
          setMessage('')
          handleAlert('success', 'Envio exitosa')
        })
        .catch(err => {
          console.log("Errr ", err);
          handleAlert('error', 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde')
        })

    }
    else {
      for (const value in error) {
        handleAlert('error', error[value])
      }
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target
    const regexLetters = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ /s]*$/

    switch (name) {
      case 'name':
        if (regexLetters.test(value)) {
          setName(value)
        }
        break;

      case 'email':
        setEmail(value)
        break;

      case 'message':
        setMessage(value)
        break;

      default:
        break;
    }
  }

  return (
    <div className={classes.containerForm}>
      <form className={classes.form}>
        <div style={{ marginBottom: 30 }}>
          <TextField
            label="Nombre"
            variant="outlined"
            name='name'
            required
            value={name}
            onChange={handleOnChange}
            className={classes.inputs}
          />
        </div>
        <div style={{ marginBottom: 30 }}>
          <TextField
            label="Correo Electrónico"
            variant="outlined"
            name='email'
            required
            value={email}
            onChange={handleOnChange}
            className={classes.inputs}
          />
        </div>
        <TextField
          label="Mensaje"
          placeholder="Mensaje"
          multiline
          required
          name='message'
          value={message}
          onChange={handleOnChange}
          className={classes.inputs}
          rows={6}
        />

        <PrimaryButton handleClick={sendEmail} text = "Enviar" color = "#000000" margin = "5% 0" />

      </form>

      <div className={classes.containerContacts}>
        <div className={classes.containerLogoImage}>
          <img className={classes.img} src={IconFlex} alt='icon flexeanalytics' />
        </div>
        <div className={classes.containerContactInfo}>
          <div className={classes.iconContainer}>
            <Email className={classes.icons}/>
            <p className={classes.email}>atención.usuarios@flexeanalytics.com</p>
          </div>

          <div className={classes.socialMediaContainer}>
            <a href = {ULR_FACEBOOK} target = "_blank" className={classes.socialMedia}><FacebookIcon fontSize="large"/></a>
            <a href = {ULR_INSTAGRAM} target = "_blank" className={classes.socialMedia}><InstagramIcon fontSize="large"/></a>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default withSnackbar(ContactsForm);