import { API } from 'aws-amplify';
import { API_QUESTIONS } from '../constant';

const saveCategory = async (name) => {
  try {
    await API.post(API_QUESTIONS, '/category',{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      },
      body: {
        categoryName: name
      }
    });
    return {
      success: true,
      message: 'Registro exitoso'
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default saveCategory;