import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CheckBox } from '../checkBox';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { COUNTRY_CODE, REGEX_PHONE, REGEX_PASSWORD, REGEX_EMAIL } from "../../lib/constant";
import ReCAPTCHA from "react-google-recaptcha";

import Signup from '../../lib/Auth/signup';
import PasswordInput from '../passwordInput';
import PrimaryButton from '../primaryButton';
import CircularProgress from '@mui/material/CircularProgress';

const useStyle = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.625rem',
    marginBottom: '6.25rem',
    padding: '7% 0',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 20,
      zIndex: 0,
      marginLeft: '-5%'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-10%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  containerButton: {
    backgroundColor: theme.palette.primary.main,
    width: '14.063rem',
    height: '3.125rem',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  labelButton: {
    color: theme.palette.white.main,
    fontSize: 16
  },
  inputs: {
    width: '100%',
    marginBottom: '20px !important'
  },
  phoneContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '5%',
  },
  selectContainer: {
    width: '22%',
    marginRight: '3%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
  },
  inputPhone: {
    width: '90%',
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    fontWeight: 600,
  },
  checkRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  captcha: {
    marginTop: 20,
  },
  passwordLabel: {
    color: '#878787',
    fontSize: '12px',
    marginBottom: '20px'
  },
  visibilityIcon: {
    background: 'transparent'
  }
}));

const CompanyRegisterForm = (props) => {
  const classes = useStyle(props);
  let navigate = useNavigate();
  const loc = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [country, setCountry] = useState('+52');
  const [phone, setPhone] = useState('');
  const [termConditions, setTermConditions] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [errors, setErrors] = useState({ errorName: false, errorEmail: false, errorMessage: false, errorSyntax: false });
  const [captcha, setCaptcha] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const handlePhoneChange = (value) => {
    if (REGEX_PHONE.test(value)) {
      setPhone(value);
    }
  }

  const handleErrors = () => {
    let error = {};

    if (email === '') {
      error.email = "Favor de ingresar un correo electrónico";
      errors.errorEmail = true;
      setErrors(errors);
    } else if (!REGEX_EMAIL.test(email)) {
      error.emailSyntax = "Favor de ingresar un correo electrónico válido";
      errors.emailSyntax = true;
      setErrors(errors);
    } else if (password === '') {
      error.password = "Favor de ingresar una contraseña";
      errors.errorPassword = true;
      setErrors(errors);
    } else if (!REGEX_PASSWORD.test(password)) {
      error.passwordSyntax = `La contraseña debe tener mínimo 6 caracteres, incluyendo por lo menos una mayúscula, un número y un caracter especial (!¡@#$%^&*(),.¿?":{}|<>_-)`;
      errors.errorpasswordSyntax = true;
      setErrors(errors);
    } else if (confirmPassword === '') {
      error.password = "Favor de confirmar la contraseña";
      errors.errorConfirmPassword = true;
      setErrors(errors);
    } else if (phone === '') {
      error.phone = "Favor de ingresar un número de teléfono";
      errors.errorPhone = true;
      setErrors(errors);
    } else if (!REGEX_PHONE.test(phone)) {
      error.emailSyntax = "Favor de ingresar un número de teléfono válido";
      errors.emailSyntax = true;
      setErrors(errors);
    } else if (password !== confirmPassword) {
      error.password = "Las contraseñas no coinciden";
      errors.errorConfirmPassword = true;
    } else if (!termConditions) {
      error.terms = "Favor de aceptar los términos y condiciones";
      errors.errorTerms = true;
    } 
      else if (!privacyPolicy) {
      error.privacyPolicy = "Favor de aceptar el aviso de privacidad";
      errors.errorPrivacy = true;
    } else if (!captcha) {
      error.captcha = "Favor de confirmar que no eres un robot";
      errors.captcha = true;
    }
    return error;
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const onChangeCaptcha = (value) => {
    setCaptcha(value);
  }

  const register = async (event) => {
    event.preventDefault();
    setIsLoading(true)

    const error = handleErrors();
    if (Object.keys(error).length === 0) {
      //Handle Register
      const status = await Signup(email, password, phone, 'ORG');
      if (status.success) {
        setIsLoading(false)

        navigate('/confirmCode', {
          state: {
            key: status.user.userSub,
            email: status.user.user.username,
            phone: phone,
            password: password,
            type: 'ORG',
            subscriptionType: 'premium',
          }
        });
      } 
      else {
        setIsLoading(false)
        handleAlert('error', status.error);
      }
    }
    else {
      setIsLoading(false)
      for (const value in error) {
        handleAlert('error', error[value]);
      }
    }
  }

  const handleOnChange = (event) => {
    const { name } = event.target

    switch (name) {
      case 'terms':
        setTermConditions(!termConditions)
      break;

      case 'privacy':
        setPrivacyPolicy(!privacyPolicy)
      break;
    }
  }

  return (
    <div className={classes.containerForm}>
      {
        isLoading ?
          <CircularProgress/>
        :
        <form className={classes.form}>
          <div>
            <TextField
              label="Correo"
              variant="outlined"
              name='email'
              id="email"
              required
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className={classes.inputs}
            />

            <PasswordInput
              label = "Contraseña"
              name = "password"
              value = {password}
              handlerPassword = {(event) => setPassword(event.target.value)}
            />

            <PasswordInput
              label = "Confirmar Contraseña"
              name = "confirmPassword"
              value = {confirmPassword}
              handlerPassword = {(event) => setConfirmPassword(event.target.value)}
            />

            <p className={classes.passwordLabel}>
              {`La contraseña debe tener mínimo 6 caracteres, incluyendo por lo menos una mayúscula, un número y un caracter especial (!¡@#$%^&*(),.¿?":{}|<>_-)`}
            </p>
            <div className={classes.phoneContainer}>
              <Select
                value={country}
                name="countryCodeSelect"
                onChange={(event) => setCountry(event.target.value)}
                className={classes.selectContainer}
              >
                {
                  COUNTRY_CODE.map(items => (
                    <MenuItem value={items.value}>{items.label}</MenuItem>
                  ))
                }
              </Select>
              <TextField
                label="Número de Teléfono"
                variant="outlined"
                name='phone'
                required
                inputProps={{ maxLength: 10 }}
                value={phone}
                onChange={(event) => handlePhoneChange(event.target.value)}
                className={classes.inputPhone}
              />
            </div>
          </div>
          <div className={classes.checkContainer}>
            <div className={classes.checkRow}>
              <CheckBox
                description = {<p>Aceptar los <a href='/termConditions' target="_blank">Términos y Condiciones</a></p>}
                check={termConditions}
                name = "terms"
                onChange={handleOnChange}
              />
              
            </div>
            <div className={classes.checkRow}>
              <CheckBox
                description = {<p>Aceptar el <a href='/privacyPolicy' target="_blank">Aviso de Privacidad</a></p>}
                check={privacyPolicy}
                name = "privacy"
                onChange={handleOnChange}
              />
              
            </div>
            <div className={classes.captcha}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                onChange={onChangeCaptcha}
              />
            </div>
          </div>
          <PrimaryButton handleClick = {register} text = "Regístrate" color = "#000000"/>
          {/* <AppsContainer/> */}
        </form>
      }
    </div>
  );
}

export default withSnackbar(CompanyRegisterForm);
