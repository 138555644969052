import React from 'react';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Modal from '@mui/material/Modal';

import PrimaryButton from '../../primaruButtonLite';
import SecondaryButton from '../../secondaryButtonLite';
const useStyles = makeStyles(theme => ({
    containerDelete: {
        width: '30%',
        background: "#FFFFFF",
        margin: '5% auto',
        padding: '2%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '70%'
        },
    },
    titleDelete: {
        textAlign: 'center',
        ...theme.typography.subtitle,
    },
    buttons: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    buttonGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: '10%',
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    buttonContainerDelete: {
        width: '45%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const ModalDelete = (props) => {
    const classes = useStyles();

    return (
        <Modal
            onClose={props.onClose}
            open={props.open}
            style={{ overflow: 'scroll' }}>
            <div className={classes.containerDelete}>
                <h3 className={classes.titleDelete}>{props.text}</h3>
                
                <div className={classes.buttonGroupContainer}>
                    <div className={classes.buttonContainerDelete}>
                        <PrimaryButton
                            onClick={props.handleClose}
                            title="Cancelar"
                        />
                    </div>
                    <div className={classes.buttonContainerDelete}>

                        <SecondaryButton
                            title="Continuar"
                            onClick={() => props.delete(props.data.id)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default withSnackbar(ModalDelete);
