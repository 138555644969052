export const config = {
  height: 300,
  showPathLabel : false,
  resizingBar : false,
  font : [
    'Arial',
    'tohoma',
    'Courier New,Courier',
    'times new roman,times',
    'calibri'
  ],
  buttonList: [['bold', 'underline', 'italic','font', 'fontSize','formatBlock','align', 'list','link','undo', 'redo']]
}