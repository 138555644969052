import React from 'react';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Background from "../../../assets/images/backgroundContactForm.svg";

const useStyle = makeStyles(theme => ({
    containerBackground: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        margin: 0,
        backgroundPosition: 'bottom',
        alignItems: 'center',
    },
    registerContainer: {
        width: '80%'
    },
    title: {
        ...theme.typography.title,
        fontSize: 50,
        color: theme.palette.primary.main,
        textAlign: 'justify',
        lineHeight: 1,
        margin: '5% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
        },
    },

    textContainer: {
        backgroundColor: theme.palette.white.main,
        borderRadius: '0.625rem',
        marginBottom: '6.25rem',
        padding: '5%',
        boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 30%)',

        [theme.breakpoints.down('md')]: {
            zIndex: 0
        },
        [theme.breakpoints.down('sm')]: {
            padding: '15%',
        },
    },

    text: {
        textAlign: 'justify',
        marginBottom: '5%',
        '&:last-child': {
            marginBottom: 0,
        }
    },
    planList: {
        textAlign: 'center',
        listStyle: 'none',
        marginBottom: '5%',
    },
    termConditions: {
        color: theme.palette.blue.main,
        fontWeight: 'bold',
        textDecoration: 'none'
    }
}))

const UserMessageScreen = (props) => {
  const classes = useStyle(props);

  return (
    <div className={classes.containerBackground}>
        <div className={classes.registerContainer}>
            <h2 className={classes.title}>
                Gracias por tu interés en las oportunidades de monetización de Flexeanalytics
            </h2>

            <div className = {classes.textContainer}>
                <p className = {classes.text}>
                    Nosotros creemos que la información y datos que se generan con el 
                    uso de tu celular son de tu propiedad y el compartirlos para fines comerciales 
                    debe venir con una remuneración. Flexeanalytics está interesada en adquirir 
                    masivamente información y remunerar por ello a quienes han producido esos datos. 
                    Flexeanalytics ofrece los siguientes términos: 
                </p>

                <p className = {classes.text}>
                    Los pagos de monetización son variables y dependen de factores específicos 
                    y valor que estos datos tengan para las diferentes actividades económicas. 
                    Los pagos solo se realizan cuando tus datos se usen activamente en reportes 
                    de flexeanalytics. Los rangos pre-establecidos para cada perfil de “Usuario” 
                    son los siguientes:  
                </p>

                <ul className={classes.planList}>
                    <li>ADOPTER: De $2,000 a $4,000 pesos mexicanos</li>
                    <li>EXPLORER: De $4,001 a $7,000 pesos mexicanos</li>
                    <li>EVOLVER: De $7,001 a $10,000 pesos mexicanos</li>
                </ul>

                <p className = {classes.text}>
                    Sin embargo, Flexeanalytics no compra directamente tus datos, en cambio, 
                    lo hace por medio de aquellas plataformas que permitan la captación de tu 
                    información conforme a la normativa vigente 
                    (leer <a className={classes.termConditions} href='/termConditions' target="_blank">Términos y Condiciones y Aviso de Privacidad</a>). 
                    Por ello, con tu registro te pondremos en contacto con aquellas plataformas 
                    digitales que puedas descargar, donde tu puedas registrarte, 
                    y por donde recibirías la remuneración correspondiente. 
                    Nuevamente, gracias por tu interés en Flexeanalytics.   
                </p>
            </div>
        </div>
    </div>
  );
}

export default withSnackbar(UserMessageScreen);