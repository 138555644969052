import React from 'react';
import ReadLegalDoc from '../../components/readLegalDoc';

const UploadLegalDoc = (props) => {
  return <ReadLegalDoc
    documentName='privacy'
    title='FLEXEANALYTICS - AVISO DE PRIVACIDAD'
  />
}

export default UploadLegalDoc;