import React from 'react'
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import Background from '../../../assets/images/backgroundGrayyBottom.svg';
import CardPrice from '../../../components/cardPlan';

const useStyle = makeStyles(theme => ({
    containerBackground: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        backgroundPosition: 'center',
        minHeight: '100vh',
    },
    descriptionServices: {
        ...theme.typography.subtitle,
        fontSize: '1.5rem',
        color: '#5D5D60',
        fontWeight: 'normal',
        width: '60%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    subtitle: {
        ...theme.typography.subtitle,
        width: '56.625rem'
    },
    containerCard: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '80%',
        boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
        borderRadius: '2.313rem',
        padding: '1.875rem 0',
        backgroundColor: '#77777875',
        [theme.breakpoints.down('md')]: {
            marginTop: '100px',
            flexDirection: 'column',
            alignItems: 'center',
            width: '80%',
        },
    },
    servicesContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    card: {
        width: '30%',
        [theme.breakpoints.down('md')]: {
            width: '50%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        },
    },
    titleAndCardsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}))

const LandingUser = () => {
    const classes = useStyle();
    const navigate = useNavigate();

    const cards = [
        {
            color: "transparent",
            title: "Prospectos",
            content: "Registra a tus prospectos",
            handleClick: () => navigate('/viewLeads'),
            text: 'Ingresar'
        },
        {
            color: "#000000",
            title: "Cuestionario",
            background: 'white',
            content: "Crea, edita preguntas para tus prospectos y visualiza sus respuestas",
            handleClick: () => navigate('/survey'),
            text: 'Ingresar'
        },
        {
            color: "transparent",
            title: "Reportes Analíticos",
            content: "Diseña y visualiza tus reportes",
            handleClick: () => navigate('/downloadAnalytics'),
            text: 'Ingresar'
        }
    ]

  return (
    <div className={classes.containerBackground}>
    <div className={classes.titleAndCardsContainer}>

        <div className={classes.containerCard}>
            {
                cards.map((card, index) => (
                    <div key={index} className={classes.card}>
                        <CardPrice
                            color={card.color}
                            background={card.background}
                            title={card.title}
                            content={card.content}
                            price={card.price}
                            handleClick = {card.handleClick}
                            text = {card.text}
                        />
                    </div>
                ))
            }
        </div>
    </div>
</div>
  )
}

export default LandingUser