import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import TextField from '@mui/material/TextField';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import FileInput from '../fileInput';
import { REGEX_RFC } from "../../lib/constant";
import SendEmail from '../../lib/Email/sendEmail';

import EditUser from '../../lib/Analytics/editUser';

const useStyle = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.625rem',
    width: '100%',
    marginBottom: '6.25rem',
    paddingTop: 50,
    paddingBottom: 50,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 20,
      zIndex: 0,
      marginLeft: '-5%'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-10%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  containerButton: {
    backgroundColor: theme.palette.primary.main,
    width: '14.063rem',
    height: '3.125rem',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  labelButton: {
    color: theme.palette.white.main,
    fontSize: 16
  },
  inputs: {
    width: '100%',
    marginBottom: '10% !important'
  },
  rfcContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1%',
    marginBottom: '10%',
  },
  selectContainer: {
    width: '22%',
    marginRight: '3%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
  },
  inputRfc: {
    display: 'flex',
    width: '90%',
  },
  fielContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '1%',
    marginBottom: '10%',
  },
}));

const UserRFCForm = (props) => {
  const classes = useStyle(props);
  let navigate = useNavigate();
  const loc = useLocation();

  const [hasRfc, setHasRfc] = useState(false);
  const [rfc, setRfc] = useState('');
  const [hasFiel, setHasFiel] = useState(false);
  const [fiel, setFiel] = useState(null);

  const handleErrors = () => {
    let error = {};
    if (hasRfc && rfc === '') {
      error.rfc = 'Favor de ingresar un RFC';
    } else if (hasRfc && !REGEX_RFC.test(rfc)) {
      error.rfc = 'Favor de ingresar un RFC válido';
    } else if (hasFiel && fiel === null) {
      error.fiel = 'Favor de subir el archivo correspondiente'
    }
    return error;
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getFile = (e) => {
    try {
      setFiel(e.target.files[0]);
    } catch (err) {
      console.log("ERR FILE INPUT");
    }
  }

  const saveRFC = async (event) => {
    event.preventDefault();
    try {
      const error = handleErrors();
      if (Object.keys(error).length === 0) {
        const item = {
          hasRfc: hasRfc,
          rfc: rfc,
        }
        const statusUser = await EditUser(loc.state.userRecordKey, item);
        if (statusUser.success) {
          //SEND EMAIL AND SEND TO SCREEN WITH DETAILS
          await SendEmail(loc.state.userRecordKey, loc.state.email);
          navigate('/userComingSoon');
        } else {
          handleAlert('error', statusUser.error);
        }
      }
      else {
        for (const value in error) {
          handleAlert('error', error[value]);
        }
      }
    } catch (err) {
      console.log("ERR RFC", err);
    }
  }

  return (
    <div className={classes.containerForm}>
      <form className={classes.form}>
        <div>
          <InputLabel>¿Tienes RFC?</InputLabel>
          <div className={classes.rfcContainer}>
            <Select
              value={hasRfc}
              name="hasRfcSelect"
              onChange={(event) => setHasRfc(event.target.value)}
              className={classes.selectContainer}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Si</MenuItem>
            </Select>
            <TextField
              label="RFC"
              variant="outlined"
              name='rfc'
              required
              value={rfc}
              onChange={(event) => setRfc(event.target.value)}
              className={classes.inputRfc}
              disabled={!hasRfc}
            />
          </div>
          <InputLabel>¿Tienes FIEL o Sellos Digitales?</InputLabel>
          <div className={classes.fielContainer}>
            <Select
              value={hasFiel}
              name="hasFielSelect"
              onChange={(event) => setHasFiel(event.target.value)}
              className={classes.selectContainer}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Si</MenuItem>
            </Select>
            <div className={classes.inputRfc}>
              <FileInput
                type='file'
                name='fileCd'
                id='fileCd'
                width={130}
                onChange={getFile}
                file={fiel}
                disabled={hasFiel}
              />
            </div>
          </div>
        </div>
        <button className={classes.containerButton} onClick={(event) => saveRFC(event)}>
          <p className={classes.labelButton}>Continuar</p>
        </button>
      </form>
    </div>
  );
}

export default withSnackbar(UserRFCForm);