import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: `3px solid ${theme.palette.darkGray.main}`,
    color: (props) => (props.disabled ? theme.palette.darkGray.main : theme.palette.lightGray.main),
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: (props) => (props.marginHorizontal || 0),
    marginRight: (props) => (props.marginHorizontal || 0),
    marginTop: (props) => (props.marginVertical || 0),
    marginBottom: (props) => (props.marginVertical || 0),
    width: (props) => (props.fullWidth ? '100%' : props.width ? props.width : 'auto'),
    maxWidth: (props) => (props.maxWidth || '100'),
    ...theme.typography.input,
  },
  input: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    border: 0,
  },
  fileNameContainer: {
    marginRight: 10
  },
}));

const FileInput = (props) => {
  const classes = useStyle(props);
  return (
    <div className={classes.container}>
      <label for={props.id || undefined} className={classes.label}>
        <text>{props.placeholder || 'Subir Archivo'}</text>
        <input
          id={props.id || ''}
          name={props.name || ''}
          type={props.type || 'file'}
          onChange={props.onChange || null}
          className={classes.input}
          disabled={props.disabled}
          accept={props.accept}
        />
      </label>
      {props.file ? (
        <div className={classes.fileNameContainer}>
          <text className={classes.fileName}>
            {props.file.name.length < 30 ? props.file.name : `${props.file.name.slice(0,27)}...`}
          </text>
        </div>
      ) : (
        <div style={{ height: 27 }}></div>
      )}
    </div>
  );
}

export default FileInput;