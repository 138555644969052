import React from 'react';
import { makeStyles } from '@mui/styles';
import CardPrice from '../../../components/cardPlan';
import Background from '../../../assets/images/backgroundGrayyBottom.svg'
import { useNavigate } from 'react-router-dom';

const useStyle = makeStyles(theme => ({
    containerBackground: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        margin: 0,
        backgroundPosition: 'center',
        alignItems: 'center',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '80%',
        marginTop: '2%'
    },
    title: {
        fontSize: '62px',
        width: '80% ',
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            textAlign: 'center',
            fontSize: '40px',
        },
    },
    descriptionServices: {
        ...theme.typography.subtitle,
        fontSize: '1.5rem',
        color: '#5D5D60',
        fontWeight: 'normal',
        width: '60%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    subtitle: {
        ...theme.typography.subtitle,
        width: '56.625rem'
    },
    containerCard: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '60%',
        boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
        borderRadius: '2.313rem',
        padding: '1.875rem 0',
        background: 'linear-gradient(0deg, rgb(196,196,196) 10%, rgb(204,204,204) 30%, rgb(172,172,172) 50%)',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            width: '80%',
        },
    },
    servicesContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    services: {
        ...theme.typography.serviceText
    },
    card: {
        width: '40%',
        [theme.breakpoints.down('md')]: {
            width: '50%',
            marginBottom: '30px',
            '&:last-child': {
                marginBottom: 0
            }

        },
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        },
    },
    titleAndCardsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        margin: '5%  0 10% 0',
    }
}))

const SelectPlan = () => {
    const classes = useStyle();
    let navigate = useNavigate();

    const cards = [
        {
            color: "transparent",
            title: "Estándar",
            content: "Reportes anaíticos mensuales con información más reciente de los perfiles digitales con criterios de selección y agrupación disponibles.",
            handleClick: () => navigate('/companyRegister', { state: { subscriptionType: 'standard' } }),
            text: "Suscribirme"
        },
        {
            color: "#000000",
            title: "Premium",
            background: 'white',
            content: "Reportes analíticos mensuales personalizados, diseño de esquemas detallados de información de inteligencia de mercado y negocios.",
            handleClick: () => navigate('/companyRegister', { state: { subscriptionType: 'premium' } }),
            text: "Suscribirme"
        }
    ]

    return (
        <div className={classes.containerBackground}>
            <div className={classes.titleContainer}>
                <h1 className={classes.title}>
                    Planes Disponibles
                </h1>
            </div>

            <div className={classes.titleAndCardsContainer}>
                <div className={classes.containerCard}>
                    {
                        cards.map((card, index) => (
                            <div key={index} className={classes.card}>
                                <CardPrice
                                    color={card.color}
                                    background={card.background}
                                    title={card.title}
                                    content={card.content}
                                    handleClick={card.handleClick}
                                    text={card.text}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
};

export default SelectPlan;
