import { API } from 'aws-amplify';
import { API_BILLING } from '../constant';
const R = require('ramda');

const stampBill = async (organization, amount) => {
  try {
    const result = await API.post(API_BILLING, '/billing/stamp', {
      body: {
        ...organization,
        amount,
      },
    });
    return {
      success: true,
      data: result.data,
    }
  } catch (err) {
    const error = R.pathOr('Algo salió mal', ['response', 'data', 'error'], err);
    return {
      success: false,
			message: error,
    }
  }
}

export default stampBill;