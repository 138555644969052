const saveTermsAndConditions = async(type, data) => {
    const application =  type === 'terms' ? 'ANALYTICS_TYC' :'ANALYTICS_PP'

    try {
        const terms = await fetch(`${process.env.REACT_APP_ENDPOINT_API_TERMS2}`, {
            method: "POST",
            headers: {
                "x-api-key": process.env.REACT_APP_TERMS_API_KEY,
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                applicationKey: application,
                active: data.active,
                version: data.version,
                content: data.content
            })
        }).then(response => response.json())

        if(terms.success){
            return {
                success: true,
                message: ' Guardado con éxito'
            }
        }
        else{
            return {
                success: false,
                message: 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde'
            }
        }

    } catch (err) {
        debugger
        return {
            success: false,
            error: 'Algo salio mal',
        }
    }
}
    
export default saveTermsAndConditions;