import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import PropTypes from 'prop-types';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <text>{children}</text>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const TabPanelComponent = (props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(props.selected){
      props.selected(newValue)
    }
  };

  return <Box width={props.width || '80%'}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        {props.tabs.map((item, index) =>
          <Tab key={index} label={item} {...a11yProps(0)} />
        )}
      </Tabs>
    </Box>
    {props.panels.map((item, index) =>
      <TabPanel
        key={index}
        value={value}
        index={index}>
        {item}
      </TabPanel>)}

  </Box>

}

export default TabPanelComponent;