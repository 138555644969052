// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: [
      process.env.REACT_APP_OAUTH_SCOPE1,
      process.env.REACT_APP_OAUTH_SCOPE2,
      process.env.REACT_APP_OAUTH_SCOPE3,
      process.env.REACT_APP_OAUTH_SCOPE4,
      process.env.REACT_APP_OAUTH_SCOPE5,
    ],
    redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECTSIGNIN,
    redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECTSIGNOUT,
    responseType: process.env.REACT_APP_OAUTH_RESPONSETYPE,
  },
  federationTarget: process.env.REACT_APP_FEDERATIONTARGET,
  aws_cloud_logic_custom: [
    {
      name: process.env.REACT_APP_NAME_API1,
      endpoint: process.env.REACT_APP_ENDPOINT_API1,
      region: process.env.REACT_APP_REGION_API1,
    },
    {
      name: process.env.REACT_APP_NAME_API_TERMS,
      endpoint: process.env.REACT_APP_ENDPOINT_API_TERMS,
      region: process.env.REACT_APP_REGION_API_TERMS,
    },
    {
      name: process.env.REACT_APP_NAME_API_CHARTS,
      endpoint: process.env.REACT_APP_ENDPOINT_API_CHARTS,
      region: process.env.REACT_APP_REGION_API_CHARTS,
    },
    {
      name: process.env.REACT_APP_NAME_API_BILLING,
      endpoint: process.env.REACT_APP_ENDPOINT_API_BILLING,
      region: process.env.REACT_APP_REGION_API_BILLING,
    },
    {
      name: process.env.REACT_APP_NAME_API_DEFAULT_QUESTIONS,
      endpoint: process.env.REACT_APP_ENDPOINT_API_DEFAULT_QUESTIONS,
      region: process.env.REACT_APP_REGION_API1,
    },
  ],
  aws_user_files_s3_bucket: process.env.REACT_APP_BUCKET,
  aws_user_files_s3_bucket_region: process.env.REACT_APP_AWS_COGNITO_REGION,
};

export default awsmobile;