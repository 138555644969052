import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import LoadFiles from '../../lib/Analytics/getFiles';
import Plagiarism from '@mui/icons-material/Plagiarism';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

import { DATA_CATEGORIES } from '../../lib/constant';
import { or } from 'ramda';
import updateUser from '../../lib/User/updateUser';

const OrganizationDetails = (props) => {
  const classes = useStyle();

  const organization = props.organization;
  const legalRepresentative = organization.legalRepresentative;
  const documents = organization.documentName;

  const loadFiles = async (documentName) => {
    const urlFiles = await LoadFiles(organization.organizationRecordKey, documentName)
    window.open(urlFiles.url)
  }

  const [expanded, setExpanded] = useState(false);
  const [editFee, setEditFee] = useState(false);
  const [fee, setFee] = useState(organization.comission_percentage || 0)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeFee = (e) => {
    const value = e.target.value;
    if (value === '' || (/^\d+$/.test(value) && Number(value) >= 0 && Number(value) <= 100)) {
      const formated = value / 100
      setFee(formated);
      organization.comission_percentage = value / 100;
    }
  };

  const getCategories = () => {
    const categories = Object.entries(organization.dataTypeCategory.category).filter(item => item[1])
    const newCategories = categories.map(category =>
      DATA_CATEGORIES[category[0]]
    )
    return newCategories;
  }

  const getGender = (gender) => {
    switch (gender) {
      case 'male':
        return 'Masculino';
      case 'female':
        return 'Femenino';
      case 'nonbinary':
        return 'No binario';
      default:
        return 'Todos los géneros'
    }
  }

  const handleChangeEditFee = async () => {
    if (editFee) {
      props.setLoading(true);
      const data = {
        organizationRecordKey: organization.organizationRecordKey,
        item: { comission_percentage: fee }
      }
      await updateUser(data)
    }
    setEditFee(!editFee)
    props.setLoading(false)
  }

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }} className={classes.subtitle}>
            Empresa
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Información importante de la empresa</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.row}>
            <div className={classes.halfContainer}>
              <text className={classes.subtitle}>Información general de la empresa</text>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>RFC con homoclave</text>
                <text className={classes.label}>{organization.rfc}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Dirección Fiscal</text>
                <text className={classes.label}>{organization.address}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>País</text>
                <text className={classes.label}>México</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Estado</text>
                <text className={classes.label}>{organization.state}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Código Postal</text>
                <text className={classes.label}>{organization.zipCode}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Nombre del contacto</text>
                <text className={classes.label}>{organization.contact.name}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Correo Electrónico</text>
                <text className={classes.label}>{organization.email}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Número de teléfono</text>
                <text className={classes.label}>{organization.phone}</text>
              </div>
            </div>
            <div className={classes.halfContainer}>
              <text className={classes.subtitle}>Información de la constitución de la empresa</text>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Acta constitutiva</text>
                <text className={classes.label}>{organization.constitutiveActNo}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Notario público</text>
                <text className={classes.label}>{organization.publicNotary}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>No. Notaría</text>
                <text className={classes.label}>{organization.notaryNo}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Dirección Notario</text>
                <text className={classes.label}>{organization.notaryAddress}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Fecha de constitución</text>
                <text className={classes.label}>{organization.constitutionDate}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Comision %</text>
                {
                  editFee ?
                    <TextField
                      label="Comision %"
                      variant="outlined"
                      name='offer'
                      id="offer"
                      type="text"
                      value={fee * 100}
                      onChange={(e) => handleChangeFee(e)}
                      className={classes.inputs}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        min: 0,
                        max: 100,
                        step: 1
                      }}
                    />
                    :
                    <text className={classes.label}>{fee * 100}%</text>
                }
                <IconButton
                  aria-label="edit row"
                  size="small"
                  onClick={() => handleChangeEditFee()}
                >
                  {!editFee ?
                    <EditIcon className={classes.icon} fontSize='medium' color='info' />
                    : <CheckIcon />}
                </IconButton>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.halfContainer}>
              <text className={classes.label}>Identificación oficial</text>
              <button className={classes.documentButton} onClick={() => loadFiles(documents?.IOFile)}>
                <Plagiarism sx={{ color: '#039EDA', fontSize: 50, marginRight: '10px' }} />
                <text className={classes.labelButton}> {documents?.IOFile}</text>
              </button>
              {
                documents?.PRFile &&
                <>
                  <text className={classes.label}>Poderes del representante</text>
                  <button className={classes.documentButton} onClick={() => loadFiles(documents.PRFile)}>
                    <Plagiarism sx={{ color: '#039EDA', fontSize: 50, marginRight: '10px' }} />
                    <text className={classes.labelButton}>{documents.PRFile}</text>
                  </button>
                </>
              }
            </div>
            <div className={classes.halfContainer}>
              <text className={classes.label}>Comprobande de domicilio</text>
              <button className={classes.documentButton} onClick={() => loadFiles(documents?.CDFile)}>
                <Plagiarism sx={{ color: '#039EDA', fontSize: 50, marginRight: '10px' }} />
                <text className={classes.labelButton}>{documents?.CDFile}</text>
              </button>
              <text className={classes.label}>Acta constitutiva</text>
              <button className={classes.documentButton} onClick={() => loadFiles(documents.ACFile)}>
                <Plagiarism sx={{ color: '#039EDA', fontSize: 50, marginRight: '10px' }} />
                <text className={classes.labelButton}>{documents?.ACFile}</text>
              </button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }} className={classes.subtitle}>
            Representate Legal
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Información importante del representante legal</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.rowLabels}>
            <text className={classes.labelBold}>Nombre completo del representante</text>
            <text className={classes.label}>{legalRepresentative && legalRepresentative.name + ' ' + legalRepresentative.paternalSurname + ' ' + legalRepresentative.maternalSurname}</text>
          </div>
          <div className={classes.rowLabels}>
            <text className={classes.labelBold}>CURP</text>
            <text className={classes.label}>{legalRepresentative && legalRepresentative.curp}</text>
          </div>
          <div className={classes.rowLabels}>
            <text className={classes.labelBold}>RFC</text>
            <text className={classes.label}>{legalRepresentative && legalRepresentative.rfc}</text>
          </div>
          <div className={classes.rowLabels}>
            <text className={classes.labelBold}>Fecha de Nacimiento</text>
            <text className={classes.label}>{legalRepresentative && legalRepresentative.birthdate}</text>
          </div>
          <div className={classes.rowLabels}>
            <text className={classes.labelBold}>Género</text>
            <text className={classes.label}>{legalRepresentative && getGender(legalRepresentative.gender)}</text>
          </div>
          <div className={classes.rowLabels}>
            <text className={classes.labelBold}>Dirección</text>
            <text className={classes.label}>{legalRepresentative && legalRepresentative.address}</text>
          </div>
          <div className={classes.rowLabels}>
            <text className={classes.labelBold}>Número de teléfono</text>
            <text className={classes.label}>{legalRepresentative && legalRepresentative.phone}</text>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }} className={classes.subtitle}>
            Datos
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Datos que la organización necesita</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.row}>
            <div className={classes.halfContainer}>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Tipo de subscripción</text>
                <text className={classes.label}>{organization.subscriptionType}</text>
              </div>
              {/* <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Intervalo de pago</text>
                <text className={classes.label}>{organization.paymentInterval}</text>
              </div> */}
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Edad minima</text>
                <text className={classes.label}>{organization.dataTypeCategory.MinAge}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Edad máxima</text>
                <text className={classes.label}>{organization.dataTypeCategory.MaxAge}</text>
              </div>
              <div className={classes.rowLabels}>
                <text className={classes.labelBold}>Género</text>
                <text className={classes.label}>{getGender(organization.dataTypeCategory.gender || organization.dataTypeCategory.Gender)}</text>
              </div>
            </div>
            <div className={classes.halfContainer}>
              <text className={classes.labelBold}>Categorías</text>
              <ul>
                {getCategories().map(item => (
                  <li className={classes.label}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div >
  )

}

export default withSnackbar(OrganizationDetails)

const useStyle = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: '20px 0px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  rowLabels: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  halfContainer: {
    width: '45%',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 0
    },
  },
  subtitle: {
    ...theme.typography.title,
    fontSize: '20px !important',
    // textAlign: 'center',
  },
  labelBold: {
    fontWeight: '600',
    fontSize: '18px',
    // textAlign: 'center',
  },
  label: {
    fontWeight: '400',
    fontSize: '18px',
    // textAlign: 'center',
  },
  documentButton: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    // justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '5px',
    marginBottom: '10px',
  },
}));