import React, { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, TablePagination } from '@mui/material';
import moment from 'moment-timezone';
import { props } from 'ramda';
import GetQuestionnarie from '../../lib/Analytics/getQuestionnarie';

const SurveyComponent = (props) => {
  const classes = useStyle();

  const [loading, setLoading] = useState(false);
  const from = props.from;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [questionnaries, setQuestionnaries] = useState([]);

  useEffect(() => {
    getQuestionnaires()
  }, [])

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getQuestionnaires = async () => {
    setLoading(true);
    const result = await GetQuestionnarie(props.companyId)
    if (result.success) {
      setQuestionnaries(result.data ? result.data : null)
      props.setItemsView(result.data)
    }
    setLoading(false)
  }



  const selectQuestionnarie = (questionnarie) => {
    props.setSelectedQuestionnarie(questionnarie)
  }

  const table = () => {
    let itemsView = props.listSearch.length > 0 ? props.listSearch : questionnaries
      return (
        itemsView.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <TableRow key={index}>
            <TableCell>
              Cuestionario {item.startDate}
            </TableCell>
            <TableCell>
              {moment(item.startDate, 'DD-MM-YYYY')._i} - {moment(item.endDate, 'DD-MM-YYYY')._i}
            </TableCell>
            {/* <TableCell>
                {moment(item.startDate, 'DD-MM-YYYY')._i} - {moment(item.endDate, 'DD-MM-YYYY')._i}
              </TableCell> */}
            <TableCell>
              <button className={classes.buttonInfo} onClick={() => selectQuestionnarie(item)}><InfoIcon /></button>
            </TableCell>
          </TableRow>
        )
        )
      )
  }

  return (
    <div className={from === 'admin' ? classes.tableContainerAdmin : classes.tableContainer}>
      {
        loading ?
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
          :
          <Box marginBottom={'30px'}>
            <TableContainer className={classes.table}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.th}>
                      Nombre de cuestionario
                    </TableCell>
                    <TableCell className={classes.th}>
                      Período
                    </TableCell>
                    {/* <TableCell className={classes.th}>
                      Cantidad de preguntas
                    </TableCell> */}
                    <TableCell className={classes.th}>
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table()}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={questionnaries.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage='Registros por hoja'
            />
          </Box>
      }
    </div>
  )
}

export default withSnackbar(SurveyComponent);

const useStyle = makeStyles(theme => ({
  title: {
    fontSize: '62px',
    width: '50% ',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  subtitle: {
    margin: '0 0 2% 0',
    fontSize: '50px',
    width: '80% ',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    marginTop: '2%',
  },
  titleAndCardsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    margin: '5% 0',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '20px',
    overflowX: 'auto',
    margin: '30px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    paddingBottom: '2%',
    overflowX: 'auto',
    margin: '0 auto',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },
  tableContainerAdmin: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '100%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    paddingBottom: '2%',
    overflowX: 'auto',
    margin: '0 auto',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',

      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  thead: {
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.white.main,
    padding: 15,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
      borderBottom: `3px solid ${theme.palette.gray.main}`,
      boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)'
    },
  },
  hiddenColumns: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  buttonFilter: {
    border: 'none',
    padding: '12px 16px',
    fontSize: '16px',
    background: 'transparent',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  statusStyle: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: 5,
    paddingBottom: 5,
    background: '#5D5D60',
    borderRadius: 5,
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '10%'
    },
  },
  statusText: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 10,
    textTransform: 'uppercase',
    color: '#FFFFFF'
  },
  buttonResponse: {
    border: 'none',
    padding: '5px 10px',
    fontSize: '16px',
    background: '#EDEDED',
    borderRadius: '50%'
  },
  buttonInfo: {
    border: 'none',
    color: theme.palette.blue.main,
    padding: '12px 16px',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
    fontWeight: 'bold'
  },
  profileDescriptionContainer: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:last-child': {
      borderBottom: 'none'
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
  },
  containerButton: {
    width: 'auto',
    margin: '2% 0'
  },
  buttonDelete: {
    border: 'none',
    color: theme.palette.red.main,
    padding: '12px 16px',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
  },
  containerSelectQuestion: {
    margin: '5% 0',
    width: '100%',
  },
  selectContainer: {
    backgroundColor: theme.palette.white.main
  },
  available: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  titlePeriod: {
    fontWeight: 'bold',
    color: theme.palette.darkGray.main
  },
  response: {
    fontWeight: 'bold'
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  buttonContainer: {
    width: '20%',
  },
  button: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.darkGray.main}`,
    color: theme.palette.white.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
  }
}))