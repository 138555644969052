import { Radio } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import PrimaryButton from '../primaryButton';
import SaveResponseQuestions from '../../lib/Questionnaire/saveResponseQuestions'
import CircularProgress from '@mui/material/CircularProgress';

const QuestionTypeTwo = (props) => {

  const classes = useStyle();

  const [selectedValue, setSelectedValue] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChange = (option) => {
    setSelectedValue(option)
  }

  const saveQuestion = async () => {
    try {
      setLoading(true)

      const body = {
        employeeId: props.questionInfo.employeeId,
        neritoId: null,
        questionId: props.questionInfo.questionId,
        companyId: props.questionInfo.companyId,
        period: props.questionInfo.period,
        questionType: props.questionInfo.questionType,
        response: selectedValue,
        userName: null,
      }
      const questionnaire = await SaveResponseQuestions(body)

      if (questionnaire.success) {
        props.handleAlert('success', 'Pregunta guardada con exito');
        const newQuestions = props.questions.filter(question => question.questionId !== props.questionInfo.questionId)
        setSelectedValue({})
        props.setQuestions(newQuestions)
        setLoading(false)
      } else {
        props.handleAlert('error', 'Algo salio mal');
        setLoading(false)
      }

    } catch (error) {
      props.handleAlert('error', 'Algo salio mal');
      setLoading(false)

    }

  }

  return (
    <>
      <div>
        {
          props.questionInfo.options.map((option, index) => (
            <div className={classes.option}>
              <Radio
                checked={selectedValue.optionId === option.optionId}
                value={option.descripcion}
                onChange={() => handleChange(option)}
              />
              <div key={index}>
                {option.descripcion}
              </div>
            </div>
          ))
        }
        {
          loading ?
            <div className={classes.loading}>
              <CircularProgress />
            </div>
            :
            <div className={classes.buttonContainer}>
              <div className={classes.button}>
                <PrimaryButton classname={classes.button} text={'Guardar'} handleClick={() => saveQuestion()} />
              </div>
            </div>
        }

      </div>
    </>
  )

}

export default QuestionTypeTwo

const useStyle = makeStyles(theme => ({
  textArea: {
    width: '100%',
    border: '1px solid',
    borderColor: 'rgb(224 224 224 / 20)',
    maxHeight: 81,
    minHeight: 81,
    marginTop: 10,
    borderRadius: '5px',
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button:{
    width: '30%'
  }
}))