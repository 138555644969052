import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const getOrganizations = async () => {
  try {
    const result = await API.get(API_ANALYTICS, '/users/allOrganizations');
    return {
      success: true,
      organizations: result.items,
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default getOrganizations;