import React from 'react';
import BannerTop from './bannerTop';
import DataType from './dataType';
import ContactsForm from './contactForm';

const Landing = () => {
  return (
    <>
      <BannerTop />
      <DataType />
      <ContactsForm />
    </>
  )
};

export default Landing;
