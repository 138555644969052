module.exports = {
    API_ANALYTICS: 'analytics-api',
    API_TERMS: 'terms-api',
    API_CHARTS: 'charts-api',
    API_BILLING: 'billing-api',
    API_QUESTIONS: 'default-questions-api',
    REGEX_LETTERS: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ /s]*$/,
    REGEX_PHONE: /^[0-9]*$/,
    REGEX_PASSWORD: /^(?=.*[A-Za-z\d])(?=.*[!¡@#$%^&*(),.¿?":{}|<>_-])[A-Za-z\d!¡@#$%^&*(),.¿?":{}|<>_-]{6,}$/,
    REGEX_EMAIL: /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    REGEX_RFC: /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/,
    REGEX_LETTERS_AND_NUMBERS: /^[0-9a-zA-Z]*$/,
    REGEX_CURP: /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    COUNTRY_CODE: [
        { label: "MX", value: "+52" },
        { label: "USA", value: "+1" },
    ],
    GENDERS: [
        { label: "Masculino", value: "male" },
        { label: "Femenino", value: "female" },
        { label: "No Binario", value: "nonbinary" },
    ],
    PAYMENT_INTERVALS: [
        { label: "Semanal", value: "weekly" },
        { label: "Quincenal", value: "biweekly" },
        { label: "Mensual", value: "monthly" },
    ],
    CLABE_BILLING: '072760011480237608',
    MAXIMUM_SIZE: 5000000, // 5MB
    CHART_FILTERS: {
        age: { minValue: 0, maxValue: 100, active: false, },
        gender: { values: [], active: false },
        city: { values: [], active: false },
        state: { values: [], active: false },
    },
    QUESTIONNARIE_OPTIONS: [
        { label: "Cuestionario", value: 1 },
        { label: "Detalle de cuestionario", value: 2 },
    ],
    MIN_BIDDING: 2000,
    MAX_BIDDING: 25000,
    ULR_FACEBOOK: 'https://www.facebook.com/Flexeanalytics-107683668589308',
    ULR_INSTAGRAM: 'https://www.instagram.com/flexeanalytics/',
    DATA_CATEGORIES: {
        "Entertainment": "Entretenimiento",
        "Industrial": "Industrial",
        "Agricultural": "Agropecuario",
        "Financial": "Financieros",
        "Wholesalers": "Comercios Mayoristas",
        "Academic": "Académico",
        "Mining": "Minería",
        "Retail": "Comercios Minoristas",
        "Services": "Servicios",
        "Technology": "Tecnología",
        "Games": "Juegos",
        "News": "Noticias",
        "Shopping": "Compras",
        "Transport": "Transportes"
    },
    MONTHS: [
        { label: 'ENERO', value: '01' },
        { label: 'FEBRERO', value: '02' },
        { label: 'MARZO', value: '03' },
        { label: 'ABRIL', value: '04' },
        { label: 'MAYO', value: '05' },
        { label: 'JUNIO', value: '06' },
        { label: 'JULIO', value: '07' },
        { label: 'AGOSTO', value: '08' },
        { label: 'SEPTIEMBRE', value: '09' },
        { label: 'OCTUBRE', value: '10' },
        { label: 'NOVIEMBRE', value: '11' },
        { label: 'DICIEMBRE', value: '12' },
    ],
    INTERVAL_TYPES: [
        { label: 'SEMANAL', value: 'weekly' },
        { label: 'QUINCENAL', value: 'biweekly' },
        { label: 'MENSUAL', value: 'monthly' },
    ],
    ORDERS_STATUS: {
        ALL: 'TODAS',
        CREATED: 'CREADA',
        WAITING_PAYMENT: 'EN ESPERA DE DEPOSITO',
        DEPOSITED_PARTIALY: 'PARCIALMENTE DEPOSITADA',
        DEPOSITED_TOTALLY: 'TOTALMENTE DEPOSITADA',
        CANCELED: 'CANCELADA',
        PAIDED_TOTALLY: 'PAGADA TOTALMENTE',
        PAIDED_PARTIALY: 'PAGADA PARCIALMENTE',
        PENDING: 'PENDIENTE',
        PAID: 'PAGADO',
        READY_TO_DISBURSMENT: 'LISTO PARA DISPERSAR',
        PROCESSING: 'PROCESANDO'
    },
    BANKS: [
        { id: "2001", label: "BANXICO" },
        { id: "37006", label: "BANCOMEXT" },
        { id: "37009", label: "BANOBRAS" },
        { id: "37019", label: "BANJERCITO" },
        { id: "37135", label: "NAFIN" },
        { id: "37166", label: "BaBien" },
        { id: "37168", label: "HIPOTECARIA FED" },
        { id: "40002", label: "BANAMEX" },
        { id: "40012", label: "BBVA MEXICO" },
        { id: "40014", label: "SANTANDER" },
        { id: "40021", label: "HSBC" },
        { id: "40030", label: "BAJIO" },
        { id: "40036", label: "INBURSA" },
        { id: "40042", label: "MIFEL" },
        { id: "40044", label: "SCOTIABANK" },
        { id: "40058", label: "BANREGIO" },
        { id: "40059", label: "INVEX" },
        { id: "40060", label: "BANSI" },
        { id: "40062", label: "AFIRME" },
        { id: "40072", label: "BANORTE" },
        { id: "40106", label: "BANK OF AMERICA" },
        { id: "40108", label: "MUFG" },
        { id: "40110", label: "JP MORGAN" },
        { id: "40112", label: "BMONEX" },
        { id: "40113", label: "VE POR MAS" },
        { id: "40127", label: "AZTECA" },
        { id: "40128", label: "AUTOFIN" },
        { id: "40129", label: "BARCLAYS" },
        { id: "40130", label: "COMPARTAMOS" },
        { id: "40132", label: "MULTIVA BANCO" },
        { id: "40133", label: "ACTINVER" },
        { id: "40136", label: "INTERCAM BANCO" },
        { id: "40137", label: "BANCOPPEL" },
        { id: "40138", label: "ABC CAPITAL" },
        { id: "40140", label: "CONSUBANCO" },
        { id: "40141", label: "VOLKSWAGEN" },
        { id: "40143", label: "CIBANCO" },
        { id: "40145", label: "BBASE" },
        { id: "40147", label: "BANKAOOL" },
        { id: "40148", label: "PAGATODO" },
        { id: "40150", label: "INMOBILIARIO" },
        { id: "40151", label: "DONDE" },
        { id: "40152", label: "BANCREA" },
        { id: "40154", label: "BANCO COVALTO" },
        { id: "40155", label: "ICBC" },
        { id: "40156", label: "SABADELL" },
        { id: "40157", label: "SHINHAN" },
        { id: "40158", label: "MIZUHO BANK" },
        { id: "40159", label: "BANK OF CHINA" },
        { id: "40160", label: "BANCO S3" },
        { id: "90600", label: "MONEXCB" },
        { id: "90601", label: "GBM" },
        { id: "90602", label: "MASARI" },
        { id: "90605", label: "VALUE" },
        { id: "90608", label: "VECTOR" },
        { id: "90616", label: "FINAMEX" },
        { id: "90617", label: "VALMEX" },
        { id: "90620", label: "PROFUTURO" },
        { id: "90630", label: "CB INTERCAM" },
        { id: "90631", label: "CI BOLSA" },
        { id: "90634", label: "FINCOMUN" },
        { id: "90638", label: "NU MEXICO" },
        { id: "90642", label: "REFORMA" },
        { id: "90646", label: "STP" },
        { id: "90652", label: "CREDICAPITAL" },
        { id: "90653", label: "KUSPIT" },
        { id: "90656", label: "UNAGRA" },
        { id: "90659", label: "ASP INTEGRA OPC" },
        { id: "90661", label: "ALTERNATIVOS" },
        { id: "90670", label: "LIBERTAD" },
        { id: "90677", label: "CAJA POP MEXICA" },
        { id: "90680", label: "CRISTOBAL COLON" },
        { id: "90683", label: "CAJA TELEFONIST" },
        { id: "90684", label: "TRANSFER" },
        { id: "90685", label: "FONDO (FIRA)" },
        { id: "90686", label: "INVERCAP" },
        { id: "90689", label: "FOMPED" },
        { id: "90703", label: "TESORED" },
        { id: "90710", label: "NVIO" },
        { id: "90722", label: "Mercado Pago W" },
        { id: "90723", label: "CUENCA" },
        { id: "90902", label: "INDEVAL" },
        { id: "90903", label: "CoDi Valida" },
        { id: "90699", label: "FONDEADORA" },
        // { id: "91802", label: "BANAMEX2" },
        // { id: "91812", label: "BBVA BANCOMER2*" },
        // { id: "91814", label: "SANTANDER2*" },
        // { id: "91821", label: "HSBC2*" },
        // { id: "91927", label: "AZTECA2*" },
        // { id: "91872", label: "BANORTE2*" },
        { id: "90706", label: "ARCUS" },
    ],
    ORDERS_FILTERS: [
        'ALL',
        'CREATED',
        'WAITING_PAYMENT',
        'DEPOSITED_PARTIALY',
        'DEPOSITED_TOTALLY',
        'CANCELED',
        'PAIDED_TOTALLY',
        'PAIDED_PARTIALY',
        'PENDING',
        'PAID',
        'READY_TO_DISBURSMENT'
    ],
    SHOW_CARD_DEPOSIT_INFO: [
        'WAITING_PAYMENT',
        'DEPOSITED_PARTIALY'
    ],
}