import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const uploadFiles = async (organizationRecordKey,documentName,documentType) => {
  try {
    const result = await API.post(API_ANALYTICS, '/users/files'
    , {
      body: {
        organizationRecordKey: organizationRecordKey,
        documentName: documentName,
        documentType: documentType
      }
    }
    );
    return {
      url: result.url,
      success: true,
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default uploadFiles;