import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from "react-router-dom";
import { withSnackbar } from 'notistack';

import Background from "../../../assets/images/backgroundContactForm.svg";
import TextField from '@mui/material/TextField';
import PasswordInput from '../../../components/passwordInput';
import { CheckBox } from '../../../components/checkBox';
import {REGEX_PASSWORD, REGEX_EMAIL,COUNTRY_CODE,REGEX_PHONE } from "../../../lib/constant";
import Signup from '../../../lib/Auth/signup';

import ReCAPTCHA from "react-google-recaptcha";
import PrimaryButton from '../../../components/primaryButton';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const useStyle = makeStyles(theme => ({
    containerBackground: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        margin: 0,
        backgroundPosition: 'bottom',
        alignItems: 'center',
    },
    registerContainer: {
        width: '40%'
    },
    title: {
        ...theme.typography.title,
        fontSize: 60,
        color: theme.palette.primary.main,
        textAlign: 'center',
        lineHeight: 1,
        margin: '5% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
        },
    },
    containerForm: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        backgroundColor: theme.palette.white.main,
        borderRadius: '0.625rem',
        marginBottom: '6.25rem',
        padding: '7% 0',
        width: '100%',
        [theme.breakpoints.down('md')]: {
          padding: 20,
          zIndex: 0,
          marginLeft: '-5%'
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: '-10%',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        gap: '10px',
        [theme.breakpoints.down('md')]: {
          width: '100%'
        },
    },
    inputs: {
        width: '100%',
        marginBottom: '20px !important'
    },
    checkContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        fontWeight: 600,
    },
    passwordLabel: {
        color: '#878787',
        fontSize: 12,
        marginBottom: 20
    },
    captcha: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
    },
    phoneContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '5%',
    },
    selectContainer: {
        width: '22%',
        marginRight: '3%',
        [theme.breakpoints.down('md')]: {
          width: 'auto'
        },
    },
    inputPhone: {
        width: '90%',
    },
}))

const LeadRegisterScreen = (props) => {
    const classes = useStyle();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [termConditions, setTermConditions] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [errors, setErrors] = useState({ errorName: false, errorEmail: false, errorMessage: false, errorSyntax: false });
    const [captcha, setCaptcha] = useState(null);
    const [country, setCountry] = useState('+52');
    const [phone, setPhone] = useState('');

    let navigate = useNavigate();
    const loc = useLocation();

    const handleErrors = () => {
        let error = {};
    
        if (email === '') {
          error.email = "Favor de ingresar un correo electrónico";
          errors.errorEmail = true;
          setErrors(errors);
        } else if (!REGEX_EMAIL.test(email)) {
          error.emailSyntax = "Favor de ingresar un correo electrónico válido";
          errors.emailSyntax = true;
          setErrors(errors);
        } else if (password === '') {
          error.password = "Favor de ingresar una contraseña";
          errors.errorPassword = true;
          setErrors(errors);
        } else if (!REGEX_PASSWORD.test(password)) {
          error.passwordSyntax = `La contraseña debe tener mínimo 6 caracteres, incluyendo por lo menos una mayúscula, un número y un caracter especial (!¡@#$%^&*(),.¿?":{}|<>_-)`;
          errors.errorpasswordSyntax = true;
          setErrors(errors);
        } else if (confirmPassword === '') {
          error.password = "Favor de confirmar la contraseña";
          errors.errorConfirmPassword = true;
          setErrors(errors);
        }  else if (password !== confirmPassword) {
          error.password = "Las contraseñas no coinciden";
          errors.errorConfirmPassword = true;
        } else if (!termConditions) {
          error.terms = "Favor de aceptar los términos y condiciones";
          errors.errorTerms = true;
        } 
          else if (!privacyPolicy) {
          error.privacyPolicy = "Favor de aceptar el aviso de privacidad";
          errors.errorPrivacy = true;
        } else if (!captcha) {
          error.captcha = "Favor de confirmar que no eres un robot";
          errors.captcha = true;
        }
        return error;
    }

    const handlePhoneChange = (value) => {
        if (REGEX_PHONE.test(value)) {
          setPhone(value);
        }
    }
    
    const handleAlert = (variant, message) => {
        props.enqueueSnackbar(message, { variant });
    };
    
    const onChangeCaptcha = (value) => {
        setCaptcha(value);
    }
    
    const register = async (event) => {
        event.preventDefault();
        const error = handleErrors();
        if (Object.keys(error).length === 0) {
        //Handle Register
            const status = await Signup(email, password,phone,'LEAD_USER');
            if (status.success) {
                navigate('/confirmCode', {
                    state: {
                        key: status.user.userSub,
                        email: status.user.user.username, 
                        password: password,
                        country_code:country,
                        phone: phone,
                        type: 'LEAD_USER',
                    }
                });
            } 
            else {
                handleAlert('error', status.error);
            }
        }
        else {
            for (const value in error) {
                handleAlert('error', error[value]);
            }
        }
    }
    
    const handleOnChange = (event) => {
        const { name } = event.target

        switch (name) {
            case 'terms':
            setTermConditions(!termConditions)
            break;

            case 'privacy':
            setPrivacyPolicy(!privacyPolicy)
            break;
        }
    }

    return (
        <main className={classes.containerBackground}>
            <div className={classes.registerContainer}>
                <h2 className={classes.title}>
                    Registro de Usuario
                </h2>
                
                <section className={classes.containerForm}>
                    <form className={classes.form}>
                        <div>
                            <TextField
                                label="Correo Electrónico"
                                variant="outlined"
                                name='email'
                                id="email"
                                required
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                className={classes.inputs}
                            />

                            <PasswordInput
                                label = "Contraseña"
                                name = "password"
                                value = {password}
                                handlerPassword = {(event) => setPassword(event.target.value)}
                            />

                            <PasswordInput
                                label = "Confirmar Contraseña"
                                name = "confirmPassword"
                                value = {confirmPassword}
                                handlerPassword = {(event) => setConfirmPassword(event.target.value)}
                            />

                            <p className={classes.passwordLabel}>
                                {`La contraseña debe tener mínimo 6 caracteres, incluyendo por lo menos una mayúscula, un número y un caracter especial (!¡@#$%^&*(),.¿?":{}|<>_-)`}
                            </p>

                            <div className={classes.phoneContainer}>
                                <Select
                                value={country}
                                name="countryCodeSelect"
                                onChange={(event) => setCountry(event.target.value)}
                                className={classes.selectContainer}
                                >
                                {
                                    COUNTRY_CODE.map(items => (
                                    <MenuItem value={items.value}>{items.label}</MenuItem>
                                    ))
                                }
                                </Select>
                                <TextField
                                label="Número de Teléfono"
                                variant="outlined"
                                name='phone'
                                required
                                inputProps={{ maxLength: 10 }}
                                value={phone}
                                onChange={(event) => handlePhoneChange(event.target.value)}
                                className={classes.inputPhone}
                                />
                            </div>

                        </div>

                        <div className={classes.checkContainer}>
                            <CheckBox
                                check={termConditions}
                                name = "terms"
                                onChange={handleOnChange}
                                description = {<p>Aceptar los <a href='/termConditions' target="_blank">Términos y Condiciones</a></p>}
                            />

                            <CheckBox
                                check={privacyPolicy}
                                name = "privacy"
                                onChange={handleOnChange}
                                description = {<p>Aceptar el <a href='/privacyPolicy' target="_blank">Aviso de Privacidad</a></p>}
                            />
                        </div>

                        <div className={classes.captcha}>
                            <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                            onChange={onChangeCaptcha}
                            />
                        </div>

                        <PrimaryButton 
                            handleClick = {register}
                            text = "Regístrate"
                            color = "#000000"
                        />

                    </form>
                </section>
            </div>
        </main>
    )
}

export default withSnackbar(LeadRegisterScreen);
