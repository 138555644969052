import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { useNavigate, useLocation } from "react-router-dom";
import Background from '../../../assets/images/backgroundGrayyBottom.svg';
import { Tabs, Tab, Box, Table, TableBody, TableRow, TableCell, TableContainer, TableHead } from '@mui/material';
import PropTypes from 'prop-types';
import Results from '../../../components/results/results';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBar from '../../../components/searchBar';
import GetResults from '../../../lib/CreateFiles/getResults';
import * as R from 'ramda';
import { useCookies } from 'react-cookie';
import Files from '../../../components/results/files';
import gerOrders from '../../../lib/Orders/getOrders';
import getOrder from '../../../lib/Orders/getOrder';
import ConfirmDeleteOrderModal from '../../../components/confirmDeleteOrderModal';
import deleteOrder from '../../../lib/Orders/deleteOrder';
import confirmOrder from '../../../lib/Orders/confirmOrder';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <text>{children}</text>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const ResultsScreen = (props) => {
  const classes = useStyle();
  const perPage = 10;
  let navigate = useNavigate();
  const loc = useLocation();
  const state = loc.state;
  const [cookies] = useCookies(['user']);

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [results, setResults] = useState([])
  const [dataSuccess, setDataSuccess] = useState([]);
  const [dataErrors, setDataErrors] = useState([]);
  const [search, setSearch] = useState('');
  const companyId = cookies.user.sub;
  const from = R.pathOr('', ['from'], state);
  const [selectedFile, setSelectedFile] = useState('')
  const [openDeleteOrderModal, setOpenDeleteOrderModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState('')


  const getAllOrders = async () => {
    try {
      const order = await gerOrders(companyId)
      if (order.success) {
        setResults(order.data)
      } else {
        handleAlert('error', 'Algo salio mal')
      }
    } catch (error) {
      handleAlert('error', 'Algo salio mal')
    }
    setLoading(false)
  }

  const selectOrder = async (file, index) => {
    setSelectedFile(file);
    // const response = await getOrder(fileRecordKey)
    //tomamos el id y hacemos el get a la orden
    const resultsError = file.detail.filter(item => !item.successful);
    const resultsSuccess = file.detail.filter(item => item.successful);
    setDataErrors(resultsError)
    setDataSuccess(resultsSuccess)
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFilter = (event) => {
    const filter = (event.target.value).toLowerCase();
    const filteredArr = value === 0 ?
      dataSuccess.filter(item => item.titular.includes(filter))
      :
      dataErrors.filter(item => item.titular.includes(filter));
    setSearch(filter);
  }


  const handleDeleteOrder = async (order) => {
    try {
      setLoading(true);
      const res = await deleteOrder(order.orderRecordKey)
      if (res.success) {
        handleAlert('success', 'Se elimino la orden exitosamente')
        await getAllOrders()
      }
      setOpenDeleteOrderModal(false)
      setLoading(false);
    } catch (error) {
      handleAlert('error', 'Algo salió mal')
    }
  }

  const handleConfirmOrder = async (orderId) => {
    try {
      setLoading(true)
      const res = await confirmOrder(orderId)
      if (res.success) {
        await getAllOrders()
        handleAlert('success', 'Orden confirmada exitosamente')
      } else {
        handleAlert('error', 'Algo salio mal')
      }
    } catch (error) {
      handleAlert('error', 'Algo salio mal')
    }
    setLoading(false)

  }

  const SearchContainer = () => {
    return (
      <div className={classes.searchContainer}>
        <SearchBar
          value={search}
          handleOnChange={handleFilter}
        />
      </div>
    )
  }

  return (
    <div className={classes.containerBackground}>
      <text className={classes.title}>
        Ordenes
      </text>

      {loading ?
        <div>
          <CircularProgress />
        </div>
        :
        <>
          <ConfirmDeleteOrderModal
            onClose={() => setOpenDeleteOrderModal(false)}
            open={openDeleteOrderModal}
            item={selectedOrder}
            deleteOrder={() => handleDeleteOrder(selectedOrder)}
          />
          <Box width={'80%'}>
            {
              selectedFile ?
                <>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Éxitosos" {...a11yProps(0)} />
                      <Tab label="Errores" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <SearchContainer />
                    <Results data={dataSuccess} from={state.from === 'leads' ? 'leads' : 'bidding'} success={true} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <SearchContainer />
                    <Results data={dataErrors} from={state.from === 'leads' ? 'leads' : 'bidding'} success={false} />
                  </TabPanel>
                </>
                :
                <Files
                  setLoading={setLoading}
                  handleAlert={handleAlert}
                  setOpenDeleteOrderModal={setOpenDeleteOrderModal}
                  companyId={companyId}
                  setSelectedOrder={setSelectedOrder}
                />
            }
          </Box>
        </>
      }
    </div>
  )
}

export default withSnackbar(ResultsScreen)

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  title: {
    margin: '2%  0 2% 0',
    fontSize: '62px',
    width: '80% ',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  subtitle: {
    margin: '0 0 2% 0',
    fontSize: '50px',
    width: '80% ',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  searchContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '100%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
    margin: '20px 0px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '30%',
    alignItems: 'center',
    margin: '10px'
  }
}));
