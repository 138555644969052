import React from 'react'
import '../../styles/styles.css'

export const CheckBox = (props) => {
  return (
    <div>
        <input className="inp-cbx" name = {props.name || 'checkBox'} id = {`check${props.id || props.label || props.name}`} type="checkbox" style={{display: 'none'}} checked = {props.check} disabled = {props.disable || false} onChange = {props.onChange}/>
        <label className="cbx" htmlFor={`check${props.id || props.label || props.name}`}>
            <span>
                <svg width="12px" height="10px" viewbox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                </svg>
            </span>
            {props.description &&
              <span>{props.description}</span>
            }
        </label>
    </div>  
  )
}