import React from 'react'
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
    container: {
        width: '100%',
        borderRadius: 10,
        backgroundColor: theme.palette.white.main,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-around',
        alignItems: 'center',
        boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column'
      },
    },
    button: {
        fontSize: 20,
        height: '7vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        color: theme.palette.darkGray.main
    },
    switchButton:{
        display: 'none'
    },
    SelectButton: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    }
}));

const ToggleMenu = (props) => {
    const classes = useStyle();

    const selected = props.selectedOption || 1;

    const changeSelected = (value) => {
      props.handleClick(value)
    }

  return (
    <div className={classes.container}>
      {props.elements.map((element, index) => {
        return (
            <label 
                htmlFor={`label-${element.label}`}
                key = {index}
                className={selected === element.value ? `${classes.button} ${classes.SelectButton}` : classes.button }>
                <input type="radio" id = {`label-${element.label}`} name="switch-label" onClick={() => changeSelected(element.value)}  className={classes.switchButton}/>
                {element.label}
            </label>
        )
      })}
    </div>
  )
}

export default ToggleMenu