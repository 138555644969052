import React from 'react';
import { makeStyles } from '@mui/styles';
import { MapContainer, TileLayer, Marker, Circle } from 'react-leaflet'

const ChartMap = (props) => {
  const classes = useStyle();
  return (
    <div className={classes.chartContainer}>
      <h3 className={classes.title}>{props.title}</h3>
      <MapContainer 
        className={classes.mapContainer} 
        center={[props.center.latitude, props.center.longitude]} 
        zoom={13}
        preferCanvas={true}
        scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {props.points && props.points.map((p, i) => (
          <Circle key={i} center={[p.latitude, p.longitude]} radius={25}/>
        ))}
        <Circle center={[props.center.latitude, props.center.longitude]} pathOptions={{color: 'green'}} radius={4000}/>
      </MapContainer>
    </div>
  )
}

export default ChartMap;

const useStyle = makeStyles(theme => ({
  chartContainer: {
    backgroundColor: 'white',
    border: `5px solid ${theme.palette.lightGray.main}`,
    height: 600,
    paddingLeft: 10,
    paddingRight: 10,
  },
  title: {
    marginTop: 10,
  },
  mapContainer: {
    marginTop: 10,
    height: 530,
    zIndex: 1,
  },
}));