import React, { useState,useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import getCategory from '../../../lib/Admin/getCategory';
import getQuestionsDefault from '../../../lib/Admin/getQuestions';
import SearchBar from '../../../components/searchBar';
import PrimaryButton from '../../../components/primaruButtonLite';
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import { Select,MenuItem,FormControl,InputLabel,TablePagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalDelete from '../../../components/surveysComponents/modals/modalQuestionDelete';
import DeleteQuestion from '../../../lib/Admin/deleteQuestion';
import ModalQuestion from '../../../components/surveysComponents/modals/modalQuestion';

const Questions = (props) => {
  const classes = useStyle()
  const navigate = useNavigate();

  const [selectQuestion, setSelectQuestion] = useState({id: '', question: ''});
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('')
  const [categories, setCategories] = useState('')
  const [allQuestions, setAllQuestions] = useState([])
  const [allCategories, setAllCategories] = useState([])
  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  
  useEffect(() => {
    getQuestions()
  }, [])

  const getQuestions = async() => {
    const [resultCategory, resultQuestions] = await Promise.all([getCategory(),getQuestionsDefault()])
    const filter = resultCategory.data.filter(item => item.isActivated === true)

    setAllCategories(filter)
    setAllQuestions(resultQuestions.data)
    setQuestions(resultQuestions.data)
    setIsLoading(false)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const table = () => {
    return(
      questions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,index) => (
        <tr className ={classes.tr} key = {index}>
          <td className={`${classes.tableRegister} ${classes.question}`} data-label="Pregunta">{item.questionText}</td>
          <td className={classes.tableRegister} data-label="Tipo">{getQuestionType(item.questionType)}</td>
          <td className={classes.tableRegister} data-label="Cantidad de opciones">{item.options ? item.options.length > 0 && item.options.length : null}</td>
          <td className={classes.tableRegister} data-label="Acciones">
            <div className = {classes.actionContainer}>
              <button className={classes.buttonAction} onClick = {() => (setSelectQuestion(item),setOpenModalEdit(true))}>
                  <EditIcon sx = {{color: '#039EDA'}}/>
              </button>

              <button className={classes.buttonAction} onClick = {() => (setSelectQuestion({ id: item.defaultRecordKey, question: item.questionText }),setOpenModalDelete(true))}>
                  <DeleteIcon sx = {{color: '#EE715B'}}/>
              </button>
            </div>
          </td>
        </tr>
      ))
    )
  }

  const getQuestionType = (type) => {
      switch (type) {
        case 1:
          return 'Texto libre';
        case 2:
          return 'Selección única';
        case 3:
          return 'Selección múltiple';
        default:
          return '';
      }
  }

  const deleteQuestion = async(question) => {
    const result = await DeleteQuestion(question)
    if(result.success) {
      getQuestions()
      setOpenModalDelete(false)
      handleAlert('success', 'Pregunta eliminada con exito')
    }
    else {
      handleAlert('error','Favor de intentarlo nuevamente')
    }
  }

  const handleFilter = (event) => {
    const { name, value } = event.target
    switch (name) {
      case 'search':
        setSearch(value)

        if(value !== ''){
          const filter = allQuestions.filter(items => items.questionText.includes(value))
          setQuestions(filter)
        }
        else{
          setQuestions(allQuestions)
        }
      break;

      case 'category':
        setCategories(value)
        const filter = allQuestions.filter(items => items.category == value)
        setQuestions(filter)
      break;
    
      default:
      break;
    }
  }

  return (
    <>
    {
        isLoading ?
        <CircularProgress/>
        :
        <>
          <div className={classes.rowContainer}>
            <SearchBar
              value={search}
              handleOnChange={handleFilter}
              name='search'
            />

            <div className={classes.buttonContainer}>
              <PrimaryButton onClick = {() => navigate('/admin/newQuestions')} title = "Nueva Pregunta"/>
              <FormControl className={classes.inputs}>
                <InputLabel id='category-select-label'>Categoria</InputLabel>
                  <Select
                      labelId='category-select-label'
                      id='category-select'
                      label='Categoria'
                      variant='outlined'
                      value={categories}
                      name="category"
                      onChange={handleFilter}
                      // size = 'small'
                    >
                    {
                      allCategories.map((items,index) => (
                        <MenuItem key={index} value={items.value}>{items.name}</MenuItem>
                      ))
                    }
                  </Select> 
              </FormControl>
            </div>

          </div>

          <ModalDelete
            open={openModalDelete}
            data={selectQuestion}
            text = {`¿Estás seguro de eliminar la pregunta ${selectQuestion.question}?`}
            delete={() => deleteQuestion(selectQuestion.id)}
            handleClose={() => setOpenModalDelete(false)}
          />

          {
            openModalEdit &&
              <ModalQuestion
                open={openModalEdit}
                onClose={() => setOpenModalEdit(false)}
                questionInfo={selectQuestion}
                getQuestions={getQuestions}
                category={allCategories}
                from = 'Admin'
              />
          }

          <div className={`${classes.rowContainer} ${classes.tableContainer}`}>
            <table className={classes.table}>
              <thead className={classes.thead}>
                <tr>
                  <th className={classes.th}>Pregunta</th>
                  <th className={classes.th}>Tipo</th>
                  <th className={classes.th}>Cantidad de opciones</th>
                  <th className={classes.th}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {
                  table()
                }
              </tbody>
            </table>
            <TablePagination
              component="div"
              count={questions.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage='Registros por hoja'
            />
          </div>

        </>
    }
    </>
  )
}

export default withSnackbar(Questions)

const useStyle = makeStyles(theme => ({
  rowContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '20px',
    margin: '30px 0px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      padding: 'none',
    },
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  table: {
    borderCollapse: 'collapse'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px !important',
    gap: 15,
    [theme.breakpoints.down('md')]: {
      flexDirection:'column'
    },
  },
  inputs: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  thead: {
    borderBottom:  `1px solid ${theme.palette.lightGray.main}`,
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
      borderBottom: 'none',
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    width: '25%',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    fontSize: '14px !important',
    textAlign: 'center',
    width: '25%',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.lightGray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'justify',
      width: '80%',
        '&::before': {
            display:'flex',
            justifyContent: 'space-between',
            content: 'attr(data-label)',
            fontWeight: 'bold',
            textTransform: 'uppercase'
        },
        '&:last-child':{
            borderBottom: 'none'
        }
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 15
  },
  buttonAction:{
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center'
  },
  tr: {
    borderBottom:  `1px solid ${theme.palette.lightGray.main}`,
    [theme.breakpoints.down('md')]: {
      borderBottom: 'none',
      backgroundColor: theme.palette.white.main
    },
  },
  question: {
    textAlign: 'justify'
  }
}))