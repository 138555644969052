import React,{ useState,useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import Background from '../../../assets/images/backgroundGrayyBottom.svg'
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from '@material-ui/icons/Clear';
import AddTask from '@material-ui/icons/AddCircle';
import PrimaryButton from '../../../components/primaryButton';
import saveQuestion from '../../../lib/Admin/saveQuestion';
import getCategory from '../../../lib/Admin/getCategory';

const NewQuestion = (props) => {
  const classes = useStyle();
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([])
  const [questionName, setQuestionName] = useState('')
  const [questionType, setQuestionType] = useState(1)
  const [answers, setAnswers] = useState([])
  const [textAnswers, setTextAnswers] = useState('')
  const [category, setCategory] = useState([])
  const [selectCategory, setSelectCategory] = useState('')

  useEffect(() => {
    getCategories()
  }, [])

  const getCategories = async() => {
    const result = await getCategory();
    const filter = result.data.filter(item => item.isActivated === true)
    setCategory(filter)
  }
  
  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handlerDelete = (id, type) => {
    switch (type) {
      case 'options':
        const optionsResut = answers.filter(items => items.optionId !== id)
        setAnswers(optionsResut)
        break;

      case 'question':
        const questionResult = questions.filter((items, index) => index !== id)
        setQuestions(questionResult)
        break;
    }
  }

  const handleOnChange = (event) => {
    const { value, name } = event.target
    switch (name) {

      case 'questionType':
        setQuestionType(parseInt(value));
      break;

      case 'textAnswers':
        setTextAnswers(value)
      break;

      case 'question':
        setQuestionName(value)
      break;

      case 'category':
        setSelectCategory(value)
      break;
    }
  }

  const handleAnswers = () => {
    const count = answers.length + 1
    const error = handleErrors('answers')
    if (questionType !== 1 && error.length === 0) {
      if (answers.length === 0) {
        setAnswers([{ 'optionId': count, 'descripcion': textAnswers }])
        setTextAnswers('')
      }
      else {
        setAnswers([...answers, { 'optionId': count, 'descripcion': textAnswers }])
        setTextAnswers('')
      }
    }
    else {
      handleAlert('error', error[0])
      setAnswers([])
    }
  }

  const handleErrors = (type) => {
    const errors = []

    switch (type) {
      case 'addQuestion':
        if (questionName === '') {
          errors.push("El campo 'Pregunta' es requerido")
        }
        if (questionType !== 1 && answers.length === 0) {
          errors.push("Favor de agregar respuestas a su pregunta")
        }
        if (questionType !== 1 && answers.length <= 1) {
          errors.push("Favor de agregar más de una respuesta")
        }
        if (selectCategory === '') {
          errors.push("Favor de seleccionar una categoria")
        }
        return errors

      case 'answers':
        if (textAnswers === '' && questionType !== 1 && answers.length < 1) {
          errors.push("El campo 'Respuestas' es requerido")
        }
        return errors
    }
  }

  const addQuestion = async() => {
    const errors = handleErrors('addQuestion')
    if(errors.length === 0) {
        const result = await saveQuestion(questionName,questionType,answers,selectCategory)
        if(result.success){
            handleAlert('success','Pregunta registrada exitosamente')
            navigate(-1)
        }
        else{
          handleAlert('error','Favor de intentarlo nuevamente')
        }
    }
    else {
        errors.forEach(element => {
            handleAlert('error',element)
        });
    }
  }

  return (
    <div className={classes.containerBackground}>
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>
            Nueva pregunta
        </h1>
        <PrimaryButton margin = '0px' width = '20%' color = 'black' text = "Agregar Pregunta" handleClick = {addQuestion}/>
      </div>

      <div className={classes.tableContainer}>
        <article>
          <div className={`${classes.inputQuestionContainer}`} fullWidth>
            <TextField
              label='Pregunta'
              variant="outlined"
              name='question'
              required
              value={questionName}
              onChange={handleOnChange}
              fullWidth
            />
            <FormControl className={classes.inputs} fullWidth>
                <InputLabel id='category-select-label'>Categoria</InputLabel>
                <Select
                    labelId='category-select-label'
                    id='category-select'
                    label='Categoria'
                    variant='outlined'
                    value={selectCategory}
                    name="category"
                    onChange={handleOnChange}
                    fullWidth
                >
                {
                category.map((items,index) => (
                  <MenuItem key={index} value={items.value}>{items.name}</MenuItem>
                ))
                }
                </Select> 
            </FormControl>
          </div>
          <RadioGroup row value={questionType} onChange={handleOnChange} className={classes.containerRadioButton}>
            <FormControlLabel name='questionType' value='1' control={<Radio color="primary" size="large" />} label="Texto libre" />
            <FormControlLabel name='questionType' value='2' control={<Radio color="primary" size="large" />} label="Selección Única" />
            <FormControlLabel name='questionType' value='3' control={<Radio color="primary" size="large" />} label="Selección Múltiple" />
          </RadioGroup>

          {
            questionType !== 1 &&
            <div>
              <h4 className={classes.titleQuestion}>
                Respuesta
              </h4>
              <div className={classes.newAnswersContainer}>
                <TextField
                  label='Respuesta'
                  variant="outlined"
                  name='textAnswers'
                  required
                  value={textAnswers}
                  onChange={handleOnChange}
                  fullWidth
                  sx = {{marginTop: '10px'}}
                />

                <button className={classes.buttonAdd} onClick={handleAnswers}>
                  <AddTask fontSize="large" />
                </button>
              </div>
              {
                answers.length > 0 ?
                  answers.map(item => (
                    <div className={classes.containerAnswers}>
                      <span>{item.descripcion}</span>
                      <button className={classes.deleteButton} onClick={() => handlerDelete(item.optionId, 'options')}><DeleteIcon /></button>
                    </div>
                  ))
                  :
                  <>
                    <p>Aún no se han ingresado respuestas para la pregunta.</p>
                  </>
              }
            </div>
          }
        </article>
      </div>
    </div>
  )
};

export default withSnackbar(NewQuestion);

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
    paddingBottom: '30px'
  },
  title: {
    fontSize: '62px',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    margin: '2% 0%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
    },
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(300px,1fr))',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      padding: '5%'
    }
  },
  inputQuestionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 10,
      width: '90%'
    }
  },
  containerRadioButton: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  newAnswersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '2%',
    [theme.breakpoints.down('sm')]: {
      gap: 0,
    }
  },
  buttonAdd: {
    border: 'none',
    color: '#5D5D60',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center'
  },
  containerAnswers: {
    width: '95%',
    display: 'grid',
    gridTemplateColumns: '1fr 50px',
    alignItems: 'center',
    wordBreak: 'break-all',
    marginLeft: '5%',
  },
  questionContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 50px',
    wordBreak: 'break-all',
    alignItems: 'center',
    gap: '3%',
    marginBottom: '3%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit,minmax(50px,1fr))',
      gap: 0,
    }
  },
  deleteButton: {
    border: 'none',
    color: '#CC3C3C',
    borderRadius: 30,
    cursor: 'pointer',
    background: 'transparent',
  },
  inputs: {
    width: '30% !important',
    marginLeft: '5% !important',
    [theme.breakpoints.down('md')]: {
      width: '80% !important',
      marginLeft: 'none !important'
    }
  }
}))