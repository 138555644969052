import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Background from '../../../assets/images/backgroundGrayyBottom.svg';
import { Tabs, Tab, Box } from '@mui/material';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import LeadsTable from '../../../components/dispersionTables/leads';
import DispersionTable from '../../../components/dispersionTables/dispersion';
import * as R from 'ramda';
import { useCookies } from 'react-cookie';
import GetLeads from '../../../lib/Analytics/getLeads';
import EditLead from '../../../lib/Analytics/editLead';
import removeAccents from '../../../lib/removeAccents';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <text>{children}</text>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Dispersion = (props) => {
  const classes = useStyle();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [file, setFile] = useState(null);
  const [leads, setLeads] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [shownLeads, setShownLeads] = useState([]);
  const [cookies] = useCookies(['user']);
  const [failedItems, setFailedItems] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getSavedLeads();
  }, []);

  const getFullName = (item) => {
    const fullName = `${item.name} ${item.secondName} ${item.paternalLastName} ${item.maternalLastName}`
    return removeAccents(fullName).toLowerCase();
  }

  const getSavedLeads = async () => {
    try {
      setLoading(true);
      const session = await Auth.currentSession();
      const organizationRecordKey = R.pathOr('N/A', ['accessToken', 'payload', 'sub'], session);
      const response = await GetLeads(organizationRecordKey);
      const leadsArr = response.data.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
      setLeads(leadsArr)
      setShownLeads(leadsArr)
      const failedItems = leadsArr.filter((lead) => lead.fileStatus === 'error' || lead.fileStatus === 'draft')
      setFailedItems(failedItems);
      setLoading(false);
    } catch (err) {
      handleAlert('error', 'Algo salió mal');
    }
  }

  const editLead = async (editLead) => {
    try {
      const response = await EditLead([editLead]);
      if (response.success) {
        await getSavedLeads();
        handleAlert('success', 'Se ha guardado el prospecto con éxito')
      } else {
        handleAlert('error', 'Algo salió mal')
      }
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
    }
  }

  const editBatchLeads = async (leads) => {
    try {
      const response = await EditLead(leads);
      if (response.success) {
        handleAlert('success', 'Se han guardado los prospectos con éxito')
      } else {
        handleAlert('error', 'Algo salió mal')
      }
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
    }
  }

  return (
    <div className={classes.containerBackground}>
      {loading ?
        <div className={classes.loading}>
          <CircularProgress color="inherit" />
        </div>
        :
        <>
          <text className={classes.title}>
            Dispersión
          </text>
          <Box width={'80%'}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {/* <Tab label="Alta de usuarios" {...a11yProps(0)} /> */}
                <Tab label="Dispersión" {...a11yProps(0)} />
              </Tabs>
            </Box>
            {/* <TabPanel value={value} index={0}>
              <LeadsTable
                leads={leads}
                shownLeads={shownLeads}
                failedItems={failedItems}
                editLead={editLead}
                editBatchLeads={editBatchLeads}
                getSavedLeads={getSavedLeads}
              />
            </TabPanel> */}
            <TabPanel value={value} index={0}>
              <DispersionTable
                leads={leads}
                shownLeads={shownLeads}
                editLead={editLead}
                editBatchLeads={editBatchLeads}
                getSavedLeads={getSavedLeads}
                companyId={props.companyId}
              />
            </TabPanel>
          </Box>
        </>}
    </div>
  )

}

export default withSnackbar(Dispersion)

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  title: {
    margin: '2%  0 0 0',
    fontSize: '62px',
    width: '80% ',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  subtitle: {
    margin: '0 0 2% 0',
    fontSize: '50px',
    width: '80% ',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  searchContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '100%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '10px',
    margin: '20px 0px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '30%',
    alignItems: 'center',
    margin: '10px'
  },
  loading: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
}));