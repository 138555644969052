import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Modal from '@mui/material/Modal';
import PrimaryButton from '../primaryButton';

const ConfirmDeleteItemModal = (props) => {
  const classes = useStyles();
  return (
    <Modal
      onClose={props.onClose}
      open={props.open}
    >
      <div className={classes.modalContainer}>
        <text className={classes.title}>
          ¿Estas seguro que deseas eliminar a
        </text>
        <text className={classes.subtitle}>
          {props.item.firstName} {props.item.lastName} de la orden de pago?
        </text>
        <div className={classes.buttonContainer}>
          <PrimaryButton handleClick = {() => props.deleteItem(props.item)} text = "Si" color = "#000000"/>
          <PrimaryButton handleClick = {props.onClose} text = "No" color = ""/>
        </div>
      </div>
    </Modal>
  );
};

export default withSnackbar(ConfirmDeleteItemModal);

const useStyles = makeStyles(theme => ({
  modalContainer: {
    width: '50%',
    background: "#FFFFFF",
    margin: '20% auto',
    padding: '2%',
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'column',
    borderRadius: 20,
  },
  title: {
    ...theme.typography.title,
    textAlign: 'center'
  },
  subtitle: {
    ...theme.typography.subtitle,
    fontWeight: '500',
    textAlign: 'center'
  },
  buttonContainer: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    gap: 30
  },
  button: {
    backgroundColor: '#000',
    width: '100%',
    padding: 15,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50px',
  },
  buttonLabel: {
    color: 'white',
    textDecoration: 'none'
  },
  noBackground: {
    backgroundColor: 'transparent',
    margin: '5% 0'
  }
}));
