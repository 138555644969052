import React from 'react'
import { makeStyles } from '@mui/styles';
import Background from '../../../assets/images/backgroundComingSoon.svg'
import ComingSoonIcon from '../../../assets/images/icons/ComingSoon.svg'
import LinkedInIcon2 from '../../../assets/images/icons/linkedin.svg'

import LinkedInIcon from '@mui/icons-material/LinkedIn';

const useStyle = makeStyles(theme => ({
    containerBackground: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        margin: 0,
        backgroundPosition: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: theme.palette.primary.main
    },
    title: {
        ...theme.typography.title,
        color: theme.palette.white.main,
        fontSize: 80,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 40,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
        },
    },
    subtitle: {
        ...theme.typography.mediumText,
        color: theme.palette.white.main,
        fontSize: 22,
        fontWeight: '400',
        textAlign: 'justify',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
        },
        maxWidth: 1200,
        marginTop: 20
    },
    lastTitle: {
        ...theme.typography.mediumText,
        color: theme.palette.white.main,
        fontSize: 22,
        fontWeight: '400',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
        },
        marginTop: 20,
    },
    imgIcon: {
        width: '20%'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10%',
        [theme.breakpoints.down('md')]: {
            margin: '0 10%',
        },
    },
    socialMediaIcon: {
        marginTop: 20,
        background: theme.palette.white.main,
        borderRadius: 5,
        padding: '20%',
        width: '60%',
    }
}))

const UserComingSoon = () => {
    const classes = useStyle();
  return (
    <div className={classes.containerBackground}>
        <div className={classes.textContainer}>
            <img src={ComingSoonIcon} alt ="coming soon icon" className={classes.imgIcon}/>
            <h1 className={classes.title}>Enviamos un correo con</h1>
            <h1 className={classes.title}>los siguientes pasos</h1>
            <h5 className={classes.subtitle}>Gracias por registrarte en Flexeanalytics. A partir de este momento ya formas parte del grupo de usuarios que pueden vender sus datos anonimizados. 

Nosotros pondremos tu perfil de datos anonimizados a la venta con distintas empresas interesadas y estaremos notificándote por medio de Nerito app en cuanto tu perfil haya sido elegido. 

En esta misma comunicación te daremos el detalle de cuánto dinero recibirás por cada venta de datos. </h5>
            <h5 className={classes.lastTitle}>Saludos cordiales,</h5>
            <h5 className={classes.subtitle}>FlexeAnalytics</h5>
            <div>
                <a href="#">
                    <img 
                        src={LinkedInIcon2} 
                        alt ="LinkedIn icon" 
                        className={classes.socialMediaIcon}
                    />
                </a> 
            </div>
            <LinkedInIcon/>
        </div>
    </div>
  )
}

export default UserComingSoon