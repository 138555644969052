const getAvailableQuestions = async (phone) => {
  try {
    const questionnaire = await fetch(`${process.env.REACT_APP_URL_ENDPOINT_SURVEY}/getavailablequestion?phoneNumber=${phone}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY_SURVEY
        }
    })
    const response = await questionnaire.json()
    if(response.success){
        return {
          success: true,
          data: response.data
        }
    }
    else{
        return {
          success: false,
          data: response.data,
        }
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default getAvailableQuestions;