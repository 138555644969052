import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const createOrder = async (body) => {
  try {
    const result = await API.post(API_ANALYTICS, `/bidding-orders`, {
      body
    });
    return {
      success: true,
      data: result.data
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default createOrder;