import { API } from 'aws-amplify';
import { API_CHARTS } from '../constant';

const getChartsData = async (organizationRecordKey, period, organizationType) => {
  try {
    const result = await API.get(API_CHARTS, '/charts', {
      queryStringParameters : {
        organizationRecordKey: organizationRecordKey,
        intervalRecordKey: period,
        organizationType: organizationType || 'computo',
      }
    });
    return {
      success: true,
      data: result.data,
    };
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    };
  }
}

export default getChartsData;