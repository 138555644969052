import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const deleteOrderItem = async (orderRecordKey, itemId) => {
  try {
    const result = await API.del(API_ANALYTICS, `/bidding-orders/${orderRecordKey}/item/${itemId}`);
    return {
      success: true,
      data: result.data
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}


export default deleteOrderItem