import React, { useEffect } from 'react';
import * as R from 'ramda'

import Footer from '../components/footer/index';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import NavBar from '../components/navBar';
import Landing from './StaticPages/LandingScreens/Landing';
import CompanyRegister from './StaticPages/CompanyRegisterScreen';
import CompanyLogin from './StaticPages/CompanyLoginScreen';
import ForgotPassword from './StaticPages/ForgotPassword';
import CompanyInformation from './StaticPages/CompanyInformationScreen';
import CompanyRepresentative from './StaticPages/CompanyRepresentativeScreen';
import UserLogin from './StaticPages/UserLoginScreen';
import UserRegister from './StaticPages/UserRegisterScreen';
import UserRFC from './StaticPages/UserRFCScreen';
import UserComingSoon from './StaticPages/UserComingSoon';
import ConfirmCode from './StaticPages/ConfirmCodeScreen';
import TermsConditions from './StaticPages/TermsAndConditions';
import ReadTermsAndConditions from './StaticPages/ReadTermsAndConditions';
import ReadTermsAndConditions2 from './StaticPages/ReadTermsAndConditions2';
import ReadPrivacyPolicy from './StaticPages/ReadPrivacyPolicy';
import CompanyDataTypeScreen from './StaticPages/CompanyDataTypeScreen';
import LeadRegisterScreen from './StaticPages/LeadRegisterScreen';
import LeadInformation from './StaticPages/LeadInformationScreen';
import UserMessageScreen from './StaticPages/UserMessageScreen';
import CompanyDocument from './StaticPages/CompanyUploadFilesScreen';

import SelectPlan from './OrganizationPages/SelectPlanScreen';
import ComingSoon from './OrganizationPages/ComingSoon';
import WaitForContact from './OrganizationPages/WaitForContact';
import SelectProfiles from './OrganizationPages/SelectProfiles/index';
import UploadLeads from './OrganizationPages/UploadLeads';
import ViewLeads from './OrganizationPages/ViewLeads';
import DownloadAnalytics from './OrganizationPages/DownloadAnalytics';
import LandingUser from './OrganizationPages/LandingScreen';
import Success from './OrganizationPages/Success';
import Survey from './OrganizationPages/SurveyScreens';
import Charts from './OrganizationPages/Charts';
import NewQuestionnarie from './OrganizationPages/SurveyScreens/newQuestionnarie';
import LeadsProposals from './OrganizationPages/LeadsProposals';
import Results from './OrganizationPages/results';
import Dispersion from './OrganizationPages/dispersion';
import Payments from './OrganizationPages/Payments';

import OrganizationList from './AdminPages/OrganizationsList';
import OrganizationDetails from './AdminPages/organizationDetails';
import ManageTerms from './AdminPages/manageTerms';
// import ManagePrivacyPolicy from './AdminPages/managePrivacyPolicy';
import ManageDocuments from './AdminPages/ManageDocuments';
import UploadLegalDoc2 from '../components/uploadLegalDocSecond';
import BillingScreen from './AdminPages/BillingScreen';
import DefaultQuestions from './AdminPages/DefaultQuestionsScreen';
import NewQuestion from './AdminPages/DefaultQuestionsScreen/newQuestions';
import Orders from './OrganizationPages/Orders';
import EditOrder from './OrganizationPages/EditOrder';
import OrderInfo from './OrganizationPages/OrderInfo';

const Home = (props) => {
  const location = useLocation();

  const [cookies] = useCookies(['lastAction', 'user']);
  const { user } = cookies;
  const companyIdUL = R.pathOr(null, ['sub'], user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  /*
    Falta usar el isAuthenticated en cada request que se haga al servidor
    para evitar que solo las cookies sean un paso de una sesion activa
  */
  return (
    <>
      <NavBar user={user} />
      <div style={{ zIndex: '-1' }}>
        <Routes>
          {!user ?
            <>
              <Route path="/success" element={<Success />} />
              <Route path="/userLogin" element={<UserLogin />} />
              {/* <Route path="/userRegister" element={<UserRegister />} /> */}
              <Route path="/userRegister" element={<LeadRegisterScreen />} />
              <Route path="/userInformation" element={<LeadInformation />} />
              <Route path="/companyLogin" element={<CompanyLogin />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/userMessage" element={<UserMessageScreen />} />
              <Route path="/companyInformation" element={<CompanyInformation />} /> {/* con usuario */}
              <Route path="/companyRepresentative" element={<CompanyRepresentative />} /> {/* con usuario */}
              <Route path="/companyDocuments" element={<CompanyDocument />} /> {/* con usuario */}
              <Route path="/companyRegister" element={<CompanyRegister />} /> {/* con publica */}
              <Route path="/companyDataType" element={<CompanyDataTypeScreen />} /> {/* con usuario */}
              <Route path="/userRfc" element={<UserRFC />} />   {/* con usuario */}
              <Route path="/userComingSoon" element={<UserComingSoon />} />   {/* con usuario */}
            </>
            :
            user['custom:group'] === 'ORG' ?
              <>
                <Route path="/" element={<LandingUser />} />
                <Route path="/comingSoon" element={<ComingSoon />} />
                <Route path="/waitContact" element={<WaitForContact />} />
                <Route path="/selectProfile" element={<SelectProfiles />} />
                <Route path="/uploadLeads" element={<UploadLeads />} />
                <Route path="/viewLeads" element={<ViewLeads />} />
                <Route path="/bidding" element={<LeadsProposals />} />
                <Route path="/downloadAnalytics/companyId=:companyId" element={<DownloadAnalytics />} />
                <Route path="/downloadAnalytics" element={<Navigate replace to={`/downloadAnalytics/companyId=${companyIdUL}`} />} />
                <Route path="/survey/companyId=:companyIdUL" element={<Survey />} />
                <Route path="/survey" element={<Navigate replace to={`/survey/companyId=${companyIdUL}`} />} />
                <Route path="/newQuestionnarie" element={<NewQuestionnarie />} />
                <Route path="/charts" element={<Charts />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/orders/edit" element={<EditOrder />} />
                <Route path="/orders/info" element={< OrderInfo />} />
                <Route path="/results" element={<Results />} />
                <Route path="/dispersion" element={<Dispersion companyId={user.sub} />} />
                <Route path="/pagos" element={<Payments />} />
                <Route path="*" element={<Navigate replace to={"/"} />} />
              </>
              : user['custom:group'] === undefined &&
              <>
                <Route path="/admin/organizationList" element={<OrganizationList />} />
                <Route path="/admin/organizationDetails" element={<OrganizationDetails />} />
                <Route path="/admin/orders/edit" element={<EditOrder />} />
                <Route path="/admin/orders/info" element={<OrderInfo />} />
                {/* <Route path="/admin/manageTerms" element={<ManageTerms />} /> */}
                <Route path="/admin/manageTerms" element={<UploadLegalDoc2 />} />
                <Route path="/admin/manage" element={<ManageDocuments />} />
                <Route path="/admin/billing" element={<BillingScreen />} />
                <Route path='/admin/questions' element={<DefaultQuestions />} />
                <Route path='/admin/newQuestions' element={<NewQuestion />} />
              </>
          }
          <Route path="/" element={<Landing />} />
          <Route path="/comingSoon" element={<ComingSoon />} />
          <Route path="/confirmCode" element={<ConfirmCode />} />
          {/* <Route path="/termConditions" element={<ReadTermsAndConditions />} /> */}
          <Route path="/termConditions" element={<ReadTermsAndConditions2 />} />
          <Route path="/privacyPolicy" element={<ReadPrivacyPolicy />} />
          {/* <Route path="/plans" element={<SelectPlan />} /> */}
          <Route path="/Success" element={<Success />} />
          <Route path="*" element={<Navigate replace to={"/"} />} />
        </Routes>
      </div>
      <Footer />
    </>
  )
};

export default Home;
