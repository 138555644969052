import React from 'react'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles';
import Background from '../../../../assets/images/backgroundContactForm.svg'
import ContactForm from '../../../../components/contactForm'

const useStyle = makeStyles(theme => ({
    container: {
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        padding: '100px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            padding: 0,
        },
    },
    contactContainer: {
        width: '50%',
        marginTop: '70px',
        [theme.breakpoints.down('md')]: {
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
        },
    },
    titleContainer: {
        display: 'flex',
        width: '50%',
        justifyContent: 'center',
        marginTop: '70px',
        [theme.breakpoints.down('md')]: {
            paddingTop: '100px',
            width: '100%',
            alignItems: 'center',

        },
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 62,
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
        },
    },
    subtitle: {
        color: theme.palette.primary.main,
        fontSize: 30,
        fontWeight: 'normal'
    },
    titleDescriptionContainer: {
        width: '80%',
        marginTop:'20%',
    }

}))

const ContactsForm = () => {
    const classes = useStyle();

    return (
        <div id = "Contacts" className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.titleDescriptionContainer}>
                    <h2 className={classes.title}>¿Tienes más preguntas?</h2>
                    <h3 className={classes.subtitle}>Contáctanos para resolver todas tus dudas.</h3>
                </div>
            </div>
            <div className={classes.contactContainer}>
              <h2 className={classes.title}>Contacto</h2>
                <ContactForm />
            </div>
        </div>
    )
}

export default ContactsForm