import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import DeteleQuestion from '../../lib/Analytics/deleteQuestion';

import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import ModalQuestion from '../../components/surveysComponents/modals/modalQuestion';
import ModalDelete from '../../components/surveysComponents/modals/modalQuestionDelete';
import { Box, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, TablePagination } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import GetQuestions from '../../lib/Analytics/getQuestions';

const DetailQuestionnaire = (props) => {
  const classes = useStyle();


  useEffect(() => {
    getQuestions(props.selectedQuestionnarie)
  }, [])

  const companyId = props.companyId;
  const from = props.from;
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [questionInfo, setQuestionInfo] = useState({ options: [] });
  const [questionDelete, setQuestionDelete] = useState({ id: '', question: '', countResponses: '' });
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getQuestionType = (type) => {
    switch (type) {
      case 1:
        return 'Texto libre';
      case 2:
        return 'Selección única';
      case 3:
        return 'Selección múltiple';
      default:
        return '';
    }
  }

  const getResponses = (options, responses) => {
    const newArray = options.map(option => {
      const newResponses = responses.filter(response => response.optionId === option.optionId)[0]
      return {
        ...option,
        ...newResponses
      }
    })
    return newArray.map((item, index) => (
      <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={index}>
        <p style={{ textAlign: 'left' }}>{item.descripcion}</p>
        <p>{item.count}</p>
      </div>
    ))
  }

  const setDeleteQuestion = (question) => {
    setQuestionDelete({ id: question.questionId, question: question.questionText, countResponses: question.quantityResponses });
    setOpenModalDelete(true)
  }

  const setEditQuestion = (question) => {
    setQuestionInfo(question);
    setOpenModal(true)
  }

  const deleteQuestion = async (question) => {
    try {
      const result = await DeteleQuestion(questionDelete.id);
      if (result.success) {
        setOpenModalDelete(false)
        handleAlert('success', result.message)
        getQuestions(props.selectedQuestionnarie)
      } else {
        handleAlert('error', result.message)
      }
    } catch (err) {
      handleAlert('error', err.message)
    }
  }

  const getQuestions = async (questionnarie) => {
    setLoading(true)
    const result = await GetQuestions(questionnarie.intervalRecordKey)
    setQuestions(result.data)
    props.setItemsView(result.data)
    setLoading(false)
  }

  const table = () => {

    let itemsView = props.listSearch.length > 0 ? props.listSearch : questions

    return (
      itemsView.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
        <TableRow key={index}>
          <TableCell>{item.questionText}</TableCell>
          <TableCell align="center">{getQuestionType(item.questionType)}</TableCell>
          <TableCell align="center">{item.quantityResponses}</TableCell>
          <TableCell align="center">
            {item.questionType === 1 ?
              <p>Pregunta de texto libre</p>
              :
              getResponses(item.options, item.response)
            }
          </TableCell>
          <TableCell align="center">
            {item.available ?
              <CheckCircleIcon sx={{ fontSize: 20, color: '#40AB74' }} />
              :
              <CloseIcon sx={{ fontSize: 20, color: '#CC3C3C' }} />}
          </TableCell>
          {from !== 'admin' &&
            <TableCell align="center">
              <button className={classes.buttonInfo} onClick={() => setEditQuestion(item)}>
                <EditIcon />
              </button>
              {item.quantityResponses < 1 &&
                <button className={classes.buttonDelete} onClick={() => setDeleteQuestion(item)}>
                  <DeleteIcon />
                </button>}
            </TableCell>
          }
        </TableRow>
      ))
    )
  }

  return (
    <>
      {
        loading ?
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
          :
          <Box marginBottom={'30px'} className={from === 'admin' ? classes.tableContainerAdmin : classes.tableContainer}>
            <ModalQuestion
              open={openModal}
              onClose={() => setOpenModal(false)}
              companyId={companyId}
              questionInfo={questionInfo}
              getQuestions={getQuestions}
              selectedQuestionnarie={props.selectedQuestionnarie}
            />
            <ModalDelete
              open={openModalDelete}
              data={questionDelete}
              delete={() => deleteQuestion(questionDelete)}
              handleClose={() => setOpenModalDelete(false)}
              intervalRecordKey={props.intervalRecordKey}
              text = {`¿Estás seguro de eliminar la pregunta ${questionDelete.question}?`}

            />
            <TableContainer className={classes.table}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.th} sx={{ width: '20%' }}>Pregunta</TableCell>
                    <TableCell className={classes.th} align="center" sx={{ width: '10%' }}>Tipo de respuesta</TableCell>
                    <TableCell className={classes.th} align="center" sx={{ width: '10%' }}>Cantidad de respuestas</TableCell>
                    <TableCell className={classes.th} align="center" sx={{ width: '30%' }}>Respuestas de usuario</TableCell>
                    <TableCell className={classes.th} align="center" sx={{ width: '10%' }}>Disponibilidad</TableCell>
                    {from !== 'admin' &&
                      <TableCell className={classes.th} align="center" sx={{ width: '20%' }}>Acciones</TableCell>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table()}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={questions.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage='Registros por hoja'
            />
          </Box>
      }
    </>
  )
}

export default withSnackbar(DetailQuestionnaire);


const useStyle = makeStyles(theme => ({
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '80%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    paddingBottom: '2%',
    overflowX: 'auto',
    margin: '0 auto',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },
  tableContainerAdmin: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '100%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    paddingBottom: '2%',
    overflowX: 'auto',
    margin: '0 auto',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  thead: {
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.white.main,
    padding: 15,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
      borderBottom: `3px solid ${theme.palette.gray.main}`,
      boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)'
    },
  },
  hiddenColumns: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  buttonFilter: {
    border: 'none',
    padding: '12px 16px',
    fontSize: '16px',
    background: 'transparent',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  statusStyle: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: 5,
    paddingBottom: 5,
    background: '#5D5D60',
    borderRadius: 5,
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '10%'
    },
  },
  statusText: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 10,
    textTransform: 'uppercase',
    color: '#FFFFFF'
  },
  buttonResponse: {
    border: 'none',
    padding: '5px 10px',
    fontSize: '16px',
    background: '#EDEDED',
    borderRadius: '50%'
  },
  buttonInfo: {
    border: 'none',
    color: theme.palette.blue.main,
    padding: '12px 16px',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
    fontWeight: 'bold'
  },
  buttonDelete: {
    border: 'none',
    color: theme.palette.red.main,
    padding: '12px 16px',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'transparent',
  },
  available: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  response: {
    fontWeight: 'bold'
  },
  button: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.darkGray.main}`,
    color: theme.palette.white.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
  }
}))