import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { CheckBox } from '../checkBox';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { REGEX_PHONE, REGEX_EMAIL } from "../../lib/constant";
import ReCAPTCHA from "react-google-recaptcha";

import saveUser from '../../lib/Analytics/saveUser2';

const useStyle = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.625rem',
    marginBottom: '6.25rem',
    paddingTop: 50,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 20,
      zIndex: 0,
      marginLeft: '-5%'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-10%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  containerButton: {
    backgroundColor: theme.palette.primary.main,
    width: '14.063rem',
    height: '3.125rem',
    borderRadius: '4.25rem',
    '&:hover': {
      cursor: 'pointer'
    },
    width: '60%',
    margin: '6% auto',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  labelButton: {
    color: theme.palette.white.main,
    fontSize: 16
  },
  inputs: {
    width: '100%',
    marginBottom: '20px !important'
  },
  phoneContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '5%',
  },
  selectContainer: {
    width: '22%',
    marginRight: '3%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
  },
  inputPhone: {
    width: '90%',
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    fontWeight: 600,
  },
  checkRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  captcha: {
    marginTop: 20,
  },
  passwordLabel: {
    color: '#878787',
    fontSize: '12px',
    marginBottom: '20px'
  },
}));

const UserRegisterForm = (props) => {
  const classes = useStyle(props);
  let navigate = useNavigate();

  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('');
  const [smartphone, setSmartphone] = useState(false);
  const [captcha, setCaptcha] = useState(true);
  const [age, setAge] = useState(18)
  const [name, setName] = useState('')

  const handlePhoneChange = (value) => {
    if (REGEX_PHONE.test(value)) {
      setPhone(value);
    }
  }

  const handleErrors = () => {
    let error = {};
    if (name === '') {
      error.name = "Favor de ingresar un correo electrónico";
    }

    if (email === '') {
      error.email = "Favor de ingresar un correo electrónico";
    } 

    if (!REGEX_EMAIL.test(email)) {
      error.emailSyntax = "Favor de ingresar un correo electrónico válido";
    } 

    if (phone === '') {
      error.phone = "Favor de ingresar un número de teléfono";
    } 

    if (!REGEX_PHONE.test(phone)) {
      error.emailSyntax = "Favor de ingresar un número de teléfono válido";
    } 

    if (!captcha) {
      error.privacy = "Favor de confirmar que no eres un robot";
    }

    if (age < 18) {
      error.age = "Debes ser mayor de edad";
    }
    return error;
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const onChangeCaptcha = (value) => {
    setCaptcha(value);
  }

  const register = async (event) => {
    event.preventDefault();
    const error = handleErrors();
    console.log("error ",error);
    if (Object.keys(error).length === 0) {
      //Handle Register
      const items = {
        name,
        phone,
        email,
        age,
        smartphone
      }
      const status = await saveUser(items);
      if (status.success) {
        navigate('/userMessage');
      } else {
        handleAlert('error', status.error);
      }
    }
    else {
      for (const value in error) {
        handleAlert('error', error[value]);
      }
    }
  }

  return (
    
    <div className={classes.containerForm}>
      <form className={classes.form}>
        <div>
          <TextField
            label="Nombre"
            variant="outlined"
            name='name'
            id="name"
            required
            inputProps={{ maxLength: 50 }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            className={classes.inputs}
          />
          <TextField
            label="Teléfono Celular"
            variant="outlined"
            name='phone'
            id="phone"
            required
            inputProps={{ maxLength: 10 }}
            value={phone}
            onChange={(event) => handlePhoneChange(event.target.value)}
            className={classes.inputs}
          />
          <TextField
            label="Correo Electrónico"
            variant="outlined"
            name='email'
            id="email"
            required
            inputProps={{ maxLength: 50 }}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className={classes.inputs}
          />

          <TextField
            label="Edad"
            variant="outlined"
            name='age'
            id="age"
            required
            type="number"
            inputProps={{ min:18, max: 90 }}
            value={age}
            onChange={(event) => setAge(event.target.value)}
            className={classes.inputs}
          />

        </div>
        <div className={classes.checkContainer}>
          <div className={classes.checkRow}>
            <CheckBox
              description="¿Tu celular es un smartphone?"
              label="smartphone"
              check={smartphone}
              onChange={() => setSmartphone(!smartphone)}
            />
              {/* <p>¿Tu celular es un smartphone?</p> */}
          </div>

          <div className={classes.captcha}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
              onChange={onChangeCaptcha}
            />
          </div>

        </div>
        <button className={classes.containerButton} onClick={register}>
          <p className={classes.labelButton}>Enviar</p>
        </button>
      </form>
    </div>
  );
}

export default withSnackbar(UserRegisterForm);