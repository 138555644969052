import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Background from '../../../assets/images/backgroundGrayyBottom.svg'
import * as R from 'ramda';
import CircularProgress from '@mui/material/CircularProgress';
import OrganizationLeads from '../../../components/organizationLeads';
import SearchBar from '../../../components/searchBar';

const LeadsProposals = (props) => {
  const classes = useStyle();

  const [loading, setLoading] = useState(true);
  const [organizationRecordKey, setOrganizationRecordKey] = useState('')

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    setLoading(true);
    const session = await Auth.currentSession();
    const organizationRecordKey = R.pathOr('N/A', ['accessToken', 'payload', 'sub'], session);
    setOrganizationRecordKey(organizationRecordKey);
    setLoading(false);
  }

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="inherit" />
      </div>
    )
  }

  return (
    <div className={classes.containerBackground}>
      <text className={classes.title}>
        Prospectos guardados
      </text>
      <text className={classes.subtitle}>
        Ofertas sugeridas por tipo de perfil
      </text>
      <div style={{ width: '90%' }}>
        <OrganizationLeads organization={organizationRecordKey} from='organizationUser' />
      </div>
    </div >
  )
}

export default withSnackbar(LeadsProposals);

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  loading: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    margin: '2%  0 0 0',
    fontSize: '62px',
    width: '80% ',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  subtitle: {
    margin: '0 0 2% 0',
    fontSize: '50px',
    width: '80% ',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
}));