import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Home from './screens/Home';
import theme from './styles/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={4}>
        <Router>
          <Home/>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
