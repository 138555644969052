import React from 'react';
import { makeStyles } from '@mui/styles';
import BackgroundComplete from '../../../../assets/images/backgroundComplete.svg'
import Phone from '../../../../assets/images/smarthPhone.png'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SaveIcon from '@mui/icons-material/Save';
import ConstructionIcon from '@mui/icons-material/Construction';

const useStyle = makeStyles(theme => ({
    containerBackground: {
        display: 'flex',
        background: `url(${BackgroundComplete}) no-repeat center center`,
        backgroundSize: 'cover',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    containerText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '50%',
    },
    title: {
        ...theme.typography.title,
        fontSize: 62,
        color: theme.palette.white.main,
        textAlign: 'left',
        width: '100%',
        lineHeight: 1,
        marginBottom: 10,

        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
        },
    },
    descriptionTitle: {
        ...theme.typography.mediumText,
        fontSize: 18,
        fontWeight: 'normal',
        color: theme.palette.white.main,
        textAlign: 'justify',
        width: '60%',
        [theme.breakpoints.down('md')]: {
            width: '100%',

        },
    },
    containerTextBottom: {
        display: 'flex',
        flexDirection: 'column',
        margin: '100px 0',
        alignItems: 'center',
        width: '80%',
        [theme.breakpoints.down('md')]: {
            marginTop: '100px',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '200px',
        },
    },
    services: {
        ...theme.typography.serviceText
    },
    servicesText: {
        width: '50%',
        fontSize: '60px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            fontSize: '40px',
        },
    },
    descriptionServices: {
        ...theme.typography.subtitle,
        fontSize: '1.5rem',
        color: '#5D5D60',
        fontWeight: 'normal',
        width: '100%',
        textAlign: 'justify'
    },
    containerCards: {
        marginTop: '50px',
        marginBottom: '50px',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    icon: {
        position: 'relative',
        top: '0.625rem'
    },
    imgPhone: {
        height: '50%',
        width: '90%'
    },
    imgPhoneContainer: {
        width: '50%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            width: '65%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    imgAndTextContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        height: '30%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '190px'
        },
    },
    servicesTextBottom: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    servicesContainer:{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '30%',
        padding: '0 40px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginTop: 20,
            padding: 0
        },
    },
    icons: {
        borderRadius:' 50%',
        background: theme.palette.lightGray.main,
        padding: 10,
        marginBottom: '5%'
    },
    description: {
        color: '#878787',
        textAlign: 'justify'
    },

}))

const BannerTop = () => {
    const classes = useStyle();

    return (
        <div id = "Loading" className={classes.containerBackground}>
            <div className={classes.imgAndTextContainer}>
                <div className={classes.imgPhoneContainer}>
                    <img className={classes.imgPhone} src={Phone} />
                </div>
                <div className={classes.containerText}>
                    <h2 className={classes.title}>FlexeAnalytics</h2>
                    <p className={classes.descriptionTitle}>
                        Es un servicio de reportes analíticos para mejorar la 
                        información con que cuentan los negocios respecto a los hábitos de compra, 
                        dinámicas del mercado y aspectos sobre la productividad laboral 
                        que son claves para mejorar la rentabilidad de un negocio.  
                    </p>
                </div>
            </div>
            <div id = "WhyUs" className={classes.containerTextBottom}>
                <div className={classes.servicesTextBottom}>
                    <h1 className={classes.servicesText}>
                        ¿Por qué nosotros?  
                    </h1>
                    <h5 className={classes.descriptionServices}>
                        Flexeanalytics además de hacer uso de información estructurada como la competencia, 
                        hacemos uso de explotación masiva de información no estructurada por medio de 
                        herramientas de Ciencia de Datos. 
                    </h5>
                </div>
                <div className={classes.containerCards}>
                    <div className={classes.servicesContainer}>
                        <SignalCellularAltIcon fontSize="large" className={classes.icons}/>
                        <h4>
                            Información
                            analítica
                        </h4>
                        <p className={classes.description}>
                            Utilizamos los principios de Big Data y Ciencia de Datos para procesar 
                            masivamente información de fuentes estructuradas y no estructuradas. <br/>
                            Para la empresa, es una inversión que te permitirá conocer información 
                            relevante de las preferencias y hábitos de tus posibles clientes, 
                            además de comprender como las preferencias y dinámicas laborales así 
                            como tendencias de utilización digital pueden impulsar 
                            la productividad de tu negocio. 

                        </p>
                    </div>

                    <div className={classes.servicesContainer}>
                        <PhoneIphoneIcon fontSize="large" className={classes.icons}/>
                        <h4>
                            Oportunidades de
                            monetización digital
                        </h4>
                        <p className={classes.description}>
                            En Flexeanalytics creemos en que tus datos son valiosos para diferentes 
                            actividades económicas y por tanto deben generarte ingresos. <br/>
                            Con nosotros, puedes formar parte de una base de datos anónima 
                            que permite integrar información estadística que al ser explotada 
                            te genere ingresos adicionales.
                        </p>
                    </div>

                    <div className={classes.servicesContainer}>
                        <SaveIcon fontSize="large" className={classes.icons}/>
                        <h4>
                            Privacidad e información
                            anonimizada
                        </h4>
                        <p className={classes.description}>
                            Proveerá todos los mecanismos informáticos y 
                            de seguridad de la información para garantizar 
                            la privacidad de los datos. 
                        </p>
                    </div>

                    <div className={classes.servicesContainer}>
                        <ConstructionIcon fontSize="large" className={classes.icons}/>
                        <h4> Tools Innovadoras de Inteligencia Artificial </h4>
                        <p className={classes.description}>
                            Somos pioneros en México en Ofrecer soluciones para empresas, 
                            basadas en Transformadores Generativos Pre-Entrenados o GPTs incluyendo el uso de Modelos de Lenguaje o LLMs 
                        </p>
                    </div>

                    
                </div>
            </div>
        </div>
    )
};

export default BannerTop;
