import React, { useState, useLayoutEffect, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Logo from '../../assets/images/Logo.svg'
import '../../styles/styles.css'
import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link as Scroll } from 'react-scroll'
import NavBarOrganization from '../navbarOrganization';
import NavBarAdmin from '../navbarAdmin';

const NavBar = (props) => {

    const user = props.user

    const classes = useStyle();
    const [menu, setMenu] = useState(false)
    const [screen, setScreen] = useState(false)

    useLayoutEffect(() => {
        window.addEventListener('resize', getScreenSize);
        getScreenSize();
        return () => window.removeEventListener('resize', getScreenSize);
    }, []);

    const getScreenSize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 899.95) {
            setScreen(false)
        } else {
            setScreen(true)
        }
    };

    return (
        <div className={classes.container}>
            {!user ?
                <>
                    <div className={classes.containerLogo}>
                        <Scroll to="Loading" spy={true} smooth={true}>
                            <NavLink to={'/'}>
                                <img src={Logo} alt='Logo' />
                            </NavLink>
                        </Scroll>
                    </div>

                    <button className={classes.menuBtn} onClick={() => setMenu(!menu)}>{menu ? <CloseIcon className={classes.menu} fontSize="large" /> : <MenuIcon className={classes.menu} fontSize="large" />}</button>
                    <nav className={screen ? classes.containerMenu : menu ? classes.containerMenu : classes.closeMenu}>
                        <Scroll to="Loading" spy={true} smooth={true}>
                            <NavLink to={'/'} className={classes.menu}>Inicio</NavLink>
                        </Scroll>
                        <Scroll to="Contacts" spy={true} smooth={true}>
                            <NavLink to={'/'} className={classes.menu}>Contacto</NavLink>
                        </Scroll>
                        <Scroll to="WhyUs" spy={true} smooth={true}>
                            <NavLink to={'/'} className={classes.menu}>¿Por qué nosotros? </NavLink>
                        </Scroll>

                        <NavLink className={classes.buttonSession} to={'/companyRegister'} >Registrate</NavLink>
                        <NavLink className={classes.menu} to={'/companyLogin'} >Iniciar Sesión</NavLink>
                    </nav>
                </>
                :
                user['custom:group'] === 'ORG' ?
                    <>
                        <NavBarOrganization />
                    </>
                    :
                    <>
                        <NavBarAdmin />
                    </>
            }
        </div>
    )

};

const useStyle = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: "5.438rem",
        position: 'sticky',
        top: 0,
        zIndex: 1,
        background: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            height: "10%",
        },
        zIndex: 999,
    },
    containerLogo: {
        background: theme.palette.white.main,
        width: '14.438rem',
        height: "5.438rem",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    containerMenu: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'row',
        width: '80%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'
        },
    },
    menu: {
        textDecoration: "none",
        color: theme.palette.white.main,
        '&:hover': {
            display: 'inline-block',
            borderBottom: '2px solid #5D5D60',
            paddingBottom: 2,
        }
    },
    buttonSession: {
        textDecoration: "none",
        color: theme.palette.primary.main,
        borderRadius: 50,
        backgroundColor: theme.palette.white.main,
        textAlign: 'center',
        padding: '5px 20px',
        fontWeight: 'bold'
    },
    signUp: {
        color: theme.palette.white.main
    },
    loginContainer: {
        display: 'flex',
        width: '20%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
        },
    },
    menuBtn: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
            backgroundColor: 'transparent',
            textAlign: 'center',
        },
    },
    closeMenu: {
        display: 'none'
    },
}))

export default NavBar;
