import { API } from 'aws-amplify';
import { API_QUESTIONS } from '../constant';

const editQuestion = async (questionId,item) => {
  try {
    const result = await API.put(API_QUESTIONS,`/${questionId}`,{
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY
        },
        body: {
            item: item
        }
    });
    return {
      success: result.success,
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default editQuestion;