import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { Box, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, TablePagination, Typography, Grid } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Refresh } from '@material-ui/icons';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import { CSVLink, CSVDownload } from "react-csv";
import removeAccents from '../../../lib/removeAccents';
import csvtojsonV2 from 'csvtojson';
import Background from '../../../assets/images/backgroundGrayyBottom.svg';
import {
  REGEX_EMAIL,
  REGEX_LETTERS,
  REGEX_PHONE,
  MIN_BIDDING,
  MAX_BIDDING,
  ORDERS_STATUS,
  SHOW_CARD_DEPOSIT_INFO,
} from "../../../lib/constant";
import * as R from 'ramda';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import moment from 'moment';
import saveBatchBidding from '../../../lib/Bidding/saveBatchBidding';
import CircularProgress from '@mui/material/CircularProgress';
import getOrganization from '../../../lib/Analytics/getOrganization';
import getValidCurrentInterval from '../../../lib/Analytics/getValidateCurrentInterval'
import validateAmountInterval from '../../../lib/Analytics/validateAmountInterval'
import formatPayloadOrder from '../../../lib/Orders/formatOrderBody';
import PrimaryButton from '../../../components/primaryButton';
import editOrganization from '../../../lib/Analytics/editOrganization';
import ModalLeadsErrors from '../../../components/modalLeadsErrors';
import SearchBar from '../../../components/searchBar';
import theme from '../../../styles/theme';
import getOrder from '../../../lib/Orders/getOrder';
import getOrderItems from '../../../lib/Orders/getOrderItems';
import batchStampBills from '../../../lib/Billing/batchStampBills';
import downloadBills from '../../../lib/Billing/downloadBills';
import changeStatus from '../../../lib/Orders/changeStatus';

const OrderInfo = (props) => {
  const classes = useStyle();
  let navigate = useNavigate();
  const [cookies] = useCookies(['user']);

  const loc = useLocation();
  const state = loc.state;
  const { orderRecordKey, from } = state
  const companyId = cookies.user.sub;
  const [loading, setLoading] = useState(true);
  const [leads, setLeads] = useState([]);
  const [coment, setComent] = useState('');
  const [date, setDate] = useState('');
  const [shownLeads, setShownLeads] = useState([]);
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [search, setSearch] = useState('');
  const [failedItems, setFailedItems] = useState([]);
  const [modalErrorsVisible, setModalErrorsVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderInfo, setOrderInfo] = useState({});
  const [openRows, setOpenRows] = useState([]);

  const handleToggle = (index) => {
    setOpenRows((prevOpenRows) => {
      if (prevOpenRows.includes(index)) {
        return prevOpenRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevOpenRows, index];
      }
    });
  };

  useEffect(() => {
    initData()
  }, [])


  const initData = async (data) => {
    setLoading(true);
    try {
      const resOrder = await getOrder(orderRecordKey)
      const resItems = await getOrderItems(orderRecordKey)
      setLeads(resItems.data)
      setShownLeads(resItems.data)
      setOrderInfo(resOrder.data[0])
    } catch (error) {
      handleAlert('error', 'Algo salio mal')
    }
    setLoading(false)
  }

  const handleOnChange = (event) => {
    const { name, value, id, } = event.target
    switch (name) {
      case 'coment':
        setComent(value)
        break;
      case 'date':
        setDate(value)
        break;
      default:
        break;
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event) => {
    const filter = (event.target.value).toLowerCase();
    const filteredArr = leads.filter(item => getFullName(item).includes(filter));
    setSearch(filter);
    setShownLeads(filteredArr);
  }

  const getFullName = (item) => {
    const fullName = `${item.name} ${item.secondName} ${item.paternalLastName} ${item.maternalLastName}`
    return removeAccents(fullName).toLowerCase();
  }

  const setEdit = (edit, index) => {
    const newData = [...leads];
    newData[index].edit = edit;
    setLeads(newData)
    setShownLeads(newData)
  }

  const getType = (tempAmount) => {
    const amount = parseInt(tempAmount);
    if (amount >= 2000 && amount <= 6000) {
      return 'Adopter'
    } else if (amount >= 6001 && amount <= 12000) {
      return 'Evolver'
    } else if (amount >= 12001 && amount <= 25000) {
      return 'Explorer'
    } else {
      return 'N/A'
    }
  }

  const moneyFormat = (amount) => {
    let newAmount = amount;
    if (newAmount !== undefined) {
      if (typeof amount === 'string') {
        newAmount = parseFloat(amount)
      }
      const formatedAmount = newAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      })
      return formatedAmount
    } else {
      return '$0'
    }
  }

  const getTotal = () => {
    let total = 0;
    if (leads.length > 0) {
      leads.map((item) => {
        if (item.amount) {
          total = total + parseFloat(item.amount);
        } else {
          total = total + 0;
        }
      })
    }
    return total
  }

  const handleNewAmount = (index, amount) => {
    const newLeads = [...leads];
    newLeads[index].amount = amount;
    setLeads(newLeads);
  }

  const editLead = async (index, lead) => {
    try {
      setEdit(false, index)
      const editLead = { ...lead }
      props.editLead(editLead)
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
    }
  }

  const createCSV = (arrayToConvert) => {
    const csvData = [["nombre", "telefono", "propuesta"]]
    arrayToConvert.map((lead) => (
      csvData.push([
        (`${lead.name} ${lead.secondName} ${lead.paternalLastName} ${lead.maternalLastName}` || ''),
        (lead.phone || ''),
        (lead.amount || 0),
      ])
    ))
    setCsvData(csvData);
  }

  const getFile = async (e) => {
    setLoading(true);
    try {
      const file = e.target.files[0];
      const fileText = await file.text();
      const fileJson = await csvtojsonV2().fromString(fileText);
      const leadsArr = fileJson.map((item, index) => {
        const newAmount = parseFloat(item.propuesta);
        const oldLead = leads.filter(leadItem => leadItem.phone === item.telefono)[0]
        const lastAmount = oldLead.amount || 0
        return {
          ...oldLead,
          ...item,
          position: index + 1,
          lastAmount,
          newAmount,
        }
      });
      const status = verifyFile(leadsArr);
      if (status !== '') {
        handleAlert('error', status);
      } else {
        setLeads(leadsArr);
        setShownLeads(leadsArr);
        await saveBatchOffer(leadsArr);
        setFile(file);
      }
    } catch (err) {
      setFile(null)
      handleAlert('error', 'Algo Salio Mal');
    }
    setLoading(false);
  }

  const verifyFile = (leads) => {
    let errorMessage = '';
    for (const lead of leads) {
      if (R.isNil(lead.nombre) || lead.nombre === '') {
        errorMessage = `Campo "nombre" vacío (Renglón: ${lead.position})`;
        break;
      } else if (!REGEX_LETTERS.test(lead.nombre)) {
        errorMessage = `Error de formato en campo "nombre" (Renglón: ${lead.position})`;
        break;
      } else if (R.isNil(lead.telefono) || lead.telefono === '') {
        errorMessage = `Campo "telefono" vacío (Renglón: ${lead.position})`;
        break;
      } else if (!REGEX_PHONE.test(lead.telefono)) {
        errorMessage = `Error de formato en campo "telefono" (Renglón: ${lead.position})`;
        break;
      } else if (R.isNil(lead.propuesta) || lead.propuesta === '') {
        errorMessage = `Campo "propuesta" vacío (Renglón: ${lead.position})`;
        break;
      } else if (isNaN(parseFloat(lead.propuesta))) {
        errorMessage = `Campo "propuesta" debe ser un número (Renglón: ${lead.position})`;
        break;
      } else if (parseFloat(lead.propuesta) < MIN_BIDDING || parseFloat(lead.propuesta) > MAX_BIDDING) {
        errorMessage = `Campo "propuesta" debe estar en un rango entre 2,000.00 a 25,000.00 (Renglón: ${lead.position})`;
        break;
      }
    }
    return errorMessage;
  }

  const cleanFileInput = (event) => {
    event.target.value = null
  }


  const saveBatchOffer = async (shownLeads) => {
    setLoading(true);
    if (shownLeads.length > 0) {
      try {
        const purgedLeads = shownLeads.map(item => ({
          leadRecordKey: item.leadRecordKey,
          newAmount: item.newAmount,
          lastAmount: item.lastAmount,
          userType: getType(item.newAmount)
        }));
        const response = await saveBatchBidding(companyId, purgedLeads, 'offered');
        if (response.success) {
          if (response.failedItems.length > 0) {
            const newFailedItems = response.failedItems.map(item => {
              const oldItem = leads.filter(lead => lead.leadRecordKey === item.leadRecordKey)[0]
              return {
                ...item,
                ...oldItem,
              }
            })
            setFailedItems(newFailedItems)
            handleAlert('warning', 'Algunos prospectos no se guardaron correctamente, errores.csv')
            setModalErrorsVisible(true)
          }
          else {
            handleAlert('success', 'Prospectos guardados con éxito')
            props.getSavedLeads();
          }
        }
        else {
          handleAlert('error', 'Algo salió mal');
        }
      } catch (err) {
        handleAlert('error', 'Algo salió mal');
      }
    } else {
      handleAlert('warning', 'Selecciona al menos un prospecto para continuar');
    }
    setLoading(false);
  }

  const editStatus = async (index, item) => {
    try {
      let lead = item;
      if (lead.elegible != undefined) {
        lead.elegible = !lead.elegible
      } else {
        lead.elegible = false
      }
      const edited = await editLead(index, lead, 'leads')

    } catch (error) {
      handleAlert('error', 'Algo salió mal');


    }
  }

  const getText = (item) => {
    let text;

    if (item.elegible != undefined) {
      text = item.elegible ? 'Desactivar' : 'Activar'
    } else {
      text = 'Desactivar'
    }
    return text
  }

  const stampBills = async () => {
    try {
      setLoading(true);
      const period = orderInfo.period.split('/');
      const serviceDate = `${period[1]}-${period[0]}-28`;
      const response = await batchStampBills(orderRecordKey, serviceDate);
      initData();
    } catch (err) {
      handleAlert('error', err.message);
    } finally {
      setLoading(false);
    }
  }

  const downloadStampedBills = async () => {
    try {
      setLoading(true);
      const result = await downloadBills(orderRecordKey);
      const url = result?.data?.signedURL;
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      handleAlert('error', err.message);
    } finally {
      setLoading(false);
    }
  }

  const totalRecivedTransfers = (items) => {
    const sum = items.reduce((acc, item) => {
      return acc + item.monto
    }, 0)
    return sum
  }

  const handleChangeStatus = async (orderRecordKey) => {
    try {
      setLoading(true);
      const res = await changeStatus(orderRecordKey)
      if (res.success) {
        handleAlert('success', 'La orden se actualizo correctamente.');
        await initData()
      }
    } catch (error) {
      handleAlert('error', error.message);
    }
    setLeads(false)
  }

  const table = () => {
    if (shownLeads.length > 0) {
      return (
        shownLeads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <>
            <TableRow key={index} className={classes.tableHeader} sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => handleToggle(index)}
                >
                  {openRows.includes(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell className={classes.info} data-label="Name" align="left">{`${item.receiverName}`}</TableCell>
              <TableCell className={classes.info} data-label="destinationAccount" align="center">{item.destinationAccount || 'N/A'}</TableCell>
              <TableCell className={classes.info} data-label="phoneNumber" align="center">{item.phoneNumber}</TableCell>
              <TableCell className={classes.info} data-label="Clabe" align="center">
                {item.edit ?
                  <TextField
                    label="Oferta"
                    variant="outlined"
                    name='offer'
                    id="offer"
                    type="number"
                    value={item.amount}
                    onChange={(event) => handleNewAmount(index, event.target.value)}
                    className={classes.inputs}
                  /> :
                  item.amount ?
                    `${moneyFormat(item.amount || 0)}` :
                    `${moneyFormat(item.lastAmount || 0)}` || '$0'}
              </TableCell>
              <TableCell className={classes.info} data-label="phoneNumber" align="center">
                {ORDERS_STATUS[item.status]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                <Collapse in={openRows.includes(index)} timeout='auto' unmountOnExit>
                  <Box sx={{ marginTop: 2, marginBottom: 3, marginLeft: 3, marginRight: 3 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Datos
                    </Typography>
                    <Table size='small'>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography>
                              {`ID Banxico: ${item?.stp?.claveRastreo || 'N/A'}`}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {`Clave de Rastreo: ${item?.stpStateChanges?.claveRastreo || 'N/A'}`}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {`Estatus Banxico: ${item.stpStateChanges?.estado ? item.stpStateChanges.estado : item?.err ? 'ERROR' : 'N/A'}`}
                            </Typography>
                          </TableCell>
                          {item?.stpStateChanges?.estado === 'LIQUIDADO' && (
                            <TableCell>
                              <Typography>
                                {`Fecha Liquidación: ${item?.stpStateChanges?.tsLiquidacion ? new Date(Number(item?.stpStateChanges?.tsLiquidacion)).toLocaleString('sv') : 'N/A'}`}
                              </Typography>
                            </TableCell>
                          )}
                          {item?.stpStateChanges?.estado === 'DEVUELTO' && (
                            <TableCell>
                              <Typography>
                                {`Causa Devolución: ${item?.stpStateChanges?.causaDevolucion || 'N/A'}`}
                              </Typography>
                            </TableCell>
                          )}
                          {item?.err && (
                            <TableCell>
                              <Typography>
                                {item?.err?.message?.descripcionError && `Causa Error: ${item?.err?.message?.descripcionError}`}
                                {item?.err?.mensaje && `Causa Error: ${item?.err?.mensaje}`}
                              </Typography>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ))
      );
    }
  }


  return (
    <div className={classes.containerBackground}>
      {
        loading ?
          <div className={classes.loadingContainer}>
            <CircularProgress color="inherit" />
          </div>
          :
          <>
            <ModalLeadsErrors
              onClose={() => setModalErrorsVisible(false)}
              open={modalErrorsVisible}
              failedItems={failedItems}
            />
            <Grid item container sx={{
              width: '70%',
              boxShadow: 5,
              p: 4,
              borderRadius: '10px',
              margin: '30px',
              flexWrap: 'wrap',
              gap: '50px',
              justifyContent: { md: 'space-between', xs: 'flex-start' },
              backgroundColor: theme.palette.white.main,
            }}>
              <Grid item>
                <Typography>
                  Status:
                </Typography>
                <Typography>
                  {ORDERS_STATUS[orderInfo.status]}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Periodo:
                </Typography>
                <Typography>
                  {orderInfo.period}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Método de envío:
                </Typography>
                <Typography>
                  {orderInfo.sendMethod || 'N/A'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Comentarios:
                </Typography>
                <Typography>
                  {orderInfo.comments || 'N/A'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Total depositado:
                </Typography>
                <Typography
                  textAlign={'right'}
                >
                  {moneyFormat(totalRecivedTransfers(orderInfo.receivedTransfers))}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Total de la orden:
                </Typography>
                <Typography
                  textAlign={'right'}
                >
                  {moneyFormat(orderInfo.stpPayOrder?.detail.totalOrder || orderInfo.totalOrder)}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ width: '70%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {!orderInfo.stampingStatus && (
                <PrimaryButton width='300px' text='Facturar' color='black' handleClick={stampBills} />
              )}
              {orderInfo.stampingStatus === 'PROCESSING' && (
                <PrimaryButton width='300px' text='Facturación en Proceso...' color='black' />
              )}
              {orderInfo.stampingStatus === 'COMPLETE' && (
                <PrimaryButton width='300px' text='Descargar Facturas' color='black' handleClick={downloadStampedBills} />
              )}
              <IconButton sx={{ marginLeft: '20px', height: '50px' }} onClick={initData}>
                <Refresh fontSize='large' />
              </IconButton>
            </Box>
            {SHOW_CARD_DEPOSIT_INFO.indexOf(orderInfo.status) !== -1 &&
              < Grid item container sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{
                  bgcolor: 'background.paper',
                  boxShadow: 14,
                  p: 4,
                  borderRadius: '10px',
                  width: '70%',
                }}>
                  <Grid container gap={3} direction={'column'}>
                    <Typography variant='h5'>
                      Detalles de deposito
                    </Typography>
                    <Grid item container direction={'row'}>
                      <Grid item container direction={'column'} md={6} spacing={1}>
                        <Grid item>
                          <Typography>
                            CLABE
                          </Typography>
                          <Typography>
                            {orderInfo.stpPayOrder.clabe}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            Concepto del pago
                          </Typography>
                          <Typography>
                            {orderInfo.stpPayOrder.payOrderNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container direction={'column'} md={6} spacing={1}>
                        <Grid item container direction={'row'}>
                          <Grid item md={3}>
                            <Typography>
                              Subtotal:
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography>
                              {orderInfo.stpPayOrder.detail.subtotalOrder}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container direction={'row'}>
                          <Grid item md={3}>
                            <Typography>
                              Comision:
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>
                              {orderInfo.stpPayOrder.detail.totalComision}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container direction={'row'}>
                          <Grid item md={3}>
                            <Typography>
                              IVA
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>
                              {orderInfo.stpPayOrder.detail.totalIVA}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container direction={'row'}>
                          <Grid item md={3}>
                            <Typography>
                              Total a pagar:
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>
                              {moneyFormat(orderInfo.stpPayOrder?.detail.totalOrder || orderInfo.totalOrder)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Typography>
                        Informacion importante:
                      </Typography>
                      <Typography>
                        Se debe hacer el pago con el concepto de pago proporcionado.
                      </Typography>
                      <Typography>
                        Se debe hacer el pago en su totalidad o parcial, la orden se dispersara al estar completamente pagada.
                      </Typography>
                    </Grid>
                    {from === 'admin' &&
                      <PrimaryButton
                        text={'Confirmar Depósito'}
                        handleClick={() => handleChangeStatus(orderInfo.orderRecordKey)}
                      />
                    }
                  </Grid>
                </Box>
              </Grid>
            }
            <Box marginBottom={'30px'} className={classes.tableContainer}>
              <TableContainer>
                <div className={classes.rowTotal}>
                  <div className={classes.total}>
                    <text className={classes.totalText}>Total de prospectos</text>
                    <text className={classes.totalTextBold}>{leads.length}</text>
                  </div>
                  <div className={classes.total}>
                    <text className={classes.totalText}>Monto total de subasta</text>
                    <text className={classes.totalTextBold}>{moneyFormat(getTotal())}</text>
                  </div>
                </div>
                <Table aria-label="collapsible table">
                  <TableHead className={classes.thead}>
                    <TableRow classes={classes.infoRow}>
                      <TableCell className={classes.th} align="left"></TableCell>
                      <TableCell className={classes.th} align="left">Nombre</TableCell>
                      <TableCell className={classes.th} align="center">Destino</TableCell>
                      <TableCell className={classes.th} align="center">Numero de telefono</TableCell>
                      <TableCell className={classes.th} align="center">Propuesta de pago</TableCell>
                      <TableCell className={classes.th} align="center">Estatus del pago</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table()}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={leads.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage='Registros por hoja'
                />
              </TableContainer>
            </Box>
          </>
      }
    </div >
  )
}

export default withSnackbar(OrderInfo)

const useStyle = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    marginTop: '2%'
  },
  containerBackground: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    backgroundPosition: 'center',
    alignItems: 'center',
    gap: 20,
    alignItems: 'center',
  },
  title: {
    fontSize: '62px',
    width: '80% ',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
    },
  },
  fileInputContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  fileInputContainer2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    width: '40%',
    marginTop: -100,
    marginBottom: -20,
    [theme.breakpoints.down('lg')]: {
      width: '60%',
      marginTop: -50,
      marginBottom: -20,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 0,
      marginBottom: -20,
    },
  },
  saveContainer: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '70%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '20px',
    overflowX: 'auto',
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  thead: {
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.white.main,
    padding: 15,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
      borderBottom: `3px solid ${theme.palette.gray.main}`,
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
    overflow: 'hidden',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fileContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '15px 0px',
    gap: '10px',
    justifyContent: 'space-between'
  },
  fileUploadButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // margin: '5px'
  },
  uploadLabel: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.darkGray.main}`,
    color: theme.palette.white.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
    height: 'fit-content',
    margin: '5px'
  },
  uploadLabelDisabled: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.gray.main,
    border: `2px solid ${theme.palette.darkGray.main}`,
    color: theme.palette.white.main,
    borderRadius: 20,
    padding: '5px 10px 5px 10px',
    ...theme.typography.input,
    height: 'fit-content',
    margin: '5px'
  },
  uploadInput: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    border: 0,
  },
  hint: {
    display: 'flex',
    ...theme.typography.subtitle,
    color: '#878787',
  },
  errorIcon: {
    width: '25px',
    height: '25px',
    backgroundColor: '#CC3C3C',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  halfContainer: {
    width: '50%',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0px 20px 0px 0px',
  },
  infoBold: {
    ...theme.typography.subtitle,
    fontWeight: '600',
    fontSize: '14px',
  },
  info: {
    ...theme.typography.subtitle,
    fontSize: '14px !important',
    textAlign: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '20%',
  },
  rowTotal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottom: `1px solid ${theme.palette.gray.main}`,
  },
  total: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '45%'
  },
  totalText: {
    fontSize: '28px',
  },
  totalTextBold: {
    fontSize: '28px',
    fontWeight: 'bold'
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '50px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  warning: {
    backgroundColor: 'white',
    color: 'red'
  },
  activeLead: {
    backgroundColor: 'red',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row'
  }
}));