import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Modal from '@mui/material/Modal';
import PrimaryButton from '../primaryButton';
import { CSVLink, CSVDownload } from "react-csv";

const ModalLeadsErrors = (props) => {
  const classes = useStyles();

  const [csvData, setCsvData] = useState([]);

  const createCSV = (arrayToConvert) => {
    if (props.from === 'biding') {
      const csvData = [["nombre", "telefono", "tipo de usuario", "propuesta"]]
      arrayToConvert.map((lead) => (
        csvData.push([
          (`${lead.name} ${lead.secondName} ${lead.paternalLastName} ${lead.maternalLastName}` || ''),
          (lead.phone || ''),
          (lead.userType || ''),
          (lead.amount || 0),
        ])
      ))
      setCsvData(csvData);
    } else if (props.from === 'leads') {
      const csvData = [["nombre", "segundo nombre", "apellido paterno", "apellido materno", "rfc", "correo", "clabe", "telefono", "error"]]
      arrayToConvert.map((lead) => (
        csvData.push([
          (lead.name || ''),
          (lead.secondName || ''),
          (lead.paternalLastName || ''),
          (lead.maternalLastName || ''),
          (lead.rfc || ''),
          (lead.email || ''),
          (lead.clabe || ''),
          (lead.phone || 0),
          (lead.error),
        ])
      ))
      setCsvData(csvData);
    }
    window.setTimeout(function(){
      props.onClose()
    }, 3000);
  }

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}
      style={{ overflow: 'scroll' }}
    >
      <div className={classes.modalContainer}>
        <text className={classes.title}>
          Algo salió mal
        </text>
        <text className={classes.subtitle}>
          Descarga el siguiente documento para ver los registros que no se pudieron procesar
        </text>
        <div className={classes.buttonContainer}>
          <button onClick={() => createCSV(props.failedItems)} className={classes.noBackground}>
            <CSVLink
              style={{
                backgroundColor: '#000',
                width: '100%',
                padding: 15,
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '50px',
                textDecoration: 'none'
              }}
              data={csvData}
              filename="errores.csv">
              <text className={classes.buttonLabel}>
                Descargar
              </text>
            </CSVLink>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default withSnackbar(ModalLeadsErrors);

const useStyles = makeStyles(theme => ({
  modalContainer: {
    width: '50%',
    background: "#FFFFFF",
    margin: '20% auto',
    padding: '2%',
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'column',
    borderRadius: 20,
  },
  title: {
    ...theme.typography.title,
    textAlign: 'center'
  },
  subtitle: {
    ...theme.typography.subtitle,
    fontWeight: '500',
    textAlign: 'center'
  },
  buttonContainer: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',

  },
  button: {
    backgroundColor: '#000',
    width: '100%',
    padding: 15,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50px',
  },
  buttonLabel: {
    color: 'white',
    textDecoration: 'none'
  },
  noBackground: {
    backgroundColor: 'transparent',
    margin: '5% 0'
  }
}));
