import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
    visibilityIcon: {
      background: 'transparent'
    },
    inputs: {
        width: '100%',
        marginBottom: '20px !important'
    },
}));

const PasswordInput = (props) => {
    const classes = useStyle();
    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false
    })

    const handleShowPassword = (event) => {
        event.preventDefault()

        if(props.name === "password"){
            setShowPassword({...showPassword,password:!showPassword.password})
        }
        else {
            setShowPassword({...showPassword,confirmPassword:!showPassword.confirmPassword})
        }
    }
    
  return (
        <TextField
            label={`${props.label}`}
            variant="outlined"
            name={`${props.name}`}
            id={`${props.name}`}
            required
            type={
                props.name === 'password' ?
                showPassword.password ? "text" : "password"
                :
                showPassword.confirmPassword ? "text" : "password"
            }
            value={props.value}
            onChange={props.handlerPassword}
            className={classes.inputs}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <button className={classes.visibilityIcon} onClick={(event) => handleShowPassword(event)}>
                    {
                        props.name === 'password' ?
                        showPassword.password ? <VisibilityIcon/> : <VisibilityOffIcon/>
                        :
                        showPassword.confirmPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>

                    }
                  {/* {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>} */}
                </button>
              </InputAdornment>,
            }}
        />
  ) 
}

export default PasswordInput