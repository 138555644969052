import {
  API_TERMS,
} from '../constant';
import { API, Storage } from 'aws-amplify';

const uploadTerms = async (document, documentType, documentName, maxDate, currentDate, progressCallback) => {
  try {

    const config = {
      contentType: documentType,
      level: process.env.REACT_APP_BUCKET_PUBLIC,
      progressCallback,
    };

    const result = await Storage.put(`legalDocs/${documentName}/${documentName + '_' + currentDate}`,
      document,
      config,
    );

    const response = await API.post(API_TERMS, `/legalDocs`,{ 
      body: {
        maxDate,
        version: currentDate,
        documentName,
      }
    })
    return {
      url: result,
      success: true,
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default uploadTerms;