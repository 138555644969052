import React, { useState, useLayoutEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Logo from '../../assets/images/Logo.svg'
import '../../styles/styles.css'
import { useNavigate, NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../styles/theme';
import LogOut from '../../lib/Auth/logout';
import { useCookies } from 'react-cookie';

const NavBarOrganization = (props) => {

    const user = props.user
    let navigate = useNavigate();


    const classes = useStyle();
    const [menu, setMenu] = useState(false)
    const [screen, setScreen] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['lastAction', 'user']);


    useLayoutEffect(() => {
        window.addEventListener('resize', getScreenSize);
        getScreenSize();
        return () => window.removeEventListener('resize', getScreenSize);
    }, []);

    const getScreenSize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 899.95) {
            setScreen(false)
        } else {
            setScreen(true)
        }
    };

    const logOut = async () => {
        const response = await LogOut();
        removeCookie('lastAction', { path: '/' });
        removeCookie('user', { path: '/' })
    }


    return (
        <div className={classes.container}>
            <div className={classes.containerLogo}>
                <NavLink to={'/'}>
                    <img src={Logo} alt='Logo' />
                </NavLink>
            </div>

            <button className={classes.menuBtn} onClick={() => setMenu(!menu)}>{menu ? <CloseIcon className={classes.menu} fontSize="large" /> : <MenuIcon className={classes.menu} fontSize="large" />}</button>
            <nav className={screen ? classes.containerMenu : menu ? classes.containerMenu : classes.closeMenu}>
                <NavLink to={'/pagos'} className={classes.menu}>Pagos</NavLink>
                <NavLink to={'/viewLeads'} className={classes.menu}>Prospectos</NavLink>
                <NavLink to={'/bidding'} className={classes.menu}>Subastas</NavLink>
                <NavLink to={'/dispersion'} className={classes.menu}>Dispersión</NavLink>
                {/* <NavLink to={'/survey'} className={classes.menu}>Cuestionario</NavLink> */}
                <p className={classes.menu} onClick={() => navigate('/survey')}>Cuestionarios</p>
                <NavLink to={'/downloadAnalytics'} className={classes.menu}>Reportes</NavLink>

                <div className={classes.menu} onClick={logOut}>
                    Cerrar Sesión
                </div>
            </nav>
        </div>
    )
};

const useStyle = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: "5.438rem",
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: "100%",
        background: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            height: "10%",
        },
    },
    containerLogo: {
        background: theme.palette.white.main,
        width: '14.438rem',
        height: "5.438rem",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    containerMenu: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'row',
        width: '80%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'
        },
    },
    menu: {
        textDecoration: "none",
        color: theme.palette.white.main,
        cursor: "pointer",
        '&:hover': {
            display: 'inline-block',
            borderBottom: '2px solid #5D5D60',
            paddingBottom: 2,
        }
    },
    buttonSession: {
        width: '100%',
        textDecoration: "none",
        color: theme.palette.primary.main,
        borderRadius: 50,
        backgroundColor: theme.palette.white.main,
        textAlign: 'center',
        padding: '5% 10%',
        fontWeight: 'bold'
    },
    signUp: {
        color: theme.palette.white.main
    },
    loginContainer: {
        display: 'flex',
        width: '20%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
        },
    },
    menuBtn: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
            backgroundColor: 'transparent',
            textAlign: 'center',
        },
    },
    closeMenu: {
        display: 'none'
    },
}))

export default NavBarOrganization;