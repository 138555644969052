import { API } from 'aws-amplify';
import { API_QUESTIONS } from '../constant';

const getQuestions = async () => {
  try {
    const result = await API.get(API_QUESTIONS, '/',{
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY
        }
    });
    
    return {
      success: true,
      data: result.data,
    }
  } catch (err) {
    
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default getQuestions;