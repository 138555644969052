import React from 'react'
import { makeStyles } from '@mui/styles';
import Background from "../../../assets/images/backgroundContactForm.svg";
import CompanyRepresentativeForm from '../../../components/companyRepresentativeForm';

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    margin: 0,
    backgroundPosition: 'bottom',
    alignItems: 'center',
  },
  registerContainer: {
    width: '60%'
  },
  title: {
    ...theme.typography.title,
    fontSize: 60,
    color: theme.palette.primary.main,
    textAlign: 'center',
    lineHeight: 1,
    margin: '5% 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
}));

const CompanyRepresentative = () => {
  const classes = useStyle();

  return (
    <div className={classes.containerBackground}>
      <div className={classes.registerContainer}>
        <h2 className={classes.title}>
          Representante Legal
        </h2>
        <CompanyRepresentativeForm />
      </div>
    </div>
  )
}

export default CompanyRepresentative;