import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableRow, TableCell, TableContainer, TableHead, IconButton, TablePagination, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PaidIcon from '@mui/icons-material/Paid';
import { useNavigate } from 'react-router';
import deleteOrder from '../../lib/Orders/deleteOrder';
import { ORDERS_STATUS } from '../../lib/constant';
import OrdersFilters from '../orderFilters.js';
import gerOrders from '../../lib/Orders/getOrders';
import confirmOrder from '../../lib/Orders/confirmOrder';
import payOrder from '../../lib/Orders/payOrder';
import changeStatus from '../../lib/Orders/changeStatus';
import formatPayloadConfirmOrder from '../../lib/formatPayloadConfirmOrder';

const Files = (props) => {
  const classes = useStyle();
  let navigate = useNavigate();

  const from = props.from;
  const [shownData, setShownData] = useState([]);
  const [data, setData] = useState([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    getAllOrders()
  }, [])

  const getAllOrders = async () => {
    try {
      setLoading(true)
      const order = await gerOrders(props.companyId)
      if (order.success) {
        setData(order.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
        setShownData(order.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
      }
    } catch (error) {
      props.handleAlert('error', 'Algo salio mal')
    }
    setLoading(false)
  }

  const handleConfirmOrder = async (e, order) => {
    try {
      e.stopPropagation();
      setLoading(true)
      const payload = formatPayloadConfirmOrder(order)
      const res = await confirmOrder(payload)
      if (res.success) {
        props.handleAlert('success', 'Orden confirmada exitosamente')
        if (from === 'admin') {
          navigate('/admin/orders/info', {
            state: {
              orderRecordKey: order.orderRecordKey,
              from
            }
          })
        } else {
          navigate('/orders/info', {
            state: {
              orderRecordKey: order.orderRecordKey
            }
          })
        }
      } else {
        props.handleAlert('error', 'Algo salio mal')
      }
    } catch (error) {
      props.handleAlert('error', 'Algo salio mal')
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToEdit = (e, item) => {
    e.stopPropagation();
    if (from === 'admin') {
      navigate('/admin/orders/edit', {
        state: {
          orderRecordKey: item.orderRecordKey
        }
      })
    } else {
      navigate('/orders/edit', {
        state: {
          orderRecordKey: item.orderRecordKey
        }
      })
    }

  }
  const goToInfo = (e, item) => {
    e.stopPropagation();
    if (from === 'admin') {
      navigate('/admin/orders/info', {
        state: {
          orderRecordKey: item.orderRecordKey,
          from
        }
      })
    } else {
      navigate('/orders/info', {
        state: {
          orderRecordKey: item.orderRecordKey
        }
      })
    }
  }

  const handleDeleteOrder = async (e, item) => {
    e.stopPropagation();
    props.setSelectedOrder(item)
    props.setOpenDeleteOrderModal(true)
  }

  // const handleConfirmOrder = async (e, item) => {
  //   e.stopPropagation();
  //   await props.confirmOrder(item.orderRecordKey)
  // }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handlePayOrder = async (e, order) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const res = await payOrder(order.orderRecordKey);
      if (res.success) {
        props.handleAlert('success', 'Orden enviada a pagar exitosamente');
        await delay(5000); // Espera 5 segundos
        await getAllOrders();
      } else {
        props.handleAlert('error', 'Algo salio mal');
      }
    } catch (error) {
      props.handleAlert('error', 'Algo salio mal');
    } finally {
      setLoading(false); // Asegúrate de establecer el estado de carga en false al final
    }
  };

  const moneyFormat = (amount) => {
    let newAmount = amount;
    if (newAmount !== undefined) {
      if (typeof amount === 'string') {
        newAmount = parseFloat(amount)
      }
      const formatedAmount = newAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      })
      return formatedAmount
    } else {
      return '$0'
    }
  }

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   setFormInputs({ ...formInputs, [name]: value });
  // };


  const files = () => {
    if (shownData.length > 0) {
      return (
        shownData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <>
            <TableRow key={index}
              onClick={item.status !== 'CREATED' ? (e) => goToInfo(e, item) : (e) => goToEdit(e, item)}
            >
              <TableCell component="th" scope="row" className={classes.info}>
                {item.period}
              </TableCell>
              {/* {from === 'admin' && <TableCell component="th" scope="row" className={classes.info}>
                {item.fileType === 'RP-OUT' ? 'Alta de usuarios' : item.fileType === 'PP-OUT' ? 'Dispersión' : ''}
              </TableCell>} */}
              <TableCell className={classes.info} align="center">{item.comments}</TableCell>
              <TableCell className={classes.info} align="right">{moneyFormat(item.totalOrder)}</TableCell>
              <TableCell className={classes.info} align="center">{ORDERS_STATUS[item.status]}</TableCell>
              <TableCell className={classes.info} align="center">
                {
                  (item.status === 'DEPOSITED_TOTALLY' && from === 'admin') &&
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={(e) => handlePayOrder(e, item)}
                  >
                    <PaidIcon />
                  </IconButton>

                }
                {item.status === 'CREATED' &&
                  <>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={(e) => handleConfirmOrder(e, item)}
                    >
                      <CheckCircleIcon />
                    </IconButton>

                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={(e) => goToEdit(e, item)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={(e) => handleDeleteOrder(e, item)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                }
              </TableCell>

            </TableRow >
          </>
        ))
      )
    }
  }

  return (
    <>
      {loading ?
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
        :
        <>
          <OrdersFilters
            orders={data}
            setOrders={setShownData}
          />
          {
            shownData.length > 0 ?
              <TableContainer className={classes.tableContainer}>
                <Table aria-label="collapsible table">
                  <TableHead className={classes.thead}>
                    <TableRow classes={classes.infoRow}>
                      <TableCell className={classes.th}>Periodo</TableCell>
                      {/* {from === 'admin' &&
                  <TableCell className={classes.th}>Tipo de archivo</TableCell>} */}
                      <TableCell className={classes.th} align="center">Comentario</TableCell>
                      <TableCell className={classes.th} align="right">Total de subasta</TableCell>
                      <TableCell className={classes.th} align="center">Estatus</TableCell>
                      <TableCell className={classes.th} align="center">Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files()}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={data.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage='Registros por hoja'
                />
              </TableContainer>
              :
              <div className={classes.tableContainer}>
                <text className={classes.subtitle}>Aún no hay respuestas</text>
              </div>
          }
        </>
      }
    </>
  )
}

export default Files;

const useStyle = makeStyles(theme => ({
  tableContainer: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.white.main,
    borderRadius: 30,
    width: '100%',
    boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
    padding: '20px',
    overflowX: 'auto',
    tableLayout: 'fixed',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  thead: {
    [theme.breakpoints.down('md')]: {
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.white.main,
    padding: 15,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
      borderBottom: `3px solid ${theme.palette.gray.main}`,
    },
  },
  th: {
    padding: '.35em',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      display: 'block',
      marginBottom: '.625em',
    },
  },
  tableRegister: {
    ...theme.typography.subtitle,
    padding: 10,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      '&::before': {
        display: 'flex',
        justifyContent: 'space-between',
        content: 'attr(data-label)',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '&:last-child': {
        borderBottom: '0'
      }
      ,
      '&:nth-child(5)': {
        borderBottom: '0'
      }
    },
    overflow: 'hidden',
  },
  halfContainer: {
    width: '50%',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0px 20px 0px 0px',
  },
  infoBold: {
    ...theme.typography.subtitle,
    fontWeight: '600',
    fontSize: '14px',
  },
  info: {
    ...theme.typography.subtitle,
    fontSize: '14px !important',
  },
  subtitle: {
    margin: '0 0 2% 0',
    fontSize: '20px',
    width: '80% ',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '20px',
    },
  },
  selectContainer: {
    backgroundColor: theme.palette.white.main,
    textColor: theme.palette.gray.main,
    width: '10%'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));