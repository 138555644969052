import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Background from '../../../assets/images/backgroundGrayyBottom.svg'
import ToggleMenu from '../../../components/toggleMenu';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';

const useStyle = makeStyles(theme => ({
    containerBackground: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        margin: 0,
        backgroundPosition: 'center',
        alignItems: 'center',
    },
    title: {
        fontSize: '62px',
        width: '50% ',
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            textAlign: 'center',
            fontSize: '40px',
        },
    },
    titleContainer: {
        width: '60%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '3%',
        [theme.breakpoints.down('md')]: {
            alignItems: 'start'
        },
    },
    card: {
        width: '90%',
        paddingBottom: '5%',
        [theme.breakpoints.down('md')]: {
            width: '50%',
            marginBottom: '30px',
            '&:last-child': {
                marginBottom: 0
            }
        },
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        },
    },
    titleAndCardsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        margin: '5%  0 10% 0',
    },
    profileDescriptionContainer: {
        padding: '2%',
        width: '50%',

        '&:last-child': {
            borderBottom: 'none'
        }
    },
    descriptionProfile: {
        textAlign: 'justify',
        color: theme.palette.darkGray.main,
        fontSize: 20
    },
    toggleContainer: {
        width: '60%',
    },
    tableContainer: {
        backgroundColor: theme.palette.white.main,
        borderRadius: 30,
        width: '60%',
        boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
        padding: '10px',
        overflowX: 'auto',
        margin: '0 auto',
        tableLayout: 'fixed',
        [theme.breakpoints.down('md')]: {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            borderCollapse: 'collapse',
        },
    },
    tableRegister: {
        ...theme.typography.subtitle,
        padding: 10,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          paddingRight: 20,
          borderBottom: `1px solid ${theme.palette.gray.main}`,
          display: 'block',
          fontSize: '.8em',
          textAlign: 'right',

            '&::before': {
                display:'flex',
                justifyContent: 'space-between',
                content: 'attr(data-label)',
                fontWeight: 'bold',
                textTransform: 'uppercase'
            },
            '&:last-child': {
                borderBottom: '0'
            }
            ,
            '&:nth-child(5)': {
                borderBottom: '0'
            }
        },
    },
    th: {
        padding: '.35em',
        [theme.breakpoints.down('md')]: {
            paddingRight: 20,
            display: 'block',
            marginBottom: '.625em',
        },
    },
    thead: {
        [theme.breakpoints.down('md')]: {
            height: 1,
            overflow: 'hidden',
            position: 'absolute',
        },
    },
    tableHeader: {
        backgroundColor: theme.palette.white.main,
        padding: 15,
        [theme.breakpoints.down('md')]: {
            display: 'block',
            marginBottom: 20, 
            borderBottom: `3px solid ${theme.palette.gray.main}`,
        },
    },
    hiddenColumns: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    }

}))

const SelectProfiles = () => {
    const classes = useStyle();
    const perPage = 10

    const profilesType = [  {label: 'Adopter', index: 1}, 
                            {label: 'Explorer', index: 2}, 
                            {label: 'Evolver', index: 3},
                        ]
    const [selectOpciont, setSelectOpciont] = useState(1)
    // const [pages, setPages] = useState(0);
    const [pages, setPages] = useState(Math.ceil(5 / perPage));

    const [selectedPage, setSelectedPage] = useState(1);

    const [profiles, setProfiles] = useState([])
    
    const changeOption = (index) => {
        setSelectOpciont(profilesType[index]);
    };

    const handlePagination = (event, value) => {
        setSelectedPage(value)
    }

    const profileInfo = {
        adopter : 
        [
            {
                state: 'Sonora',
                city: 'Hermosillo',
                zipCode: 83000,
                birthday: '03/12/1997',
                salary: 3450
            },
            {
                state: 'Sonora',
                city: 'Hermosillo',
                zipCode: 83000,
                birthday: '04/12/1997',
                salary: 3451
            },
            {
                state: 'Sonora',
                city: 'Hermosillo',
                zipCode: 83000,
                birthday: '05/12/1997',
                salary: 3452
            },
            {
                state: 'Sonora',
                city: 'Hermosillo',
                zipCode: 83000,
                birthday: '06/12/1997',
                salary: 3453
            },
            {
                state: 'Sonora',
                city: 'Hermosillo',
                zipCode: 83000,
                birthday: '07/12/1997',
                salary: 3454
            }
        ],
        explorer : 
        [
            {
                state: 'Sonora',
                city: 'Agua Prieta',
                zipCode: 83000,
                birthday: '03/12/1997',
                salary: 3455
            },
            {
                state: 'Sonora',
                city: 'Navojoa',
                zipCode: 83000,
                birthday: '04/12/1997',
                salary: 3456
            },
            {
                state: 'Sonora',
                city: 'Navojoa',
                zipCode: 83000,
                birthday: '05/12/1997',
                salary: 3457
            },
            {
                state: 'Sonora',
                city: 'Navojoa',
                zipCode: 83000,
                birthday: '06/12/1997',
                salary: 3458
            },
            {
                state: 'Sonora',
                city: 'Navojoa',
                zipCode: 83000,
                birthday: '07/12/1997',
                salary: 3459
            }
        ],
        evolver : 
        [
            {
                state: 'Sonora',
                city: 'Agua Prieta',
                zipCode: 83000,
                birthday: '03/12/1997',
                salary: 3460
            },
            {
                state: 'Sonora',
                city: 'Agua Prieta',
                zipCode: 83000,
                birthday: '04/12/1997',
                salary: 3461
            },
            {
                state: 'Sonora',
                city: 'Agua Prieta',
                zipCode: 83000,
                birthday: '05/12/1997',
                salary: 3462
            },
            {
                state: 'Sonora',
                city: 'Agua Prieta',
                zipCode: 83000,
                birthday: '06/12/1997',
                salary: 3463
            },
            {
                state: 'Sonora',
                city: 'Agua Prieta',
                zipCode: 83000,
                birthday: '07/12/1997',
                salary: 3464
            }
        ]
    }

    const table = (array) => {
        if(array.length > 0) {
          return(
                  array.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map((item,index) => (
                    <tr className = {classes.tableHeader}>
                        <td className={`${classes.tableRegister}`} data-label="Id de Usuario" >Usuario {index + 1}</td>
                        <td className={`${classes.tableRegister}`} data-label="Estado" >{item.state}</td>
                        <td className={`${classes.tableRegister} ${classes.hiddenColumns}`} data-label="Cuidad" >{item.city}</td>
                        <td className={`${classes.tableRegister} ${classes.hiddenColumns}`} data-label="Código Postal" >{item.zipCode}</td>
                        <td className={`${classes.tableRegister}`} data-label="Fecha de Nacimiento" >
                        {moment(item.birthday).format('DD/MM/YYYY')}
                        </td>
        
                        <td className={`${classes.tableRegister} ${classes.hiddenColumns}`} data-label="Salario">{item.salary.toFixed(2)}</td>
                    </tr>
                ))
            )
        }
        else{
          return (<td className={classes.noAnswer} colSpan={6}>Por el momento no hay respuestas del usuario</td>)
        }
      }

    return (
        <div className={classes.containerBackground}>
            <div className={classes.titleAndCardsContainer}>
                <div className={classes.titleContainer}>
                    <h1 className={classes.title}>
                        Perfiles Disponibles
                    </h1>
                </div>

                <div className = {classes.toggleContainer}>
                    <ToggleMenu 
                        elements = {profilesType}
                        handleClick = {changeOption}
                    />
                </div>

                <div className={classes.profileDescriptionContainer}>
                    <p className = {classes.descriptionProfile}>
                        {
                            selectOpciont.index === 1 ?
                                `Tiene y usa un smartphone para música, videos, chat. 
                                Principalmente modelos entry-line en modo pre-pago.`
                            :
                            selectOpciont.index === 2 ?
                                "Además usa plataformas digitales para compras, transporte. Tiene una app bancaria. Explora servicio post-pago en modelos mid-range."
                            :
                            "Ya es usuario de múltiples plataformas sociales, financieras y de compras. Usa sitios de noticias. Modelos high-end en post-pago"
                        }
                    </p>
                </div>

                <table className={classes.tableContainer}>
                    <thead className={classes.thead}>
                        <tr className = {classes.tableHeader}>
                            <th className={classes.th} >Id de Usuario</th>
                            <th className={classes.th} >Estado</th>
                            <th className={classes.th} >Cuidad</th>
                            <th className={classes.th} >Código Postal</th>
                            <th className={classes.th} >Fecha de Nacimiento</th>
                            <th className={classes.th} >Salario</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            selectOpciont.index === 1 ?
                            table(profileInfo.adopter)
                        :
                            selectOpciont.index === 2 ?
                            table(profileInfo.explorer)
                        :
                            table(profileInfo.evolver)
                        }
                    </tbody>
                </table>

                <div>
                    <Pagination count={pages} shape="rounded" onChange={handlePagination} />
                </div>
            </div>
        </div>
    )
};

export default SelectProfiles;
