import { API } from 'aws-amplify';
import { API_ANALYTICS } from '../constant';

const saveQuestionnaire = async (body) => {
  try {
    const result = await API.post(API_ANALYTICS, '/intervals', {
        body
    });
    return {
      success: true,
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default saveQuestionnaire;