import { useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { ORDERS_FILTERS, ORDERS_STATUS } from '../../lib/constant';

const OrdersFilters = (props) => {
  const [formInputs, setFormInputs] = useState({
    from: '',
    to: '',
    status: 'ALL',
  });
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormInputs({ ...formInputs, [name]: value });
  };
  
  const applyFilters = () => {
    const { from, to, status } = formInputs;
    const filteredOrders = props.orders.filter((order) => {
      const orderDate = new Date(order.createdAt);
      const fromDate = from ? new Date(from) : null;
      const toDate = to ? new Date(to) : null;
  
      return (
        (status === 'ALL' || order.status === status) &&
        (!fromDate || orderDate >= fromDate) &&
        (!toDate || orderDate <= toDate)
      );
    });
  
    props.setOrders(filteredOrders);
  };
  
  useEffect(applyFilters, [formInputs, props.orders]);

  return (
    <Grid container display="flex" justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item xs={6}>
          <TextField
            id={'from'}
            label={'Desde'}
            variant="standard"
            fullWidth
            onChange={handleChange}
            value={formInputs.from}
            name={'from'}
            type={'date'}
            sx={{ paddingBottom: '20px' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id={'to'}
            label={'Hasta'}
            variant="standard"
            fullWidth
            onChange={handleChange}
            value={formInputs.to}
            name={'to'}
            type={'date'}
            sx={{ paddingBottom: '20px' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} md={6}>
        <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
          <InputLabel id="status">Status</InputLabel>
          <Select
            labelId="status"
            id="status"
            value={formInputs.status}
            label="Status"
            name="status"
            onChange={handleChange}
          >
            {/* <MenuItem value="ALL"></MenuItem> */}
            {ORDERS_FILTERS.map((status, index) => (
              <MenuItem key={index} value={status}>
                {ORDERS_STATUS[status]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default OrdersFilters;
