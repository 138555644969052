import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme =>({
    containerServices : {
        backgroundColor: theme.palette.white.main,
        boxShadow: '0px 0px 20px 0px rgb(0 0 0 / 20%)',
        width: '100%',
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
    },
    titleCards: {
        fontSize: '2.4rem',
        fontWeight: 'bold',
        marginTop: '-2.5rem'
    },
    imgIcons: {
        width: '6.25rem',
        position: 'relative',
        bottom: '3.125rem'
    },
    descriptionCard :{
        fontSize: '1.5rem',
        color: theme.palette.darkGray.main,
        textAlign: 'justify',
    },
    buttonService1: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '-55px',
        width: '60%',
    },
    textCard:{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    imgDescriptionContainer:{
        padding: '0 30px 45px 30px',
    }
}))

const CardDescription = (props) => {
    const classes = useStyle(props);
  return (
    <div className={classes.containerServices}>
            <div className={classes.textCard}>
                <hr style={{borderTop: '0.938rem solid #E5E5E5'}}/>
                <div className={classes.imgDescriptionContainer}>
                    <img src = {props.img} className = {classes.imgIcons} alt = "icono de empresa" />
                    <h2 className={classes.titleCards}>{props.title}</h2>

                        <p className={classes.descriptionCard}>
                            {props.description}​​
                        </p>
                </div>
            </div>
    </div>
  )
}

export default CardDescription