import { API } from 'aws-amplify';
import { API_QUESTIONS } from '../constant';

const editCategory = async (categoryId,category) => {
  try {
    await API.put(API_QUESTIONS, `/category/${categoryId}`,{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      },
      body: {
        categoryName: category
      }
    });
    return {
      success: true,
      message: 'Actualización exitosa'
    }
  } catch (err) {

    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
}

export default editCategory;